import {
  SET_CHECKOUT_DATA,
  SET_REFERENCE,
  SET_CURRENT_CARD_INFO,
  SET_SEARCH_QUERY_KEY,
  SET_CHECKED_PAYMENT,
  SET_GIFTCARD_PAYMENT_FROM
} from './ActionTypes'

export const setCheckoutData = (payload) => {
  return {
    type: SET_CHECKOUT_DATA,
    payload
  }
}

export const setReference = (payload) => {
  sessionStorage.setItem('reference', payload)
  return {
    type: SET_REFERENCE,
    payload
  }
}

export const setCurrentCardInfo = (payload) => {
  localStorage.setItem('currentCardInfo', JSON.stringify(payload))
  return {
    type: SET_CURRENT_CARD_INFO,
    payload
  }
}

export const setSearchQueryKey = (payload) => {
  sessionStorage.setItem('search_query_key', payload)
  return {
    type: SET_SEARCH_QUERY_KEY,
    payload
  }
}

export const setCheckedPayment = (payload) => {
  sessionStorage.setItem('checkedPayment', payload)
  return {
    type: SET_CHECKED_PAYMENT,
    payload
  }
}

export const setGiftCardPaymentFrom = (payload) => {
  sessionStorage.setItem('giftCardPaymentFrom', JSON.stringify(payload))
  return {
    type: SET_GIFTCARD_PAYMENT_FROM,
    payload
  }
}





