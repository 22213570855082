import React, { Component } from 'react'
import { Toast, Mask } from 'antd-mobile'
import idBackBG from "@assets/images/certificate/pic_idback.png"
import camera from "@assets/images/activation/icon_paishe_white.png"

import './index.less'
import Activationsamples from '@comps/Activationsamples'
import { transformImageFile, scrollToTop } from "@utils/utils"
import { t } from 'i18next'
import Header from '@comps/nav/Header'
import KYCHeader from '@pages/kyc-auth/non-ssn/components/Header'

import StepFooter from '@pages/Home/Steps/StepFooter'
import { uploadNonSSN } from '@api/netki'

export default class UploadAddressImages extends Component {
  state = {
    bool: true, //控制多次点击
    value: '',
    isBtnDisabled: true,
    isSendCode: true,
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: true },
    ],
    picture: "",
    pictured: "",
    isHas: true,
    isFalse: true,
    uploading: false,
    visible: true,
    from: ''
  }

  componentDidMount() {
    if (this.props.history.location.search.includes('nonssn')) {
      this.setState({
        from: 'nonssn'
      })
    } else {
      this.setState({
        from: ''
      })
    }
    scrollToTop()
  }

  chuanOne = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tps").src = reader?.result
      }
      this.setState({
        pictured: e.target?.files[0],
        isHas: false
      })
      transformImageFile(e.target?.files[0], this.afterCompressOne);
    } else {
      this.setState({
        isHas: true
      })
    }
  }

  afterCompressOne = (file) => {
    this.setState({
      pictured: file,
      isHas: false
    });
  }

  chuan = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tpp").src = reader?.result
      }
      this.setState({
        picture: e.target.files[0],
        isFalse: false
      })
      transformImageFile(e.target?.files[0], this.afterCompress);
    } else {
      this.setState({
        isFalse: true
      })
    }
  }

  afterCompress = (file) => {
    this.setState({
      picture: file,
      isHas: false
    });
  }

  authentication = async () => {
    const { picture, pictured, uploading } = this.state

    if (!pictured || !picture || uploading) { return false; }

    var formdata = new FormData();
    formdata.append('kycFile', picture)
    formdata.append('fileType', 'billing')
    this.setState({
      uploading: true
    })
    const res = await uploadNonSSN(formdata)
    if (res?.status_code === 100000) {
      formdata.set('kycFile', pictured)
      const res1 = await uploadNonSSN(formdata)
      this.setState({
        uploading: false
      })
      if (res1.status_code === 100000) {
        this.props.history.push('/bill-address-edit');
      }
      this.setState({
        uploading: false
      })
    } else {
      Toast.show({
        content: '请重新上传',
        duration: 4000,
      })
      this.setState({
        uploading: false
      })
    }
  }

  render() {
    const { isHas, isFalse, picture, pictured, visible, uploading, from } = this.state
    return (
      <div className="stepSeven" style={{ minHeight: "760px" }}>
        <Header />
        <KYCHeader title={t('KYC.nonssn.address.title')} subTitle={t('KYC.nonssn.address.subTitle')} />
        <div className='document-type textAlignLeft'>
          <p className='img-title'>{t('KYC.nonssn.address.imgTitle1')} </p>
          <div className="upload-image">
            <img className="holder-image" src={idBackBG} style={!isHas ? { opacity: "0" } : {}} alt="" />
            <input type="file" accept="image/*" onChange={this.chuanOne} style={{
              position: "absolute", top: "22%",
              left: "37%",
              width: "26%",
              height: "50%", opacity: "0"
            }} />
            <div className="tip-text">
              <p>{t('KYC.nonssn.address.upload')}</p>
              <p onClick={() => { this.setState({ visible: true }) }}>
                {t('steps.IDCheckExample')}
              </p>
            </div>
            {!isHas &&
              <>
                <img src="" alt="" className='tps' />
                <div className="bottom-btn">
                  <input type="file" className='files' accept="image/*" onChange={this.chuanOne} />
                  <div className="action" onClick={() => { document.querySelector('.files').click() }}>
                    <img src={camera} alt="" />
                    {t('steps.IDCheckClickToReUpload')}
                  </div>
                </div>
              </>}
          </div>
          <p className='img-title'>{t('KYC.nonssn.address.imgTitle2')} </p>
          <div className="upload-image">
            <img className="holder-image" src={idBackBG} style={!isFalse ? { opacity: "0" } : {}} alt="" />
            <input className='int' type="file" accept="image/*" onChange={this.chuan} style={{
              position: "absolute", top: "22%",
              left: "37%",
              width: "26%",
              height: "50%", opacity: "0"
            }} />
            <div className="tip-text">
              <p>{t('KYC.nonssn.address.upload')}</p>
              <p onClick={() => { this.setState({ visible: true }) }}>
                {t('steps.IDCheckExample')}
              </p>
            </div>
            {!isFalse &&
              <>
                <img src="" alt="" className='tpp' />
                <div className="bottom-btn">
                  <input type="file" className='filed' accept="image/*" onChange={this.chuan} />
                  <div className="action" onClick={() => { document.querySelector('.filed').click() }}>
                    <img src={camera} alt="" />
                    {t('steps.IDCheckClickToReUpload')}
                  </div>
                </div>
              </>}
          </div>

        </div>
        <StepFooter
          notice={t('steps.IDCheckPhotoHint2')}
          isLoading={uploading}
          disabled={!((picture && pictured) || uploading)}
          btnSubmit={this.authentication}
          btnText={t('steps.nextButton')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
        {visible ?
          <Mask
            visible={visible}
            onMaskClick={() => {
              this.setState({
                visible: false
              })
            }}
          >
            <Activationsamples address={"address"} from={from}></Activationsamples>
          </Mask>
          : null}
      </div>
    )
  }
}

