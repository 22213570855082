import React, { Component } from 'react'
import moneyImg from '@assets/images/tabbar/active-price.png'
import MyHeader from '@comps/MyHeader'
import { DownFill } from 'antd-mobile-icons'
import { Image, Tabs } from 'antd-mobile'
import './index.less'
import { accountTransactionsList } from '@api/account'
const HeadWarp = (props) => {
  return (
    <>
      <div className='myheader'>
        <MyHeader titleShow={true} title='我的账户明细' searchShow={true} />
      </div>
      <div className="head-tap-warp">
        <Tabs onChange={(key) => {
          props.chooseTab(key);
        }}>
          <Tabs.TabPane title='全部' key='1'>
          </Tabs.TabPane>
          <Tabs.TabPane title='充值' key='2'>
          </Tabs.TabPane>
          <Tabs.TabPane title='提现' key='3'>
          </Tabs.TabPane>
          <Tabs.TabPane title='转账' key='4'>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}

const MonthWarp = (props) => {
  console.log(props)
  return (
    <>
      <div className='month-warp'>
        <div className="month-t">
          <h2>9</h2> <p>月</p>
          <DownFill fontSize={12} className='down-i' />
          {/* <DownFill fontSize={12} className='down-i down-ib'/> */}
        </div>
        <div className="money-t">
          <p>支出  <span>${props.expenses}</span></p>    <p> 收入 <span>${props.income}</span></p>
        </div>
      </div>
    </>
  )
}

const AccountInfoList = (props) => {
  return (
      <div className="info-list-warp" >
        { props.transactionsList.map((item, index) => {
          return (
            <div className="info-list" key={index}>
              <div className="account-lf">
                <div className="icon-a">
                  <Image src={moneyImg} width={20} height={20} fit='fill  ' />
                </div>
                <div className="account-title">
                  <div className="title-des">
                    {item.description}
                 </div>
                  <div className="times">
                    {item.transDate}
                   </div>
                </div>
              </div>

              <div className="account-rt">
                <div className="ac-t">
                  <p className="je1">
                  $ {(item.fee || 0.00).toFixed(2)}
                </p>
                  {/* <div className="jm mark" >
                    减免
                </div> */}
                  {/* <div className="wrz mark">
                  未入账
                </div>
                <p className="je2">
                - $0.50
                </p> */}
                </div>
                <div className="ac-b">
                  余额：$ {item.balance}
                 </div>
              </div>
            </div>       
          )
        }) }
      </div>   
  )
}

class TransAccountInfoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses:'',//支出
      income:'', //收入
      transactionsList: [],
      keyword: '1381234',
      noUsers: false,
      startDate: '2020-03',
      endDate: '2020-05',
      pageNum: 1,
      pageSize: 10,
      bool: true,
      transType: 'TRANSFER' //充值
      // transType:'RECHARGE' 提现
      // transType:'WITHDRAW' 全部
    }
  }


  componentWillMount() {
    this.getAccountTransactions()
  }
  handleTransferAccount = () => { }

  handleFreezeAccount = () => {
    this.props.history.push('/freezeAccountB')
  }

  chooseTab = (e) => {
    console.log(e, 'e')
  }

  // 获取交易明细，账单列表
  getAccountTransactions = async () => {
    const { bool, transType, startDate, endDate, pageNum, pageSize } = this.state;

    if (bool) {   //如果为true 开始执行     
      this.setState({ bool: false })   //将isRepeatClick变成false，将不会执行处理事件
      // 在此处编写点击事件执行的代码      
      const res = await accountTransactionsList({
        transType: transType, startDate: startDate, endDate: endDate, pageNum: pageNum, pageSize: pageSize
      })
      console.log(bool)
      if (res.statusCode === '200') {
        this.setState({
          expenses:res.data.expenses,
          income:res.data.income,
          transactionsList: res.data.transactions
        })
        this.setState({ bool: false })   //将isRepeatClick变成false，将不会执行处理事件
        console.log('1111')
      }
      this.setState({ bool: true })   // 将isRepeatClick设置为true 

    }
  } 


  onRef = (parms) => {
    // parms子组件 传递过来的参数
    console.log(parms)
  }

  render() {
    // 没有常用的用户 NoUser =true;
    return (
      <div>
      
        <section className="AccountInfoList d-flex flex-column align-items-center">
          <HeadWarp chooseTab={this.chooseTab} />
         
          <MonthWarp  expenses={this.state.expenses} income={this.state.income}/>
          {this.state.transactionsList.length > 0 ? <AccountInfoList transactionsList={this.state.transactionsList} /> : ''}
        </section>
      </div>
    )
  }

}

export default TransAccountInfoList
