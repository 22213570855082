import React from 'react'
import { Button } from 'antd-mobile'
import { InformationCircleOutline } from 'antd-mobile-icons'
import './index.less'

export default function KYCFooter(props) {
    const { notice, disabled, btnSubmit, btnText, licenseInfoBottom, isLoading } = props
    
    return (
        <div id="kyc-footer">
            {notice && <p className="notice">
              <InformationCircleOutline className="icon" fontSize={14} color="#F9941E"/>
              <span dangerouslySetInnerHTML={{__html: notice}}/>
            </p>}
            <Button 
              block
              className="next-button"
              shape="rounded"
              color="danger"
              disabled={disabled}
              onClick={btnSubmit}
              loading={isLoading}
            >
              {btnText}
            </Button>
            <p>
              {licenseInfoBottom}
            </p>
        </div>
    )
}