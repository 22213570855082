import './index.less'
import React, { useState, useEffect } from 'react'
import { Radio, NavBar, Space, Button, Mask, InfiniteScroll, Toast } from 'antd-mobile'
import { DeleteOutline, ExclamationCircleFill, CloseCircleOutline } from 'antd-mobile-icons'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import acBank from '@assets/images/associatedBank/bank.png'
import { useHistory } from 'react-router-dom'
import { getBindRequestList, deleteBindRequest } from '@api/account'
import { setCurrentBankInfo, setBankList as setStoreBankList } from '@actions/account'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

const DeleteBankPop = (props) => {
    const {
        content, btnTxt, bank, deletePopVisible,
        setDeletePopVisible, bankListLength,
        getBindList
    } = props
    const history = useHistory()

    const deletedBank = async (bindRequestId) => {
        const res = await deleteBindRequest({
            bindRequestId
        })
        if (res.status_code === 100000) {
            if (bankListLength === 1) {
                Toast.show({
                    content: t('addBank.delete_pop.deleted_successfully'),
                    duration: 3000,
                })
                history.push('/associatedBank')
            } else {
                Toast.show({
                    content: t('addBank.delete_pop.deleted_successfully_multiple'),
                    duration: 3000,
                })
            }
            getBindList(1)
            setDeletePopVisible(false)
        } else {
            Toast.show({
                content: t('common.serviceException'),
                duration: 3000,
            })
        }
    }

    return (
        <Mask visible={deletePopVisible}>
            <div id="delete-bank-pop">
                <CloseCircleOutline className="close" fontSize={32} color="#FFFFFF" onClick={() =>{
                    setDeletePopVisible(false)
                } }/>
                <ExclamationCircleFill fontSize={60} color="#FF3841"/>
                <div className="content"><Trans i18nKey={content}/></div>
                <div className="bank-info">
                    <img src={bank?.logo || acBank} alt="" />
                    <span>{bank?.account_nickname}</span>
                </div>
                <Button onClick={() => deletedBank(bank.bind_request_id)}>{btnTxt}</Button>
            </div>
        </Mask>
        
    )
}

export default function ChooseBank() {
    const [checkId, setCheckId] = useState(0)
    const [deletePopVisible, setDeletePopVisible] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const [hasMore, setHasMore] = useState(false)
    const [bankList, setBankList] = useState([])
    const [currentBank, setCurrentBank] = useState({})
    const [pagination, setPagination] = useState({})

    const getBindList = async (page) => {
        try {
            const res = await getBindRequestList({ page })
            if (res.status_code === 100000) {
                let currentBankList = page === 1 ? [] : bankList
                const { list, pagination } = res.response_data
                page === 1 && dispatch(setStoreBankList(list))
                currentBankList = currentBankList.concat(list)
                setBankList(currentBankList)
                setPagination(pagination)
                const { current_page, page_size, total } = pagination
                setHasMore(current_page * page_size < total)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const initData = async () => {
        await getBindList(1)
    }

    useEffect(() => {
        initData()
    }, [])

    const loadMore = async () => {
        const { current_page } = pagination
        await getBindList(current_page + 1)
    }

    const toVerify = () => {
        dispatch(setCurrentBankInfo(bankList[checkId]))
        history.push('/addBankVerify')
    }

    return (
        <div id="choose-bank">
            <NavBar className="nav" onBack={() => history.goBack()}>
                <Trans i18nKey={t(`chooseBank.pop.title`)}/>
            </NavBar>
            <Radio.Group defaultValue={0} onChange={setCheckId}>
                <Space direction='vertical' className="banks-space">
                    {
                        bankList.map((item, index) => 
                        <div className="bank-option" key={index}>
                            <Radio value={index} style={{
                                '--icon-size': '18px',
                                '--adm-color-primary': '#FF3841'
                            }}/>
                            <div className="bank-info">
                                <img src={item.logo || acBank} alt="" />
                                <div className="bank-detail">
                                    <div className="name">{item.account_nickname}</div>
                                    <div className="datetime">{dayjs(item.submitted_at).format('YYYY-MM-DD HH:mm')}</div>
                                </div>
                                <DeleteOutline fontSize={16} color="#383838" onClick={() => {
                                    setCurrentBank(item)
                                    setDeletePopVisible(true)
                                }}/>
                            </div>
                        </div>)
                    }
                </Space>
            </Radio.Group>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            <div className="bottom-btn">
                <Button onClick={toVerify}>{t('chooseBank.pop.btn')}</Button>
            </div>
            <Mask visible={deletePopVisible}>
                <DeleteBankPop
                    deletePopVisible={deletePopVisible}
                    setDeletePopVisible={setDeletePopVisible}
                    content={t('addBank.delete_pop.content')}
                    btnTxt={t('addBank.delete_pop.btn_txt')} 
                    bank={currentBank}
                    bankListLength={bankList.length}
                    getBindList={getBindList}
                />
            </Mask>
        </div>
    )
}