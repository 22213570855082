import React from 'react'
import './index.less'
import commonPopIcon from '@assets/images/common/notice.png'
import serviceQrcode from '@assets/images/common/qrcode_service.png'

import { useTranslation } from 'react-i18next'
import { CloseCircleOutline } from 'antd-mobile-icons'

export default function FreezePasswordPop(props) {
  const { t } = useTranslation()

  return (
    <div id='freeze-pop-container'>
        <CloseCircleOutline className="close" color='#fff' fontSize={36} onClick={props.closeFreezePop}/>
        <div className='common-pop'>
          <img className='common-icon' src={commonPopIcon} alt=''></img>
          <div className='common-content'>
            <div className='common-header-title' dangerouslySetInnerHTML={{__html: t('common_pop.freeze_pop.title')}} />
            <div className='common-header-subtitle' dangerouslySetInnerHTML={{__html: t('common_pop.no_permission_pop.sub')}} />
            <img className="qrcode" src={serviceQrcode} alt=''/>
          </div>
        </div>
    </div>
  )
}
