import request from "@utils/request";

// 更新ssn
export const updateSsn = (data) => {
  return request({
    method: "POST",
    url: `user/update-ssn`,
    data
  }); 
};

// 获取所有国家信息列表
export const getCountryList = (data) => {
  return request({
    method: "POST",
    url: `info/get-country-list`,
    data
  });
};

export const getLoadingAddress = (params) => {
  const { type, query } = params
  return request({
    method: 'GET',
    url: `/app/static/loading-address/${type}/${query}`,
  })
}

export const getLinkageAddress = (params) => {
  const { type, query } = params
  return request({
    method: 'GET',
    url: `/app/static/get-linkage-address/${type}/${query}`,
  })
}

export const getSearchAddress = (params) => {
  const { type, query } = params
  return request({
    method: 'GET',
    url: `/app/static/search-address/${type}/${query}`,
  })
}

export const startActivationProcess = () => {
  return request({
    method: "GET",
    url: `/app/user/get-identity-verification-request`
  });
};

export const updateActivationAddress = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/submit-address-in-identity-verification-request`,
    data
  });
};

export const sendEmailCode = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/send-email-verification-in-identity-verification-request`,
    data
  });
};

export const verifyEmailCode = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/verify-email-in-identity-verification-request`,
    data
  });
};

export const updateIdentity = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/update-ssn-in-identity-verification-request`,
    data
  });
};