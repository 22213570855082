import React, { useState, useEffect, useRef } from 'react'
import './index.less'
import {   Input } from 'antd-mobile'
import { UpOutline, CloseCircleFill, DownOutline } from 'antd-mobile-icons'
import requireImg from '@assets/images/home/require.png'
import { getSearchAddress } from '@api/active'
import { t } from 'i18next'

export default function AddressForm(props) {
    const [isSpecificAddressFocus, setIsSpecificAddressFocus] = useState(false)
    const [isCityFocus, setIsCityFocus] = useState(false)
    const [isStateFocus, setIsStateFocus] = useState(false)
    const [isPostcodeFocus, setIsPostcodeFocus] = useState(false)

    const [isCityDropShow, setIsCityDropShow] = useState(false)
    const [isStateDropShow, setIsStateDropShow] = useState(false)
    const [isZipDropShow, setIsZipDropShow] = useState(false)

    const [cities, setCities] = useState([])
    const [states, setStates] = useState([])
    const [zips, setZips] = useState([])
    const timer = useRef(undefined)
    const [isNotInit, setIsNotInit] = useState(false)

    const {specificAddress, setSpecificAddress, city, setCity, state, setState, postcode, setPostcode} = props

    const getSearchAddressByType = async ({type, query}) => {
        const res  = await getSearchAddress({
            type,
            query
        })
        if (res.status_code === 100000) {
            const { list } = res.response_data
            switch (type) {
                case 'city':
                    setCities(list)
                    break;
                case 'state':
                    setStates(list)
                    break;
                case 'zip':
                    setZips(list)
                    break;
                default: 
                    break;
            }
        }
    }

    const clickEvent = (e) => {
        const className = e.target.className
        if (typeof className === 'string' && !(className.includes('drop-icon') || className.includes('search-item'))) {
            setIsCityDropShow(false)
            setIsStateDropShow(false)
            setIsZipDropShow(false)
            window.removeEventListener('click', clickEvent, true)
        }
    }

    useEffect(() => {
        if (isCityDropShow || isStateDropShow || isZipDropShow) {
          window.addEventListener('click', clickEvent, true)
        } else {
          window.removeEventListener('click', clickEvent, true)
        }
    }, [isCityDropShow, isStateDropShow, isZipDropShow])

    useEffect(() => {
        return () => {
            window.removeEventListener('click', clickEvent, true)
            if (timer) clearTimeout(timer.current)
        }
    }, [])

    useEffect(() => {
        if(isNotInit) {
            if (timer) {
                clearTimeout(timer.current)
                timer.current = null;
            }

            if(!city) {
                return
            }

            timer.current = setTimeout(() => {
                getSearchAddressByType({
                    type: 'city',
                    query: city
                })
            }, 800)
        }
    }, [city])

    useEffect(() => {
        if (isNotInit) {
            if (timer) {
                clearTimeout(timer.current)
                timer.current = null;
            }

            if(!state) {
                return
            }
            timer.current = setTimeout(() => {
                getSearchAddressByType({
                    type: 'state',
                    query: state
                })
            }, 500)
        }
    }, [state])

    useEffect(() => {
        if (isNotInit) {
            if (timer) {
                clearTimeout(timer.current)
                timer.current = null;
            }

            if(!postcode) {
                return
            }
            timer.current = setTimeout(() => {
                getSearchAddressByType({
                    type: 'zip',
                    query: postcode
                })
            }, 500)
        }
    }, [postcode])

    return (
        <div className="address-form">
            
            <div className="input-container">
                <Input
                    className={`address-input ${isSpecificAddressFocus ? "active": ""}`}
                    placeholder={t('steps.AddressPlaceholderStreet')}
                    value={specificAddress}
                    minLength={4}
                    maxLength={40}
                    onFocus={() => setIsSpecificAddressFocus(true)}
                    onBlur={() => setIsSpecificAddressFocus(false)}
                    onChange={(val) => {
                        val = val.replace(/[\u4e00-\u9fa5]/ig, '')
                        if(val.length > 40) {
                            val = val.substr(0, 40)
                        }
                        setSpecificAddress(val)
                    }}
                />
                <img src={requireImg} alt="" />
            </div>

            <div className="input-container">
                <div className="input-inner-container">
                    <Input
                        className={`address-input ${isCityFocus ? "active": ""}`}
                        placeholder={t('steps.AddressPlaceholderCity')}
                        value={city}
                        onFocus={(e) => {
                            setIsCityFocus(true)
                        }}
                        onChange={(val) => {
                            if (val !== '') {
                                val = val.replace(/[\u4e00-\u9fa5]/ig, '')
                                val = val[0].toUpperCase() + val.slice(1).toLowerCase()
                                if(val.length > 30) {
                                    val = val.substr(0, 30)
                                }
                                setIsCityDropShow(true)
                                setCity(val)
                                setIsNotInit(true)
                            } else {
                                setIsCityDropShow(false)
                                setCity('')
                            }
                        }}
                        onBlur={() => {
                            setIsCityFocus(false)
                        }}
                    />
                    {city && <CloseCircleFill className="clear-icon" color="#E1E1E1" fontSize={20} onClick={() => {
                        setIsCityDropShow(false)
                        setCity('')
                    }}/>}
                    {cities.length > 0 && isCityDropShow && <UpOutline className="down-icon drop-icon" fontSize={20} color="#BBBBBB" onClick={() => {
                            setIsCityDropShow(false)
                        }}/>}
                    {cities.length > 0 && !isCityDropShow &&
                        <DownOutline className="down-icon" fontSize={20} color="#BBBBBB" onClick={() => {
                            setIsCityDropShow(true)
                            setIsStateDropShow(false)
                            setIsZipDropShow(false)
                        }}/>
                    }
                </div>
                {isCityDropShow && 
                    <div className='search'>
                        <ul className='search-ul'>
                            {cities.map((value, index) => <li className='search-item' key={index} onClick={(e) => {
                                setIsCityDropShow(false)
                                setCity(e.target.innerHTML)
                            }}>{value}</li>)}
                        </ul>
                    </div>
                }
                <img src={requireImg} alt="" />
            </div>

            <div className="input-container">
                <div className="input-inner-container">
                    <Input
                        className={`address-input ${isStateFocus ? "active": ""}`}
                        placeholder={t('steps.AddressPlaceholderState')}
                        value={state}
                        onFocus={() => {
                            setIsStateFocus(true)
                        }}
                        onChange={(val) => {
                            if (val !== '') {
                                val = val.replace(/[\u4e00-\u9fa5]/ig, '')
                                val = val.toUpperCase()
                                if(val.length > 2) {
                                    val = val.substr(0, 2)
                                }
                                setIsStateDropShow(true)
                                setState(val)
                                setIsNotInit(true)
                            } else {
                                setState('')
                                setIsStateDropShow(false)
                            }
                        }}
                        onBlur={() => {
                            setIsStateFocus(false)
                        }}
                    />
                    {state && <CloseCircleFill className="clear-icon" color="#E1E1E1" fontSize={20} onClick={() => {
                        setState('')
                        setIsStateDropShow(false)
                    }}/>}
                    {states.length > 0 && !isStateDropShow && <DownOutline className="down-icon" fontSize={20} color="#BBBBBB" onClick={() => {
                        setIsCityDropShow(false)
                        setIsStateDropShow(true)
                        setIsZipDropShow(false)
                    }}/>}
                    {states.length > 0 && isStateDropShow && <UpOutline className="down-icon drop-icon" fontSize={20} color="#BBBBBB" onClick={() => setIsStateDropShow(false)}/>}
                </div>
                
                {isStateDropShow && <div className='search'>
                    <ul className='search-ul'>
                    {
                        states.map((value, index) => (
                        <li 
                            className='search-item'
                            key={index}
                            onClick={e => {
                                setState(e.target.innerHTML)
                                setIsStateDropShow(false)
                            }}
                        >{value}</li>
                        ))
                    }
                    </ul>
                </div>}
            
                <img src={requireImg} alt="" />
            </div>

            <div className="input-container">
                <div className="input-inner-container">
                    <Input
                        className={["address-input", isPostcodeFocus ? "active": ""].join(' ')}
                        placeholder={t('steps.AddressPlaceholderZip')}
                        value={postcode}
                        maxLength={5}
                        onFocus={() => {
                            setIsPostcodeFocus(true)
                        }}
                        onBlur={() => {
                            setIsPostcodeFocus(false)
                        }}
                        onChange={(val) => {
                            val = val.replace(/[\u4e00-\u9fa5]/ig, '')
                            val = val.replace(/[^\d]/g, '')
                            if(val.length > 5) {
                                val = val.substr(0, 5)
                            }
                            if (val !== '') {
                                setPostcode(val)
                                setIsZipDropShow(true)
                                setIsNotInit(true)
                            } else {
                                setPostcode('')
                                setIsZipDropShow(false)
                            }
                        }}
                    />
                    {postcode && <CloseCircleFill className="clear-icon" color="#E1E1E1" fontSize={20} onClick={() => {
                        setPostcode('')
                        setIsZipDropShow(false)
                    }}/>}
                    {zips.length > 0 && !isZipDropShow && <DownOutline className="down-icon" fontSize={20} color="#BBBBBB" onClick={() => {
                        setIsCityDropShow(false)
                        setIsStateDropShow(false)
                        setIsZipDropShow(true)
                    }}/>}
                    {zips.length > 0 && isZipDropShow && <UpOutline className="down-icon drop-icon" fontSize={20} color="#BBBBBB" onClick={() => setIsZipDropShow(false)}/>}
                </div>
                {isZipDropShow && <div className='search'>
                    <ul className='search-ul'>
                    {
                        zips.map((value, index) => 
                        <li 
                            className='search-item'
                            key={index}
                            onClick={e => {
                                setPostcode(e.target.innerHTML)
                                setIsZipDropShow(false)
                                // getLinkageAddressByType({
                                //     type: 'zip',
                                //     query: code
                                // })
                            }}
                        >{value}</li>
                        )
                    }
                    </ul>
                </div>}
                <img src={requireImg} alt="" />
            </div>
        </div>
    )
}