import React, { useEffect, useRef, useState, useCallback } from "react"
import { Popup, Dialog, Toast, Modal, Mask, Divider, List, Button } from "antd-mobile"
import MyHeader from "@comps/MyHeader"
import HomeVisaCardDialog from "@comps/HomeVisaCardDialog"
import MyTabbar from "@comps/MyTabbar"
import packUpImg from "@assets/images/home/packUp.png"
import packDownImg from "@assets/images/home/packDown.png"
import walletImg from "@assets/images/home/icon_wallet.png"
import giftcardImg from "@assets/images/home/icon_giftcard.png"
import giftImg from "@assets/images/home/icon_gift.png"
import rmbImg from "@assets/images/home/icon_rmb.png"
import rmbPayImg from "@assets/images/home/Spendmoney.png"
import { useHistory, useLocation } from "react-router-dom"

import {
  getBalance,
  updateLanguage,
  getUserInfo,
  getPermissionByCode,
  getUserBehavior,
  submitUserBehavior,
  syncReferralCode,
} from "@api/user"
import { handleDot, scrollToTop } from "@utils/utils"
import { formatNumberToUSD } from "@utils"
import "./index.less"
import { useDispatch, useSelector } from "react-redux"
import { switchSelectedLang, setIsFirstVisit } from "@actions/user"
import { setReferralCodeFrom } from "@actions/savingAccount"
import { setPasswordRelatedChannel, setActionToken } from "@actions/password"
import { EyeInvisibleOutline, RightOutline, EyeFill, QuestionCircleOutline } from "antd-mobile-icons"
import { setActiveFromMsg, setHomeEyeStatus } from "@actions/common"
import ReactLoading from "react-loading"
import { t } from "i18next"
import { getModuleStatus } from "@api/common"
import loadable from "@loadable/component"

// const GuideTip = loadable(() => import("./GuideTip"))
const Tip = loadable(() => import("@comps/Tip"))
const VerifyTranPwd = loadable(() => import("@pages/Home/VerifyTranPwd"))
const ExceptionPop = loadable(() => import("@comps/ExceptionPop"))
const FreezePasswordPop = loadable(() => import("@comps/FreezePasswordPop"))
const StopTakingNotice = loadable(() => import("@comps/StopTakingNotice"))
const CommonPop = loadable(() => import("@comps/CommonPop"))
const SharePop = loadable(() => import("@comps/SharePop"))
const GuideTutorial = loadable(() => import("@comps/Home/GuideTutorial"))
const TillstarInfo = loadable(() => import("@comps/Home/TillstarInfo"))
const AccountQuestion = loadable(() => import("@comps/Home/AccountQuestion"))
const EditTillstarID = loadable(() => import("@comps/EditTillstarID"))
const CommonTipPop = loadable(() => import("@comps/CommonTipPop"))

class MoreErroPassword extends React.Component {
  remove = () => {
    this.props.freeze()
  }
  recharge = () => {
    this.props.geTian()
  }
  render() {
    return (
      <>
        <div id="no-balance-popup" className="mask-center">
          <div className="mask-top">
            <p>{t("home.noBalancePopupTitle")}</p>
            <p>{t("home.noBalancePopupTitle1")}</p>
          </div>
          <div className="mask-bootom" style={{ marginTop: "0.7rem", display: "flex" }}>
            <span className="mask-left" onClick={this.remove}>
              {t("home.noBalancePopupContinue")}
            </span>
            <span className="mask-right" onClick={this.recharge}>
              {t("home.noBalancePopupDeposit")}
            </span>
          </div>
        </div>
      </>
    )
  }
}

const CommonListItem = (props) => {
  const { img, title, desc, extra, clickTo, clickFun, cashbackAccount } = props
  const history = useHistory()

  const goPage = () => {
    if (clickFun) {
      clickFun()
      return
    }

    clickTo && history.push(clickTo)
  }
  return (
    <div className="listItem" onClick={() => goPage()}>
      <div className={desc ? "itemLeft" : "itemLeft"}>
        <img src={img} alt="" />
        <div className="titles">
          <span className={["itemTitle", desc ? "" : "btm_0"].join(" ")}>{title}</span>
          {desc && <span className="itemDesc">{desc}</span>}
        </div>
      </div>
      <div className="itemRight">
        {extra && <span className={["extra",
          Number(cashbackAccount) >= 9.99
            ? Number(cashbackAccount) >= 10000.0
              ? Number(cashbackAccount) >= 100000.0
                ? "maxSmallSize"
                : "littleSize"
              : "smallSize"
            : "",
        ].join(" ")}>{extra}</span>}
        <RightOutline fontSize={14} />
      </div>
    </div>
  )
}

let timer = null

const Index = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const verifyTranPwdHandler = useRef()

  const showStopTakingPop = useSelector((state) => state.common.showStopTakingPop)
  const selectedLang = useSelector((state) => state.user.selectedLang)
  const amount_risk_pop = useSelector((state) => state.rmb.amount_risk_pop)
  const homeThreeGuide = useSelector((state) => state.rmb.homeThreeGuide)
  const activeFromMsg = useSelector((state) => state.common.activeFromMsg)
  const savingAccountToken = useSelector((state) => state.savingAccount.savingAccountToken)
  const isFirstVisit = useSelector((state) => state.user.isFirstVisit)
  const eyeStatus = useSelector((state) => state.common.homeEyeStatus)

  // const [eyeStatus, setEyeStatus] = useState(false)
  const [cardEyeStatus, setCardEyeStatus] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [stopTakingPop, setstopTakingPop] = useState(false)
  const [shareShow, setShareShow] = useState(false)
  const [unFold, setUnFold] = useState(false)
  const [isRiskPopVisible, setIsRiskPopVisible] = useState(false)
  const [isLoadingShow, setIsLoadingShow] = useState(false)
  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const [isExceptionPopVisible, setIsExceptionPopVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isTillstarInfoVisible, setIsTillstarInfoVisible] = useState(false)
  const [isAccountQuestionVisible, setIsAccountQuestionVisible] = useState(false)
  const [isGuideTutorialVisible, setIsGuideTutorialVisible] = useState(false)
  const [isFreeze, setIsFreeze] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isSetPassword, setIsSetPassword] = useState(false)
  const [identityVerificationRequestStatus, setIdentityVerificationRequestStatus] = useState(false)
  const [rechargeLoading, setRechargeLoading] = useState(false)
  const [saveTransInLoading, setSaveTransInLoading] = useState(false)
  const [saveAccountLoading, setSaveAccountLoading] = useState(false)
  const [isEditTillstarIDVisible, setIsEditTillstarIDVisible] = useState(false)
  const [activeProblem, setActiveProblem] = useState(false)

  const [verifyStatus, setverifyStatus] = useState(undefined)
  const [cardNumber, setCardNumber] = useState(undefined)
  const [defaultFinancialAccount, setDefaultFinancialAccount] = useState(undefined)
  const [balance, setBalance] = useState(undefined)
  const [cashbackAccount, setCashbackAccount] = useState(undefined)
  const [finalNumber, setFinalNumber] = useState(undefined)

  const [userInfo, setUserInfo] = useState(undefined)
  const [newCard, setNewCard] = useState(undefined)
  const [finalBalance, setFinalBalance] = useState(undefined)
  const [isBirthdayAbnormalPopVisible, setIsBirthdayAbnormalPopVisible] = useState(false)

  const countServer = useRef(0)
  const [rmbTime, setRmbTime] = useState("0min 0s")
  const [rmbPayBalance, setRmbPayBalance] = useState(0)
  const [showPayment, setShowPayment] = useState(false)

  const isChinese = selectedLang === "zh_CN"
  const isAbnormal = verifyStatus === 3 || (verifyStatus === 4 && [4, 9].includes(identityVerificationRequestStatus))
  const hideInfo = !userInfo || (userInfo && (verifyStatus === 0 || verifyStatus === 1 || verifyStatus === 2)) || (userInfo && isAbnormal)

  const referralFrom = useCallback(async () => {
    if (userInfo && Object.keys(userInfo).length) {
      const referralCodeFrom = localStorage.getItem("referralCodeFrom")
      if (referralCodeFrom) {
        await syncReferralCode({
          referralCode: referralCodeFrom,
        })
        dispatch(setReferralCodeFrom(""))
      }
    }
  }, [])

  // 消费金显示设置，倒计时
  const initPayment = (params) => {
    setShowPayment(true)
    setRmbPayBalance(params.balance)
    countServer.current = new Date(params.expire_at)

    timer = setInterval(() => {
      const remainingTime = countServer.current - new Date()

      if (remainingTime <= 0) {
        clearInterval(timer)
        setTimeout(() => {
          setShowPayment(false)
          setRmbPayBalance(0)
          setRmbTime("0min 0s")
        }, 700)
        return
      }

      const total = remainingTime / 1000
      const min = Math.floor(total / 60)
      const second = Math.round(total % 60)
      setRmbTime(`${min}min ${second}s`)
    }, 1000)
  }

  // 初始化
  const initData = useCallback(async (type = "init") => {
    try {
      const token = localStorage.getItem("token")

      if (token) {
        type === "init" && setIsLoadingShow(true)
        const userInfoRes = await getUserInfo()

        if (!userInfoRes || (userInfoRes && !userInfoRes?.response_data)) {
          type === "init" && setIsLoadingShow(false)
          setShowDialog(false)
          return
        }

        const result = userInfoRes
        const data = result.response_data || {}
        const verifyStatus = data.identity_verification_status
        const identityVerificationRequestStatus = data.identity_verification_request_status
        const cardNumber = data.default_financial_account?.active_card?.card_number
        const externalAccounts = data.default_financial_account?.bound_external_account_total
        const defaultFinancialAccount = data.default_financial_account
        const balance = handleDot(data.default_financial_account?.balance?.balance)
        const isSetPassword = data.is_transaction_pin_set
        const active_financial_accounts = data.active_financial_accounts
        const cashback_account = handleDot(data.financial_balance?.cashback_account)
        const financial_balance = data.financial_balance
        const isFreeze = data.default_financial_account?.status === 2
        const language = data.preferred_language?.language === "zh_CN" ? "zh_CN" : "en_US"
        // 是否有待使用的人民币消费金 || { balance: 500.23, exipre_at: 20 }
        const isHaveRmbPay = data.rmb_spending_balance

        let temp = (cardNumber || "").split("")
        temp.splice(4, 0, " ")
        temp.splice(9, 0, " ")
        temp.splice(14, 0, " ")

        const cardNumberWithSpace = temp.join("")
        const tempFinal = cardNumberWithSpace ? cardNumberWithSpace.split(" ") : []
        const finalNumber = tempFinal[tempFinal.length - 1] || ""

        dispatch(switchSelectedLang(language))
        type === "init" && setIsLoadingShow(false)

        if (localStorage.getItem("isFirstCome") === null) {
          localStorage.setItem("isFirstCome", "true")
        }

        if (verifyStatus === 3 && identityVerificationRequestStatus === 7) {
          setActiveProblem(true)
          return
        }

        setUserInfo(data)
        setverifyStatus(verifyStatus)
        setIdentityVerificationRequestStatus(identityVerificationRequestStatus)
        setCardNumber(cardNumber)
        setDefaultFinancialAccount(defaultFinancialAccount)
        setBalance(balance)
        setIsSetPassword(isSetPassword)
        setIsFreeze(isFreeze)
        setCashbackAccount(cashback_account)
        setFinalNumber(finalNumber)
        setFinalBalance(financial_balance)

        if (isHaveRmbPay) {
          // 设置人民币消费金条显示，倒计时，金额
          initPayment(isHaveRmbPay)
        } else {
          setShowPayment(false)
          clearInterval(timer)
          timer = null
        }


        const res = await getUserBehavior("first_click_kyc")
        if (res.status_code === 100000) {
          if (!res.response_data.status && verifyStatus === 4 && [5, 10].includes(identityVerificationRequestStatus)) {
            submitUserBehavior("first_click_kyc")
          }
        }

        // 没有设置 Tillstar ID，弹框设置
        if (!data.tillstar_id) {
          setIsEditTillstarIDVisible(true)
          return
        }

        if (localStorage.getItem("isExceptionPopFirstVisible") === null) {
          const abnormal = verifyStatus === 3 || (verifyStatus === 4 && [4, 9].includes(identityVerificationRequestStatus))
          if (abnormal) {
            localStorage.setItem("isExceptionPopFirstVisible", "true")
            onExceptionPopOpen()
            return
          }
        }

        if (localStorage.getItem("isFromLogin") === "true") {
          localStorage.setItem("isFromLogin", "false")
          dispatch(setHomeEyeStatus(false))

          if (defaultFinancialAccount?.status === 2 && externalAccounts <= 0) {
            // 冻结账户，且没有绑定银行卡 不跳转绑卡页
          }

          // 没有设置 Tillstar ID，弹框设置
          if (!data.tillstar_id) {
            setIsEditTillstarIDVisible(true)
            return
          }

          if (verifyStatus === 4 && defaultFinancialAccount?.first_bind_external_account) {
            handleCard()
          }
        }

        if (localStorage.getItem("isFirstCome") === "true") {
          localStorage.setItem("isFirstCome", "false")

          if ([0, 1].includes(verifyStatus)) {
            // 停机维护
            const result = await getModuleStatus("module_kyc")
            if (result.status_code === 300012) return

            setShowDialog(true)
            return
          }

          // 没有设置 Tillstar ID，弹框设置
          if (!data.tillstar_id) {
            setIsEditTillstarIDVisible(true)
            return
          }

          if (verifyStatus === 4 && externalAccounts <= 0) {
            handleCard()
          }
        }

        // 获取用户行为
        const backThreeRes = await getUserBehavior("saving_back_home")
        // 引导展示
        if (backThreeRes.response_data.status && backThreeRes.response_data.trigger_times <= 3 && homeThreeGuide) {
          //  this.setState({ isGuideShow: true });
        } else {
          //  this.setState({ isGuideShow: false });
        }

        if (activeFromMsg) {
          handleActivate()
          dispatch(setActiveFromMsg(false))
        }

        // 激活成功且开通账户成功
        if (verifyStatus === 4 && active_financial_accounts > 0) {
          const res = await getUserBehavior("first_use_saving_acccount")
          // 引导展示
          if (res.status_code === 100000 && !res.response_data?.status) {
            // 隐藏储蓄账号
            // setIsGuideTutorialVisible(true)
          } else {
            setIsGuideTutorialVisible(false)
          }
        }
      }

      // 没有token
    } catch (error) {
      console.log("error", error)
    }
  }, [])

  useEffect(() => {
    scrollToTop()
    initData()
    referralFrom()
  }, [])

  const handleCard = () => {
    if (location?.state) {
      if (location.state?.isflase !== true) {
        history.push("/activeStatus")
      }
    } else {
      history.push("/activeStatus")
    }
  }

  const handleActivate = async () => {
    if (isFreeze) {
      Toast.show({
        content: t("home.dollaraccounthasbeenfrozen"),
        duration: 2000,
      })
      return false
    }
    const result = await noFreezehandleActivate()
    if (!result) return false

    return true
  }

  const noFreezehandleActivate = async () => {
    if (userInfo && [0, 1].includes(verifyStatus)) {
      let result = await getModuleStatus("module_kyc")

      if (result.status_code === 300012) return

      setShowDialog(true)
      return false
    }
    if (verifyStatus === 2) {
      Toast.show({
        content: t("transfer.accountInOpeningErr"),
        duration: 2000,
      })
      return false
    }
    if (userInfo && isAbnormal) {
      onExceptionPopOpen()
      return false
    }

    return true
  }

  const onSwitch = async () => {
    try {
      const language = isChinese ? "en_US" : "zh_CN"
      dispatch(switchSelectedLang(language))

      Object.keys(userInfo).length > 0 &&
        (await updateLanguage({
          language: language,
        }))
    } catch (error) {
      console.log(error)
    }
  }

  const bindGirdClicked = async (index) => {
    if (!userInfo) {
      return history.push("/login")
    }

    // 停服维护
    if (index === 1 || index === 0) {
      let result = await getModuleStatus("module_external_account")
      if (result.status_code === 300012) return
    }

    if (index === 2) {
      if (amount_risk_pop.is_risk) {
        setIsRiskPopVisible(true)
        return
      }
    }

    if (isFreeze && ![2, 3].includes(index)) {
      Toast.show({
        content: t("home.dollaraccounthasbeenfrozen"),
        duration: 2000,
      })
      return
    }

    switch (index) {
      case 0:
        addMoney()
        break
      case 1:
        handleCashOut()
        break
      case 2:
        goRmb()
        break
      default:
        break
    }
  }
  // 充值
  const addMoney = async () => {
    setRechargeLoading(true)
    let result = await getModuleStatus("module_external_account")
    if (result.status_code === 300012) return setRechargeLoading(false)

    if (isFreeze) {
      setRechargeLoading(false)
      Toast.show({
        content: t("home.dollaraccounthasbeenfrozen"),
        duration: 2000,
      })
      return
    }
    const res = await getPermissionByCode({ permissionCode: "permission_external_account" })
    if (res.status_code === 300008) return setRechargeLoading(false)

    if ([0, 1].includes(verifyStatus)) {
      setRechargeLoading(false)
      setShowDialog(true)
      return
    }

    if (isAbnormal) {
      setRechargeLoading(false)
      onExceptionPopOpen()
      return
    }

    if (verifyStatus === 2) {
      setRechargeLoading(false)
      Toast.show({
        content: t("transfer.accountInOpeningErr"),
        duration: 2000,
      })
      return
    }

    if (verifyStatus === 4 && ![4, 9].includes(identityVerificationRequestStatus)) {
      setRechargeLoading(false)
      history.push("/recharge")
      return
    }
  }
  // 提现
  const handleCashOut = async () => {
    if (submitting) return

    setSubmitting(true)
    let result = await getModuleStatus("module_external_account")
    if (result.status_code === 300012) return setSubmitting(false)

    if (isFreeze) {
      Toast.show({
        content: t("home.dollaraccounthasbeenfrozen"),
        duration: 1000,
      })
      setSubmitting(false)
      return
    }
    const res = await getPermissionByCode({ permissionCode: "permission_external_account" })
    if (res.status_code === 300008) return setSubmitting(false)

    if ([0, 1].includes(verifyStatus)) {
      setSubmitting(false)
      setShowDialog(true)
      return
    }
    if (isAbnormal) {
      setSubmitting(false)
      onExceptionPopOpen()
      return
    }
    if (verifyStatus === 2) {
      setSubmitting(false)
      Toast.show({
        content: t("transfer.accountInOpeningErr"),
        duration: 2000,
      })
      return
    }
    if (verifyStatus === 4 && ![4, 9].includes(identityVerificationRequestStatus)) {
      //获取账户余额
      const res = await getBalance({ token: "token" })

      if (res.response_data?.balance <= 0) {
        setSubmitting(false)
        setVisible(true)
      } else {
        setVisible(false)
        history.push("/withdraw")
      }
    }
  }
  // 人名币消费金
  const goRmb = async () => {
    if (userInfo === undefined) {
      history.push("/login")
      return
    }

    const res = await getPermissionByCode({ permissionCode: "permission_instant_consumption_coin" })
    if (res.status_code === 300008) return
    if ([0, 1].includes(verifyStatus)) {
      let result = await getModuleStatus("module_kyc")
      if (result.status_code === 300012) return

      setShowDialog(true)
      return
    }

    if (isAbnormal) {
      onExceptionPopOpen()
      return
    }

    if (verifyStatus === 2) {
      Toast.show({
        content: t("home.card_info.waitForAccountOpening"),
        duration: 2000,
      })
      return
    }

    if (verifyStatus === 4) {
      history.push("/rmb")
    }
  }

  // savingAccount
  const enterSavingAccount = async (url = "") => {
    if (!userInfo) {
      return history.push("/login")
    }

    !url && setSaveAccountLoading(true)
    url && setSaveTransInLoading(true)

    // 查看不需校验冻结，转入需要校验
    if (url) {
      const result = await handleActivate()

      if (!result) {
        setSaveAccountLoading(false)
        setSaveTransInLoading(false)
        return
      }
    } else {
      const result = await noFreezehandleActivate()
      if (!result) {
        setSaveAccountLoading(false)
        setSaveTransInLoading(false)
        return
      }
    }

    const res = await getPermissionByCode({
      permissionCode: "permission_savings_account",
    })
    if (res.status_code === 300008) {
      setSaveAccountLoading(false)
      setSaveTransInLoading(false)
      return
    }

    setSaveAccountLoading(false)
    setSaveTransInLoading(false)
    if (savingAccountToken) {
      url ? history.push(url) : history.push("/savingAccount")
    } else {
      verifyPwd({
        type: "savingAccount",
        url,
      })
    }
  }
  //验证交易密码
  const verifyPwd = ({ type, url }) => {
    const isCheckSavingAccount = type === "savingAccount" ? true : false

    if (isSetPassword) {
      verifyTranPwdHandler.current = Dialog.show({
        closeOnMaskClick: true,
        className: "verifyPWDDialog",
        content: (
          <VerifyTranPwd
            actionType={isCheckSavingAccount ? "checkSavingAccount" : ""}
            setPasswordActionToken={(token) => token && dispatch(setActionToken(token))}
            forgetPassword={forgetPassword}
            getCardImage={getCardImage}
            stopPropagation={["click"]}
            onClick={(e) => e.stopPropagation()}
            closeVerifyTranPwd={() =>
              closeVerifyTranPwd({
                isCheckSavingAccount,
                url,
              })
            }
            openFreezePasswordPop={() => {
              verifyTranPwdHandler.current.close()
              setIsPasswordFreeze(true)
            }}
          />
        ),
      })
    } else {
      Toast.show({
        content: t("realAuthentication.settheradingpassword"),
        duration: 5000,
      })
      setTimeout(() => {
        const channel = isCheckSavingAccount ? "savingAccount" : "checkPassword"
        sessionStorage.removeItem("recognition")
        dispatch(
          setPasswordRelatedChannel({
            channel: channel,
            type: "set",
            url: "/",
            backUrl: "/",
          })
        )
        history.push("/verifyCode")
      }, 2000)
    }
  }

  const forgetPassword = () => {
    verifyTranPwdHandler.current.close()
    dispatch(
      setPasswordRelatedChannel({
        channel: "normalSetting",
        type: "reset",
        url: "/",
        backUrl: "/",
      })
    )
    history.push("/resetTransactionPasswordA")
  }

  const closeVerifyTranPwd = ({ isCheckSavingAccount, url }) => {
    verifyTranPwdHandler.current.close()
    if (isCheckSavingAccount) {
      url ? history.push(url) : history.push("/savingAccount")
    }
  }

  const getCardImage = (base64Image) => {
    setNewCard(base64Image)
    setCardEyeStatus(true)
  }

  const onHide = () => {
    setNewCard(undefined)
    setCardEyeStatus(false)
  }

  const onExceptionPopOpen = () => {
    dispatch(setPasswordRelatedChannel({}))
    setIsExceptionPopVisible(true)
  }
  // 是否显示余额
  const changeEye = () => {
    if (balance) {
      const tempBalance = "******"
      let status = !eyeStatus

      if (status) {
        setBalance(tempBalance)
      } else {
        setBalance(handleDot(defaultFinancialAccount?.balance?.balance))
      }
      // setEyeStatus(status)
      // 存储到redux
      dispatch(setHomeEyeStatus(status))
    }
  }
  // 查看账户
  const handleAccount = () => {
    if (!userInfo) {
      return history.push("/login")
    }
  }

  const transTitle = () => {
    if (!userInfo) {
      return (
        // 未登录
        <List.Item clickable onClick={() => handleAccount()}>
          {t("home.activeAccount")}
        </List.Item>
      )
    }

    if (userInfo && (verifyStatus === 0 || verifyStatus === 1)) {
      return (
        // 激活、继续激活
        <List.Item clickable onClick={() => handleActivate()}>
          {t("home.activeAccount")}
        </List.Item>
      )
    }

    if (userInfo && verifyStatus === 2) {
      return (
        // 审核中
        <List.Item clickable onClick={() => handleActivate()}>
          {t("home.activeAccount")}
        </List.Item>
      )
    }

    if (userInfo && isAbnormal) {
      return (
        // 激活、继续激活
        <List.Item clickable onClick={() => handleActivate()}>
          {t("home.activeAccount")}
        </List.Item>
      )
    }

    // 正常
    return (
      <List.Item
        description={
          <span className="desc">
            {t("home.lastFourNum")}
            {finalNumber}
          </span>
        }
        clickable
        onClick={() => setIsTillstarInfoVisible(true)}
      >
        {t("home.viewAccount")}
      </List.Item>
    )
  }

  const onPackDown = async () => {
    if (!userInfo) return history.push("/login")

    const result = await noFreezehandleActivate()
    if (!result) return false

    !unFold && setUnFold(true)
  }

  const commonToast = async () => {
    if (!userInfo) return history.push("/login")

    const result = await handleActivate()
    if (!result) return false

    return true
  }

  const clickReward = async () => {
    if (!userInfo) return history.push("/login")
    const result = await commonToast()
    if (!result) return false

    history.push("/reward")
  }

  const clickShowCard = async (params) => {
    if (!userInfo) return history.push("/login")
    const result = await handleActivate()
    if (!result) return false

    verifyPwd(params)
  }

  const handleCloseTip = () => {
    setShowDialog(false)

    if (!isFirstVisit) {
      dispatch(setIsFirstVisit(true))
    }
  }

  const setIsOnlyOneShow = () => {
    submitUserBehavior("first_use_saving_acccount")
    setIsGuideTutorialVisible(false)
  }

  return (
    <div className="home" id="newHome">
      <Mask from="home" visible={showDialog && !showStopTakingPop} disableBodyScroll>
        <Tip onClose={() => handleCloseTip()} verifyStatus={verifyStatus} />
      </Mask>
      <Mask visible={isLoadingShow}>
        <ReactLoading className="loading" type={"spinningBubbles"} color={"#fff"} />
      </Mask>
      <Mask visible={isPasswordFreeze}>
        <FreezePasswordPop
          closeFreezePop={() => {
            initData()
            setIsPasswordFreeze(false)
          }}
        />
      </Mask>
      <ExceptionPop
        visible={isExceptionPopVisible}
        onClose={() => setIsExceptionPopVisible(false)}
        content={
          <>
            <div className="mask-title" style={isChinese ? { fontWeight: "600" } : {}}>
              {t("home.applyAccountWithException")}
            </div>
            <div className="mask-subtitle" style={isChinese ? { fontWeight: "600" } : {}}>
              {t("home.customerServiceReachYou")}
              <br />
              <span>{t("home.serviceWithInHours")}</span>
              {isChinese ? t("home.contactYou") : null}
            </div>
            <div className="warm-tip">{t("home.PleaseBePatient")}</div>
          </>
        }
      />
      <CommonPop from="home" amountRiskPop={isRiskPopVisible} onRiskControlClose={() => setIsRiskPopVisible(false)} />
      <Popup visible={shareShow} position="bottom">
        <SharePop cancelShare={() => setShareShow(false)} />
      </Popup>
      <Popup visible={isTillstarInfoVisible} position="bottom">
        <TillstarInfo
          financialAccount={userInfo?.default_financial_account}
          close={() => setIsTillstarInfoVisible(false)}
          openAccountQuestion={() => setIsAccountQuestionVisible(true)}
        />
      </Popup>
      <Popup visible={isAccountQuestionVisible}>
        <AccountQuestion close={() => setIsAccountQuestionVisible(false)} />
      </Popup>
      <Mask visible={isGuideTutorialVisible}>
        <GuideTutorial close={() => setIsOnlyOneShow()} />
      </Mask>
      <Modal
        visible={visible}
        content={<MoreErroPassword freeze={() => history.push("/withdraw")} geTian={() => history.push("/recharge")} />}
        closeOnMaskClick={true}
        showCloseButton={true}
        onClose={() => {
          setVisible(false)
        }}
      />

      {/* 设置 Tillstar ID */}
      <Mask visible={isEditTillstarIDVisible}>
        <EditTillstarID close={() => {
          setIsEditTillstarIDVisible(false)
          history.go(0)
        }}
        />
      </Mask>
      <CommonTipPop isVisible={activeProblem} setIsVisible={(bool) => setActiveProblem(bool)} content={
        <>
          <div className='title' dangerouslySetInnerHTML={{ __html: t('KYC.billsAbnormalTitle') }} />
          <div className='subtitle' dangerouslySetInnerHTML={{ __html: t('KYC.billsAbnormalTip') }} />
          <Button className='center-btn' onClick={() => {
            setActiveProblem(false)
            history.push('/KYCAbnormal')
          }}>{t('KYC.billsAbnormalBtn')}</Button>
        </>
      }></CommonTipPop>

      <div className="home-top">
        {/* 停服公告 */}
        <div className="topNotice">
          <StopTakingNotice
            stopTakingPop={stopTakingPop}
            setStopTakingPop={(e) => {
              setstopTakingPop(e)
            }}
            moduleCode="module_show_card_info"
          />
        </div>

        <MyHeader
          title={t("home.my_account")}
          msgShow="true"
          showNotify={true}
          isShareShow={false}
          switchLangShow="true"
          selectedLang={selectedLang}
          onSwitch={onSwitch}
          shareShow={() => setShareShow(true)}
        />
      </div>

      {/* 卡片 */}
      <div className={["card-container", !unFold ? "" : "card-container1"].join(" ")}>
        <HomeVisaCardDialog
          unFold={unFold}
          leftBottom={userInfo?.first_name + " " + userInfo?.last_name}
          newCard={newCard}
          cardNumber={cardNumber}
          hideInfo={hideInfo}
          verifyPwd={clickShowCard}
          cardEyeStatus={cardEyeStatus}
          onHide={() => onHide()}
        />
        <div className="tutorial" onClick={() => history.push("/faq")}>
          <div className="left">
            <img src={walletImg} alt="" />
            <span>{t("home.tutorialTitle")}</span>
          </div>
          <RightOutline />
        </div>

        <div
          className="packUp"
          onClick={() => {
            unFold && setUnFold(false)
          }}
        >
          <img src={packUpImg} alt="" />
        </div>
      </div>

      <div className={["home-content", unFold ? "" : "home-content1"].join(" ")}>
        <div
          className="packDown"
          onClick={() => {
            onPackDown()
          }}
        >
          <img src={packDownImg} alt="" />
        </div>
        <div className="title">{transTitle()}</div>

        <div className="balanceContent">
          {/* 隐藏储蓄账户后宽度适配, 如果显示储蓄账号，把此宽度配置删除 */}
          <div className="balance" style={{ width: "100%" }}>
            <div className="top">
              <span className="text">
                {t("home.card_info.able_balance")}
                {userInfo && ![0, 1, 2].includes(verifyStatus) && !isAbnormal && (
                  <span className="eyes">
                    {eyeStatus ? <EyeInvisibleOutline onClick={() => changeEye()} /> : <EyeFill onClick={() => changeEye()} />}
                  </span>
                )}
              </span>
            </div>
            <div className="amount">
              <span className="dollar">$</span>
              {!userInfo ? "0.00" : eyeStatus ? "*****" : balance ? formatNumberToUSD(balance) : "0.00"}
            </div>
            <div className="actions">
              <Button className={isChinese ? "action" : "action action_en"} onClick={() => bindGirdClicked(0)} loading={rechargeLoading}>
                {t(`home.recharge`)}
              </Button>
              <Button className={isChinese ? "action" : "action action_en"} onClick={() => bindGirdClicked(1)} loading={submitting}>
                {t(`home.withdraw`)}
              </Button>
            </div>
          </div>
          {/* 隐藏储蓄账户 */}
          {/* <div className="balance savingAccount">
            <div className="top">
              <div className="text">{t("home.saving_account.title")}</div>
              { isChinese && <div className="rightText">
                {t("home.saving_account.earning_rate", { rate: handleDot((finalBalance?.max_interest_of_points || 0.025) * 100) })}
              </div>}
            </div>
            <div className="bottom">
              <div className="amount">
                <span className="dollar">$</span>
                {"***"}
              </div>
              { !isChinese && <div className="rightText">
                  {t("home.saving_account.earning_rate", { rate: handleDot((finalBalance?.max_interest_of_points || 0.025) * 100) })}
              </div>}
            </div>
            <div className="actions">
              <Button
                className={isChinese ? "action" : "action action_en"}
                onClick={() => enterSavingAccount()}
                loading={saveAccountLoading}
              >
                {t("home.viewSavingAccount")}
              </Button>
              <Button
                className={isChinese ? "action" : "action action_en"}
                onClick={() => enterSavingAccount("/savingAccount/transfer?type=in")}
                loading={saveTransInLoading}
              >
                {t("home.transferIn")}
              </Button>
            </div>
          </div> */}
        </div>

        {showPayment && <div className="rmbPay">
          <div className="listItem">
            <div className="itemLeft">
              <img src={rmbPayImg} alt="" />
              <div className="titles">
                <span className={["itemTitle"].join(" ")}>
                  <span className="titleText">{t("home.rmbPay")}</span>
                  <span className="titleTextRight" onClick={() => history.push("/tutorials?key=rmbPayment")}><QuestionCircleOutline /></span>
                </span>
                <span className="itemDesc">
                  {!isChinese && <span>{t("home.rmbPayRefund")}</span>}
                  <span className="red">{rmbTime}</span>
                  {isChinese && <span>{t("home.rmbPayRefund")}</span>}
                </span>
              </div>
            </div>
            <div className="itemRight">
              <div className="dollor">$</div>
              <div className="rmbAmount">{handleDot(formatNumberToUSD(rmbPayBalance))}</div>
            </div>
          </div>
        </div>}
        {/* 隐藏奖励购物点 */}
        {/* <div className="rewards">
          <CommonListItem
            title={
              <span
                className={[
                  Number(cashbackAccount) >= 9.99
                    ? Number(cashbackAccount) >= 10000.0
                      ? Number(cashbackAccount) >= 100000.0
                        ? "maxSmallSize"
                        : "littleSize"
                      : "smallSize"
                    : "",
                ].join(" ")}
              >
                {hideInfo || Number(cashbackAccount) === 0 || !isChinese
                  ? t("home.rewardPoint")
                  : t("home.rewardPointHave", { points: handleDot(formatNumberToUSD(cashbackAccount)) })}
              </span>
            }
            cashbackAccount={cashbackAccount}
            img={giftImg}
            desc={false}
            extra={
              hideInfo || Number(cashbackAccount) === 0
                ? false
                : t("home.rewardPointTwo", {
                    interest: handleDot((finalBalance?.max_interest_of_points || 0) * 100),
                    cashback: handleDot((finalBalance?.max_cashback_percent || 0) * 100),
                  })
            }
            clickTo={"/reward"}
            clickFun={() => clickReward()}
          />
        </div> */}

        <div className="moreFeature">
          <div className="moreTitle">{t("home.moreFeature")}</div>
          <CommonListItem
            title={t("home.rmb.title")}
            img={rmbImg}
            desc={t("home.rmb.desc")}
            extra={false}
            clickTo={"/rmb"}
            clickFun={() => bindGirdClicked(2)}
          />

          <Divider />

          {/* 礼卡购买入口隐藏 */}
          {/* <CommonListItem
            title={t("home.giftCard.title")}
            img={giftcardImg}
            desc={t("home.giftCard.desc")}
            extra={false}
            clickTo={"/giftCard"}
          />
          <Divider /> */}

          {/* 信用账户入口 */}
          {userInfo && userInfo.is_allowed_trust_credit && <>
            <CommonListItem
              title={t("home.statement.title")}
              img={giftcardImg}
              desc={t("home.statement.desc")}
              extra={false}
              clickTo={"/statementList"}
            />
            <Divider />
          </>}
        </div>
      </div>

      <MyTabbar />
      <CommonTipPop isVisible={isBirthdayAbnormalPopVisible} setIsVisible={setIsBirthdayAbnormalPopVisible} content={
        <>
          <div className='common-tip-title' dangerouslySetInnerHTML={{ __html: t('KYC.birthdayAbnormalTitle') }} />
          <div className='common-tip-subtitle' dangerouslySetInnerHTML={{ __html: t('KYC.birthdayAbnormalTip') }} />
          <div className='common-tip-btn' onClick={() => {
            setIsBirthdayAbnormalPopVisible(false)
            history.push('/reconfirmBirthday')
          }}>{t('KYC.birthdayAbnormalBtn')}</div>
        </>
      }>
      </CommonTipPop>
    </div>
  )
}

export default Index
