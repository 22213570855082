import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import "./index.less"
import { CloseOutline } from 'antd-mobile-icons'
import { NumberKeyboard, PasscodeInput, Mask, Toast } from "antd-mobile"
import { rechargeMoney, verify } from "@api/account"
import { connect } from 'react-redux'
import { setPasswordRelatedChannel } from '@actions/password'
import { setTransferOrigin } from '@actions/transfer'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import ReactLoading from "react-loading"

const mapDispatchToProps = {
  setPasswordRelatedChannel,
  setTransferOrigin,
}

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang,
  }
}

const TitleWarpB = () => {
  return (
    <div className="title-wrapper">
      <p>{t("transfer.Entertransactionpassword")}</p>
      <p>{t("transfer.securityaccount")}</p>
      <p>{t("transfer.Pleaseenterinformation")}</p>
    </div>
  )
}
class RechargePassword extends Component {
  state = {
    error: false,
    failedTimes: 0,
    remainderTimes: 0,
    isLoadingShow: false,
    password: ''
  }

  passcodeInputRef = React.createRef()

  onPasswordChange = async (code) => {
    const { amount, achAccountId, openFreezePasswordPop, autoEnterIn } = this.props
    const { error } = this.state

    this.setState({
      password: error && code.length === 5 ? '' : code
    })
    
    if (code.length === 6) {
      this.passcodeInputRef.current.blur()
      this.setState({
        isLoadingShow: true
      })
      const verif = await verify({
        transactionPin: code,
        actionCode: "AccountDeposit_VerifyCurrentPin"
      })
      if (verif.status_code === 100000) {
        // todo 添加是否自动转入至储蓄账户字段
        const recc = await rechargeMoney({
          externalAccountId: achAccountId,
          amount,
          debitCreditIndicator: "C",
          actionCode: "AccountDeposit_SubmitRequest",
          token: verif.response_data.token,
          is_auto_transfer: autoEnterIn
        })
        this.setState({
          isLoadingShow: false
        })
        if (recc.status_code === 100000) {
          this.props.setTransferOrigin('/recharge')
          setTimeout(() => {
            this.props.history.push(`/transactionDetail?code=${recc.response_data.code}`)
          }, 500)
        } else {
          this.props?.hiddenMask()
          Toast.show({
            content: t('error.transfer.303009'),
            duration: 3000,
          })
        }
      } else if (verif.status_code === 301025) {
        this.setState({
          isLoadingShow: false
        })
        const { total_limit, failed_times } = verif.response_data
        this.setState({
          failedTimes: failed_times,
          remainderTimes: total_limit - failed_times,
          error: true
        })
      } else if (verif.status_code === 301016) {
        this.setState({
          isLoadingShow: false
        })
        openFreezePasswordPop()
        this.setState({
          error: true
        })
      } else {
        this.setState({
          isLoadingShow: false,
          error: true
        })
      }
    } else {
      this.setState({
        error: false
      })
    }
  }

  render() {
    const { amount, perTransFee } = this.props
    const { error, failedTimes, remainderTimes, isLoadingShow, password } = this.state
    
    return (
      <div id="recharge-password">
          <div className="overlayContent">
            <CloseOutline
              onClick={() => {
                this.props?.hiddenMask()
              }}
              className="close-img"
              color="#FFF"
              fontSize={16}/>
            <div>
              <TitleWarpB
                isRepeat={false}
                amount={amount}
                perTransFee={perTransFee}
              />
              {error && <div className="password-error-msg">
                <Trans i18nKey={t(`password.passwordIncorrect`)}  values={{ failedTimes, remainderTimes }}/>
              </div>}
              <PasscodeInput
                ref={this.passcodeInputRef}
                className="passcode-input"
                error={error}
                value={password}
                onChange={this.onPasswordChange}
                seperated
                keyboard={<NumberKeyboard randomOrder={true}/>}
              />
              <div className="varification-tips tipp">
                <div
                  className="forget-password"
                  onClick={() => {
                    this.props.setPasswordRelatedChannel({
                      channel: 'normalSetting',
                      type: 'reset',
                      url: '/recharge',
                      backUrl: '/recharge',
                    })
                    this.props.history.push("/resetTransactionPasswordA")
                  }}
                >
                  {t("transfer.Forgotpassword")}
                </div>
              </div>
            </div>
          </div>
          <Mask visible={isLoadingShow}>
            <ReactLoading className='loading' type={"spinningBubbles"} color={"#fff"}/>
          </Mask>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RechargePassword))
