import React, { useEffect, useState } from "react"
import "./index.less"
import { Input, Button } from "antd-mobile"
import { scrollToTop } from "@utils/utils"

import { t } from "i18next"
import { useSelector } from 'react-redux'

export default function UpdateName() {
  const userInfo =  useSelector(state => state.user.userInfo)
  const [firstName] = useState('')
  const [lastName] = useState('')

  useEffect(async () => {
    scrollToTop()
  }, [])

  return (
    <div id="update-name-container">
      <div className="title">{t('mine.updateNameTitle')}</div>
      <Input className="first-name" value={firstName} placeholder={userInfo.first_name}/>
      <Input className="last-name" value={lastName} placeholder={userInfo.last_name}/>
      <Button>{t('common.finish')}</Button>
    </div>
  )
}