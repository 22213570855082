import './index.less'
import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

export const LinkText = ({href, children}) => {
    return (
        <Link to={href || ''}>{children}</Link>
    )
}

export default function CommonBottomPopup(props) {
    const { title, content, link } = props
    return (
        <div className="common-bottom-popup">
            <div className="popup-title">
                {title}
            </div>
            <div className="popup-content">
                <Trans
                    defaults={content}
                    components={[<LinkText href={link}/>]}
                />
            </div>
        </div>
    )
}