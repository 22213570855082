import React, { Component } from 'react'
import { Toast, Mask } from 'antd-mobile'
import idFrontBG from "@assets/images/certificate/pic_idfront.png"
import idBackBG from "@assets/images/certificate/pic_idback.png"
import camera from "@assets/images/activation/icon_paishe_white.png"
import nonssnId from "@assets/images/certificate/nonssn-id.png"
import nonssnExample1 from "@assets/images/certificate/nonssn_example1.png"
import nonssnExample2 from "@assets/images/certificate/nonssn_example2.png"
import nonssnExample3 from "@assets/images/certificate/nonssn_example3.png"
import nonssnExample4 from "@assets/images/certificate/nonssn_example4.png"

import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import { onfio } from "@api/netki"
import qs from 'qs'
import './index.less'
import Activationsamples from '@comps/Activationsamples'
import { transformImageFile, scrollToTop } from "@utils/utils"
import { t } from 'i18next'
import Header from '@comps/nav/Header'
import KYCHeader from '@pages/kyc-auth/non-ssn/components/Header'
import { uploadNonSSN } from '@api/netki'

export default class StepSeven extends Component {
  state = {
    bool: true, //控制多次点击
    value: '',
    isBtnDisabled: true,
    isSendCode: true,
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: true },
    ],
    picture: "",
    pictured: "",
    isHas: true,
    isFalse: true,
    uploading: false,
    visible: true,
    from: '',
    exa: '',
    nonssnExample: [
      {
        name: t('KYC.nonssn.idCheck.example.0'),
        pic: nonssnExample1
      },
      {
        name: t('KYC.nonssn.idCheck.example.1'),
        pic: nonssnExample2
      },
      {
        name: t('KYC.nonssn.idCheck.example.2'),
        pic: nonssnExample3
      },
      {
        name: t('KYC.nonssn.idCheck.example.3'),
        pic: nonssnExample4
      }

    ]
  }

  componentDidMount() {
    if (this.props.history.location.search.includes('nonssn')) {
      this.setState({
        from: 'nonssn',
        visible: false
      })
    } else {
      this.setState({
        from: ''
      })
    }
    scrollToTop()
  }

  chuanOne = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tps").src = reader?.result
      }
      this.setState({
        pictured: e.target?.files[0],
        isHas: false
      })
      transformImageFile(e.target?.files[0], this.afterCompressOne);

    } else {
      this.setState({
        isHas: true
      })
    }
  }

  afterCompressOne = (file) => {
    this.setState({
      pictured: file,
      isHas: false
    });
  }

  chuan = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tpp").src = reader?.result
      }
      this.setState({
        picture: e.target.files[0],
        isFalse: false
      })
      transformImageFile(e.target?.files[0], this.afterCompress);
    } else {
      this.setState({
        isFalse: true
      })
    }
  }

  afterCompress = (file) => {
    this.setState({
      picture: file,
      isHas: false
    });
  }

  authentication = async () => {
    const { picture, pictured, uploading, from } = this.state

    const { licence } = qs.parse(this.props.location.search.slice(1))
    this.setState({
      uploading: true
    })
    if (!pictured || !picture || uploading) { return false; }
    let applicantId = localStorage.getItem('AuthorizationID')
    var formdata = new FormData();
    if (from === 'nonssn') {
      formdata.append('kycFile', picture)
      formdata.append('fileType', 'identity_card')
      const res = await uploadNonSSN(formdata)
      if (res?.status_code === 100000) {
        formdata.set('kycFile', pictured)
        const res1 = await uploadNonSSN(formdata)
        if (res1.status_code === 100000) {
          this.props.history.push(`/xStepNine?from=${from}`);
        }
        this.setState({
          uploading: false
        })
      } else {
        Toast.show({
          content: t('steps.guideActionFailed'),
          duration: 4000,
        })
        this.setState({
          uploading: false
        })
      }
      return;
    }

    formdata.append("front_document", picture);
    formdata.append("back_document", pictured);
    formdata.append("verificationRequestId", applicantId);
    formdata.append("document_type", licence);


    const res = await onfio(formdata)

    switch (res?.status_code) {
      case 100000:
        this.props.history.push(`/xStepNine`);
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.SSNErr'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
      case 300008: //Your account is not qualified for this function.
        this.props.history.push("/");
        break;
      case 300010: //The file uploaded is too large.
        Toast.show({
          content: t('steps.fileUploadErr'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErrBackToHome'),
          duration: 4000,
        })
        this.props.history.push("/");
        break;
      case 301019: //Onfido service call failed.
      case 300002: //Invalid parameter
      case 301017: //Invalid identity verification parameters
      default:
        Toast.show({
          content: t('steps.guideActionFailed'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
    }
  }

  render() {
    const { list, isHas, isFalse, picture, pictured, visible, uploading, from, nonssnExample, exa } = this.state
    return (
      <div className="stepSeven" style={{ minHeight: "760px" }}>
        {
          from === 'nonssn' ? <>
            <Header />
            <KYCHeader title={t('KYC.nonssn.idCheck.title')} subTitle={t('KYC.nonssn.idCheck.subTitle')} />
          </> : <StepHeader
            title={t('steps.IDCheckTitle')}
            subTitle={t('steps.IDCheckSubTitle')}
            list={list}
          />
        }

        <div className='document-type textAlignLeft'>
          {
            from === 'nonssn' ? <span className='upload-id-identify-title'>{t('KYC.nonssn.idCheck.upload')}</span> : <>
              <p className='type-title'>
                {t('steps.IDCheckUploadIDPhoto')}
              </p>
              <p>
                <span style={{
                  fontFamily: 'Alibaba PuHuiTi',
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "174.2%",
                  color: "#999999"
                }}>{t('steps.IDCheckPhotoHint')}</span>
              </p></>
          }
          <div className={from === 'nonssn' ? 'upload-id-identify-file nonssn' : 'upload-id-identify-file'}>
            <div className="upload-image">
              <img className="holder-image" src={from === 'nonssn' ? nonssnId : idFrontBG} style={!isHas ? { opacity: "0" } : {}} alt="" />
              <input type="file" accept="image/*" onChange={this.chuanOne} style={{
                position: "absolute", top: "22%",
                left: "37%",
                width: "26%",
                height: "50%", opacity: "0"
              }} />
              <div className="tip-text">
                <p>
                  {t('steps.IDCheckClickToUploadFrontPhoto')}
                </p>
                {
                  from !== 'nonssn' &&
                  <p onClick={() => { this.setState({ visible: true }) }}>
                    {t('steps.IDCheckExample')}
                  </p>
                }
              </div>

              {!isHas &&
                <>
                  <img src="" alt="" className='tps' />
                  <div className="bottom-btn">
                    <input type="file" className='files' accept="image/*" onChange={this.chuanOne} />
                    <div className="action" onClick={() => { document.querySelector('.files').click() }}>
                      <img src={camera} alt="" />
                      {t('steps.IDCheckClickToReUpload')}
                    </div>
                  </div>
                </>}
            </div>
            <div className="upload-image">
              <img className="holder-image" src={from === 'nonssn' ? nonssnId : idBackBG} style={!isFalse ? { opacity: "0" } : {}} alt="" />
              <input className='int' type="file" accept="image/*" onChange={this.chuan} style={{
                position: "absolute", top: "22%",
                left: "37%",
                width: "26%",
                height: "50%", opacity: "0"
              }} />
              <div className="tip-text">
                <p>
                  {t('steps.IDCheckClickToUploadBackPhoto')}
                </p>
                {
                  from !== 'nonssn' && <p onClick={() => { this.setState({ visible: true }) }}>
                    {t('steps.IDCheckExample')}
                  </p>
                }

              </div>
              {!isFalse &&
                <>
                  <img src="" alt="" className='tpp' />
                  <div className="bottom-btn">
                    <input type="file" className='filed' accept="image/*" onChange={this.chuan} />
                    <div className="action" onClick={() => { document.querySelector('.filed').click() }}>
                      <img src={camera} alt="" />
                      {t('steps.IDCheckClickToReUpload')}
                    </div>
                  </div>
                </>}
            </div>
          </div>
          {
            from === 'nonssn' && <div className='non-ssn-example-container'>
              <p className='upload-id-identify-title'>{t('KYC.nonssn.idCheck.exampleTitle')}</p>
              <div className='non-ssn-example-item'>
                {
                  nonssnExample.map((item, index) => {
                    return <div className='non-ssn-content' key={item.pic} onClick={() => {
                      this.setState({
                        visible: true,
                        exa: item.pic
                      })
                    }}><img src={item.pic} alt='' /><span>{item.name}</span></div>
                  })
                }

              </div>
            </div>
          }

        </div>

        <StepFooter
          notice={t('steps.IDCheckPhotoHint2')}
          isLoading={uploading}
          disabled={!((picture && pictured) || uploading)}
          btnSubmit={this.authentication}
          btnText={t('steps.nextButton')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
        {visible ?
          <Mask
            visible={visible}
            onMaskClick={() => {
              this.setState({
                visible: false
              })
            }}
          >
            <Activationsamples lssueds={from !== 'nonssn' ? "lssueds" : exa} from={from} ></Activationsamples>
          </Mask>
          : null}

      </div>
    )
  }
}

