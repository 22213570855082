import React, { Component } from 'react'
import { identityName } from '@api/user'
import requireImg from '@assets/images/home/require.png'
import { Mask, Toast } from 'antd-mobile'
import StepHeader from './StepHeader'
import KYCFooter from "@comps/KYC/KYCFooter"
import Birthday from "@comps/Birthday"
import { InformationCircleFill } from 'antd-mobile-icons'
import Tip from '@comps/Tip'
import './index.less'

import { scrollToTop } from "@utils/utils"

import { connect } from 'react-redux'
import CInput from 'react-composition-input'
import { t } from 'i18next'

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang
  }
}
class Steps extends Component {
  state = {
    list: [
      { name: t('steps.navNameAndDob'), active: true },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: false },
    ],
    isInitFirstName: true,
    isInitLastName: true,
    firstName: '',
    lastName: '',
    birthday: '',
    year: '',
    month: '',
    day: '',
    submitting: false,
    showDialog: false,
    verifyStatus: 1,
    isDatePickerVisible: false,
    isIncludeChinese: false,
    isFirstNameIncludeChinese: false,
    isLastNameIncludeChinese: false,
    isFirstNameFocus: false,
    isLastNameFocus: false,
    clientHeight: 0,
    firstNameCompisiton: '',
    isSubmitLoading: false
  }

  hideFooter = () => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
  }

  componentDidMount() {
    scrollToTop()
    this.setState({
      clientHeight: document.body.clientHeight
    })
    window.addEventListener('resize', this.hideFooter, true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideFooter, true)
  }

  handleCloseTip = () => {
    this.setState({
      showDialog: false,
    })
  }

  submits = async () => {
    const verificationRequestId = localStorage.getItem('AuthorizationID')
    const { firstName, lastName, year, month, day } = this.state
    this.setState({ submitting: true, isSubmitLoading: true })
    
    const res = await identityName({
      verificationRequestId,
      firstName,
      lastName,
      year,
      month,
      day
    })

    this.setState({ isSubmitLoading: false })
    
    switch (res?.status_code) {
      case 100000:
        this.props.history.push('/stepOne')
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.NameAndDobBirthdaySubmitErr'),
          duration: 4000,
        })
        this.setState({
          submitting: false
        })
        break;
      case 300008: //Your account is not qualified for this function.
        this.props.history.push('/');
        break;
      case 301004: //No logged in user.
        Toast.show({
          content: t('general.redirectLogin'),
          duration: 4000,
        })
        this.props.history.push('/login');
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErr'),
          duration: 4000,
        })
        this.setState({
          showDialog: true,
        })
        break;
      case 300009: //The format/value of parameter is not correct.
      case 300002: //Invalid parameter
      default:
        Toast.show({
          content: t('steps.NameAndDobBirthdaySubmitErr'),
          duration: 4000,
        })
        this.setState({
          submitting: false
        })
        break;
    }
  }

  get isFirstNameError() {
    const {
      isInitFirstName,
      isFirstNameIncludeChinese,
      firstName,
    } = this.state
    if (isInitFirstName) return false
    return firstName.length < 2 || firstName.length > 20 || isFirstNameIncludeChinese
  }

  get isLastNameError() {
    const {
      isInitLastName,
      isLastNameIncludeChinese,
      lastName,
    } = this.state
    if (isInitLastName) return false
    return lastName.length < 2 || lastName.length > 20 || isLastNameIncludeChinese
  }
  get isInitUserName() {
    const {
      isInitFirstName,
      isInitLastName,
    } = this.state
    return isInitFirstName && isInitLastName
  }

  get isUserNameError() {
    const {
      isInitFirstName,
      isInitLastName,
      firstName,
      lastName,
    } = this.state
    if (!isInitFirstName && (firstName.length < 2 || firstName.length > 20)) return true
    if (!isInitLastName && (lastName.length < 2 || lastName.length > 20)) return true
    return false
  }

  get nextDisabled() {
    const {
      firstName,
      lastName,
      year,
      month,
      day
    } = this.state
    if (firstName && lastName && year && month && day) {
      return firstName.length < 2 || firstName.length > 20 || lastName.length < 2 || lastName.length > 20
    } else {
      return true
    }
  }

  render() {
    const {
      list,
      firstName,
      lastName,
      submitting,
      showDialog,
      verifyStatus,
      isIncludeChinese,
      isFirstNameFocus,
      isLastNameFocus,
      isSubmitLoading,
    } = this.state
    const { selectedLang } =  this.props
    return (
      <div className='steps-page'>
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={verifyStatus} />
        </Mask>
        <StepHeader
          title={t('steps.NameAndDobTitle')}
          title1={t('steps.NameAndDobTitle1')}
          subTitle={t('steps.NameAndDobSubTitle')}
          subTitle1={t('steps.NameAndDobSubTitle1')}
          list={list}
        />
        <div className="user-info">
          <div className="user-name">
            <div className="input-box">
              <img src={requireImg} alt="" />
              <CInput
              className={["name", this.isFirstNameError ? "error" : "", isFirstNameFocus ? "active": ""].join(" ")}
              placeholder={t('steps.firstName')}
              onFocus={(e) => {
                this.setState({
                  isFirstNameFocus: true
                })
              }}
              onBlur={(e) => {
                this.setState({
                  isFirstNameFocus: false
                })
              }}
              onInputChange={(evt) => {
                const e = evt.target.value
                const reg = new RegExp('[\\u4E00-\\u9FA5]+','g')
                const includeChinese = reg.test(e)
                const currentValue = e.replace(/[^\w. ]/ig, '')
                this.setState({
                  isFirstNameIncludeChinese: includeChinese,
                  isIncludeChinese: includeChinese,
                  firstName: currentValue,
                  isInitFirstName: false
                })
              }}
              value={firstName}
              minLength={2}
              maxLength={20}
            />
            </div>
            <div className="input-box">
              <img src={requireImg} alt="" />
              <CInput
                className={["name", this.isLastNameError ? "error" : "", isLastNameFocus ? "active": ""].join(" ")}
                placeholder={t('steps.lastName')}
                onFocus={(e) => {
                  this.setState({
                    isLastNameFocus: true
                  })
                }}
                onBlur={(e) => {
                  this.setState({
                    isLastNameFocus: false
                  })
                }}
                onInputChange={(evt) => {
                  const e = evt.target.value
                  const reg = new RegExp('[\\u4E00-\\u9FA5]+','g')
                  const includeChinese = reg.test(e)
                  const currentValue = e.replace(/[^\w. ]/ig, '')
                  this.setState({
                    isLastNameIncludeChinese: includeChinese,
                    isIncludeChinese: includeChinese,
                    lastName: currentValue,
                    isInitLastName: false
                  })
                }}
                value={lastName}
                minLength={2}
                maxLength={20}
              />
            </div>
            
          </div>
          {this.isInitUserName && <div className="notice textAlignLeft">
            <InformationCircleFill className="notice-img" fontSize={14} color="#1F49DA"/>
            <span className={`notice-text ${selectedLang === "en_US" ? "is_en" : null}`} >
              {t('steps.NameAndDobNameletterHint')}
              </span>
          </div>}
          {this.isUserNameError && !isIncludeChinese && <div className="notice textAlignLeft notice-error">
            <span className={`notice-text ${selectedLang === "en_US" ? "is_en" : null}`}>
              {t('steps.NameAndDobNameLengthError')}
              </span>
          </div>}
          {isIncludeChinese && <div className="notice textAlignLeft notice-error">
            <span className={`notice-text ${selectedLang === "en_US" ? "is_en" : null}`}>
              {t('steps.NameAndDobNameletterTip')}
              </span>
          </div>}

          <Birthday className="user-birthday" setYear={(year) => {
            this.setState({
              year
            })
          }} setMonth={(month) => {
            this.setState({
              month
            })
          }} setDay={(day) => {
            this.setState({
              day
            })
          }}/>
        </div>
        <KYCFooter 
          isLoading={isSubmitLoading}
          disabled={this.nextDisabled || submitting}
          btnSubmit={this.submits}
          btnText={t('steps.nextButton')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
      </div>
    )
  }
}
export default connect(mapStateToProps, null)(Steps)
