import React, { Component } from 'react'
import { Swiper, Space, PageIndicator } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

import languageCn from '@assets/images/login/languageCn.png'
import languageEn from '@assets/images/login/languageEn.png'

import './index.less'
import { withRouter } from 'react-router-dom'

import { setAppGuideStatus } from '@redux/actions'
import { switchSelectedLang } from '@actions/user'
// import { t } from 'i18next'

const staticPath = 'https://static1.front.youworld.us/gs/login/guide/'
const cnGuide1 = `${staticPath}cn/1.gif`
const cnGuide2 = `${staticPath}cn/2.gif`
const cnGuide3 = `${staticPath}cn/3.gif`
const cnGuide4 = `${staticPath}cn/4.gif`
const cnGuide5 = `${staticPath}cn/5.gif`
const enGuide1 = `${staticPath}cn/1.gif`
const enGuide2 = `${staticPath}cn/2.gif`
const enGuide3 = `${staticPath}cn/3.gif`
const enGuide4 = `${staticPath}cn/4.gif`
const enGuide5 = `${staticPath}cn/5.gif`

// 引导： 隐藏储蓄账户以及礼品卡购买
const cnGuideContent = [
  {
    img: cnGuide1,
    style: {
      width: '343px',
      margin: '0px 21px'
    },
    title: '登录Tillstar™️钱包',
    subTitle: <div>海外超好用的钱包 <br /> 美金&人民币轻松消费，美金实时转账</div>,
    swiper: '滑动了解更多',
    switch: languageCn
  },
  // {
  //   img: cnGuide2,
  //   style: {
  //     width: '100%',
  //   },
  //   title: '首年超优收益 最高2.50%',
  //   subTitle: <div>零风险、高收益、高返现<br/><br/>存款消费双向收益<br/>资产每日增值</div>
  // },
  {
    img: cnGuide3,
    style: {
      width: '100%',
      // padding:'50px 34px'
    },
    title: '享双币支付美金商品',
    subTitle: <div>支持使用钱包进行美金、人民币消费<br /><br /> 可绑定到apple wallet线下消费
      <br />可在线上官网使用钱包消费 </div>
  },
  // {
  //   img: cnGuide4,
  //   style: {
  //     width: '100%',
  //   },
  //   title: '美金不限量购礼卡',
  //   subTitle: <div>支持双币，不限量购礼卡<br /><br /> 微信/支付宝/银联 购礼卡好汇率 <br />同时支持美金购卡不限量！</div>
  // },
  {
    img: cnGuide5,
    style: {
      width: '100%',
    },
    title: '美金余额实时转账  极速到账',
    subTitle: <div>Tillstar™️钱包内美金余额实时转账<br /><br /> 选择好友，一键转账美金 <br />手机号就能转～</div>
  }
]
const enGuideContent = [
  {
    img: enGuide1,
    style: {
      width: '343px',
      margin: '0px 21px'
    },
    title: 'LOG IN',
    subTitle: <div>Best choice for shopping overseas <br />Bi-currency shopping in US dollars and RMB <br />Instant US dollar transfer <br /></div>,
    swiper: 'Swipe',
    switch: languageEn
  },
  // {
  //   img: enGuide2,
  //   style: {
  //     width: '100%',
  //   },
  //   title: <div>24 hours bi-lingual <br/>customer service</div>,
  //   subTitle: <div> Bi-lingual customer service available<br /> 24 hours a day<br />Help with any of your questions<br />Convenient and safe shopping<br /></div>
  // },
  {
    img: enGuide3,
    style: {
      width: '100%',
      // padding:'50px 34px'
    },
    title: 'Bi-currency shopping',
    subTitle: <div>Make your payments in US dollars or RMB<br /><br />In-person payment with apple wallet<br />Online shopping with your Tillstar wallet <br /> </div>
  },
  // {
  //   img: enGuide4,
  //   style: {
  //     width: '100%',
  //   },
  //   title: 'Unlimited Gift Card Purchases in USD',
  //   subTitle: <div>Supports purchases in both currencies<br /><br />Great Exchange Rate with WeChat/Alipay/UnionPay purchases of gift cards unlimited gift card purchases in USD！</div>
  // },
  {
    img: enGuide5,
    style: {
      width: '100%',
    },
    title: 'Instant US dollar transfer',
    subTitle: <div>Instant transfer of US dollar balance in your Tillstar account<br /><br />One click transfer to a friend account<br />Transfer by phone number </div>
  }
]
class Guide extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      lang: 'zh_CN',
      guideContent: cnGuideContent
    }
  }
  componentDidMount() {
    this.setState({
      guideContent: window.localStorage.getItem("selectedLang") === 'en_US' ? enGuideContent : cnGuideContent,
      lang: window.localStorage.getItem("selectedLang")
    })
  }

  bindLoginClick() {
    this.props.store.dispatch(setAppGuideStatus(false))
    this.props.history.push('/login')
  }
  bindSwitchLang() {
    this.props.store.dispatch(switchSelectedLang(this.state.lang === 'en_US' ? 'zh_CN' : 'en_US'))
    this.setState({
      guideContent: this.state.lang === 'en_US' ? cnGuideContent : enGuideContent,
      lang: this.state.lang === 'en_US' ? 'zh_CN' : 'en_US',
    })

  }
  render() {
    const items = this.state.guideContent.map((item, index) => (
      <Swiper.Item key={index}>
        <div className='app-guide-item'>{
          index === 0
            ? <>
              <img className='switch' alt='' src={item.switch} onClick={this.bindSwitchLang.bind(this)} />
              <div className='app-guide-title' >{item.title}</div>
              <div className='app-guide-sub-title'>{item.subTitle}</div>
              <div className='swiper-text-tips'>{item.swiper} <RightOutline fontSize={12} color='#1F49DA' /></div>
              <img className='banner-image' src={item.img} alt='' style={item.style} />
            </>
            : <>
              <img className='banner-image' src={item.img} alt='' style={item.style} />
              <div className='app-guide-title normal' >{item.title}</div>
              <div className='app-guide-sub-title normal'>{item.subTitle}</div>
            </>
        }
        </div>
      </Swiper.Item>
    ))

    return (
      <div id='app-guide'>
        <Space direction='vertical' block>
          <Swiper
            indicator={() => null}
            onIndexChange={(index) => {
              this.setState({ currentIndex: index }

              )
            }}
            style={{
              '--track-padding': ' 0 0 16px',
            }}
            loop>{items}</Swiper>
        </Space>
        <div className='app-guide-banner-indicator'>
          <PageIndicator
            total={this.state.guideContent.length}
            current={this.state.currentIndex}
            style={{
              '--dot-color': '#C4C4C4',
              '--active-dot-color': '#FF3841;',
              '--dot-size': '10px',
              '--active-dot-size': '10px',
              '--dot-border-radius': '50%',
              '--active-dot-border-radius': '10px',
              '--dot-spacing': '8px',
            }} />
        </div>
        <div className='app-guide-login' onClick={this.bindLoginClick.bind(this)}>{this.state.lang === 'en_US' ? 'LOG IN' : '立即登录'}</div>
      </div>
    )
  }
}

export default withRouter(Guide)
