import React, { Component } from 'react'
import { EyeOutline, EyeInvisibleOutline, CloseCircleFill } from 'antd-mobile-icons'
import requireImg from '@assets/images/home/require.png'
import { updateIdentity } from '@api/active'
import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import './index.less'
import { scrollToTop } from "@utils/utils"
import { Mask, Input, Toast } from "antd-mobile";
import Tip from '@comps/Tip'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

class StepOne extends Component {
  state = {
    nextDisabled: true,
    placeholder: t('steps.SSNHint'),
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: true },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: false },
    ],
    submitting: false,
    showOrCloseSSN: false,
    ssn: '',
    isSSNFocus: false,
    showDialog: false,
    verifyStatus: 1,
    clientHeight: 0,
    isSubmitLoading: false
  }

  hideFooter = () => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
    // const bottomBtn = document.querySelector('#page-footer')
    // if (bottomBtn) {
    //   bottomBtn.style.display = document.body.clientHeight < clientHeight ? 'none' : 'block'
    // }
  }

  componentDidMount() {
    scrollToTop()
    this.setState({
      clientHeight: document.body.clientHeight
    })

    window.addEventListener('resize', this.hideFooter, true)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideFooter, true)
  }
  handleCloseTip = () => {
    this.setState({
      showDialog: false
    })
  }
  // 下一步
  handleNextButton = async () => {
    const { nextDisabled, submitting, ssn } = this.state
    if (nextDisabled || submitting) {
      return
    }
    this.setState({ submitting: true, isSubmitLoading: true })

    let identityNumber = ssn
    let verificationRequestId = localStorage.getItem('AuthorizationID')
    const res = await updateIdentity({
      identityNumber,
      verificationRequestId
    })
    this.setState({ isSubmitLoading: false })
  
    switch (res?.status_code) {
      case 100000:
        this.props.history.push("xStepTwo");
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.SSNErr'),
          duration: 4000,
        })
        this.setState({
          nextDisabled: false,
          submitting: false
        })
        break;
      case 300008: //Your account is not qualified for this function.
        this.props.history.push("/");
        break;
      case 301004: //No logged in user.
        Toast.show({
          content: t('general.redirectLogin'),
          duration: 4000,
        })
        this.props.history.push("/login");
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErr'),
          duration: 4000,
        })
        this.setState({
          showDialog: true,
        })
        break;
      case 300009: //The format/value of parameter is not correct.
      case 300002: //Invalid parameter
      default:
        Toast.show({
          content: t('steps.NameAndDobBirthdaySubmitErr'),
          duration: 4000,
        })
        this.setState({
          nextDisabled: false,
          submitting: false
        })
        break;
    }
  }

  get ssnInputValue() {
    const { ssn, showOrCloseSSN } = this.state
    let code = showOrCloseSSN ? '-' : ' '
    let temp = ''
    if(ssn.length <= 3) {
      temp = ssn
    } else if(ssn.length > 3 && ssn.length < 6) {
      temp = `${ssn.slice(0, 3)}${code}${ssn.slice(3, ssn.length)}`
    } else if(ssn.length > 5) {
      temp = `${ssn.slice(0, 3)}${code}${ssn.slice(3, 5)}${code}${ssn.slice(5, ssn.length)}`
    }
    return showOrCloseSSN ? temp : temp.replace(/[0-9]/g, '*')
  }

  render() {
    const {
      nextDisabled,
      placeholder,
      ssn,
      list,
      showDialog,
      submitting,
      showOrCloseSSN,
      verifyStatus,
      isSSNFocus,
      isSubmitLoading
    } = this.state
    return (
      <div className='stepOne-page'>
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={verifyStatus}/>
        </Mask>
        <div className="stepOne">
          <StepHeader
            title={t('steps.guideYW')}
            subTitle={<Trans i18nKey={t(`steps.SSNDesc`)}/>}
            list={list}
          />
          <div className='ssn-input-wrapper'>
            <div className="ssn-inner-wrapper">
              <img src={requireImg} alt="" />
              <Input
                className={["ssn-input", !showOrCloseSSN && this.ssnInputValue.length ? "star" : "", isSSNFocus ? "active": ""].join(" ")}
                placeholder={placeholder}
                type="tel"
                value={this.ssnInputValue}
                onFocus={(e) => {
                  this.setState({
                    isSSNFocus: true
                  })
                }}
                onBlur={(e) => {
                  this.setState({
                    isSSNFocus: false
                  })
                }}
                onChange={(e) => {
                  if (e.length === this.ssnInputValue.length - 1) {
                    this.setState({ ssn: ssn.slice(0, ssn.length - 1), nextDisabled: true })
                  } else if (ssn.length < 9 ) {
                    let currentChar = e.slice(-1)
                    const reg = new RegExp('[0-9]')
                    if (reg.test(currentChar)){
                      this.setState({
                        ssn: ssn + currentChar
                      }, () => {
                        if (this.state.ssn.length === 9) {
                          this.setState({ nextDisabled: false })
                        }
                      })
                    }
                  } else {
                    this.setState({ ssn })
                  }
                }}
                maxLength={20}
              />
             {this.ssnInputValue.length ? <CloseCircleFill className="clear-ssn-icon" fontSize={20} color="#E1E1E1" onClick={() => {
               this.setState({ssn: '', nextDisabled: true})
             }} /> : null}
            {showOrCloseSSN ?
            <EyeOutline className="ssn-eye" fontSize={22} color="#000" onClick={() => {this.setState({showOrCloseSSN: !showOrCloseSSN})}}/>:
            <EyeInvisibleOutline className="ssn-eye" fontSize={22} color="#000" onClick={() => {this.setState({showOrCloseSSN: !showOrCloseSSN})}}/>
            }
            </div>
          </div>
          <StepFooter 
            isLoading={isSubmitLoading}
            disabled={nextDisabled || submitting}
            btnSubmit={this.handleNextButton}
            btnText={t('steps.nextButton')}
            licenseInfoBottom={t('steps.guideFPB')}
          />
        </div>
      </div>
    )
  }
}

export default StepOne
