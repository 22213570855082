import {
  SET_COMMON_POP_CONTENT,
  SET_COMMONN_POP_STATUS,
  GET_COUNTRY_LIST,
  SET_KYC_ENTRANCE,
  SET_IS_NO_PERMISSION,
  SET_STOP_TAKING,
  SET_LOGIN_FROM_PAGE,
  SET_FROM_MSG,
  SET_ACTIVE_FROM_MSG,
  SET_HOME_EYE_STATUS
} from '../actions/ActionTypes'

const initialState = {
  pop_content_code: '',
  show_common_pop: false,
  pop_content_params: {},
  common_pop_status: [400013,400014,400015,400016,400017],
  countryCodeList: JSON.parse(localStorage.getItem('countryCodeList') || null) || [],
  kyc_entrance: localStorage.getItem('kycEntrance') || '/',
  is_no_permission: false,
  showStopTakingPop: false,
  stopTakingStartDate: "",
  stopTakingEndDate: "",
  fromMsg: JSON.parse(localStorage.getItem('fromMsg')) || false,
  activeFromMsg: JSON.parse(localStorage.getItem('activeFromMsg')) || false,
  homeEyeStatus: JSON.parse(localStorage.getItem('homeEyeStatus')) || false,
}


const common =  (state = initialState, action) => {
  switch(action.type) {
    case SET_COMMON_POP_CONTENT:
      console.log(action, 'action')
      return {
        ...state,
        pop_content_code: action.status_code,
        pop_content_params: action.params,
        show_common_pop: action.status_code !== 3008
      }
    case SET_COMMONN_POP_STATUS: 
      return {
        ...state,
        show_common_pop: false,
        pop_content_code: '',
        pop_content_params: {}
      }
    case GET_COUNTRY_LIST: 
      localStorage.setItem('countryCodeList', JSON.stringify(action.countryCodeList))
      return {
        ...state,
        countryCodeList: action.countryCodeList
      }
    case SET_KYC_ENTRANCE: 
      return {
        ...state,
        kyc_entrance: action.payload
      }
    case SET_IS_NO_PERMISSION: 
      return {
        ...state,
        is_no_permission: action.payload
      }
    case SET_STOP_TAKING:
      return {
        ...state,
        ...action.data
      }
    case SET_LOGIN_FROM_PAGE:
      return {
        ...state,
        fromData: action.data
      }
    case SET_FROM_MSG:
      localStorage.setItem('fromMsg', JSON.stringify(action.payload))
      return {
        ...state,
        fromMsg: action.payload
      }
    case SET_ACTIVE_FROM_MSG:
      localStorage.setItem('activeFromMsg', JSON.stringify(action.payload))
      return {
        ...state,
        activeFromMsg: action.payload
      }
    case SET_HOME_EYE_STATUS:
      return {
        ...state,
        homeEyeStatus: action.payload
      }
    default:
      return state
  }
}

export default common