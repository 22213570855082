import React, { useState } from 'react'
import { NavBar, Space, Toast } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import './index.less'
import { useHistory } from 'react-router-dom'
import ActivateAlert from '@comps/ActivateAlert'
export default function Header() {
  const history = useHistory()
  const back = () => history.goBack()
  const [showStopActiveFlowDialog, setShowStopActiveFlowDialog] = useState(false)

  const cancel = () => setShowStopActiveFlowDialog(true)
  const right = (
    <div style={{ fontSize: 20 }} >
      <Space style={{ '--gap': '18px' }} onClick={cancel}>
        <CloseOutline />
      </Space>
    </div>
  )
  const handleCloseTip = () => setShowStopActiveFlowDialog(false)
  const handleConfirm = () => history.push('/')
  return (
    <div className='navbar-header'>
      <NavBar onBack={back} right={right}></NavBar>
      {showStopActiveFlowDialog && <ActivateAlert onClose={handleCloseTip} handleConfirm={handleConfirm} />}

    </div>
  )
}
