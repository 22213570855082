import flow from "@assets/images/rmb/order/flow.png"
import wechat from "@assets/images/rmb/icons/order_wechat.png"
import alipay from "@assets/images/rmb/icons/alipay.png"
import union from "@assets/images/rmb/icons/union.png"
import flowStore from "@assets/images/rmb/order/flow_store.png"
import flowUsEnabled  from "@assets/images/rmb/order/flow_us_enabled.png"
import flowEnabled from "@assets/images/rmb/order/flow_enabled.png"

import merchantIcon from "@assets/images/rmb/order/merchant_icon.png"
import rmbIcon from "@assets/images/rmb/order/rmb.png"
import rmbIconEnabled from "@assets/images/rmb/order/rmb_enabled.png"


import freezeing from "@assets/images/rmb/order/freezeing.png"
import freezeReceipt from "@assets/images/rmb/order/freeze_receipt.png"

import React from 'react'

import './index.less'
import { useTranslation } from "react-i18next"

const rmbMethodsType = {
  wechat: {
    img: wechat
  },
  alipay: {
    img: alipay
  },
  unionpay: {
    img: union
  }
}
const RmbPurchaseFlow = (props) => {
  const {transactionDetail, t} = props
  const rmbTyps = rmbMethodsType[transactionDetail.pay_type]
  return (
    <>
      <div className="flow-item">
        <img className="flow-icon" src={rmbTyps.img} alt="" />
        <span>{t(`rmb.${transactionDetail.pay_type}`)}{t(`rmb.pay`)}</span>
      </div>
      <div className="flow-item">
        <img className="flow-verctor" src={flowEnabled} alt=""/>
      </div>
      <div className="flow-item">
        <img className="flow-icon" src={flowUsEnabled} alt="" /> 
        <span>美金消费金</span>
      </div>
      <div className="flow-item">
        <img className="flow-verctor" src={flowEnabled} alt=""/>
      </div>
      <div className="flow-item">
          <img className="flow-icon" src={flowStore}  alt=''/><span>等待商户核销</span>
        </div>
    </>
  )
}
const USDConsumptionFlow = (props) => {
  const {transactionDetail, t} = props
  const rmbEnabled = [5014, 5015, 5016, 6009, 6010, 6018].includes(transactionDetail.status)
  
  return (
    <>
      {
        rmbEnabled ? 
          <>
            <div className="flow-item">
                <img className="flow-icon" src={flowUsEnabled} alt="" />
                <span>{t('transaction_detail.flow.usd_text')}</span>
            </div>
            <div className="flow-item">
              <img className="flow-verctor" src={flowEnabled} alt=""/>
            </div>
            <div className="flow-item"> 
                <img className="flow-icon" src={merchantIcon} alt="" /> 
                <span>{t('transaction_detail.flow.merchant_status')}</span>
            </div>
            <div className="flow-item"><img className="flow-verctor" src={flowEnabled} alt=""/></div>
            <div className="flow-item">
              <img className="flow-icon" src={rmbIconEnabled}  alt=''/><span style={{color:'#000'}}>{t('transaction_detail.flow.switch_rmb')}</span>
            </div>
          </>
        :
        <>
            <div className="flow-item">
                <img className="flow-icon" src={flowUsEnabled} alt="" />
                <span>{t('transaction_detail.flow.usd_text')}</span>
            </div>
            <div className="flow-item">
              <img className="flow-verctor" src={flowEnabled} alt=""/>
            </div>

            <div className="flow-item"> 

                <img className="flow-icon" src={merchantIcon} alt="" /> 
                <span>{t('transaction_detail.flow.merchant_status')}</span>
     
            </div>
            <div className="flow-item"><img className="flow-verctor" src={flow} alt=""/></div>
            <div className="flow-item">

                <img className="flow-icon" src={rmbIcon}  alt=''/><span >{t('transaction_detail.flow.switch_rmb')}</span>
            </div>
          </>
      }

    </>
  )
}
const USDRmbRefundFlow = (props) => {
  const {t} = props
  return (
    <>
      <div className="flow-item"><img className="flow-icon" src={flowUsEnabled} alt="" /><span>{t('transaction_detail.flow.tillstar')}</span></div>
      <div className="flow-item"><img className="flow-verctor" src={flowEnabled} alt=""/></div>
      <div className="flow-item"><img className="flow-icon" src={rmbIconEnabled} alt="" /><span>{t('transaction_detail.flow.rmb_payment')}</span></div>
    </>
  )
}
const FreezeRefundFlow = (props) => {
  const {t} = props
  return (
    <>
      <div className="flow-item"><img className="flow-icon" src={freezeing} alt="" /><span>{t('transaction_detail.flow.freezeing')}</span></div>
      <div className="flow-item"><img className="flow-verctor" src={flowEnabled} alt=""/></div>
      <div className="flow-item"><img className="flow-icon" src={freezeReceipt} alt="" /> </div><span>{t('transaction_detail.flow.freeze_receipt')}</span>
    </>
  )
}

export default function Flow(props) {
  const { transactionDetail } = props
  const status = transactionDetail.status
  const  {t} = useTranslation()
  const showRmbFlow = [5002, 5009, 5010, 5011, 5012, 5013, 5014,  5017, 6008, 6009, 6011, 5015, 5016, 6018, 6010].includes(status)
  const showUsdRmbRefundFlow = [6012, 6013, 6014].includes(status)
  const showFreezeRefuudFlow =  RegExp(/80/).test(status)

  return (
    <div id="rmb-flow" style={{padding: (showUsdRmbRefundFlow || showFreezeRefuudFlow) ? '0 80px': '0 21px'}}>
      {
        status === 6003
        && <RmbPurchaseFlow transactionDetail={transactionDetail} t={t} />
      }
      {
        showRmbFlow
        && <USDConsumptionFlow transactionDetail={transactionDetail} t={t} />
      }
      {
        showUsdRmbRefundFlow
        && <USDRmbRefundFlow transactionDetail={transactionDetail} t={t} />
      }
      {
        showFreezeRefuudFlow
        && <FreezeRefundFlow  transactionDetail={transactionDetail} t={t} />
      }
    </div>
  )
}