import openWallet from '@assets/images/faq/main/openWallet.png'
import linkBanks from "@assets/images/faq/main/linkBanks.png"
import usewallet from "@assets/images/faq/main/usewallet.png"
import linkApplePay from "@assets/images/faq/main/applePay.png"
import useApplePay from "@assets/images/faq/main/useApplePay.png"
import linkGooglePay from "@assets/images/faq/main/googlepay.png"
import rmbPayment from "@assets/images/faq/main/rmbPayment.png"
import buyCard from "@assets/images/faq/main/buyCard.png"
import useCard from "@assets/images/faq/main/useCard.png"

import qa1 from "@assets/images/faq/main/qa/1.png"
import qa2 from "@assets/images/faq/main/qa/2.png"
import qa3 from "@assets/images/faq/main/qa/3.png"
import qa4 from "@assets/images/faq/main/qa/4.png"
import qa5 from "@assets/images/faq/main/qa/5.png"
import qa6 from "@assets/images/faq/main/qa/6.png"
import qa7 from "@assets/images/faq/main/qa/7.png"
import qa8 from "@assets/images/faq/main/qa/8.png"

import qa11 from "@assets/images/faq/main/qa/1_1.png"
import qa21 from "@assets/images/faq/main/qa/2_1.png"
import qa31 from "@assets/images/faq/main/qa/3_1.png"
import qa41 from "@assets/images/faq/main/qa/4_1.png"
import qa51 from "@assets/images/faq/main/qa/5_1.png"
import qa71 from "@assets/images/faq/main/qa/7_1.png"
import qa81 from "@assets/images/faq/main/qa/8_1.png"


import faqBanner from "@assets/images/faq/faq_banner.png"

const initialState = {
  faq_banner: faqBanner,
  tutorials: [
    {
      logo: useCard,
      type: 'tutorials',
      key: 'addShortcut'
    },
    {
      logo: openWallet,
      type: 'tutorials',
      key: 'openWallet'
    },
    {
      logo: linkBanks,
      type: 'tutorials',
      key: 'linkBanks'
    },
    {
      logo: usewallet,
      type: 'tutorials',
      key: 'walletSpending'
    },
    {
      logo: linkApplePay,
      type: 'tutorials',
      key: 'linkApplePay'
    },
    {
      logo: linkGooglePay,
      type: 'tutorials',
      key: 'linkGooglePay'
    },
    {
      logo: useApplePay,
      type: 'tutorials',
      key: 'useApplePay'
    },
    {
      logo: rmbPayment,
      type: 'tutorials',
      key: 'rmbPayment'
    },
    // 隐藏礼卡相关的教程信息
    // {
    //   logo: buyCard,
    //   type: 'tutorials',
    //   key: 'buyCard'
    // },
    // {
    //   logo: useCard,
    //   type: 'tutorials',
    //   key: 'useCard'
    // }
  ],
  qa: [
    {
      logo: qa1,
      type: 'qa',
      icon: qa11,
      content: [1, 2, 3]
    },
    {
      logo: qa2,
      type: 'qa',
      icon: qa21,
      content: [1, 2, 3, 4, 5]
    },
    {
      logo: qa3,
      type: 'qa',
      icon: qa31,
      content: [1, 2, 3]
    },
    {
      logo: qa4,
      type: 'qa',
      icon: qa41,
      content: [1, 2, 3, 4, 5, 6]
    },
    // 隐藏礼卡以及储蓄账户相关的Faq信息
    // {
    //   logo: qa5,
    //   type: 'qa',
    //   icon: qa51,
    //   content: [1,2,3,4,5,6,7,8,9]
    // },
    // {
    //   logo: qa6,
    //   type: 'qa'
    // },
    // {
    //   logo: qa7,
    //   type: 'qa',
    //   icon: qa71,
    //   content: [1, 2, 3, 4]
    // },
    // {
    //   logo: qa8,
    //   type: 'qa',
    //   icon: qa81,
    //   content: [1,2,3,4,5,6,7]
    // }
  ]
}
const faq = (state = initialState, action) => {
  return state
}

export default faq