import React, { Component } from 'react'
import { getUserInfo, getPermissionByCode } from '@api/user'
import Tip from '@comps/Tip'
import { RightOutline } from 'antd-mobile-icons'
import { NavBar, Toast, Mask } from 'antd-mobile'

import { scrollToTop } from "@utils/utils"
import './index.less'
import { connect } from 'react-redux'
import { setPasswordRelatedChannel } from '@actions/password'
import { getModuleStatus } from "@api/common"
import { t } from 'i18next'
const mapDispatchToProps = {
  setPasswordRelatedChannel,
}
class Setting extends Component {
  state = {
    isSetPassword: true,
    phoneNumber: '',
    isSetTransactionPin: false,
    isFreeze: false,
    verifyStatus: undefined,
    showDialog: false,
    activegGalileoAccounts: undefined,
    userInfoR: undefined,
    lang:localStorage.getItem("selectedLang"),
    verifyRequestStatus: undefined
  }
  goBack = () => {
    this.props.history.push({ pathname: '/mine', state: { router: 'setting' } })
  }

  // 交易密码
  handleTradePassword = () => {
    const { isSetTransactionPin, isFreeze } = this.state
    if (isFreeze) {
      Toast.show({
        content: t('realAuthentication.dollaraccountfrozen'),
        duration: 1000,
      })
      return
    }
    if (isSetTransactionPin) {
      this.props.history.push('/tradePassword')
    } else {
      this.props.setPasswordRelatedChannel({
        channel: 'normalSetting',
        type: 'set',
        url: '/setting',
        backUrl: '/setting'
      })
      this.props.history.push('/verifyCode')
    }
  }

  // 账户冻结
  handleFreezeAccount = async () => {
    const { isFreeze, verifyStatus,userInfoR } = this.state

    if (isFreeze) {
      Toast.show({
        content: t('realAuthentication.dollaraccountfrozen'),
        duration: 1000,
      })
      return
    }
    if (verifyStatus === 3 || (verifyStatus === 4 && userInfoR?.response_data?.active_financial_accounts === 0 && [4, 9].includes(userInfoR?.response_data?.identity_verification_request_status))) {
      Toast.show({
        content: t('account.settingPageAccountFailedErr'),
        duration: 2000,
      })
      return
    }
    if ([0, 1].includes(verifyStatus)) {
      let result = await getModuleStatus('module_kyc');
      if (result.status_code === 300012) return  
      
      this.setState({
        showDialog: true
      })
      return
    }
    if (verifyStatus === 2) {
      Toast.show({
        content: t('account.settingPageAccountInOpeningErr'),
        duration: 1000,
      })
      return
    }
    this.props.history.push('/freezeAccount')
  }

  verifyAccountStatus = async () => {
    const { verifyStatus, isFreeze, userInfoR } = this.state

    if (isFreeze) {
      Toast.show({
        content: t('realAuthentication.dollaraccountfrozen'),
        duration: 1000,
      })
      return false
    }
    if (verifyStatus === 3 || (verifyStatus === 4 && userInfoR?.response_data?.active_financial_accounts === 0 && [4, 9].includes(userInfoR?.response_data?.identity_verification_request_status))) {
      Toast.show({
        content: t('account.settingPageAccountFailedErr'),
        duration: 1000,
      })
      return false
    }
    if ([0, 1].includes(verifyStatus)) {
      let result = await getModuleStatus('module_kyc');
      if (result.status_code === 300012) return false
  
      this.setState({
        showDialog: true
      })
      return false
    }
    if (verifyStatus === 2) {
      Toast.show({
        content: t('account.settingPageAccountInOpeningErr'),
        duration: 2000,
      })
      return false
    }
    return true
  }

  //管理账户
  manageAccount = async () => {
    const isGoNext = await this.verifyAccountStatus()

    if (!isGoNext) return
    try {
      const res = await getPermissionByCode({ permissionCode: 'permission_external_account'})
      if (res.status_code === 300008) return
      this.props.history.push('/management')
    } catch (error) {
      console.log(error)
    }
  }

  manageAddress = async () => {
    const isGoNext = await this.verifyAccountStatus()
    if (!isGoNext) return
    this.props.history.push('/myAddress')
  }
  
  handleCloseTip = () => {
    this.setState({
      showDialog: false,
    })
  }

  async componentDidMount() {
    scrollToTop()
    
    sessionStorage.removeItem("recognition")
    sessionStorage.setItem("password", "password")
    const userInfoRes = await getUserInfo()
    this.setState({
      userInfoR: userInfoRes
    })
    if (userInfoRes.response_data) {
      this.setState({
        isSetTransactionPin: userInfoRes?.response_data?.is_transaction_pin_set,
        verifyStatus: userInfoRes?.response_data?.identity_verification_status,
        isFreeze: userInfoRes?.response_data?.default_financial_account?.status === 2,
        activegGalileoAccounts: userInfoRes?.response_data?.active_galileo_accounts,
        verifyRequestStatus: userInfoRes?.response_data?.identity_verification_request_status,
        externalAccounts: userInfoRes?.response_data?.bound_external_accounts,
      })
    }
    let phoneNumberStr = localStorage.getItem('phoneNumberStr')
    phoneNumberStr && this.setState({
      phoneNumber: phoneNumberStr
    })
  }

  render() {
    const { isSetTransactionPin, phoneNumber, isFreeze, showDialog, verifyStatus, verifyRequestStatus } = this.state
    let frozenContent = ''
    if ([0, 1, 3].includes(verifyStatus) || (verifyStatus === 4 && [4, 9].includes(verifyRequestStatus))) {
      frozenContent = <span>{t('account.notactivated')}</span>
    } else if (verifyStatus === 2) {
      frozenContent = <span>{t('account.beingactivated')}</span>
    } else if (verifyStatus === 4) {
      frozenContent = <span>{t('account.SecureAccount')}</span>
    }
    const frozenContentArr = [frozenContent, <RightOutline fontSize={14} color='#999'/>]
    
    return (
      <div className="setting" style={{position: "relative", top: "-12px"}}>
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={verifyStatus} />
        </Mask>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          onBack={this.goBack}
        >
           {t('account.SecuritySettings')}
        </NavBar>
        <div className="client-container common-container d-flex align-items-center justify-content-between">
          <span className="label">
            {t('account.Bindingphone')}
          </span>
          <div className="d-flex align-items-center">
            <span>{phoneNumber}</span>
          </div>
        </div>

        <div className={["client-container common-container d-flex align-items-center justify-content-between pointer", isFreeze ? "setting-freeze" : null].join(' ')} onClick={this.handleTradePassword}>
          <span className="label">
            {t('account.TradePin')}
          </span>
          <div className="d-flex align-items-center">
            {isSetTransactionPin ? null : <span>
              {t('account.Temporarilynotset')}
            </span>}
            <RightOutline fontSize={14} color='#999'/>
          </div>
        </div>
        <div className={["client-container common-container  manage-container", isFreeze ? "setting-freeze" : null].join(' ')}>
          <div className="account pointer d-flex align-items-center justify-content-between" onClick={this.manageAccount}>
            <span className="label">
              {t('account.LinkedBanks')}
              </span>
            <div className="d-flex align-items-center">
            <RightOutline fontSize={14} color='#999'/>
            </div>
          </div>
          <div className="address pointer d-flex align-items-center justify-content-between" onClick={this.manageAddress}>
            <span className="label">
              {t('account.LinkedAddresses')}
              </span>
            <div className="d-flex align-items-center">
              <RightOutline fontSize={14} color='#999'/>
            </div>
          </div>
          
        </div>
        <div className="client-container common-container d-flex align-items-center justify-content-between pointer" onClick={this.handleFreezeAccount}>
          <span className="label">
          {t('account.Accountisfrozen')}
          </span>
          <div className="d-flex align-items-center">
          { 
          isFreeze ? <span>{t('account.hasbeenfrozen')}</span> :
          frozenContentArr
          }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Setting)
