import React, { useState, useEffect } from 'react'
import { NavBar, Tabs } from 'antd-mobile'
import './index.less'

import { useHistory, useLocation } from 'react-router-dom'

const staticPath = 'https://static1.front.youworld.us/gs/tutorials'

const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1 //android终端

const tabsData = [
  {
    key: 'addShortcut',
    title: '添加到首页快捷方式',
    en_title: '添加到首页快捷方式',
    // android图片更新pending

    // imgsCn: [
    //   isAndroid ? `${staticPath}/shortcut/android.png` : `${staticPath}/shortcut/iOS.png`
    // ],
    // imgsEn: [
    //   isAndroid ? `${staticPath}/shortcut/androidEN.png` : `${staticPath}/shortcut/iOSEN.png`
    // ]
    imgsCn: [
      isAndroid ? `${staticPath}/shortcut/iOS.png` : `${staticPath}/shortcut/iOS.png`
    ],
    imgsEn: [
      isAndroid ? `${staticPath}/shortcut/iOSEN.png` : `${staticPath}/shortcut/iOSEN.png`
    ]
  },
  {
    key: 'openWallet',
    title: '激活Tillstar钱包',
    en_title: 'Open Tillstar wallet',
    imgsCn: [
      `${staticPath}/activation/cn_1.png`,
      `${staticPath}/activation/cn_2.png`,
      `${staticPath}/activation/cn_3.png`
    ],
    imgsEn: [
      `${staticPath}/activation/en_1.png`,
      `${staticPath}/activation/en_2.png`,
      `${staticPath}/activation/en_3.png`
    ]
  },
  {
    key: 'linkBanks',
    title: '关联银行账户',
    en_title: 'Link banks',
    imgsCn: [
      `${staticPath}/bank/cn_1.png`,
      `${staticPath}/bank/cn_2.png`,
      `${staticPath}/bank/cn_3.png`,
      `${staticPath}/bank/cn_4.png`,
      `${staticPath}/bank/cn_5.png`
    ],
    imgsEn: [
      `${staticPath}/bank/en_1.png`,
      `${staticPath}/bank/en_2.png`,
      `${staticPath}/bank/en_3.png`,
      `${staticPath}/bank/en_4.png`,
      `${staticPath}/bank/en_5.png`
    ],
  },
  {
    key: 'walletSpending',
    title: 'Tillstar钱包消费',
    en_title: 'Tillstar钱包消费',
    imgsCn: [
      `${staticPath}/tillstar/cn_1.png`,
      `${staticPath}/tillstar/cn_2.png`,
      `${staticPath}/tillstar/cn_3.png`
    ],
    imgsEn: [
      `${staticPath}/tillstar/en_1.png`,
      `${staticPath}/tillstar/en_2.png`,
      `${staticPath}/tillstar/en_3.png`
    ]
  },
  {
    key: 'linkApplePay',
    title: '添加到Apple Pay',
    en_title: 'Link Apple Pay',
    imgsCn: [
      `${staticPath}/apple/cn_1.png`,
      `${staticPath}/apple/cn_2.png`,
      `${staticPath}/apple/cn_3.png`,
      `${staticPath}/apple/cn_4.png`
    ],
    imgsEn: [
      `${staticPath}/apple/en_1.png`,
      `${staticPath}/apple/en_2.png`,
      `${staticPath}/apple/en_3.png`,
      `${staticPath}/apple/en_4.png`
    ]
  },
  {
    key: 'linkGooglePay',
    title: '添加到Google Pay',
    en_title: 'Link Google Pay',
    imgsCn: [
      `${staticPath}/google/cn_1.png`,
      `${staticPath}/google/cn_2.png`,
      `${staticPath}/google/cn_3.png`
    ],
    imgsEn: [
      `${staticPath}/google/en_1.png`,
      `${staticPath}/google/en_2.png`,
      `${staticPath}/google/en_3.png`
    ]
  },
  {
    key: 'useApplePay',
    title: '使用Apple Pay',
    en_title: '使用Apple Pay',
    imgsCn: [
      `${staticPath}/applePay/cn_1.png`,
      `${staticPath}/applePay/cn_2.png`,
      `${staticPath}/applePay/cn_3.png`
    ],
    imgsEn: [
      `${staticPath}/applePay/en_1.png`,
      `${staticPath}/applePay/en_2.png`,
      `${staticPath}/applePay/en_3.png`
    ]
  },
  {
    key: 'rmbPayment',
    title: '人民币消费',
    en_title: 'RMB Payment',
    imgsCn: [
      `${staticPath}/rmb/cn_1.png`,
      `${staticPath}/rmb/cn_2.png`,
      `${staticPath}/rmb/cn_3.png`,
      `${staticPath}/rmb/cn_4.png`,
      `${staticPath}/rmb/cn_5.png`,
      `${staticPath}/rmb/cn_6.png`,
    ],
    imgsEn: [
      `${staticPath}/rmb/en_1.png`,
      `${staticPath}/rmb/en_2.png`,
      `${staticPath}/rmb/en_3.png`,
      `${staticPath}/rmb/en_4.png`,
      `${staticPath}/rmb/en_5.png`,
      `${staticPath}/rmb/en_6.png`,
    ]
  },
  // {
  //   key: 'buyCard',
  //   title: '如何购买礼卡',
  //   en_title: 'How to buy card',
  //   imgsCn: [
  //     `${staticPath}/buycard/cn_1.png`,
  //     `${staticPath}/buycard/cn_2.png`,
  //     `${staticPath}/buycard/cn_3.png`,
  //     `${staticPath}/buycard/cn_4.png`,
  //     `${staticPath}/buycard/cn_5.png`
  //   ],
  //   imgsEn: [
  //     `${staticPath}/buycard/en_1.png`,
  //     `${staticPath}/buycard/en_2.png`,
  //     `${staticPath}/buycard/en_3.png`,
  //     `${staticPath}/buycard/en_4.png`,
  //     `${staticPath}/buycard/en_5.png`
  //   ]
  // },
  // {
  //   key: 'useCard',
  //   title: '如何使用礼卡',
  //   en_title: 'How to use card',
  //   imgsCn: [
  //     `${staticPath}/usecard/cn_1.png`,
  //     `${staticPath}/usecard/cn_2.png`,
  //     `${staticPath}/usecard/cn_3.png`,
  //     `${staticPath}/usecard/cn_4.png`
  //   ],
  //   imgsEn: [
  //     `${staticPath}/usecard/en_1.png`,
  //     `${staticPath}/usecard/en_2.png`,
  //     `${staticPath}/usecard/en_3.png`,
  //     `${staticPath}/usecard/en_4.png`
  //   ]
  // },
  // {
  //   key: 'VIPLevel',
  //   title: 'VIP分级',
  //   en_title: 'VIP Level',
  //   imgsCn: [
  //     vipCn1,
  //     vipCn2,
  //     vipCn3,
  //     vipCn4,
  //     vipCn5
  //   ],
  //   imgsEn: [
  //     vipEn1,
  //     vipEn2,
  //     vipEn3,
  //     vipEn4,
  //     vipEn5
  //   ],
  // },
  // {
  //   key: 'refundRules',
  //   title: '退款细则',
  //   en_title: 'Refund Rules',
  //   imgsCn: [
  //     `${staticPath}/refund/cn_1.png`,
  //     `${staticPath}/refund/cn_2.png`,
  //     `${staticPath}/refund/cn_3.png`
  //   ],
  //   imgsEn: [
  //     `${staticPath}/refund/en_1.png`,
  //     `${staticPath}/refund/en_2.png`,
  //     `${staticPath}/refund/en_3.png`
  //   ],
  // }
]
export default function Tutorials() {
  const history = useHistory()
  const location = useLocation()
  const [lang, setLang] = useState('en_US')
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
    setLang(localStorage.getItem('selectedLang'))
    const search = new URLSearchParams(location.search)
    const currentKey = search?.get('key') ? search?.get('key') : 'addShortcut'
    const currentIndex = tabsData.findIndex((item) => item.key === currentKey)
    setActiveIndex(currentIndex.toString())
  }, [])

  const back = () => {
    history.goBack()
  }

  return (
    <div id='app-tutorials'>
      <NavBar onBack={back}> {lang === 'en_US' ? 'Tutorial' : '教程'}</NavBar>
      <Tabs
        activeLineMode='fixed'
        activeKey={activeIndex}
        onChange={(index) => {
          setActiveIndex(index)
          history.replace(`/tutorials?key=${tabsData[index]['key']}`)
        }}
      >
        {tabsData.map((item, index) =>
          <Tabs.Tab title={lang === 'en_US' ? item.en_title : item.title} key={index} />
        )}
      </Tabs>
      <div className="tab-content">
        {
          lang === 'en_US'
            ? tabsData[activeIndex].imgsEn.map((imgsItem, imgsIndex) => <img src={imgsItem} alt='' key={imgsItem} />)
            : tabsData[activeIndex].imgsCn.map((imgsItem, imgsIndex) => <img src={imgsItem} alt='' key={imgsItem} />)
        }
      </div>
    </div>
  )

}
