import React, { Component } from 'react'
import { verifyTransactionPin } from '@api/user'
import ExceptionPop from '@comps/ExceptionPop'
import { NumberKeyboard, PasscodeInput, Toast, NavBar, Mask } from 'antd-mobile'
import { scrollToTop } from "@utils/utils"
import { connect } from 'react-redux'
import { CloseOutline } from 'antd-mobile-icons'
import { setActionToken, setPasswordRelatedChannel } from '@actions/password'
import { t } from 'i18next'
import './index.less'

import FreezePasswordPop from "@comps/FreezePasswordPop"

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
  }
}

const mapDispatchToProps = {
  setActionToken,
  setPasswordRelatedChannel
}
class EditTransactionPassword extends Component {
  state = {
    title: '设置密码', 
    password: '',
    error: false,
    isExceptionPopVisible: false,
    isPasswordFreeze: false
  }

  passcodeInputRef = React.createRef()

  componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.passcodeInputRef.current.focus()
  }
  goBack = () => {
    const { passwordRelatedChannel, history } = this.props
    if (passwordRelatedChannel.type === 'reset') {
      history.replace('/resetTransactionPasswordA')
    } else {
      history.replace('/tradePassword')
    }
  }
  onPasswordChange = async (code) => {
    if(code.length === 6){
      this.passcodeInputRef.current.blur()
      this.setState({
        password: code
      })
      const res = await verifyTransactionPin({
        actionCode: 'UpdateTransactionPin_VerifyCurrentPin',
        transactionPin: code
      })
      switch (res.status_code) {
        case 100000: 
          Toast.show({
            content: t('home.pinVerifiedSuccess'),
          })
          if(res.response_data) {
            this.props.setActionToken(res.response_data.token)
            this.props.history.push('/editTransactionPasswordB')
          }
          break;
        // case 300001:
        //   this.setState({
        //     error: true
        //   })
        //   this.passcodeInputRef.current.focus()
        //   break;
        // case 300002:
          // this.setState({
          //   error: true
          // })
        //   break;
        case 301006:
          this.setState({
            isExceptionPopVisible: true,
            error: true
          })
          break;
        case 301008:
          Toast.show({
            content: t("transactionPin.transactionPinNotSet"),
          })
          break;
        case 301016: 
          // Toast.show({
          //   content: t("transactionPin.exceedWrongPinLimit"),
          // })
          this.setState({
            isPasswordFreeze: true,
            error: true
          })
          break;
        default: 
          Toast.show({
            content: t("transactionPin.incorrectPIN"),
          })
          this.setState({
            error: true
          })
          break;
      }
    } else if(code.length === 5){
      this.setState({
        password: this.state.error ? '': code,
        error: false
      })
    } else {
      this.setState({
        password: code,
        error: false
      })
    }
  }
  render() {
    const { error, isExceptionPopVisible, password, isPasswordFreeze } = this.state
    return (
      <div className='setting-password-a-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          back={null}
          right={<CloseOutline onClick={this.goBack} fontSize={24}/>}
        >
          {t("setPassword.Changetransactionpassword")}
        </NavBar>
        <section className="setting-password-a d-flex flex-column align-items-center">
          <div className="title-warp-setting-password-a">
            <p>
              {t("setPassword.Verifytransactionpassword")}
            </p>
            <div className="sub-title-setting-password-a">
              {t("setPassword.dentitybeforechanging")}
            </div>
          </div>
          <div className='error-message'>{ error && t("resetPin.PreviousPinErr")}</div>
           
          <div className='verification-code'>
            <PasscodeInput
              ref={this.passcodeInputRef}
              value={password}
              length={6}
              seperated
              keyboard={<NumberKeyboard randomOrder={true}/>}
              onChange={this.onPasswordChange}
              error={error}
              style={{
              '--cell-gap': '12px',
            }}/>
          </div>
          <div className="varification-tips" onClick={() => {
            this.props.setPasswordRelatedChannel({
              channel: 'normalSetting',
              type: 'reset',
              url: '/tradePassword',
              backUrl: '/editTransactionPassword',
            })
            this.props.history.push('/resetTransactionPasswordA')
          }}>
              {t("transfer.Forgotpassword")}
          </div>
        </section>

        <Mask visible={isPasswordFreeze}>
          <FreezePasswordPop
            closeFreezePop={() => {
              this.setState({ isPasswordFreeze: false }, () => {
                this.props.history.push("/")
              })
            }}
          />
        </Mask>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>
      
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionPassword)
