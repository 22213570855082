import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "../../router";
import FrontendAuth from "../../router/frontendAuth";
// import MyTabbar from '@comps/MyTabbar'
import Guide from "./Guide";
import './index.less'

import store from "../../store";

import { setAppGuideStatus } from "../../store/action";
import RightPop from '@comps/RightPop'
import StopTakingPop from '@comps/StopTakingPop'

export default class App extends Component {
  constructor(props) {
    super(props)
    store.subscribe(()=>{
      this.setState(store.getState())
    })
    window.zE('messenger', 'close')
  }

  componentDidMount() {
    // const userInfoRes = await getUserInfo().catch(error => { console.log(error);});
    const isLogin = localStorage.getItem('token')

    if(isLogin) {
      store.dispatch(setAppGuideStatus(false))
    } else {
      store.dispatch(setAppGuideStatus(true))
    }
  }
  render() {
    const { guide } =  store.getState()
    const paths = [
      '/savingAccount/about',
      '/landingPage'
    ]

    return (
      <Router>
        {
          (paths.includes(window.location.pathname) || !guide.appGuideStatus) ? <div>
          <Switch>
              <FrontendAuth routerConfig={routes} />
          </Switch> 
        </div> :  <div>
            <Guide store={store} />
          </div>
        }

        {
          <RightPop/>
        }

        {
          <StopTakingPop />
        }
      </Router>
    )
  }
}