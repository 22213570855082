import React, { Component } from 'react'
import { Toast,Mask } from 'antd-mobile'
import prFrontBG from "@assets/images/certificate/pic_prfront.png"
import prBackBG from "@assets/images/certificate/pic_prfront.png"
import camera from "@assets/images/activation/icon_paishe_white.png"
import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import { onfio } from "@api/netki"
import qs from 'qs'
import './index.less'
import Activationsamples from '@comps/Activationsamples'
import { transformImageFile, scrollToTop} from "@utils/utils"
import { t } from 'i18next'

export default class StepEight extends Component {
  state = {
    bool: true, //控制多次点击
    value: '',
    isBtnDisabled: true,
    isSendCode: true,
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: true },
    ],
    picture: "",
    pictured: "",
    isHas: true,
    isFalse: true,
    uploading: false,
    visible: true,
  }

  componentDidMount() {
    scrollToTop()
  }

  chuanOne = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tps").src = reader?.result
      }
      this.setState({
        pictured: e.target?.files[0],
        isHas: false
      })
      transformImageFile(e.target?.files[0], this.afterCompressOne);
    } else {
      this.setState({
        isHas: true
      })
    }
  }
  
  afterCompressOne = (file) => {
    this.setState({
      pictured: file,
      isHas: false
    });
  }
  
  chuan = (e) => {
    if (e.target?.files[0]) {
      let reader = new FileReader();
      reader?.readAsDataURL(e.target?.files[0])
      reader.onload = function () {
        document.querySelector(".tpp").src = reader?.result
      }
      this.setState({
        picture: e.target.files[0],
        isFalse: false
      })
      transformImageFile(e.target?.files[0], this.afterCompress);
    } else {
      this.setState({
        isFalse: true
      })
    }
  }
  
  afterCompress = (file) => {
    this.setState({
      picture: file,
      isHas: false
    });
  }
  
  authentication = async () => {
    const { picture, pictured ,uploading} = this.state
    const { licence } = qs.parse(this.props.location.search.slice(1))
    if (!pictured || !picture || uploading) { return false; }
    let applicantId = localStorage.getItem('AuthorizationID')
    var formdata = new FormData();
    formdata.append("front_document", picture);
    formdata.append("back_document", pictured);
    formdata.append("verificationRequestId", applicantId);
    formdata.append("document_type", licence);
  
    this.setState({
      uploading: true
    })
    const res = await onfio(formdata)
  
    switch (res?.status_code) {
      case 100000:
        this.props.history.push("/xStepNine");
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.SSNErr'),
          duration: 4000,
        })
        this.setState({uploading: false})
        break;
      case 300008: //Your account is not qualified for this function.
        this.props.history.push("/");
        break;
      case 300010: //The file uploaded is too large.
        Toast.show({
          content: t('steps.fileUploadErr'),
          duration: 4000,
        })
        this.setState({uploading: false})
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErrBackToHome'),
          duration: 4000,
        })
        this.props.history.push("/");
        break;
      case 301019: //Onfido service call failed.
      case 300002: //Invalid parameter
      case 301017: //Invalid identity verification parameters
      default:
        Toast.show({
          content: t('steps.guideActionFailed'),
          duration: 4000,
        })
        this.setState({uploading: false})
        break;
    }
  }
  render() {
    const { list, isHas, isFalse, picture, pictured,visible, uploading } = this.state

    return (
      <div className="stepEight" style={{minHeight: "760px"}}>
        <StepHeader
          title={t('steps.IDCheckTitle')}
          subTitle={t('steps.IDCheckSubTitle')}
          list={list}
        />
        <div className='document-type textAlignLeft'>
          <p className='type-title'>
            {t('steps.IDCheckUploadPRPhoto')}
          </p>
          <p>
            <span style={{
              fontFamily: 'Alibaba PuHuiTi',
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "174.2%",
              color: "#999999"
            }}>{t('steps.IDCheckPhotoHint')}</span>
          </p>
          <div className="upload-image">
            <img className="holder-image" src={prFrontBG} style={!isHas ? { opacity: "0" } : {}} alt="" />
            <input type="file" accept="image/*" onChange={this.chuanOne} style={{
              position: "absolute", top: "22%",
              left: "37%",
              width: "26%",
              height: "50%", opacity: "0"
            }} />
            <div className="tip-text">
            <p>
            {t('steps.IDCheckClickToUploadPRFrontPhoto')}
            </p>
            <p onClick={() => {this.setState({visible: true})}}>
              {t('steps.IDCheckExample')}
            </p>
            </div>
            {!isHas &&
              <>
                <img src="" alt="" className='tps' />
                <div className="bottom-btn">
                  <input type="file" className='files' accept="image/*" onChange={this.chuanOne}/>
                  <div className="action" onClick={() => { document.querySelector('.files').click() }}>
                    <img src={camera} alt="" />
                    {t('steps.IDCheckClickToReUpload')}
                  </div>
                </div>
              </>}
          </div>
          <div className="upload-image">
            <img src={prBackBG} className="holder-image" style={!isFalse ? { opacity: "0" } : {}} alt="" />
            <input className='int' type="file" accept="image/*" onChange={this.chuan} style={{
              position: "absolute", top: "22%",
              left: "37%",
              width: "26%",
              height: "50%", opacity: "0"
            }} />
            <div className="tip-text">
            <p>
            {t('steps.IDCheckClickToUploadPRBackPhoto')}
            </p>
            <p onClick={() => {this.setState({visible: true})}}>
              {t('steps.IDCheckExample')}
            </p>
            </div>
            {!isFalse &&
              <>
                <img src="" alt="" className='tpp' />
                <div className="bottom-btn">
                    <input type="file" className='filed' accept="image/*" onChange={this.chuan} />
                    <div className="action" onClick={() => { document.querySelector(".filed").click() }}>
                      <img src={camera} alt=""/>
                      {t('steps.IDCheckClickToReUpload')}
                    </div>
                  </div>
              </>}
          </div>
        </div>
        <StepFooter
          notice={t('steps.IDCheckPhotoHint2')}
          isLoading={uploading}
          disabled={!((picture && pictured) || uploading)}
          btnSubmit={this.authentication}
          btnText={t('steps.nextButton')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
        {visible ? 
          <Mask
              visible={visible}
              onMaskClick={()=>{
                this.setState({
                  visible:false
                })
              }}
          >
            <Activationsamples permits={"permits"}></Activationsamples>
          </Mask>
        : null }
      </div>
    )
  }
}

