import './index.less'
import React, { useState } from 'react'
import { NavBar, Button, Input, Popup, Mask, Toast } from 'antd-mobile'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InformationCircleFill, ExclamationCircleFill } from 'antd-mobile-icons'
import CommonBottomPopup from '@comps/CommonBottomPopup'
import { verifyMicroCredit } from '@api/account'
import { useSelector } from 'react-redux'

const ErrorPop = (props) => {
    const { content, btnTxt, isVerifyErrorShow, setIsVerifyErrorShow } = props
    return (
        <Mask visible={isVerifyErrorShow} onMaskClick={() => setIsVerifyErrorShow(false)}>
            <div id="error-pop">
                <ExclamationCircleFill fontSize={60} color="#FF3841"/>
                <div className="content"><Trans i18nKey={content}/></div>
                <Button onClick={() => setIsVerifyErrorShow(false)}>{btnTxt}</Button>
            </div>
        </Mask>
    )
}

export default function Verify() {
    const history = useHistory()
    const [amount1, setAmount1] = useState('')
    const [amount2, setAmount2] = useState('')
    const [isVerifyTipShow, setIsVerifyTipShow] = useState(false)
    const [isVerifyErrorShow, setIsVerifyErrorShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const currentBankInfo = useSelector(state => state.account.currentBankInfo)
    const verifyBankFrom = useSelector(state => state.account.verifyBankFrom)

    const toVerify = async () => {
        if (amount1.length === 0 || amount2.length === 0 || parseInt(amount1) === 0 || parseInt(amount2) === 0) {
            Toast.show({
                content: t('addBank.verify.correct_amount'),
                duration: 3000,
            })
            return
        }

        const newAmount1 = `0.${amount1}`
        const newAmount2 = `0.${amount2}`

        setLoading(true)
        const res = await verifyMicroCredit({
            verifyAmount1: newAmount1,
            verifyAmount2: newAmount2,
            bindRequestId: currentBankInfo.bind_request_id
        })
        setLoading(false)
        switch (res.status_code) {
            case 100000: 
                history.push(verifyBankFrom)
                break;
            case 302037: 
                setIsVerifyErrorShow(true)
                break;
            case 302039: 
                Toast.show({
                    content: t('addBank.verify.correct_amount'),
                    duration: 3000,
                })
                break;
            default:
                Toast.show({
                    content: t('common.serviceException'),
                    duration: 3000,
                })
                break;
        }
    }

    return (
        <div id="verify-bank">
            <NavBar className="nav" onBack={() => history.goBack()}/>
            <div className="title">
                <Trans i18nKey={t(`addBank.verify.title`)}/>
            </div>
            <div className="bank-info">
                <label>{t(`addBank.verify.related_bank`)}</label>
                <div className="bank-nickname">{currentBankInfo.account_nickname}</div>
            </div>
            <div className="amount-info">
                <div className="label" onClick={() => setIsVerifyTipShow(true)}>
                    <span className="amount-label">{t(`addBank.verify.verify_amount`)}</span>
                    <InformationCircleFill fontSize={12} color="#E1E1E1"/>
                    <span className="amount-faq">{t(`addBank.verify.verify_amount_tip`)}</span>
                </div>
                <Input className="amount-input" maxLength="2" type="number" value={amount1} clearable style={{
                    '--font-size': '16px'
                }} onChange={(val) => setAmount1(val.slice(0, 2))}/>
                <Input className="amount-input" maxLength="2" type="number" value={amount2} clearable style={{
                    '--font-size': '16px'
                }} onChange={(val) => setAmount2(val.slice(0, 2))}/>
            </div>
            <Button onClick={toVerify} loading={loading}>{t('addBank.verify.btn')}</Button>
            <Popup visible={isVerifyTipShow} closeOnMaskClick={true} onMaskClick={() => setIsVerifyTipShow(false) }>
                <CommonBottomPopup
                    title={t(`addBank.verify.verification_tip.title`)}
                    content={t(`addBank.verify.verification_tip.content`)}
                />
            </Popup>
            
            <ErrorPop
                content={t('addBank.verify.error_pop.content')} 
                btnTxt={t('addBank.verify.error_pop.btnTxt')}
                isVerifyErrorShow={isVerifyErrorShow}
                setIsVerifyErrorShow={setIsVerifyErrorShow}
            />
        </div>
    )
}