import React from 'react'
import './index.less'

export default function KYCHeader(props) {

  const { title = '', subTitle = '' } = props

  return (
    <div id='kyc-header-conatiner'>
      <div className='title'>{title}</div>
      <div className='sub-title' dangerouslySetInnerHTML={{ __html: subTitle }} >
      </div>
    </div >
  )
}