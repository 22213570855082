import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import cn from './zh-cn.json'
import en from './en-us.json'

const resources = {
  "zh_CN": {
    translation: cn
  },
  "en_US": {
    translation: en
  },
};
let localLanguage = localStorage.getItem("selectedLang") || "zh_CN"
if(!localLanguage) {
  localLanguage = "zh_CN";
}
else {
  if(localLanguage !== 'zh_CN' && localLanguage !== 'en_US') {
    localLanguage = "zh_CN";
  }
} 
localStorage.setItem("selectedLang", localLanguage)
i18n.use(LanguageDetector) //嗅探当前浏览器语言 zh-CN
.use(initReactI18next) // 将 i18n 向下传递给 react-i18next
  .init({ //初始化
    resources, //本地多语言数据
    fallbackLng: localLanguage, //默认当前环境的语言, 根据目前项目获取缓存，之后会用setLanguage 更改语言版本，而非缓存 const [language,setLanguage] = useState('zh-CN') setLanguage(当前选中的语言类型)，
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    }
  })

export default i18n