import {
  SET_CURRENT_BANK_INFO,
  SET_BANK_LIST,
  SET_VERIFY_BANK_FROM,
  SET_SELECTED_RECHARGE_BANK,
  SET_SELECTED_WITHDRAWAL_BANK,
  SET_SELECTED_BANK
} from './ActionTypes'
  
export const setCurrentBankInfo = (payload) => {
  sessionStorage.setItem('currentBankInfo', JSON.stringify(payload))
  return {
    type: SET_CURRENT_BANK_INFO,
    payload
  }
}

export const setBankList = (payload) => {
  sessionStorage.setItem('bankList', JSON.stringify(payload))
  return {
    type: SET_BANK_LIST,
    payload
  }
}

export const setVerifyBankFrom = (payload) => {
  sessionStorage.setItem('verifyBankFrom', payload)
  return {
    type: SET_VERIFY_BANK_FROM,
    payload
  }
}

export const setSelectedRechargeBank = (payload) => {
  localStorage.setItem('selectedRechargeBank', payload)
  return {
    type: SET_SELECTED_RECHARGE_BANK,
    payload
  }
}

export const setSelectedWithdrawalBank = (payload) => {
  localStorage.setItem('selectedWithdrawalBank', payload)
  return {
    type: SET_SELECTED_WITHDRAWAL_BANK,
    payload
  }
}

// 绑卡来源
export const setSelectedBank = (payload) => {
  localStorage.setItem('selectedBank', JSON.stringify(payload))
  return {
    type: SET_SELECTED_BANK,
    payload
  }
}