import React, { Component } from 'react'
import passport from "@assets/images/activation/icon_passport@2x.png"
import licens from "@assets/images/activation/icon_license@2x.png"
import identity from "@assets/images/activation/icon_identity@2x.png"
import residence from "@assets/images/activation/icon_residence@2x.png"
import StepHeader from '../Steps/StepHeader'
import qs from 'qs'
import './index.less'
import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'
import Header from '@comps/nav/Header'
import KYCHeader from '@pages/kyc-auth/non-ssn/components/Header'

export default class StepFour extends Component {
  state = {
    bool: true, //控制多次点击
    value: '',
    isBtnDisabled: true,
    isSendCode: true,
    from: '',
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: true },
    ],
  }

  IdCard = () => {
    this.props.history.push({
      pathname: `/xStepFive`,
      search: qs.stringify({ licence: "driving_licence", from: this.state.from })
    })
  }

  componentDidMount = () => {
    if (this.props.history.location.search.includes('nonssn')) {
      this.setState({
        from: 'nonssn'
      })
    } else {
      this.setState({
        from: ''
      })
    }
    scrollToTop()
  }
  render() {
    const { list, from } = this.state
    return (
      <div className="stepFour" >
        {
          from === 'nonssn' ? <>
            <Header />
            <KYCHeader title="额外的证件验证" subTitle="上传除护照外的额外证件进行下一步的卡片激活验证，系统将为您自动录入身份信息" />
          </> : <StepHeader
            title={t('steps.IDCheckTitle')}
            subTitle={t('steps.IDCheckSubTitle')}
            list={list}
          />
        }
        <div className='document-type textAlignLeft'>
          <p className='type-title'>
            {from === 'nonssn' ? '选择验证证件类型' : t('steps.IDCheckSelectIDType')}
          </p>
          <ul className='textAlignLeft'>
            {
              from !== 'nonssn' && <li onClick={
                () => {
                  this.props.history.push({
                    pathname: `/xStepSix`,
                    search: qs.stringify({ licence: "passport" })
                  })
                }}>
                <img src={passport} alt="" />
                <p className='typeText'>
                  <p className='typeText-left'>{t('steps.IDCheckPassport')}</p>
                  <p className='typeText-right'>{t('steps.IDCheckPassportFrontPage')}</p>
                </p>
              </li>
            }

            <li onClick={this.IdCard}>
              <img src={licens} alt="" />
              <p className='typeText'>
                <p className='typeText-left'>{t('steps.IDCheckDriverLience')}</p>
                <p className='typeText-right'>{t('steps.IDCheckFrontAndBack')}</p>
              </p>
            </li>
            <li onClick={
              () => {
                this.props.history.push({
                  pathname: `/xStepSeven`,
                  search: qs.stringify({ licence: "national_identity_card", from: from })
                })
              }}>
              <img src={identity} alt="" />
              <p className='typeText'>
                <p className='typeText-left'>{t('steps.IDCheckID')}</p>
                <p className='typeText-right'>{t('steps.IDCheckFrontAndBack')}</p>
              </p>
            </li>
            {
              from !== 'nonssn' && <li onClick={
                () => {
                  this.props.history.push({
                    pathname: `/xStepEight`,
                    search: qs.stringify({ licence: "residence_permit" })
                  })
                }}>
                <img src={residence} alt="" />
                <p className='typeText'>
                  <p className='typeText-left'>{t('steps.IDCheckPR')}</p>
                  <p className='typeText-right'>{t('steps.IDCheckFrontAndBack')}</p>
                </p>
              </li>
            }
          </ul>
        </div>

        <div className='step-bottom'>
          {t('steps.guideFPB')}
        </div>
      </div >
    )
  }
}

