import PhoneLogin from "@pages/login/PhoneLogin";
import Policy from "@pages/login/Policy";
import Home from "@pages/Home";
import RecordList from "@pages/Record/RecordList"; // 账单明细列表
import List from "@pages/Record/List"; // 账单明细列表
import Card from "@pages/Card";
import Account from "@pages/Account";
import Mine from "@pages/Mine";
import React from "react";

// 隐藏礼卡相关
/**
 * 
  import GiftCard from "@pages/GiftCard"
  import GCPayment from "@pages/GiftCard/Payment"
  import GCConfirm from "@pages/GiftCard/Confirm"
  import GCResult from "@pages/GiftCard/Result"
  import GCResultCallBack from "@pages/GiftCard/Result/CallBack"
  import GCRefund from "@pages/GiftCard/Refund"
  import GCRefundDetail from "@pages/GiftCard/Refund/Detail"
  import GCOrder from "@pages/GiftCard/Order"
  import SearchOrder from "@pages/GiftCard/Order/SearchOrder"
*/
import Steps from "@pages/Home/Steps";
import StepOne from "@pages/Home/StepOne";
import StepThree from "@pages/Home/StepThree";
import StepTwo from "@pages/Home/StepTwo";
import SubmitApplication from "@pages/NetKi/SubmitApplication";

import Plaid from "@pages/Plaid";
import PlaidSuccess from "@pages/Plaid/Success";
import plaidFail from "@pages/Plaid/Fail";
import VerificationCode from "@pages/Home/StepThree/VerificationCode";
import Authentication from "@pages/Mine/Authentication";

import TradePassword from "@pages/Mine/TradePassword";
import Setting from "@pages/Mine/Setting";
import FreezeAccount from "@pages/Mine/FreezeAccount";
import FreezeAccountC from "@pages/Mine/FreezeAccountC";
import SettingVerification from "@pages/Mine/SettingVerification";//设置验证码
import VerifyVerificationCode from "@pages/Mine/VerifyCode";//验证码校验
import SettingPasswordB from "@pages/Mine/SettingPasswordB";//重复设置密码
import SettingPasswordC from "@pages/Mine/SettingPasswordC";//重复设置密码
import Management from "@pages/Mine/Management" //管理银行账户
import Address from '@pages/Mine/Management/Address' //管理我的地址
import EditAddress from '@pages/Mine/Management/EditAddress' //更新我的地址
import ResetTransactionPasswordA from "@pages/Mine/ResetTransactionPasswordA";//修改交易密码
import ResetTransactionPasswordC from "@pages/Mine/ResetTransactionPasswordC";//修改交易密码
import EditTransactionPassword from "@pages/Mine/EditTransactionPassword";//修改交易密码
import EditTransactionPasswordB from "@pages/Mine/EditTransactionPasswordB";//修改交易密码 
import EditTransactionPasswordC from "@pages/Mine/EditTransactionPasswordC";//修改交易密码 
import EditTransactionPasswordSuccess from "@pages/Mine/EditTransactionPasswordSuccess";//修改交易密码 
import UpdateAvatar from "@pages/Mine/UpdateAvatar"
import PersonInfo from "@pages/Mine/PersonInfo"  // 个人信息页面
import UpdateTillstarID from "@pages/Mine/UpdateTillstarID"
import UpdateName from "@pages/Mine/UpdateName"
import MinePolicy from "@pages/Mine/Policy"

import TransferAccounts from "@pages/Transfer/TransferAccounts";//转账
import TransferAccountsUser from "@pages/Transfer/TransferAccountsUser";//转账给用户界面
import AccountInfoList from "@pages/Transfer/AccountInfoList";//我的账单明细
import Withdraw from "@pages/Withdraw";//提现
import Recharge from "@pages/Recharge" //充值金额 
import feeRules from "@pages/feeRules" // 手续费规则
import ActiveStatus from "@pages/Home/ActiveStatus"  //激活成功/失败
import SelectBank from '@pages/SelectBank'
import RelateStart from "@pages/Home/RelateStart"; //开始关联

import StepFour from "@pages/Home/StepFour"
import StepFive from "@pages/Home/StepFive"
import StepSix from "@pages/Home/StepSix"
import StepSeven from "@pages/Home/StepSeven"
import StepEight from "@pages/Home/StepEight"
import StepNine from "@pages/Home/StepNine"
import RMBPayment from "@pages/RMBPayment"
import RMBOrder from "@pages/RMBPayment/Order"
import RMBRefund from "@pages/RMBPayment/Order/Refund"
import RMBTransactionReceipt from "@pages/RMBPayment/Order/TransactionReceipt"
import CallBack from "@pages/RMBPayment/Order/CallBack"
import Tutorials from "@pages/Tutorials"
import Intro from "@pages/Tutorials/Intro"
import FAQ from '@pages/FAQ'
import FAQDetail from '@pages/FAQ/Detail'

import TransactionListDetail from '@pages/Record/ListDetail'

// 0323 隐藏储蓄以及礼卡 
// begin
// import SavingAccount from '@pages/SavingAccount' // 储蓄账户主页： 包含转入转出明细
// import SavingAccountInsurance from '@pages/SavingAccount/Insurance' // 存储账户保证说明
// import SavingAccountInsuranceDetail from '@pages/SavingAccount/Insurance/detail' //存储账户保障额度明细
// import SavingAccountTransfer from '@pages/SavingAccount/Transfer' //储蓄账号转入转出操作
// import TransferResult from '@pages/SavingAccount/TransferResult' //储蓄账号转入转出操作结果页面
// import CashBack from '@pages/SavingAccount/CashBack' //储蓄账户累计奖励
// import SavingAccountDetail from '@pages/SavingAccount/AccountDetail' //账户存款
// import SavingAccountFAQ from '@pages/SavingAccount/FAQ' //储蓄账户常见问题
// import SavingAnnualizeRate from '@pages/SavingAccount/AnnualizeRate' //年化收益率主页

// import CashBackRules from '@pages/SavingAccount/CashBack/Rules' // ---
// import RevenueRules from '@pages/SavingAccount/RevenueRules' // 年化收益率收益规则
// import ShareRules from '@pages/SavingAccount/ShareRules' //奖励点推荐好友
// import AboutSavingAccount from '@pages/SavingAccount/About' // 储蓄账号介绍
// import BonusRateHikes from '@pages/SavingAccount/BonusRateHikes' //额外加息奖励介绍

// import Reward from '@pages/Reward' //奖励的分类列表
// import RewardSummary from '@pages/Reward/Summary' // 奖励的明细
// import RewardRedeem from '@pages/Reward/Redeem' // 奖励兑换
// import RewardRules from '@pages/Reward/Rules' // 奖励点的说明
// import RedeemResult from '@pages/Reward/Redeem/Result' //兑换结果页面
// # end

import BankCardTutorial from "@pages/Tutorials/BankCard"
import StopTaking from "@pages/StopTaking"

import AddBank from '@pages/AddBank'
import AssociatedBank from '@pages/AddBank/AssociatedBank'
import ChooseBank from '@pages/AddBank/ChooseBank'
import AddBankResult from '@pages/AddBank/Result'
import AddBankVerify from '@pages/AddBank/Verify'
import StatementList from '@pages/StatementList'
import PendingStatement from '@pages/PendingStatement'

import Messages from "@pages/Messages"
import MessagesList from "@pages/Messages/List"

import ReconfirmBirthday from "@pages/KYC/ReconfirmBirthday"
import KYCAbnormal from "@pages/KYC/KYCAbnormal"
import UploadPendingReview from "@pages/KYC/UploadPendingReview"
import RMBPaymentExplain from "@pages/RMBPaymentExplain"
import LandingPage from "@pages/LandingPage"

// ssn
import KYCBegin from "@pages/kyc-auth/Begin";

// non ssn

import UploadAddressImages from "@pages/kyc-auth/non-ssn/UploadAddressImages";
import BillAdressEdit from "@pages/kyc-auth/non-ssn/BillAdressEdit";

// 路由配置文件
const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/account",
    component: Account,
    exact: true
  },
  // 隐藏giftcard
  // {
  //   path: "/giftCard",
  //   component: GiftCard,
  //   exact: true
  // },
  // {
  //   path: "/gcPayment",
  //   component: GCPayment,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcConfirm",
  //   component: GCConfirm,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcPurchaseSucceed",
  //   component: GCResult,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcPurchaseFailed",
  //   component: GCResult,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcResult",
  //   component: GCResult,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcResultCallBack",
  //   component: GCResultCallBack,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcRefund",
  //   component: GCRefund,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/gcRefundDetail",
  //   component: GCRefundDetail,
  //   exact: true,
  //   auth: true
  // },
  {
    path: "/submitApplication",
    component: SubmitApplication,
    exact: true,
    auth: true
  },
  {
    path: "/plaid",
    component: Plaid,
    exact: true,
    auth: true
  },
  {
    path: "/plaidSuccess",
    component: PlaidSuccess,
    exact: true,
    auth: true
  },
  {
    path: "/plaidFail",
    component: plaidFail,
    exact: true,
    auth: true
  },
  {
    path: "/recordList",
    component: List,
    exact: true
  },
  {
    path: "/recordSearchList",
    component: RecordList,
    exact: true,
    auth: true
  },
  {
    path: "/card",
    component: Card,
    exact: true,
    auth: true
  },
  {
    path: "/mine",
    component: Mine,
    exact: true,
    auth: true
  },
  {
    path: "/tradePassword",
    component: TradePassword,
    exact: true,
    auth: true
  },
  {
    path: "/setting",
    component: Setting,
    exact: true,
    auth: true
  },
  {
    path: "/settingVerification",
    component: SettingVerification,
    exact: true,
    auth: true
  },
  {
    path: "/verifyCode",
    component: VerifyVerificationCode,
    exact: true,
    auth: true
  },
  {
    path: "/settingPasswordB",
    component: SettingPasswordB,
    exact: true,
    auth: true
  },
  {
    path: "/settingPasswordC",
    component: SettingPasswordC,
    exact: true,
    auth: true
  },
  {
    path: "/editTransactionPassword",
    component: EditTransactionPassword,
    exact: true,
    auth: true
  },
  {
    path: "/editTransactionPasswordB",
    component: EditTransactionPasswordB,
    exact: true,
    auth: true
  },
  {
    path: "/editTransactionPasswordC",
    component: EditTransactionPasswordC,
    exact: true,
    auth: true
  },
  {
    path: "/editTransactionPasswordSuccess",
    component: EditTransactionPasswordSuccess,
    exact: true,
    auth: true
  },
  {
    path: "/resetTransactionPasswordA",
    component: ResetTransactionPasswordA,
    exact: true,
    auth: true
  },
  {
    path: "/resetTransactionPasswordC",
    component: ResetTransactionPasswordC,
    exact: true,
    auth: true
  },
  {
    path: "/transferAccountsUser",
    component: TransferAccountsUser,
    exact: true,
    auth: true

  }, //转账给用户界面
  {
    path: "/accountInfoList",
    component: AccountInfoList,
    exact: true,
    auth: true
  }, //转账给用户成功界面
  {
    path: "/freezeAccount",
    component: FreezeAccount,
    exact: true,
    auth: true
  },
  {
    path: "/freezeAccountC",
    component: FreezeAccountC,
    exact: true,
    auth: true
  },
  {
    path: "/transferAccounts",
    component: TransferAccounts,
    exact: true,
    auth: true
  },
  {
    path: "/steps",
    component: Steps,
    exact: true,
    auth: true
  },
  {
    path: "/stepOne",
    component: StepOne,
    exact: true,
    auth: true
  },
  {
    path: "/xStepTwo",
    component: StepTwo,
    exact: true,
    auth: true
  },
  {
    path: "/xStepThree",
    component: StepThree,
    exact: true,
    auth: true
  },
  {
    path: "/verificationCode",
    component: VerificationCode,
    exact: true,
    auth: true
  },
  {
    path: "/xStepFour",
    component: StepFour,
    exact: true,
    auth: true
  },
  {
    path: "/xStepFive",
    component: StepFive,
    exact: true,
    auth: true
  },
  {
    path: "/xStepSix",
    component: StepSix,
    exact: true,
    auth: true
  },
  {
    path: "/xStepSeven",
    component: StepSeven,
    exact: true,
    auth: true
  },
  {
    path: "/xStepEight",
    component: StepEight,
    exact: true,
    auth: true
  },
  {
    path: "/xStepNine",
    component: StepNine,
    exact: true,
    auth: true
  },
  {
    path: "/login",
    component: PhoneLogin,
    exact: true,
  },
  {
    path: "/login/Policy",
    component: Policy,
    exact: true,
  },
  {
    path: "/withdraw",
    component: Withdraw,
    exact: true,
    auth: true
  },
  {
    path: "/recharge",
    component: Recharge,
    exact: true,
    auth: true,
    children: {
      path: "/transferAccounts",
      component: TransferAccounts,
      exact: true,
      auth: true
    }
  },
  {
    path: "/feeRules",
    component: feeRules,
    exact: true,
    auth: true
  },
  {
    path: "/activeStatus", //激活成功
    component: ActiveStatus,
    exact: true,
    auth: true
  },
  {
    path: "/selectBank",
    component: SelectBank,
    exact: true,
    auth: true
  },
  {
    path: "/relateStart",  //开始关联
    component: RelateStart,
    exact: true,
    auth: true
  },
  {
    path: "/management", //设置交易密码
    component: Management,
    exact: true,
    auth: true
  },
  {
    path: "/myAddress", //管理我的地址
    component: Address,
    exact: true,
    auth: true
  },
  {
    path: "/editAddress", //编辑我的地址
    component: EditAddress,
    exact: true,
    auth: true
  },
  // 隐藏giftcard
  // {
  //   path: "/gcOrder", //我的订单
  //   component: GCOrder,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: "/searchorder",
  //   component: SearchOrder,
  //   exact: true,
  //   auth: true
  // },
  {
    path: "/rmb",
    component: RMBPayment,
    exact: true
  },
  {
    path: "/rmbOrder",
    component: RMBOrder,
    exact: true
  },
  {
    path: "/rmbRefund",
    component: RMBRefund,
    exact: true
  },
  {
    path: "/receipt",
    component: RMBTransactionReceipt,
    exact: true
  },
  {
    path: "/rmb/callback",
    component: CallBack,
    exact: true
  },
  {
    path: "/tutorials",
    component: Tutorials,
    exact: true
  },
  {
    path: "/intro",
    component: Intro,
    exact: true
  },
  {
    path: '/faq',
    component: FAQ,
    exact: true,
  },
  {
    path: '/faqDetail',
    component: FAQDetail,
    exact: true
  },
  {
    path: '/transactionDetail',
    component: TransactionListDetail,
    exact: true,
    auth: true
  },
  // {
  //   path: '/savingAccount',
  //   component: SavingAccount,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/reward',
  //   component: Reward,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/reward/summary',
  //   component: RewardSummary,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/reward/redeem',
  //   component: RewardRedeem,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/redeem/result',
  //   component: RedeemResult,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/reward/rules',
  //   component: RewardRules,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/transfer',
  //   component: SavingAccountTransfer,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/insurance',
  //   component: SavingAccountInsurance,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/insurance/detail',
  //   component: SavingAccountInsuranceDetail,
  //   exact: true,
  //   auth: true
  // },

  // {
  //   path: '/savingAccount/transferResult',
  //   component: TransferResult,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/cashBack',
  //   component: CashBack,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/cashBack/rules',
  //   component: CashBackRules,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/detail',
  //   component: SavingAccountDetail,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/faq',
  //   component: SavingAccountFAQ,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/annualizeRate',
  //   component: SavingAnnualizeRate,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/revenueRules',
  //   component: RevenueRules,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/shareRules',
  //   component: ShareRules,
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/savingAccount/about',
  //   component: AboutSavingAccount,
  //   exact: true
  // },
  {
    path: "/tutorials/bankCard",
    component: BankCardTutorial,
    exact: true
  },
  {
    path: '/StopTaking',
    component: StopTaking,
    exact: true
  },
  {
    path: '/addBank',
    component: AddBank,
    exact: true,
    auth: true
  },
  {
    path: '/associatedBank',
    component: AssociatedBank,
    exact: true,
    auth: true
  },
  {
    path: '/chooseBank',
    component: ChooseBank,
    exact: true,
    auth: true
  },
  {
    path: '/addBankResult',
    component: AddBankResult,
    exact: true,
    auth: true
  },
  {
    path: '/addBankVerify',
    component: AddBankVerify,
    exact: true,
    auth: true
  },
  // {
  //   path: '/savingAccount/bonusRateHikes',
  //   component: BonusRateHikes,
  //   exact: true,
  //   auth: true
  // },
  {
    path: '/messages',
    component: Messages,
    exact: true,
    auth: true
  },
  {
    path: '/messages/list',
    component: MessagesList,
    exact: true,
    auth: true
  },
  {
    path: '/mine/authentication',
    component: Authentication,
    exact: true,
    auth: true
  },
  {
    path: '/reconfirmBirthday',
    component: ReconfirmBirthday,
    exact: true,
    auth: true
  },
  {
    path: '/mine/updateAvatar',
    component: UpdateAvatar,
    exact: true,
    auth: true
  },
  {
    path: '/KYCAbnormal',
    component: KYCAbnormal,
    exact: true,
    auth: true
  },
  {
    path: '/mine/personInfo',
    component: PersonInfo,
    exact: true,
    auth: true
  },
  {
    path: '/mine/updateTillstarID',
    component: UpdateTillstarID,
    exact: true,
    auth: true
  },
  {
    path: '/mine/updateName',
    component: UpdateName,
    exact: true,
    auth: true
  },
  {
    path: '/mine/policy',
    component: MinePolicy,
    exact: true,
    auth: true
  },
  {
    path: '/uploadPendingReview',
    component: UploadPendingReview,
    exact: true,
    auth: true
  },
  {
    path: '/rmbPaymentExplain',
    component: RMBPaymentExplain,
    exact: true,
    auth: true
  },
  {
    path: '/statementList',
    component: StatementList,
    exact: true,
    auth: true
  },
  {
    path: '/pendingStatement',
    component: PendingStatement,
    exact: true,
    auth: true
  },
  {
    path: '/landingPage',
    component: LandingPage
  },
  // nonssn
  {
    path: '/kycBegin',
    component: KYCBegin,
    auth: false
  },
  {
    path: '/upload-nonssn',
    component: UploadAddressImages,
    auth: false
  },
  {
    path: "/bill-address-edit",
    component: BillAdressEdit,
    auth: true
  }

]
export default routes
