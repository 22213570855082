import React, { Component } from 'react'
import { Button, Toast, NavBar, NumberKeyboard, PasscodeInput } from 'antd-mobile'
import { editTradePassword } from '@api/user'
import { scrollToTop } from "@utils/utils"
import ExceptionPop from '@comps/ExceptionPop'
import { connect } from 'react-redux'
import { CloseOutline } from 'antd-mobile-icons'
import './index.less'
import {t} from "i18next";

const mapStateToProps = (store) => {
  return {
    actionToken: store.password.actionToken,
    passwordRelatedChannel: store.password.passwordRelatedChannel,
  }
}
class EditTransactionPasswordB extends Component {
  state = {
    title: '设置密码',
    error: false,
    password: '',
    isSubmit: true,
    isExceptionPopVisible: false,
    isSubmitLoading: false
  }

  passcodeInputRef = React.createRef()

  componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.passcodeInputRef?.current.focus()
  }
  goBack = () => {
    const { passwordRelatedChannel, history } = this.props
    if (passwordRelatedChannel.type === 'reset') {
      history.go(-2)
      // history.replace('/resetTransactionPasswordA')
    } else {
      history.replace('/tradePassword')
    }
  }
  submit = async () => {
    const { password } = this.state
    const { actionToken } = this.props
    this.setState({
      isSubmitLoading: true
    })
    const res = await editTradePassword('UpdateTransactionPin_SetNewPin', actionToken, password)
    this.setState({
      isSubmitLoading: false
    })
    switch (res.status_code) {
      case 100000: 
        this.setState({
          isSubmit: false
        })
        if (res.response_data) {
          const token = res.response_data.token
          sessionStorage.setItem('editPasswordAgainToken', token)
        }
        this.props.history.push('/editTransactionPasswordC')
        break;
      // case 300001:
      //   this.setState({
      //     error: true
      //   })
      //   this.passcodeInputRef.current.focus()
      //   break;
      // case 300002:
      //   this.setState({
      //     error: true
      //   })
      //   break;
      case 301006:
        this.setState({
          isExceptionPopVisible: true,
          error: true
        })
        break;
      case 301015: 
        this.passcodeInputRef.current.focus()
        this.setState({
          error: true
        });
        Toast.show({
          content: t("resetPin.RepeatHint")
        })
        break;
      default:
        this.setState({
          error: true
        })
        Toast.show({
          content: t("resetPin.RepeatHint"),
        })
        break;
    }
  }

  onPasswordChange = async (code) => {
    if (code.length === 6) {
      this.passcodeInputRef.current.blur()
      this.setState({
        password: code,
        isSubmit: false
      })
    } else if (code.length === 5) {
      this.setState({
        password: this.state.error ? '' : code,
        error: false,
        isSubmit: true
      })
    } else {
      this.setState({
        password: code,
        error: false,
        isSubmit: true
      })
    }
  }

  render() {
    const { error, password, isSubmit, isExceptionPopVisible } = this.state
    return (
      <div className='editTransactionPasswordB-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          back={null}
          right={<CloseOutline onClick={this.goBack} fontSize={24}/>}
        >
           {t("setPassword.Setpassword")}
        </NavBar>
        <section className="editTransactionPasswordB d-flex flex-column align-items-center">
          <div className="title-warp-editTransactionPasswordB">
            <div className="title-warp-editTransactionPasswordB">
              <p>
                {t("setPassword.newtransactionpassword")}
              </p>
              <span style={{ color: "#999999" }}>{t("setPassword.paymentsandsecurity")}</span>
            </div>
          </div>
          <div className='verification-code'>
            <PasscodeInput
              ref={this.passcodeInputRef}
              length={6}
              seperated
              value={password}
              keyboard={<NumberKeyboard randomOrder={true}/>}
              onChange={this.onPasswordChange}
              error={error}
              style={{
              '--cell-gap': '12px',
            }}/>
          </div>
          <div className="varification-tips-b">
            <div 
              className="setting-password-b-times"
              dangerouslySetInnerHTML={{__html: t('setPassword.repeatedorconsecutive')}}
            >
            </div>
          </div>
          <div className="btn-container">
            <Button
              type="warning"
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center']}
              onClick={this.submit}
              disabled={isSubmit}
            >
              {t("setPassword.determine")}
            </Button>
          </div>
        </section>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>

    )
  }
}

export default connect(mapStateToProps, null)(EditTransactionPasswordB)
