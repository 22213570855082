import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import passport from "@assets/images/certificate/passport_sample.png"
import nonssnP from "@assets/images/certificate/nonssn_passport_sample.png"

import './index.less'
import { t } from 'i18next'
class ActivateAlert extends Component {

  render() {
    const { from = '' } = this.props
    return (
      <div className="sample">
        <p className='sample-title'>{t('steps.generaMask')}</p>
        <p className='sample-describe'>
          {t('steps.generaMaskdescribe')}<br />
          {t('steps.generaMaskdescribetwo')}<br />
          {t('steps.generaMaskdescribethree')}
        </p>
        <div className='passport'>
          <img src={from === 'nonssn' ? nonssnP : passport} alt="" />
        </div>
      </div>
    )
  }
}

export default withRouter(ActivateAlert)
