import { Mask, Toast } from 'antd-mobile'
import { CloseCircleOutline, QuestionCircleOutline } from 'antd-mobile-icons'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './index.less'
import rmbPopBg from '@assets/images/rmb/rmb_pop_bg.png'
import balancePopIcon from '@assets/images/rmb/balance_pop_icon.png'
import commonPopIcon from '@assets/images/common/notice.png'
import serviceQrcode from '@assets/images/common/qrcode_service.png'

import { Link, useHistory } from 'react-router-dom'
import { setRmbBalancePop, setSwitchRmbPop, setSwitchRmbUnabledPop } from '@actions/rmb'
import { setPendingTransactionPop } from '@actions/transaction'
import { Trans, useTranslation } from 'react-i18next'
import { setCommonPopStatus } from '@actions/common'
import { formatNumberToUSD} from "@utils"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { contactService } from '@utils/utils'

export default function CommonPop(props) {

  const { code, onRiskControlClose, amountRiskPop,
    from, isRiskShow = false, setIsRiskShow, isGiftCardPopShow = false,
    setIsGiftCardPopShow, isBalancePop = false, setIsBalancePop,
    isWechatPaymentPopShow = false, setIsWechatPaymentPopShow,
    isCashbackPop = false, setIsCashbackPop,
    isCalculateRevenuePop = false, 
    isGcConfirm = false,
    setIsCalculateRevenuePop} = props
  const history = useHistory()
  const dispatch = useDispatch()
  const transactionDetail = useSelector(state => state.transaction.transaction_detail)
  const rmbBalancePop = useSelector(state => state.rmb.rmb_balance_pop)
  const switchRmbPop = useSelector(state => state.rmb.switch_rmb_pop)
  const switchRmbUnabledPop = useSelector(state => state.rmb.switch_rmb_unabled_pop)
  const amountRiskData = useSelector(state => state.rmb.amount_risk_pop)
  // const amountRiskPop = useSelector(state => state.rmb.amount_risk_pop.is_risk)
  const userInfo =  useSelector(state => state.user.userInfo)
  const commmonPopShow =  useSelector(state => state.common.show_common_pop)
  const commmonPopCode =  useSelector(state => state.common.pop_content_code)
  const balance = parseFloat(userInfo.default_financial_account?.balance?.balance)
  const {amount, count} = useSelector(state => state.common.pop_content_params)
  const {t} = useTranslation();
  const pendingTransactionPop = useSelector(state => state.transaction.pending_status)
  const tillstarLink = 'app.tillstar.com'

  const selectedLang = useSelector(state => state.user.selectedLang)
  const giftCardPopTip = useMemo(() => {
    const { identity_verification_status, identity_verification_request_status } = userInfo
    if (identity_verification_status === 2) {
      return t(`common.pop.activation_under_review`)
    } else if (identity_verification_status === 3 || (identity_verification_status === 4 && [4, 9].includes(identity_verification_request_status))) {
      return t(`common.pop.activation_exception`)
    } else if (userInfo.default_financial_account?.status === 2) {
      return t(`common.pop.account_freeze`)
    }
  }, [userInfo])

  const copy = (t)=>{
    Toast.show({
      content: t('common.copy_tip'),
      duration: 1000,
    })
  }

  return (
    <div id='rmb-pop-container'>
      <Mask visible={isCalculateRevenuePop || rmbBalancePop || switchRmbPop || switchRmbUnabledPop || amountRiskPop || pendingTransactionPop || commmonPopShow || isRiskShow || isGiftCardPopShow || isBalancePop || isWechatPaymentPopShow || isCashbackPop}>
      {
        rmbBalancePop && <div className='balance-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>dispatch(setRmbBalancePop(false))}/></div>
          <div className='balance-content'>
            <div className='balance-top-bg'> <img src={rmbPopBg} alt='' /></div>
            <div className='balance-top-content'>
              <img src={balancePopIcon} alt='' />
              <div className='top-title'> {t('common_pop.rmb_balance.main')}</div>
              {balance && <div className='balance'>${balance}</div>}
            </div>
            <div className='b-text'>
              <div className='b-title' dangerouslySetInnerHTML={{__html: t('common_pop.rmb_balance.title')}} />
              <div className='b-tips'>{t('common_pop.rmb_balance.sub')}</div>
              <div className='b-btn' onClick={()=>dispatch(setRmbBalancePop(false))}>{t('common_pop.rmb_balance.btn')}</div>
              <Link to={'/faq'}>
                <div className='faq'><QuestionCircleOutline fontSize={16} /><span> {t('common_pop.rmb_balance.tips')}</span></div>
              </Link>
            </div>
          </div>
        </div>
      }
      {
        switchRmbPop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>dispatch(setSwitchRmbPop(false))}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-title' dangerouslySetInnerHTML={{__html: t('common_pop.switch_rmb.title')}} />
            <div className='s-r-btn'>
              <div className='l-btn' onClick={()=>dispatch(setSwitchRmbPop(false))}>{t('common_pop.switch_rmb.l_btn')}</div>
              <div className='r-btn'><Link to={`/receipt?code=${code}`} onClick={()=>dispatch(setSwitchRmbPop(false))}>{t('common_pop.switch_rmb.r_btn')}</Link> </div>
            </div>
          </div>
        </div>
      }
      {
        switchRmbUnabledPop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>dispatch(setSwitchRmbUnabledPop(false))}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-tip' dangerouslySetInnerHTML={{__html: t('common_pop.switch_rmb_unabled.tip')}} />
            <div className='common-title' dangerouslySetInnerHTML={{__html: t('common_pop.switch_rmb_unabled.title')}} />
            <div className='s-r-btn'>
              <div className='l-btn' onClick={()=>
                {
                  dispatch(setSwitchRmbUnabledPop(false))
                  contactService()
                }}>{t('common_pop.switch_rmb_unabled.l_btn')}</div>
              <div className='r-btn' onClick={()=>dispatch(setSwitchRmbUnabledPop(false))}>{t('common_pop.switch_rmb_unabled.r_btn')}</div>
            </div>
          </div>
        </div>
      }
      {amountRiskPop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>onRiskControlClose()}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-title a-r' dangerouslySetInnerHTML={{__html:t('common_pop.amount_risk.title')}} />
            <div className='a-r-tips' dangerouslySetInnerHTML={{__html:t('common_pop.amount_risk.sub')}} />
            <div className='s-r-btn'>
              <div className='l-btn' onClick={()=>{ 
                onRiskControlClose()
                if (from === 'home') {
                  history.push('/rmb')
                }
              }}>{t('common_pop.amount_risk.l_btn')}</div>
              <div className='r-btn'><Link to={amountRiskData.right_button ? amountRiskData.right_button : '/recordList'} onClick={()=>onRiskControlClose()}>{t('common_pop.amount_risk.r_btn')}</Link> </div>
            </div>
          </div>
        </div>}
      {
        pendingTransactionPop && <div className='common-pop'>
        <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>dispatch(setPendingTransactionPop(false))}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-title a-r' dangerouslySetInnerHTML={{__html:t('common_pop.pending_pop.title')}} />
            <div className='s-r-btn'>
              <div className='r-btn p-t' onClick={()=>dispatch(setPendingTransactionPop(false))}>{t('common_pop.pending_pop.btn')}</div>
            </div>
          </div>
        </div>
      }
      {
        commmonPopShow && <div className='common-pop'>
        <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>dispatch(setCommonPopStatus(false))}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-title a-r' > 
              <Trans i18nKey={t(`common.pop.${commmonPopCode}`)}  values={{amount: formatNumberToUSD(amount), count: count}}/>
            </div>            
            <div className='s-r-btn'>
              <div className='r-btn a-t' onClick={()=>dispatch(setCommonPopStatus(false))}>{t('common.pop.btn')}</div>
            </div>
          </div>
        </div>
      }
      {
        isRiskShow && <div className='common-pop'>
        <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>setIsRiskShow(false)}/></div>
          <div>
            <img className='common-icon' src={commonPopIcon} alt=''></img>
          </div>
          <div className='common-content'>
            <div className='common-title a-r' > 
              <Trans i18nKey={t(`common.pop.400017`)}  values={{count: transactionDetail.refundLimitCount}}></Trans>
            </div>            
            <div className='s-r-btn'>
              <div className='r-btn a-t' onClick={()=>setIsRiskShow(false)}>{t('common.pop.btn')}</div>
            </div>
          </div>
        </div>
      }
      {
      isGiftCardPopShow && <div className='common-pop'>
        <div className='close'>
          <CloseCircleOutline color='#fff' fontSize={36} onClick={()=> {
              setIsGiftCardPopShow(false)
              !isGcConfirm && history.push('/')
          }}/></div>
        <div>
        <img className='common-icon' src={commonPopIcon} alt=''></img>
      </div>
      <div className='common-content'>
        <div className='common-title' dangerouslySetInnerHTML={{__html: giftCardPopTip}} />
        <div className='s-r-btn'>
          <div className='l-btn' onClick={()=>{
            setIsGiftCardPopShow(false)
            contactService()
          }}>{t('common_pop.payment_pop.l_btn')}</div>
          <div className='r-btn' onClick={()=> {
              setIsGiftCardPopShow(false)
              !isGcConfirm && history.push('/')
          }}>{t('common_pop.payment_pop.r_btn')}</div>
        </div>
      </div>
      </div>
      }
      {
        isBalancePop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>setIsBalancePop(false)}/></div>
          <img className='common-icon' src={commonPopIcon} alt=''></img>
          <div className='common-content'>
            <div className='common-header-title' dangerouslySetInnerHTML={{__html: t('common_pop.insufficient_balance_pop.title')}} />
            <div className={selectedLang === 'zh_CN' ? 'common-header-subtitle': 'common-header-subtitle subtitle-en'} dangerouslySetInnerHTML={{__html: t('common_pop.insufficient_balance_pop.sub')}} />
            <img className="qrcode" src={serviceQrcode} alt=''/>
          </div>
        </div>
      }
      {
      isWechatPaymentPopShow && <div className='common-pop'>
        <div className='close'>
          <CloseCircleOutline color='#fff' fontSize={36} onClick={()=>setIsWechatPaymentPopShow(false)}/></div>
        <div>
        <img className='common-icon' src={commonPopIcon} alt=''></img>
      </div>
      <div className='common-content'>
        <div className={selectedLang === 'zh_CN' ? 'common-wechat-title' : 'common-wechat-title common-wechat-title-en'} dangerouslySetInnerHTML={{__html: t(`common.pop.wechat_payment_tip_title`)}} />
        <div className="copy-link">
          <div className="link-text">
            <span>{ t("common.pop.copyLink") }</span>
            <span>{tillstarLink}</span>
          </div>
          <CopyToClipboard text={tillstarLink} onCopy={()=>copy(t)}>
            <span className="copy-btn">{t('common.copy')}</span>
          </CopyToClipboard>
        </div>
        <div className="copy-tip">
          { t("common.pop.wechatDesc") }
        </div>
        <div className='s-r-btn'>
          <div className='l-btn' onClick={()=>{
            setIsWechatPaymentPopShow(false)
            contactService()
          }}>{t('common_pop.payment_pop.l_btn')}</div>
          <div className='r-btn' onClick={()=>setIsWechatPaymentPopShow(false)}>{t('common_pop.payment_pop.r_btn')}</div>
        </div>
      </div>
      </div>
      }
      {
        isCashbackPop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>setIsCashbackPop(false)}/></div>
          <img className='common-icon' src={commonPopIcon} alt=''></img>
          <div className='common-content'>
            <div className='common-header-title' dangerouslySetInnerHTML={{__html: t('common_pop.cashback_pop.title')}} />
            <div className='common-header-subtitle' dangerouslySetInnerHTML={{__html: t('common_pop.cashback_pop.sub')}} />
          </div>
        </div>
      }
      {
        isCalculateRevenuePop && <div className='common-pop'>
          <div className='close'><CloseCircleOutline color='#fff' fontSize={36} onClick={()=>setIsCalculateRevenuePop(false)}/></div>
          <img className='common-icon' src={commonPopIcon} alt=''></img>
          <div className='common-content'>
            <div className='common-header-title' dangerouslySetInnerHTML={{__html: t('common_pop.calculate_revenue_pop.title')}} />
            <div className='common-header-subtitle' dangerouslySetInnerHTML={{__html: t('common_pop.calculate_revenue_pop.sub')}} />
          </div>
        </div>
      }
      </Mask>
    </div>
  )
}
