import {
    SET_ACTION_TOKEN,
    SET_PASSWORD_RELATED_CHANNEL,
} from '../actions/ActionTypes'

const initialState = {
    passwordRelatedChannel: JSON.parse(localStorage.getItem('passwordRelatedChannel')) || {
        channel: '',
        type: '',
        url: '',
        backUrl: '',
        search: ''
    },
    actionToken: sessionStorage.getItem('actionToken') || '',
}

const password = (state = initialState, action) => {
    let newState = {
        ...state
    }
    switch (action.type) {
        case SET_ACTION_TOKEN:
            newState.actionToken = action.actionToken
            return newState
        case SET_PASSWORD_RELATED_CHANNEL:
            localStorage.setItem('passwordRelatedChannel', JSON.stringify(action.passwordRelatedChannel))
            newState.passwordRelatedChannel = action.passwordRelatedChannel
            return newState
        default:
        return state
    }
}

export default password