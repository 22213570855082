import React, { Component } from 'react'
import { CloseOutline, DownOutline} from 'antd-mobile-icons'
import { Input, Button, Toast, Picker, Checkbox } from 'antd-mobile'

import { createForm } from 'rc-form'
import { t } from 'i18next'
import { connect } from 'react-redux'

import { reqSendCode, reqLogin, getCountryList } from '@api/login'
import { updateLanguage } from '@api/user'

import languageCn from '@assets/images/login/languageCn.png'
import languageEn from '@assets/images/login/languageEn.png'

import { switchSelectedLang, setUserInfo } from '@actions/user'
import { setLoginFromPage, setCountryList } from '@actions/common'

import { phoneReg, codeReg } from '@utils/reg'
import { scrollToTop } from "@utils/utils"

import './index.less'

const welcome = 'https://static1.front.youworld.us/gs/login/welcome.png'

const TOTAL_TIME = 60

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang,
    userInfo: store.user.userInfo,
    fromData: store.common.fromData,
  }
}

const mapDispatchToProps = {
  switchSelectedLang,
  setUserInfo,
  setLoginFromPage,
  setCountryList
}

class Login extends Component {
  state = {
    isDisabledLoginBtn: true,
    isDisabledGetCode: true,
    // 决定是否显示获取验证码
    isShowSendCode: false,
    time: TOTAL_TIME,
    // 是否发送过验证码
    visible: false,
    pickerValue: ['+86'],
    isSelectedAgreement: false,
    maxLength: 11,
    bool: true, //设置开关来控制重复点击
    countryList: [],
    countryCode: '+86',
    countryCodeId: '41',
    againGetCode: false,
    clickDisabled: false,
    loginLoading: false,
    onoff: true,
    phone: '',
    smsOTP: '',
    from: '',
    countryCodeList: []
  }

  //点击确定按钮,防止重复提交
  okHandle = () => {
    const { bool } = this.state
    if (bool) {
      //如果为true 开始执行
      this.setState({ bool: false }) //将isRepeatClick变成false，将不会执行处理事件

      // 在此处编写点击事件执行的代码

      const that = this // 为定时器中的setState绑定this
      setTimeout(function () {
        // 设置点击延迟事件，1秒后将执行
        that.setState({ bool: true }) // 将isRepeatClick设置为true
      }, 1000)
    }
  }

  validator = (rule, value, callback) => {
    if (rule.field === 'phone') {
      // 手机号
      let isDisabledGetCode = true

      if (phoneReg.test(value)) {
        isDisabledGetCode = false
      }

      this.setState({
        isDisabledGetCode,
      })
    } else {
      // 验证码
      let isDisabledLoginBtn = true

      if (codeReg.test(value)) {
        isDisabledLoginBtn = false
      }
      this.setState({
        isDisabledLoginBtn,
      })
    }
    callback()
  }
  
  async componentDidMount() {
    this.tempCountryList = []
    const { location: { from } } = this.props

    this.fetchCountryList()
    if (from) {
      this.setState({
        from
      })
    }
    // 获取storage的用户信息
    if (from === '/login/Policy') {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        const { phone, smsOTP, isSelectedAgreement, pickerValue, countryCode, countryCodeId } = JSON.parse(userInfo)
        this.setState({
          phone,
          smsOTP,
          isSelectedAgreement,
          pickerValue,
          countryCode,
          countryCodeId
        })
      }
    }
  }

  fetchCountryList = async () => {
    const res = await getCountryList()

    if(res.status_code !== 100000) return
    const list = res.response_data || []

    const countryCodeList = list.map((item) => {
      return {
        ...item, 
        label: `+${item.country_calling_code} ${t(`general.countryName${item.code}`)}`, 
        value: item.id 
      }
    })

    // 存储到redux
    this.props.setCountryList(countryCodeList || [])
    this.setState({ countryCodeList })
  }

  // 跳转到城市选择器
  goCountry = async () => {
    this.setState({
      visible: true,
    })
  }

  setVisible = () => {
    this.setState({ visible: false })
  }

  setValue = (codeArray) => {
    const { countryCodeList } = this.state
    const codeId = codeArray[0]
    const country = countryCodeList.find((c) => c.id === codeId)

    if(country && country.id) {
      const { country_calling_code } = country
      this.setState({
        countryCode: `+${country_calling_code}`,
        pickerValue: [codeId],
        countryCodeId: country.id,
        onoff: true
      })
    }
  }

  // 发送验证码
  sendCode = async () => {
    const { countryCodeId, phone } = this.state
    this.setState({
      clickDisabled: true
    })

    if (!phone) {
      Toast.show({
        content: t('login.mobileErr'),
      })
      return
    }
    if (phone.length !== 10 && (Number(countryCodeId) === 220 || Number(countryCodeId) === 219)) {
      Toast.show({
        content: t('login.phoneErr'),
      })
      return
    }
    if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone) && Number(countryCodeId) === 41) {
      Toast.show({
        content: t('login.phoneErr'),
      })
      return
    }
    this.setState({
      isShowSendCode: true,
      clickDisabled: true
    })

    const res = await reqSendCode({
      countryCode: countryCodeId, 
      mobile: phone,
      language: this.props.selectedLang
    })
    const timer = setInterval(() => {
      const time = this.state.time - 1
      if (time <= 0) {
        clearInterval(timer)
        this.setState({
          time: TOTAL_TIME,
          isShowSendCode: false,
          againGetCode: true
        })
        return
      }
      this.setState({
        time
      })
    }, 1000)
    
    switch (res.status_code) {
      case 100000:
        Toast.show({
          content: t('login.OTPSendSuccess'),
          duration: 2000,
        })
        break;
      case 300002:
        Toast.show({
          content: t('login.invalidParameterErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 300008:
        this.setState({
          loginLoading: false
        })
        break;
      case 301011:
        Toast.show({
          content: t('login.OTPErr2'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 301012:
        Toast.show({
          content: t('login.OTPSendErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 301013:
        Toast.show({
          content: t('login.OTPSendLimitErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 300001:
      default:
        Toast.show({
          content: t('login.systemErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  loginOrRegist = async () => {
    const { phone, smsOTP, isSelectedAgreement, countryCodeId } = this.state
    this.setState({
      loginLoading: true
    })
    if (!isSelectedAgreement) {
      Toast.show({
        content: t('login.agreementErr'),
        duration: 2000,
      })
      this.setState({
        loginLoading: false
      })
      return
    }
    if (!phone) {
      Toast.show({
        content: t('login.mobileErr'),
        duration: 2000,
      })
      return
    }
    if (!smsOTP) {
      Toast.show({
        content: t('login.OTPErr'),
        duration: 2000,
      })
      return
    }
    const res = await reqLogin(countryCodeId, phone, smsOTP)
    
    switch (res.status_code) {
      case 100000:
        this.setState({
          loginLoading: false
        })
        Toast.show({
          content: t('login.loginSuccess'),
          duration: 2000,
        })
        if (res.response_data) {
          const userInfo = res.response_data
          const { mobile } = userInfo
          if (mobile) {
            let phoneNumberStr = ''
            const mobileLength = mobile.length
            if (mobileLength === 11) {
              phoneNumberStr = mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
            } else if (mobileLength === 10) {
              phoneNumberStr = mobile.replace(/^(\d{3})\d{4}(\d{3})$/, '$1****$2')
            }
            window.localStorage.setItem('phoneNumberStr', phoneNumberStr)
          }
          window.localStorage.setItem('token', userInfo.authorization?.access_token)
          window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
          // 登录设置
          window.localStorage.setItem('isFromLogin', "true")

          this.props.setUserInfo(userInfo)
          
          const fromData = this.props.fromData
          if(fromData && fromData.pathname !== "/") {
            const { pathname, search } = fromData

            this.props.history.push({
              pathname: pathname,
              from: '/login',
              search: search
            })

            // if(pathname === "/recordList") {
            //   let url = pathname;
            //   if(search) {
            //     url += search
            //   }
            //   window.location.href = url;
            // }else {
              
            // }
          }

          try {
            await updateLanguage({
              language: this.props.selectedLang
            })
          } catch (error) {
            console.log(error)
          }

         
          if(fromData && fromData.pathname !== "/") {
            // 清除记录跳转页面
            this.props.setLoginFromPage({
              pathname: "",
              search: ""
            })
            return
          }
          
          setTimeout(() => {
            const { from, query } = this.props.location

            if(from === "/login/Policy") {
              return
            }

            this.props.history.push({
              pathname: from || '/',
              from: '/login',
              search: query
            })
          }, 500)
        }
        break;
      case 300002:
        Toast.show({
          content: t('login.invalidParameterErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 300008:
        this.setState({
          loginLoading: false
        })
        break;
      case 301009:
      case 301011:
      case 301001:
        Toast.show({
          content: t('login.OTPErr1'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 301010:
        Toast.show({
          content: t('login.OTPExpiredErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
      case 300001:
      case 300007:
      default:
        Toast.show({
          content: t('login.systemErr'),
          duration: 4000,
        })
        this.setState({
          loginLoading: false
        })
        break;
    }
  }

  handleClose = () => {
    const fromData = this.props.fromData
    const userInfo = this.props.userInfo

    if(Object.keys(userInfo).length && fromData && (fromData.pathname !== "/" && fromData.pathname !== "")) {
      const { pathname, search } = fromData

      this.props.history.push({
        pathname: pathname,
        from: '/login',
        search: search
      })
      // 清除记录跳转页面
      this.props.setLoginFromPage({
        pathname: "",
        search: ""
      })
      return
    }

    this.props.history.push('/')
  }

  checkPolicy = (code) => {
    const { phone, smsOTP, isSelectedAgreement, pickerValue, countryCode, countryCodeId } = this.state
    const userInfo = {
      phone, smsOTP, isSelectedAgreement, pickerValue, countryCode, countryCodeId
    }
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    this.props.history.push({
      pathname: '/login/Policy',
      query: { code }
    })
  }

  handleClickFun = () => {
    const { onoff } = this.state
    if (onoff) {
      this.setState({
        onoff: false
      })
      this.sendCode()
      this.timer = setTimeout(() => {
        this.setState({
          onoff: true
        })
      }, 10000)
    } else {
      Toast.show({
        content: t('login.resendErr'),
        duration: 1000,
      })
    }
  }
  switch = () => {
    const language = this.props.selectedLang === 'zh_CN' ? 'en_US' : 'zh_CN'
    this.props.switchSelectedLang(language)
    this.fetchCountryList()
  }

  render() {
    const {
      isShowSendCode,
      time,
      pickerValue,
      visible,
      isSelectedAgreement,
      countryCode,
      countryCodeId,
      againGetCode,
      loginLoading,
      countryCodeList
    } = this.state

    return (
      <div className="login">
        <div className="login-header d-flex flex-column align-items-center">
            <img src={welcome} className="login-logo" alt="" />
            <CloseOutline className="login-close" onClick={this.handleClose} fontSize={16}/>
        </div>
        <div className="login-container d-flex flex-column align-items-center">
          <div className='login-dle'>
            <div className="login-title">
              {t('login.title')}
            </div>
            <img className="languageSwitch" onClick={this.switch} src={this.props.selectedLang === 'zh_CN' ? languageCn : languageEn} alt="" />
          </div>

          <div className="login-subTitle">
            {t('login.desc')}
          </div>

          <div className="input-container phone-container">
            <div className="select-input"></div>
            <div className="phone-input">
              <Input
                className="input"
                type="tel"
                clear
                placeholder={t('login.mobilePlaceholder')}
                maxLength={Number(countryCodeId) === 41 ? 11 : 10}
                value={this.state.phone}
                onChange={(val) => {
                  val = val.replace(/[^\d]/g, '');
                  if (val.length <= (Number(countryCodeId) === 41 ? 11 : 10)) {this.setState({phone: val})}
                }}
                onBlur={scrollToTop}
              ></Input>

              <div className="phone-prefix" onClick={this.goCountry}>
                <span>{countryCode}</span>
                <DownOutline fontSize={12} color="#999"/>
              </div>
            </div>
          </div>
          <div className="input-container code-container">
            <Input
              className="input"
              placeholder={t('login.OTPPlaceholder')}
              maxlength={6}
              value={this.state.smsOTP}
              onChange={(val) => {
                val = val.replace(/[^\d]/g, '');
                if (val.length <= 6) { 
                  this.setState({smsOTP: val}) 
                }
              }}
              onBlur={scrollToTop}
            />

            {isShowSendCode ? (
              <div
                className="login-btn-text"
                style={{
                  color: '#999999',
                }}
              >
                {`${t('login.sendOTP')}(${time}s)`}
              </div>
            ) : (
              <div
                onClick={this.handleClickFun}
                className="login-btn-text"
                style={this.state.phone ? { color: '#f53942' } : { color: '#f9acb0' }}
              >
                {againGetCode ? t('login.resendOTP') : t('login.sendOTP')
                }
              </div>
            )}
          </div>
        </div>
        <div className="agreement d-flex align-items-center justify-content-center textAlignLeft">
        <Checkbox className="none" style={{
          '--icon-size': '18px',
          'marginRight': '4px',
          '--adm-color-primary': '#FF3841'
        }} value={isSelectedAgreement} onChange={(val) => {
          this.setState({
            isSelectedAgreement: val
          })
        }}/>
          <span>
            {t('login.agreement')}
            <span onClick={() => {
              this.checkPolicy('terms_and_conditions')
            }}>《{t('login.TAndC')}》</span>
            <span onClick={() => {
              this.checkPolicy('privacy_policy')
            }}>《{t('login.privacyPolicy')}》</span>
            <span onClick={() => {
              this.checkPolicy('ach_authorization_agreement')
            }}>《{t('login.ach')}》</span>
          </span>
        </div>
        <div className="btn-container">
          <Button
            type="warning"
            className="loginButton d-flex align-items-center justify-content-center"
            onClick={this.loginOrRegist}
            loading={loginLoading}
            disabled={!(this.state.phone && this.state.smsOTP && this.state.smsOTP.length === 6)}
            style={{marginTop: "0 !important"}}
          >
            {t('login.loginButton')}
          </Button>
        </div>
        <Picker
          columns={[countryCodeList]}
          visible={visible}
          onClose={this.setVisible}
          value={pickerValue}
          onConfirm={this.setValue}
          confirmText={t('general.confirm')}
          cancelText={t('general.cancel')}
        ></Picker>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(createForm()(Login))
