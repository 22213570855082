import './index.less'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function InstantDepositNotice(props) {
    const { t } = useTranslation()
  
    return (
      <div id="instant-deposit-notice">
        <div className="title">{t('recharge.instant_deposit_notice.title1')}</div>
        <div className="content" dangerouslySetInnerHTML={{__html: t('recharge.instant_deposit_notice.tip1')}}></div>
        <div className="title">{t('recharge.instant_deposit_notice.title2')}</div>
        <div className="content" dangerouslySetInnerHTML={{__html: t('recharge.instant_deposit_notice.tip2')}}></div>
        <div className="title">{t('recharge.instant_deposit_notice.title3')}</div>
        <div className="content" dangerouslySetInnerHTML={{__html: t('recharge.instant_deposit_notice.tip3')}}></div>
      </div>
    )
  }