import React, { Component } from "react"
import { Button, NavBar } from "antd-mobile"
import "./index.less"
import { connect } from "react-redux"
import { setPasswordRelatedChannel } from "@actions/password"
import notice from "@assets/images/mine/notice.png"
import { scrollToTop } from "@utils/utils"
import { t } from "i18next"

const mapDispatchToProps = {
  setPasswordRelatedChannel,
}
class FreezeAccount extends Component {
  handleFreezeAccount = async () => {
    this.props.setPasswordRelatedChannel({
      channel: "freezeAccount",
      type: "freeze",
      url: "/freezeAccount",
      backUrl: "/resetTransactionPasswordA",
    })
    this.props.history.push("/resetTransactionPasswordA")
  }
  goBack = () => {
    this.props.history.push({ pathname: '/mine', state: { router: 'setting' } })
  }
  componentDidMount = () => {
    scrollToTop()
  }
  render() {
    return (
      <div className="freezeAccount">
        <NavBar
          style={{
            height: "36px",
          }}
          onBack={this.goBack}
        >
          {t("account.Accountisfrozen")}
        </NavBar>
        <div className="freeze-account-tip d-flex flex-column align-items-center">
          <img src={notice} alt="" />
          <p dangerouslySetInnerHTML={{__html: t('freezeAccount.freezeaccount')}}>
            
          </p>
          <div className="btn-container">
            <Button
              type="warning"
              className="warning-btn d-flex align-items-center justify-content-center"
              onClick={this.handleFreezeAccount}
            >
              {t("freezeAccount.freezeaccountStart")}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(FreezeAccount)
