import React, { useEffect, useState, useRef } from "react"
import "./index.less"
import { NavBar, ImageUploader, Button, Mask, Image, Toast } from "antd-mobile"
import { compressedFile, scrollToTop } from "@utils/utils"

import { t } from "i18next"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

import Avatar from "react-avatar"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.min.css"

import { updateAvatar } from "@api/user"
import SvgIcon from "@comps/SvgIcon"

const colorList = ["#FF3841", "#4369ED", "#7660FF", "#DF70DB", "#D6DF70", "#FFD43D", "#F9941E", "#4369ED", "#85B918", "#08B1C3"]

// dataurl 为传进来的base64格式的图片地址， return 返回的为file格式
const base64ImgtoFile = (dataurl, filename = 'file') => { 
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}

export default function Authentication() {
  const cropperRef = useRef(null)
  const history = useHistory()
  const userInfo = useSelector((state) => state.user.userInfo)
  const [editPictureShow, setEditPictureShow] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState(userInfo.avatar)
  const [picture, setPicture] = useState("")
  const [currentPic, setCurrentPic] = useState("")
  const color = colorList[userInfo?.mobile.charAt(userInfo?.mobile.length - 1)] || "#fff"

  useEffect(async () => {
    scrollToTop()
  }, [])

  const beforeUploadAvatar = async (files) => {
    try {
      const compressImg = await compressedFile(files[0], 0.3, 0.5, 800, 800)
      const objectUrl = URL.createObjectURL(compressImg)

      setPicture(objectUrl)
      setEditPictureShow(true)
      return files[0]
    } catch (error) {
      console.log(error)
    }
  }

  const onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const avatarObj = cropper.getCroppedCanvas().toDataURL()

    setCurrentPic(avatarObj)
  }

  const onComplate = async () => {
    setDisabled(false)
    setAvatar(currentPic)
    onCancel()
  }

  const onCancel = () => {
    setEditPictureShow(false)
    setCurrentPic("")
    setPicture("")
  }

  const toUpdateAvatar = async () => {
    if (!avatar) return
    const formData = new FormData()

    const newFile = base64ImgtoFile(avatar, Date.now())
    const littleFile = await compressedFile(newFile, 0.02, 0.5, 300, 300)

    formData.append("avatar", littleFile)
    console.log(formData, littleFile)
    setLoading(true)
    const res = await updateAvatar(formData)
    setLoading(false)
    if (res.status_code === 100000) {
      Toast.show({
        content: t("mine.updateAvatarSuccess"),
        duration: 3000,
      })
      history.goBack()
    } else {
      Toast.show({
        content: t("mine.updateAvatarFailure"),
        duration: 3000,
      })
    }
  }

  return (
    <div id="update-avatar-container">
      <NavBar
        onBack={history.goBack}
        style={{
          "--height": "60px",
        }}
      >
        {t("mine.updateAvatarTitle")}
      </NavBar>

      <Mask visible={editPictureShow} className="cropper-mask" opacity="0.9">
        <Cropper
          src={picture}
          style={{ height: 300, width: "100%" }}
          initialAspectRatio={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          cropBoxResizable={false}
          viewMode={2}
          minCropBoxWidth={150}
          minCropBoxHeight={150}
        />
        <div className="action">
          <Button className="action-btn" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
          <Button className="action-btn" onClick={onComplate}>
            {t("common.finish")}
          </Button>
        </div>
      </Mask>

      <ImageUploader
        className="image-uploader"
        beforeUpload={beforeUploadAvatar}
        maxCount={1}
        showFailed={false}
        preview={false}
        style={{
          "--cell-size": "300px",
        }}
      >
        {avatar ? (
          <Image src={avatar} width={300} height={300} fit="cover" style={{ borderRadius: 150 }} />
        ) : userInfo.first_name ? (
          <Avatar name={`${userInfo.first_name} ${userInfo.last_name}`} size="300" textSizeRatio={2.22} round="50%" color={color} />
        ) : (
          <SvgIcon iconClass="avatar" />
        )}
      </ImageUploader>

      <Button
        className={["upload-avatar", picture ? "active" : ""].join(" ")}
        loading={loading}
        disabled={disabled ? true : false}
        onClick={toUpdateAvatar}
      >
        {t("mine.updateAvatarBtn")}
      </Button>
    </div>
  )
}
