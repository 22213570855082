import {
    SET_ACTION_TOKEN,
    SET_PASSWORD_RELATED_CHANNEL,
} from './ActionTypes'


export const setActionToken = (actionToken) => {
  sessionStorage.setItem('actionToken', actionToken)
  return {
    type: SET_ACTION_TOKEN,
    actionToken
  }
}

export const setPasswordRelatedChannel = (passwordRelatedChannel) => {
  return {
    type: SET_PASSWORD_RELATED_CHANNEL,
    passwordRelatedChannel
  }
}
