import React, { useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { NavBar, Button } from "antd-mobile"
import "./index.less"
import icon_bank from "@assets/images/home/relateStart/icon_bank.png"
import icon_plaid from "@assets/images/home/relateStart/icon_plaid.png"
import icon_yes from "@assets/images/home/relateStart/icon_yes.png"
import { usePlaidLink } from "react-plaid-link"
import { generateToken, putPublickToken } from "@api/plaid"
import { keepLogger } from "@api/user"
// import { useSelector } from "react-redux"
// import { setSelectedRechargeBank, setSelectedWithdrawalBank } from "@actions/account"
import { t } from "i18next"

function RelateStart(props) {
  sessionStorage.removeItem("recognition")
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  // const selectedBank = useSelector((state) => state.account.selectedBank)
  // const dispatch = useDispatch()

  const createToken = useCallback(async () => {
    const linkToken = await generateToken()
    setToken(linkToken.response_data.link_token)
    setLoading(false)
  }, [])

  const onSuccess = useCallback(async (publicToken, metadata) => {
    setLoading(true)
    let accountId = metadata.account_id
    let institutionId = metadata.institution.institution_id
    let institutionName = metadata.institution.name
    let accounts = metadata.accounts
    const data = {
      externalLinkServiceProviderId: 1,
      bindParams: {
        publicToken: publicToken,
        accountId: accountId,
        institutionId: institutionId,
        institutionName: institutionName,
        accounts: accounts,
      },
      paramsType: "J",
    }
    await putPublickToken(data)

    // const setSelectedBank = selectedBank?.from === "/recharge" ? setSelectedRechargeBank : setSelectedWithdrawalBank
    // todo 需要返回账户id
    // dispatch(setSelectedBank(metadata.account_id))
    props.history.push("/plaidSuccess")
    setLoading(false)
  }, [])

  const onExit = useCallback((error, metadata) => {
    setLoading(true)
    error ? props.history.replace(`/plaidFail?msg=${error?.display_message}`) : window.history.back(-1)
    setLoading(false)
  })

  const onEvent = useCallback(async (eventName, metadata) => {
    await keepLogger({
      actionCode: `plaid_${eventName}`,
      params: metadata,
    })
  })

  // generate link token on app load
  React.useEffect(() => {
    createToken()
  }, [createToken])
  return (
    <div className="App">
      {loading ? <div className="spinner"></div> : <PlaidLink token={token} onSuccess={onSuccess} onExit={onExit} onEvent={onEvent} />}
    </div>
  )
}
function PlaidLink({ token, onSuccess, onExit, onEvent }) {
  const history = useHistory()
  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit,
    onEvent,
  })

  const goBack = () => {
    window.history.back(-1)
  }

  // useEffect(() => {
  //   if (ready) {
  //     const plaidLinkDom = document.querySelector("iframe[id^='plaid-link-iframe']")
  //     if (plaidLinkDom) {
  //       if (plaidLinkDom.attachEvent){ // 兼容IE写法
  //         plaidLinkDom.attachEvent('onload', function() {
  //           const frmDoc = plaidLinkDom.contentWindow?.document
  //           const script = frmDoc?.createElement('script')
  //           script.type = 'text/javascript'
  //           script.src = 'https://www.recaptcha.net/recaptcha/api.js?render=explicit&hl=en'
  //           console.log(frmDoc)
  //           frmDoc.body.appendChild(script)
  //         })
  //       } else {
  //         plaidLinkDom.onload = function(){
  //           const frmDoc = plaidLinkDom.contentWindow?.document
  //           const script = frmDoc?.createElement('script')
  //           console.log(frmDoc, script, 'onload')
  //           script.type = 'text/javascript'
  //           script.src = 'https://www.recaptcha.net/recaptcha/api.js?render=explicit&hl=en'
  //           console.log(frmDoc)
  //           frmDoc.body.appendChild(script)
  //         }
  //       }
  //     }
  //   }
  // }, [ready])

  return (
    <div className="relateStart">
      <div onClick={goBack}>
        <NavBar className="bgfff">{t("setPassword.Startassociating")}</NavBar>
      </div>

      <div className="wrap">
        <div className="d-flex justify-content-between align-items-center">
          <img className="icon_bank" src={icon_bank} alt="" />
          <div className="tip">
            <img src={icon_plaid} alt="" />
            {t("setPassword.CheckoutPlaidl")}
          </div>
        </div>
        <h1 className="title">{t("setPassword.associatingbankaccount")}</h1>
        <div className="text_introduction">
          {t("setPassword.bankaccountthroughPlaid")}{" "}
        </div>
        <div className="info_item">
          <img src={icon_yes} alt="" />
          {t("setPassword.bankaccountillstar")}
        </div>
        <div className="info_item">
          <img src={icon_yes} alt="" />
          {t("setPassword.Maketransaction")}
        </div>
        <div className="info_item">
          <img src={icon_yes} alt="" />
          {t("setPassword.Preventionfraud")}
        </div>
      </div>
      <div className="relate-start-btn">
        <div className="next-button-container d-flex flex-column align-items-center">
          <div className="tutorial-title" onClick={() => history.push("/tutorials/bankCard")}>
            {t("bankCardTutorial.Guide")}
          </div>
          <div onClick={open} disabled={!ready}>
            <Button type="warning" className="next-button1 d-flex align-items-center justify-content-center">
              {t("linkedAccounts.Startadding")}
            </Button>
          </div>
          <div
            className="product-introduce"
            onClick={() => {
              history.replace({
                pathname: "/login/Policy",
                query: { code: "terms_and_conditions", from: "relateStart" },
              })
            }}
            dangerouslySetInnerHTML={{ __html: t("relateStart.PageTAndC") }}
          />
        </div>
      </div>
    </div>
  )
}

export default RelateStart
