import React, {} from "react"
import "./index.less"
import { contactService } from "@utils/utils"
import contract from "@assets/images/certificate/contract.png"

export default function KYCSuccess(props) {
  const contactClick = () => {
    contactService()
  }
  
  return (
    <div id="kyc-contact-us">
      <img className="kyc-contract" src={contract} alt="联系客服" onClick={() => contactClick()} />
    </div>
  )
}
