import {
    SWITCH_SELECTED_LANG,
    SET_IS_FIRST_VISIT,
    SET_USER_INFO,
    GET_KYC_BEHAVIOR,
    SET_KYC_BEHAVIOR,
    SET_IS_TRANSACTION_PIN_SET,
} from '../actions/ActionTypes'

const initialState = {
    selectedLang: localStorage.getItem('selectedLang') || 'zh_CN',
    isFirstVisit: localStorage.getItem('isFirstVisit') || false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    activateSuccess: JSON.parse(localStorage.getItem('activateSuccess'))
}

const user = (state = initialState, action) => {
    
    let newState = {
        ...state
    }
    switch (action.type) {
        case SWITCH_SELECTED_LANG:
            newState.selectedLang = action.selectedLang
            return newState
        case SET_IS_FIRST_VISIT:
            newState.isFirstVisit = action.isFirstVisit
            return newState
        case SET_USER_INFO:
            newState.userInfo = action.userInfo
            return newState
        case SET_IS_TRANSACTION_PIN_SET:
            newState.userInfo.is_transaction_pin_set = action.payload
            return newState
        case GET_KYC_BEHAVIOR:
            return {
                ...state,
                activateSuccess: !action.status
            }
        case SET_KYC_BEHAVIOR: 
            return {
                ...state,
                activateSuccess: false
            }
        default:
        return state
    }
}

export default user
  