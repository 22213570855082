import './index.less'
import React, { useState, useEffect, useCallback } from 'react'
import { Radio, NavBar, Space, Button } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import acBank from '@assets/images/associatedBank/ac_bank.png'
import acLink from '@assets/images/associatedBank/ac_link.png'
import acPC from '@assets/images/associatedBank/ac_pc.png'
import acPlaid from '@assets/images/associatedBank/ac_plaid.png'
import acTillstar from '@assets/images/associatedBank/ac_tillstar.png'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getBindRequestList } from '@api/account'
import { setCurrentBankInfo, setBankList } from '@actions/account'
import { useDispatch, useSelector } from 'react-redux'

const bankOptions = [
    {
        value: 'tillstar',
        icon_left: acTillstar,
        icon_left_class: 'acTillstar',
        icon_right: acBank,
        icon_right_class: 'acBank',
        is_has_icon_tip: true
    },
    {
        value: 'plaid',
        icon_left: acPlaid,
        icon_left_class: 'acPlaid',
        icon_right: acBank,
        icon_right_class: 'acBank',
        is_has_icon_tip: false
    },
    {
        value: 'pc',
        icon_left: acPC,
        icon_left_class: 'acPC',
        icon_right: acTillstar,
        icon_right_class: 'acTillstar',
        is_has_icon_tip: true
    }
]

export default function AssociatedBank() {
    const [value, setValue] = useState('tillstar')
    const history = useHistory()
    const dispatch = useDispatch()
    const bankList = useSelector(state => state.account.bankList)
    const passwordRelatedChannel = useSelector(state => state.password.passwordRelatedChannel)

    const backTo = () => {
        if (passwordRelatedChannel.url === '/associatedBank' && passwordRelatedChannel.backUrl) {
            history.push(passwordRelatedChannel.backUrl)
        } else {
            history.goBack()
        }
    }

    const toAssociate = () => {
        switch (value) {
            case 'tillstar':
                history.push('/addBank')
            break;
            case 'plaid':
                history.push('/relateStart')
            break;
            default:
                history.push('/tutorials/bankCard')
            break;
        }
    }

    const initData = useCallback(async () => {
        const res = await getBindRequestList({ page: 1 })
        if (res.status_code === 100000) {
            dispatch(setBankList(res.response_data.list))
        }
    }, [])

    useEffect(() => {
        initData()
    }, [])

    return (
        <div id="associated-bank">
            <NavBar
                style={{
                    '--height': '36px',
                    '--background': '#ffffff',
                }}
                back={null}
                right={<CloseOutline onClick={backTo} fontSize={24}/>}
            />
            <div className="title">
                <Trans i18nKey={t(`associatedBank.pop.title`)}/>
            </div>
            <div className="tip">{t(`associatedBank.pop.tip`)}</div>
            { bankList.length > 0 && (bankList.length > 1 ? <div className="waiting-list">
                    <span className="waiting-list-txt">{t(`associatedBank.pop.waiting_list_multiple`)}</span>
                    <span className="to-check" onClick={() => {
                        history.push('/chooseBank')
                    }}>{t(`associatedBank.pop.to_check_multiple`)}</span>
                </div> : <div className="waiting-list">
                    <span className="waiting-list-txt">{t(`associatedBank.pop.waiting_list`, {
                        bank: (bankList[0] && bankList[0].account_nickname) || '**'
                    })}</span>
                    <span className="to-check" onClick={() => {
                        dispatch(setCurrentBankInfo(bankList[0]))
                        history.push('/addBankVerify')
                    }}>{t(`associatedBank.pop.to_check`)}</span>
                </div>)
            }
            <div className="choose-bank">
                <Radio.Group defaultValue={value} onChange={setValue}>
                    <Space direction='vertical'>
                        {
                            bankOptions.map((item, index) => 
                            <div className="bank-option" key={index}>
                                <div className="up">
                                    <Radio value={item.value} style={{
                                        '--icon-size': '18px',
                                        '--adm-color-primary': '#FF3841'
                                    }}/>
                                    <div className="option-title">
                                        <span className="txt">{<Trans i18nKey={t(`associatedBank.pop.option.${index}.title`)}/>}</span>
                                        { item.is_has_icon_tip && <span className="icon_tip">
                                        {t(`associatedBank.pop.option.${index}.icon_tip`)}
                                        </span>}
                                    </div>
                                </div>
                                <div className="down">
                                    <div className="down_tip">{t(`associatedBank.pop.option.${index}.tip`)}</div>
                                    <div className="imgs">
                                        <img className={item.icon_left_class} src={item.icon_left} alt="" />
                                        <img className="icon_link" src={acLink} alt="" />
                                        <img className={item.icon_right_class}src={item.icon_right} alt="" />
                                    </div>
                                </div>
                            </div>)
                        }
                    </Space>
                </Radio.Group>
            </div>
            <div className="bottom-btn">
                <Button onClick={toAssociate}>{t('associatedBank.pop.btn')}</Button>
            </div>
        </div>
    )
}