import React, { Component } from 'react'
import './index.less'
import { NavBar } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import { connect } from 'react-redux'
import { setPasswordRelatedChannel } from '@actions/password'
import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'

const mapDispatchToProps = {
  setPasswordRelatedChannel,
}
class TradePassword extends Component {
  goBack = () => {
    this.props.history.push({ pathname: '/mine', state: { router: 'setting' } })
  }
  componentDidMount(){
    scrollToTop()
    sessionStorage.removeItem("reset")
  }
  editTransactionPassword = () => {
    this.props.setPasswordRelatedChannel({
      channel: 'normalSetting',
      type: 'edit',
      url: '/mine',
      backUrl: '/tradePassword'
    })
    this.props.history.push('/editTransactionPassword')
  }
  resetTransactionPassword = () => {
    sessionStorage.setItem("reset","reset")
    this.props.setPasswordRelatedChannel({
      channel: 'normalSetting',
      type: 'reset',
      url: '/mine',
      backUrl: '/resetTransactionPasswordA'
    })
    this.props.history.push('/resetTransactionPasswordA')
  }
  render() {
    return (
      <div className='tradePassword-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
            'marginBottom': '12px'
          }}
          onBack={this.goBack}
        >
          {t('account.TradePin')}
        </NavBar>
        <div className="tradePassword d-flex flex-column align-items-center">
          <div className="input-container">
            <div className="modify-password d-flex align-items-center justify-content-between" onClick={this.editTransactionPassword}>
              <span className="label">
                {t("setPassword.Changetransactionpassword")}
              </span>
              <RightOutline fontSize={14} color='#999'/>
            </div>

            <div className="reset-password d-flex align-items-center justify-content-between" onClick={this.resetTransactionPassword}>
              <span className="label">
                {t("setPassword.Resettransactionpassword")}
              </span>
              <RightOutline fontSize={14} color='#999'/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(TradePassword)
