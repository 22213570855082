import request from "@utils/request";

// 当前公共请求地址前缀

export const generateToken = (data) => {
  return request({
    method: "POST",
    url: `/app/user/generate-kyc-token-in-identity-verification-request`,
    data
  });
};
export const idenToken = (data) => {
  return request({
    method: "POST",
    url: `app/user/update-identity-verification-request-status`,
    data
  });
};
export const onfio = (data) => {
  return request({
    method: 'POST',
    url: `app/auth/upload-kyc-document-in-identity-verification-request`,
    data
  });
};
export const onfioUpload = (data) => {
  return request({
    method: "POST",
    url: `app/auth/upload-kyc-live-photo-in-identity-verification-request`,
    data
  });
};

export const uploadNonSSN = (data) => {
  return request({
    method: "POST",
    url: `app/auth/upload-non-ssn-kyc-file`,
    data
  })
}

export const autoCompleteNonSSNBill = (data) => {
  return request({
    method: "POST",
    url: `app/auth/submit-non-ssn-kyc-info`,
    data
  })
}

