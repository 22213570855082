
import React from 'react'
import { RightOutline } from 'antd-mobile-icons'

import './index.less'

export default function KYCStepList(props) {
  const { list } = props
  const count = list.length
  return (
      <ul className="step-list d-flex">
        { list.map((item, index) => (
          <li
            key={`list${index}`}
            className={['d-flex', item.active ? 'active' : ''].join(' ')}>
            {item.active && <i className="circle">
              <i className='inner-circle'></i>
            </i>}
            <span>{item.name}</span>
            {index !== count - 1 && <RightOutline fontSize={12} color="#999"/> }
          </li>
          ))
        }
      </ul>
  )
}

