import React, { useEffect, useState } from 'react'
import compliance from "@assets/images/rmb/icons/compliance.png"
import loading from "@assets/images/rmb/order/loading.png"
import cancelActive from "@assets/images/rmb/order/cancel.png"
import success from "@assets/images/rmb/order/success.png"
import reject from "@assets/images/consumption/reject.png"
import cancel from "@assets/images/consumption/cancel.png"
import freeze from "@assets/images/rmb/order/freeze_logo.png"

import './index.less'
import Flow from '../Flow'
import { Trans, useTranslation } from 'react-i18next'
import { formatNumberToUSD } from '@utils'
import { handleDot } from '@utils/utils'

// 合规提醒
function ComplianceTips() {
  return (
    <div className='compliance-tips'>
      <img alt=''  src={compliance} />
      <div className='text'>
        <div>因合规要求，此笔消费金仅保留20分钟，您需尽快消费</div>
        <div className='bold-tips'>且不会记录积累在美金余额中，您可在明细中查看</div>
      </div>
    </div>
  )
}

// 6003: 支付成功
function PurchaseSuccessOrder(props) {
  const { transactionDetail, t, countDown, isExpired } = props
  let icon = success

  let tipStyle = {
    color: "#999"
  }
  if(transactionDetail.status === 6001) {
    tipStyle = {
      color: "#1F49DA"
    }
    icon = loading
  }
  if(transactionDetail.status === 6002) {
    tipStyle = {
      color: "#FF3841"
    }
    icon = reject
  }

  return (
    <div>
      {countDown !== 0 && ![6001, 6007].includes(transactionDetail.status) && <ComplianceTips />}
      <div className='order-loading'>
        <img src={icon} className='base-icon' alt=''></img>
      </div>
      <div className='amount'>+${handleDot(formatNumberToUSD(transactionDetail.amount))}</div>
      {
        transactionDetail.status !== 6001 ? <div>
          {!isExpired && countDown !== 0 && [6003, 6004].includes(transactionDetail.status)
          ? <div className='purchase-waiting'>
              <div>{t('transaction_detail.rmb_purchase.expire.l_t')} <span>{countDown[0] || 0}</span> <span>{countDown[1] || 0}</span> <label> : </label>  <span>{countDown[3] || 0}</span> <span>{countDown[4] || 0}</span>  {t('transaction_detail.rmb_purchase.expire.r_t')}</div>
              <div className='expire-text' dangerouslySetInnerHTML={{__html: t('transaction_detail.rmb_purchase.expire.text')}} />
            </div>   
          : <div className='purchase-success-text' dangerouslySetInnerHTML={{__html:t(`transaction_detail.rmb_purchase.description.${transactionDetail.status}`)}}></div>
          }
        </div>
        :<div className='loading-text' style={tipStyle} dangerouslySetInnerHTML={{__html:t(`transaction_detail.rmb_purchase.description.${transactionDetail.status}`)}} />
      }
    </div>
  )
}

const ConsumptionTips = (props) =>{
  const {t} = props

  return (
    <div className='consumption-tips'>
      <img alt=''  src={compliance} />
      <div className='text'>
        <div dangerouslySetInnerHTML={{__html: t('transaction_detail.consumption.tips')}} />
      </div>
  </div>
  )
}
function ConsumptionTransaction(props) {
  const {transactionDetail,t} = props
  let icon = success;
  switch(transactionDetail.status) {
    case 5000:
      icon = cancel
      break;
    case 5001:
      icon = reject
      break;
    default: 
      break;
  }
  let tipStyle = {}

  if (transactionDetail.status === 9002) {
    tipStyle = {
      background: '#F2F5FF',
      borderRadius: '36px',
      color: '#1F49DA',
      display: 'inline-block',
      fontSize: '14px',
      padding: '4px 8px',
      marginTop: '4px'
    }
  }
  const switchTipsShow = [5002, 5009, 5010, 5011, 5012, 5013, 5017].includes(transactionDetail.status)
  return(
    <div id='consumption'>
      { switchTipsShow && <ConsumptionTips t={t}/>}
      <div className='order-loading'>
        <img src={icon} className='base-icon' alt=''></img>
      </div>
      <div className='merchant-name'>{transactionDetail.merchant?.name}</div>
      {transactionDetail.associated_order && <div className="card-name">
        {transactionDetail.associated_order.card_name}礼卡
      </div>
}
      <div className='amount'> -${formatNumberToUSD(transactionDetail.amount)}</div>
      {
        switchTipsShow
        && 
        <div id='switch-rmb'>
          <div className='main' dangerouslySetInnerHTML={{__html:t(`transaction_detail.consumption.description.switch_rmb`)}}/>
          <div className='text' dangerouslySetInnerHTML={{__html:t('transaction_detail.consumption.description.switch_rmb_text')}}/>
        </div>
      }
      <div className='consumption-text'>
        {[5000, 5001, 5003, 5004, 5005, 5006, 5007, 5008].includes(transactionDetail.status) && t(`transaction_detail.consumption.description.${transactionDetail.status}`)}
      </div>
      {
        [9002].includes(transactionDetail.status) &&
        <div className='loading-text' style={tipStyle} dangerouslySetInnerHTML={{__html:t(`transaction_detail.rmb_purchase.description.${transactionDetail.status}`)}} />
      }
    </div>
  )
}
const USDRefundTransaction = (props) =>{
  const {transactionDetail,t} = props
  const usdRmbRefundShow = [6012,6013,6014].includes(transactionDetail.status)
  
  return (
    <div id='usd-refund-transaction'>
      { usdRmbRefundShow ?
        <div>
          <div className='order-loading'>
            {
              transactionDetail.status === 6012 && <img src={loading} className='base-icon' alt=''></img>
            }
            {
              transactionDetail.status === 6013 && <img src={reject} className='base-icon' alt=''></img>
            }
            {
              transactionDetail.status === 6014 && <img src={success} className='base-icon' alt=''></img>
            }
          </div>
          <div className='amount' > -${formatNumberToUSD(transactionDetail.amount)}</div>
          {
            transactionDetail.status === 6013 ?
             <div className='usd-refund-des-text' style={{color: '#FF3841'}}>
              <Trans i18nKey={t(`transaction_detail.usd_refund.description.${transactionDetail.status}`)} values={{reason: transactionDetail.reason?.description}} > 
                {t(`transaction_detail.usd_refund.description.${transactionDetail.status}`)}
              </Trans>
              </div>
            :
            <div className='usd-refund-des-text' style={transactionDetail.status === 6014 ? {color: '#999'} : {color: '#1F49DA'}}>
              <div dangerouslySetInnerHTML={{__html: t(`transaction_detail.usd_refund.description.${transactionDetail.status}`)}}></div>
            </div>
          }

        </div>
      : <div>
          <div className='order-loading'>
            {
              transactionDetail.status === 8010 && transactionDetail.is_gift_card && <img src={reject} className='base-icon' alt=''/>
            }
            {
              transactionDetail.status === 8011 && transactionDetail.is_gift_card && <img src={cancelActive} className='base-icon' alt=''></img>
            }
            {
              !transactionDetail.is_gift_card && <img src={ transactionDetail.status === 8011 ? success : freeze} className='base-icon' alt=''></img>
            }
            
          </div>
          <div className='amount'> +${formatNumberToUSD(transactionDetail.amount)}</div>
          {
            transactionDetail.is_gift_card && transactionDetail.status === 8011 && <div className='usd-refund-des-text' style={{color: '#333'}}>
            {t('transaction_detail.usd_refund.description.success')}
          </div>
          }
          {
            transactionDetail.is_gift_card && transactionDetail.status === 8010 && <div className='usd-refund-des-text' style={{color: '#333'}}>
            {t('transaction_detail.usd_refund.description.fail')}
          </div>
          }
          {
            !transactionDetail.is_gift_card && <div className='usd-refund-des-text' style={{color: transactionDetail.status === 8011 ? '#1F49DA': '#FF0C0C'}}>
            {transactionDetail.status === 8011 ? t('transaction_detail.usd_refund.description.success') : t('transaction_detail.usd_refund.description.freeze')}
          </div>
          
          }

            {
              [8011, 8010].includes(transactionDetail.status) && transactionDetail.is_gift_card &&<div className='usd-refund-tip'>
              {t(`transaction_detail.bottom_action.tips.${transactionDetail.status}`)}
            </div>
            }
          
        </div>
     }
     </div>

  )
}

const UsdSwitchResultTransaction = (props) => {
  const {transactionDetail, t} = props
  const amountStyle = {
    color: [6009, 6011].includes(transactionDetail.status) ? '#BDBDBD' :'#333',
    fontSize: "24px"
  }
  let icon = success
  if(transactionDetail.status === 6008){
    icon = loading
  }
  if([6009, 6011].includes(transactionDetail.status)) {
    icon = reject
  }
  return (
    <div>
    <div className='order-loading'>
      <img src={icon} className='base-icon' alt=''></img>
    </div>
    <div className='rmb-status-text'>{t(`transaction_detail.rmb_purchase.description.${transactionDetail.status}`)}</div>
    <div className='amount' style={amountStyle}>+${formatNumberToUSD(transactionDetail.amount)}</div>
  </div>
  )
}

export default function TransactionStatus(props) {
  const { transactionDetail } = props
  const [countDown, setCountDown] = useState('00:00')
  const showConsumptionTransaction = RegExp(/50/).test(transactionDetail.status) || [9002].includes(transactionDetail.status)
  const showUsdRefundTransaction =  RegExp(/80/).test(transactionDetail.status) || [6012,6013,6014].includes(transactionDetail.status);
  const {t} = useTranslation()
  const showFlowStatus = [5002, 6003, 5009, 5010, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 6008, 6009, 6010, 6011, 8001,8002,8003,8004,8005,8006,8007,8008,8009,8010,8011].includes(transactionDetail.status)
  const showUsdSwitchRmb = [6008, 6009, 6010, 6011].includes(transactionDetail.status)
  const showRmbStatus = [6001, 6002, 6003, 6004, 6005, 6006, 6007].includes(transactionDetail.status)
  const expireAtDate = new Date(transactionDetail.expire_at || 0)
  const [isExpired, setIsExpired] = useState(expireAtDate - new Date() <= 0)

  useEffect(() => {
    let countDownInterval = setInterval(() => {
      setIsExpired(expireAtDate - new Date() <= 0)
      if(isExpired) {
        setCountDown('00:00')
        clearInterval(countDownInterval)
        countDownInterval = null
      } else {
        const total = (expireAtDate - new Date()) / 1000
        let min = Math.floor(total / 60)
        if (min.toString().length === 1) {
          min = '0' + min
        }
        let second = Math.round(total % 60)
        if (second.toString().length === 1) {
          second = '0' + second
        }
        setCountDown(min + ':' + second)
      }
    }, 1000)
    return () => {
      clearInterval(countDownInterval)
      countDownInterval = null
    }
  }, [isExpired])

  return (
    <div id='transaction-status' style={(transactionDetail.is_gift_card || [9002].includes(transactionDetail.status)) ? {marginTop: 0} : {}}>
 
      { !transactionDetail.is_gift_card && showFlowStatus  && <Flow transactionDetail={transactionDetail} t={t}/> }
      {
        transactionDetail.code && 
         <div className='base'>
        {/* 0: 创建支付订单 */}
        {/* {transactionDetail.status === 0 && <PendingOrder />} */}
        {/* 1: 等待支付 */}
        {/* {transactionDetail.status === 6001 && <LoadingOrder transactionDetail={transactionDetail}/>} */}
        {/* 6003: 购买消费金·购买成功·未消费 */}
        {showRmbStatus && <PurchaseSuccessOrder isExpired={isExpired} transactionDetail={transactionDetail} countDown={countDown} t={t} /> }
        {/* 6002: 购买消费金·购买失败 */}
        {/* {transactionDetail.status === 6002 && <PaymentFailOrder transactionDetail={transactionDetail}/>} */}
        {/* 4：等待退款 */}
        {/* {transactionDetail.status === 4 && <PendingOrder />} */}
        {/* 5：退款成功 */}
        {/* {transactionDetail.status === 5 && <OrderRefundSuccess transactionDetail={transactionDetail} t={t}/>} */}
        {/* 6：退款失败 */}
        {/* {transactionDetail.status === 6 && <PendingOrder />} */}
        {/* 50xx: 消费 */}
        { showConsumptionTransaction && <ConsumptionTransaction  transactionDetail = {transactionDetail} t={t}/>}
        {/* 80xx: 美元退款 */}
        { showUsdRefundTransaction && <USDRefundTransaction transactionDetail = {transactionDetail} t={t} />}
        { showUsdSwitchRmb && <UsdSwitchResultTransaction transactionDetail = {transactionDetail} t={t}  />}
        </div>
      }

    </div>
  )

}