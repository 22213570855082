import React, { Component } from 'react'
import { Toast, NumberKeyboard, PasscodeInput } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import { withRouter } from 'react-router-dom'
import { getPhoneCode, verifyPhoneCode } from '@api/user'
import { connect } from 'react-redux'
import ExceptionPop from '@comps/ExceptionPop'
import { setActionToken } from '@actions/password'
import { scrollToTop } from "@utils/utils"
import './index.less'
import { t } from 'i18next'

const TOTAL_TIME = 60

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
    actionToken: store.password.actionToken,
    selectedLang: store.user.selectedLang,
  }
}

const mapDispatchToProps = {
  setActionToken,
}
class VerifyCode extends Component {
  state = {
    title: '身份验证',
    time: TOTAL_TIME,
    code: '',
    onoff: true,
    error: false,
    isExceptionPopVisible: false,
  }
  
  passcodeInputRef = React.createRef()

  goBack = () => {
    this.props.setActionToken("")
    this.props.history.goBack()
  }
  
  getActionCode = (type, code) => {
    let actionCode = undefined
    switch (type) {
      case 'reset': 
        actionCode = `ForgotTransactionPin_${code}OTP`
        break;
      case 'freeze': 
        actionCode = `FreezeUserCard_${code}OTP`
        break;
      default:
        actionCode = `InitialTransactionPin_${code}OTP`
        break;
    }
    return actionCode
  }

  sendCode = async () => {
    const actionCode = this.getActionCode(this.props.passwordRelatedChannel.type, 'Request')
    const res = await getPhoneCode(actionCode)
    this.setState({
      time: TOTAL_TIME
    })
    if (res.status_code === 100000) {
      this.passcodeInputRef && this.passcodeInputRef?.current?.focus()
      this.props.setActionToken(res.response_data.token)
      Toast.show({
        content: t("setPassword.verificationcodehasbeen"),
      })
      this.timer = setInterval(() => {
        const time = this.state.time - 1
        if (time <= 0) {
          clearInterval(this.timer)
          this.setState({
            time: 0,
            isShowSendCode: false,
            isDisabledGetCode: false,
          })
          return
        }
        this.setState({
          time,
        })
      }, 1000)
    } else {
      Toast.show({
        content: t("setPassword.setTransactionPinOPTSentErr"),
      })
    }
  }
  
  handleClickFun = () => {
    const { onoff } = this.state
    if (onoff) {
      this.setState({
        onoff: false
      })
      this.sendCode()
      this.timer = setTimeout(() => {
        this.setState({
          onoff: true
        })
      }, 10000)
    } else {
      console.log("请稍后点击")
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }
  
  async componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.handleClickFun()
  }
  onCodeChange = async (code) => {
    if (code.length === 6) {
      this.passcodeInputRef.current.blur()
      this.setState({
        code: code
      })
      const { passwordRelatedChannel, setActionToken, history } = this.props
      const { actionToken } = this.props
      const actionCode = this.getActionCode(this.props.passwordRelatedChannel.type, 'Verify')
      const res = await verifyPhoneCode(actionCode, actionToken, code)
      switch (res.status_code) {
        case 100000: 
          if (res.response_data) {
            setActionToken(res.response_data.token)
            if (['reset', 'freeze'].includes(passwordRelatedChannel.type) ) {
              history.replace('/resetTransactionPasswordC')
            } else {
              history.replace('/settingPasswordB')
            }
          }
          break;
        case 300001:
          this.setState({
            error: true
          })
          this.passcodeInputRef.current.focus()
          break;
        case 300002:
          this.setState({
            error: true
          })
          break;
        case 301006:
          this.setState({
            isExceptionPopVisible: true,
          })
          break;
        default:
          this.setState({
            error: true
          })
          Toast.show({
            content: t("setPassword.setTransactionPinOPTInputErr"),
          })
          break;
      }
    } else if (code.length === 5) {
      this.setState({
        code: this.state.error ? '' : code,
        error: false
      })
    } else {
      this.setState({
        code: code,
        error: false
      })
    }
  }
  render() {
    const { time, code, error, isExceptionPopVisible } = this.state

    return (
      <div id="verify-code">
        <section className="setting-pasword-a">
          <div className="close">
            <LeftOutline onClick={this.goBack} fontSize={20}/>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="title-warp">
              <div className="title">
                {t("setPassword.Entererificationcode")}
              </div>
              <div className="white-title">
                {t("setPassword.verificationcodehasbeen")}
                <br />
                {t("setPassword.verificationCodeEffectiveTime")}
              </div>
            </div>
            <div className="error-msg" dangerouslySetInnerHTML={{__html: error ? t('verifySMSOTP.incorrectOTP') : ''}}></div>
            <div className="verification-code">
                <PasscodeInput
                  ref={this.passcodeInputRef}
                  length={6}
                  value={code}
                  seperated
                  plain
                  keyboard={<NumberKeyboard randomOrder={true}/>} 
                  onChange={this.onCodeChange} 
                  error={error} 
                  style={{
              '--cell-gap': '12px',
            }}/>
            </div>
            <div className="varification-tips">
                <div className="times"> {
                  time === 0 ?
                      <span onClick={this.handleClickFun}>
                        {t("setPassword.Toresend")}
                      </span>
                      :
                      <div>
                        {t('steps.EmailResendCount', {second: time})}
                      </div>
                }
                </div>
            </div>
          </div>
        </section>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyCode))
