import React from "react"
import one_cn from "@assets/images/stopTaking/1.png"
import two_cn from "@assets/images/stopTaking/2.png"
import one_en from "@assets/images/stopTaking/1_en.png"
import two_en from "@assets/images/stopTaking/2_en.png"
import { NavBar } from "antd-mobile"
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'


import { useTranslation } from "react-i18next"
import "./index.less"

export default function StopTaking(props) {
  const history = useHistory()
  const selectedLang = useSelector(state => state.user.selectedLang)
  const { t } = useTranslation()
  const isChinese = selectedLang === "zh_CN"

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className="stopTaking">
      <NavBar
        style={{
          "--height": "36px",
          "--background": "#ffffff",
          marginBottom: "16px",
        }}
        onBack={goBack}
      >
          {t("stopTaking.maintenance_notice")}
      </NavBar>

      <div className="stopTaking-content">
          <img src={isChinese ? one_cn : one_en} alt=""/>
          <img src={isChinese ? two_cn: two_en} alt=""/>
        </div>
    </div>
  )
}
