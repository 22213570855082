import request from "@utils/request";

// 当前公共请求地址前缀
const url_prefix = "";

export const generateToken = () => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/create-account-link-token`
  });
};

export const putPublickToken = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/submit-public-token-and-account`,
    data
  });
};

export const generateTokenReAuth = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/create-re-auth-link-token`,
    data
  });
};

export const putPublickTokenReAuth = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/submit-re-auth-result`,
    data
  });
};

export const getAccounts = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/getAccounts`,
    data
  });
};

export const getExternalAccounts = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/get-external-accounts`,
    data
  });
};

// 删除账户
export const removeExternalAccount = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/app/account/remove-external-account`,
    data
  });
};


