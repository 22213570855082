import React, { Component } from 'react'
import { Toast, Swiper, Space, PageIndicator} from 'antd-mobile'

import MyHeader from '@comps/MyHeader'
import MyTabbar from '@comps/MyTabbar'
import './index.less'

import bannerContent1 from '@assets/images/card/banner_content_1.png'
import bannerContent2 from '@assets/images/card/banner_content_2.png'
import bannerContent3 from '@assets/images/card/banner_content_3.png'

import cardApplyDone from '@assets/images/card/card_apply_done.png'
import applyDescriptionIcon from '@assets/images/card/apply_description_icon.png'
import { getPhysicalCardApplyStatus, submitPhysicalCardApplyStatus } from '@api/account'
import { t } from 'i18next'

const cnCardBannerContent = [
  {
    img: bannerContent1,
    style: {
      width: '267px',
      marginTop: '-7px'
    },
    title: '加入申请队列，享实体卡福利',
    title_style: {
      marginTop: '48px'
    },
    sub_title: <div>支持商户范围更广，可使用场景更大<br/>随时随地，想刷就刷</div>
  },
  {
    img: bannerContent2,
    style: {
      width: '307px',
    },
    title: '支持更海量的商户',
    title_style: {
      marginTop: '28px'
    },
    sub_title: <div>实体卡可支持商户范围更广，使用场景更大<br/>随时随地，想刷就刷</div>
  },
  {
    img: bannerContent3,
    style: {
      width: '260px',
      marginTop: '10px'
    },
    title: '享美金开卡奖励',
    title_style: {
      marginTop: '60px'
    },
    sub_title: <div>申请实体卡将会收到开卡奖励哦<br/>兑换超值礼卡或超值权益</div>
  }
]
const enCardBannerContent = [
  {
    img: bannerContent1,
    style: {
      width: '267px',
      marginTop: '-7px'
    },
    title: <div>Apply for a physical card <br/>enjoy premium award</div>,
    title_style: {
      marginTop: '48px'
    },
    sub_title: <div>More brands supported, more shopping options<br/>Make a purchase anytime and anywhere</div>
  },
  {
    img: bannerContent2,
    style: {
      width: '307px',
    },
    title: 'Support hundreds more brands',
    title_style: {
      marginTop: '28px'
    },
    sub_title: <div>Enjoy more shopping options with more brands<br/>Make a purchase anytime and anywhere</div>
  },
  {
    img: bannerContent3,
    style: {
      width: '260px',
      marginTop: '10px'
    },
    title: 'Award for card opening',
    title_style: {
      marginTop: '60px'
    },
    sub_title: <div>Receive award for opening a physical card<br/>Redeem gift cards or other benefits</div>
  }
]

export default class Card extends Component {
  localLanguage = localStorage.getItem("selectedLang")
    

  // 申请实体卡片
  async componentDidMount(){
    if(!this.localLanguage || (this.localLanguage !== 'zh_CN' && this.localLanguage !== 'en_US')) {
      this.localLanguage = "zh_CN"
    }

    const physicalCardApplyStatus = await getPhysicalCardApplyStatus().catch(error => { console.log(error);});
    if(physicalCardApplyStatus !== undefined){
      if(physicalCardApplyStatus.response_data.apply_status === 1){
        this.setState({
          applyStatus: true
        })
      } else {
        this.setState({
          applyStatus: false
        })
      }
    }

    this.setState({
      cardBannerContent:  this.localLanguage === 'zh_CN' ? cnCardBannerContent: enCardBannerContent,
      lang: this.localLanguage
    })
  }
  constructor(props) {
    super(props);
    this.state ={
      currentIndex: 0,
      applyStatus: '',
      lang: 'en_US',
      cardBannerContent: enCardBannerContent
    }
    this.applyCardClicked = this.applyCardClicked.bind(this)
  }
  handleApplay = () => {
    Toast.show({
      content: t('card.hint'),
      duration: 2000,
    })
  }
  async applyCardClicked(){
    const submitPhysicalCardApply = await submitPhysicalCardApplyStatus().catch(error => { console.log(error);});
    if(submitPhysicalCardApply !== undefined && submitPhysicalCardApply.status_code === 100000) {
      this.setState({
        applyStatus: true
      })
    }
  }

  render() {
    
    // let leftTop = (
    //   <div className="logo-container d-flex flex-column">
    //     <img src={logoTillstar} alt="" className="logoTillstar" />
    //   </div>
    // )
    // let leftBottom = <span></span>
    const items = this.state.cardBannerContent.map((item, index) => (
      <Swiper.Item key={index}>
        <div>
          <img className='banner-image' src={item.img} alt='' style={item.style}/>
          {index === 0 && this.localLanguage === 'zh_CN'
          && <div className='first-card-tips'>享美金开卡奖励<br/>YouWorld尊贵身份标示<br/>海量支持商户</div>
          }
          {index === 0 && this.localLanguage === 'en_US'
          && <div className='first-card-tips'>Award for card opening<br/>YouWorld Platinum identifier<br/></div>
          }
          <div className='card-banner-content-title' style={item.title_style}> {item.title}</div>
          <div className='card-banner-content-sub-title'> {item.sub_title}</div>
        </div>
      </Swiper.Item>
    ))

    return (
      <div className="card-container">
        <MyHeader title={t('card.title')} />
        {
          this.state.applyStatus 
          && this.localLanguage === 'en_US' && <div>
            <img src={cardApplyDone} style={{width: '100%', padding: '15px 30px'}} alt='' />
              <div className='submit-apply-success-title en'>Congratulations! <br/>Your application is under processing! <br/>Thank you for your patience. </div>
              <div className='submit-apply-success-sub-title en'>When your Tillstar wallet account is ready<br/>we will notify you by a text message or email<br/>You can also check on the progress here </div>
              <div>
                <div className='apply-tips-title'>
                  <div>
                    <img className='icon-img' alt='' src={applyDescriptionIcon}/>
                  </div>
                  <div className='text'>Support hundreds more brands</div>
                </div>
                <div className='tips-sub-title en'>Enjoy more shopping options with more brands，<br/>Make a purchase anytime and anywhere<br/></div>
              </div>
              <div>
                <div className='apply-tips-title'>
                  <div>
                    <img className='icon-img' alt='' src={applyDescriptionIcon}/>
                  </div>
                  <div className='text'>Award for card opening</div>
                </div>
                <div className='tips-sub-title en'>Receive award for opening a physical card<br/>Redeem gift cards or other benefits</div>
              </div>
            </div>
        }
        {
          this.state.applyStatus 
          && this.localLanguage === 'zh_CN' && <div>
              <img src={cardApplyDone} style={{width: '100%', padding: '15px 30px'}} alt='' />
              <div className='submit-apply-success-title'>恭喜！您已加入队列！<br/>请耐心等待哦</div>
              <div className='submit-apply-success-sub-title'>Tillstar钱包可用时，<br/>我们将短信或者邮件通知您你也可以在此查看申请进度 </div>
              <div>
                <div className='apply-tips-title'>
                  <div>
                    <img className='icon-img' alt='' src={applyDescriptionIcon}/>
                  </div>
                  <div className='text'>海量商户，享双币刷卡消费</div>
                </div>
                <div className='tips-sub-title'>支持商户范围更广，可使用场景更大<br/>随时随地，想刷就刷</div>
              </div>
              <div>
                <div className='apply-tips-title'>
                  <div>
                    <img className='icon-img' alt='' src={applyDescriptionIcon}/>
                  </div>
                  <div className='text'>享美金开卡奖励</div>
                </div>
                <div className='tips-sub-title'>申请实体卡将会收到开卡奖励哦<br/>兑换超值礼卡或超值权益</div>
              </div>
            </div>
        }
        {
          this.state.applyStatus === false &&
          <div>
          <Space direction='vertical' block>
            <Swiper 
              indicator={() => null}
              onIndexChange={(index)=>{this.setState({currentIndex: index})}}
              style={{
                '--track-padding': ' 50px 0 16px',
              }}
              loop>{items}</Swiper>
          </Space>
          <div className='card-banner-indicator'> 
            <PageIndicator
              total={this.state.cardBannerContent.length}
              current={this.state.currentIndex}
              style={{
                '--dot-color': '#C4C4C4',
                '--active-dot-color': '#000',
                '--dot-size': '10px',
                '--active-dot-size': '10px',
                '--dot-border-radius': '50%',
                '--active-dot-border-radius': '10px',
                '--dot-spacing': '8px',
              }}
            />
          </div>
          <div className='join' onClick={this.applyCardClicked}>{this.localLanguage === 'zh_CN'? '加入申请列表' : 'Apply now'}</div>
        </div>
        }
        <MyTabbar {...this.props}></MyTabbar>
      </div>
    )
  }
}
