import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CloseOutline } from 'antd-mobile-icons'
import KYCStepList from '@comps/KYC/KYCStepList'
import ActivateAlert from '@comps/ActivateAlert'
import { useSelector } from 'react-redux'
import './index.less'

export default function StepHeader(props) {
    const [showStopActiveFlowDialog, setShowStopActiveFlowDialog] = useState(false)
    const { title, title1, subTitle, subTitle1, list } = props
    const kycEntrance = useSelector(state => state.common.kyc_entrance)
    const history = useHistory()

    const close = () => {
        setShowStopActiveFlowDialog(true)
    }
    const handleCloseTip = () => {
        setShowStopActiveFlowDialog(false)
    }
    const handleConfirm = () => {
        history.replace(kycEntrance)
    }

    return (
        <div className="steps">
            <div className="steps-top">
                <div className="steps-actions">
                    <div></div>
                    <CloseOutline onClick={close} fontSize={24} />
                </div>
                <div className="steps-top-tips">
                    <p dangerouslySetInnerHTML={{ __html: title }} />
                    <p>{title1}</p>
                    <p>{subTitle}</p>
                    <p>{subTitle1}</p>
                </div>
                <KYCStepList list={list} />
            </div>
            {showStopActiveFlowDialog && <ActivateAlert onClose={handleCloseTip} handleConfirm={handleConfirm} />}
        </div>
    )
}
