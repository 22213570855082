import React, { Component } from 'react'
import { CheckCircleFill, CloseOutline } from 'antd-mobile-icons'
import StepFooter from '@pages/Home/Steps/StepFooter'
import './index.less'

import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'
class SubmitApplication extends Component {
  state = {
    time: 7
  }
  async componentDidMount() {
    scrollToTop()
    this.timer = setInterval(() => {
      const time = this.state.time - 1
      if (time <= 0) {
        clearInterval(this.timer)
        this.props.history.push('/')
        return
      } else {
        this.setState({
          time
        })
      }
    }, 1000)

  }
  handleGotoHome = () => {
    this.props.history.push('/')
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  render() {
    let myDate = new Date()
    let month = myDate.getMonth() + 1
    let Data = myDate.getDate()
    let getHours = myDate.getHours()
    let getMinutes = myDate.getMinutes()
    let getSeconds = myDate.getSeconds()
    if (month <= 9) {
      month = `0${month}`
    }
    if (Data <= 9) {
      Data = `0${Data}`
    }
    if (getHours <= 9) {
      getHours = `0${getHours}`
    }
    if (getMinutes <= 9) {
      getMinutes = `0${getMinutes}`
    }
    if (getSeconds <= 9) {
      getSeconds = `0${getSeconds}`
    }
    const times = `${myDate.getFullYear()}-${month}-${Data} ${getHours}:${getMinutes}:${getSeconds}`
    const { time } = this.state
    return (
      <div className="submit-application-container">
        <CloseOutline className='submit-application-close' fontSize={32} color="#000" onClick={this.handleGotoHome} />
        <div className="submit-info-container">
        <CheckCircleFill fontSize={80} color="#1F49DA"/>
          <p className="submit-info-title">{t('steps.SubmittedTitle')}</p>
          <p className="submit-info-subtitle">{t('steps.SubmittedDesc')}</p>
          <p className="submit-info-subtitle">{t('steps.SubmittedDesc1')}</p>
        </div>
        <div className="order-info-container">
          <div className="order-info-item">
            <p className="order-info-title">{t('steps.SubmittedAt')}</p>
            <p className="order-info-number">{times}</p>
          </div>
        </div>
        <div className='gotoHome'>
        {t('steps.SubmittedRedirect', {
              second: time,
            })}
        </div>
        <StepFooter 
          isLoading={false}
          disabled={false}
          btnSubmit={this.handleGotoHome}
          btnText={t('steps.SubmittedBackHome')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
      </div>
    )
  }
}

export default SubmitApplication;
