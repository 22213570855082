import {
    SET_AMOUNT,
    SET_TRANSFER_ORIGIN,
} from '../actions/ActionTypes'

const initialState = {
    amount: localStorage.getItem('amount') || undefined,
    transferOrigin: localStorage.getItem('transferOrigin') || undefined,
}

const transfer = (state = initialState, action) => {
    let newState = {
        ...state
    }
    switch (action.type) {
        case SET_AMOUNT:
            newState.amount = action.payload
            return newState
        case SET_TRANSFER_ORIGIN:
            newState.transferOrigin = action.payload
            return newState
        default:
        return state
    }
}

export default transfer