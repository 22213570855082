import React, { useEffect, useState, useRef } from "react"
import "./index.less"
import { NavBar, Input, Button, Toast } from "antd-mobile"
import { scrollToTop } from "@utils/utils"

import { t } from "i18next"
import { useHistory } from "react-router"

import SvgIcon from '@comps/SvgIcon'
import loadable from '@loadable/component'

import { updateTillstarID } from '@api/user'
import { useSelector } from 'react-redux'

const CommonTipPop = loadable(() => import('@comps/CommonTipPop'))

export default function UpdateTillstarID() {
  const history = useHistory()
  const userInfo =  useSelector(state => state.user.userInfo)
  const [visible, setVisible] = useState(false)
  const [tillstarID, setTillstarID] = useState(userInfo.tillstar_id || '')
  const [isTillstarIdRepeated, setIsTillstarIdRepeated] = useState(false)
  const [isLimitExceeded, setIsLimitExceeded] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef()

  useEffect(async () => {
    scrollToTop()
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    setIsClicked(userInfo.is_allowed_update_tillstar_id && tillstarID && tillstarID.length >= 6 && tillstarID.length <= 40)
  }, [tillstarID])

  const modifyAction = () => {
    if (!isClicked) return
    setVisible(true)
  }

  const updateID = async () => {
    setIsLoading(true)
    const res = await updateTillstarID({
        tillstarId: tillstarID
    })
    setIsLoading(false)
    setVisible(false)
    switch (res.status_code) {
        case 100000: 
            Toast.show({
                content: t("mine.updateTillstarIDSuccess"),
                duration: 3000,
            })
            history.goBack()
          break;
        case 301027: 
            Toast.show({
                content: t("mine.updateTillstarIDDisabledTip"),
                duration: 3000,
            })
          break;
        case 301028: 
          setIsTillstarIdRepeated(true)
        break;
        default:
            Toast.show({
                content: t("mine.updateTillstarIDFailure"),
                duration: 3000,
            })
          break;
    }
  }

  const textChange = (val) => {
    if(val.length > 40) {
      setIsLimitExceeded(true)
      return
    }

    if (val.length < 6) {
      setIsLimitExceeded(true)
    } else {
      setIsLimitExceeded(false)
    }

    val = val.replace(/[^\w]/g, "")
    val && setIsTillstarIdRepeated(userInfo.tillstar_id && userInfo.tillstar_id === val)
    !val && setIsLimitExceeded(true)
    setTillstarID(val)

}

  return (
    <div id="update-tillstar-ID-container">
      <NavBar onBack={history.goBack}/>
      <SvgIcon iconClass="tillstar_logo" />
      <div className="tip">
          {userInfo.is_allowed_update_tillstar_id ? t('mine.updateTillstarIDTip', { count: userInfo.tillstar_id_allowed_update_times}) : t('mine.updateTillstarIDDisabledTip')}
    </div>
      <Input ref={inputRef} className={["tillstar-ID", isClicked ? "" : "disabled"].join(" ")} placeholder="Tillstar ID" value={tillstarID} onChange={textChange} disabled={!userInfo.is_allowed_update_tillstar_id}/>
      {isTillstarIdRepeated && <p className="error-tip">{ t('account.editIdExist')}</p>}
      {isLimitExceeded && <p className="error-tip">{t('account.minLength')}</p>}
      <Button className={isClicked ? "active" : ""} onClick={modifyAction}>{t('mine.modify')}</Button>
      <CommonTipPop isVisible={visible} setIsVisible={setVisible} content={
        <>
          <div className='title tillstar-id-title'>{t('mine.updateTillstarIDPopTitle')}</div>
          <div className='subtitle tillstar-id-subtitle' dangerouslySetInnerHTML={{__html: t('mine.updateTillstarIDPopSubtitle', {
              count: userInfo.tillstar_id_allowed_update_times
          })}}/>
          <Button className='l-btn tillstar-id-l-btn' onClick={()=>setVisible(false)}>{t('mine.updateTillstarIDPopLBtn')}</Button>
          <Button className='r-btn tillstar-id-r-btn' loading={isLoading} onClick={updateID}>{t('mine.updateTillstarIDPopRBtn')}</Button>
        </>
      }/>
    </div>
  )
}