import { CheckList, Popup } from 'antd-mobile'
import { CheckCircleFill } from 'antd-mobile-icons'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentMethods, setPaymentMethods, setPaymentMethodsShow, replaceByInstantConsumptionCoin } from '@actions/rmb'
import microPayment from '@assets/images/rmb/icons/micro_payment.png'
import unlimitedPayment from '@assets/images/rmb/icons/unlimited_payment.png'
import './index.less'

export default function PaymentMethodsList(props) {
  const { setIsBtnLoading, isBtnLoading } = props
  const pay_type = useSelector(state => state.rmb.pay_type)
  const paymentMethodsShow = useSelector(state => state.rmb.paymentMethodsShow)
  const currentPaymentMethod = useSelector(state => state.rmb.currentPaymentMethod)
  const paymentMethods = useSelector(state => state.rmb.paymentMethods)
  const paymentMethodsIcon = useSelector(state => state.rmb.paymentMethodsIcon)
  const payemntDefaultValue = useSelector(state => state.rmb.payemntDefaultValue)
  const transactionDetail = useSelector(state => state.transaction.transaction_detail)

  const {t} = useTranslation()
  const dispatch = useDispatch()
  
  useEffect(()=>{
    dispatch(getPaymentMethods())
  },[])

  const currentPaymentIndex = useMemo(() => {
    if (currentPaymentMethod.pay_type) {
      return paymentMethods?.findIndex((item) => item.pay_type === currentPaymentMethod.pay_type)
    } else {
      return 0
    }
  }, [currentPaymentMethod, paymentMethods])

  const handlePaymentChange = (e) => {
    const index = e[0] ? e[0] : 0
    dispatch(setPaymentMethodsShow(false))
    setIsBtnLoading && setIsBtnLoading(true)

    if(isBtnLoading) {
      return
    }

    setIsBtnLoading && setIsBtnLoading(false)
    if(payemntDefaultValue) {
      dispatch(setPaymentMethods(paymentMethods[index]))
    } else {
      const paymentParams = {
        activityCode: transactionDetail.code,
        paymentMethodId: paymentMethods[index].id,
        amount: parseFloat(transactionDetail.amount).toFixed(2),
        paymentCurrencyCode: 'CNY',
        exchangeTargetCurrencyCode: 'USD',
        pay_type: paymentMethods[index].pay_type,
      }
      dispatch(replaceByInstantConsumptionCoin(paymentParams))
    }
  }
  useEffect(() => {
    if (pay_type && setIsBtnLoading) {
      setIsBtnLoading(false)
    }
  }, [pay_type])
  const PaymentMethodsItem = () => {
    return paymentMethods.map((item, index) => (
      <CheckList.Item value={index} disabled={item.pay_type === currentPaymentMethod.pay_type }  key={item.pay_type}>
          <div className="item">
              <img alt='' src={paymentMethodsIcon[item.pay_type].icon}/>
              <div className='content'>
                  <div className='title'>
                    <span>{ t(`rmb.${paymentMethodsIcon[item.pay_type].title}`) }</span>
                    <img className={item.pay_type === 'unionpay' ? 'unionpay' : 'non-unionpay'} alt='' src={item.pay_type === 'unionpay' ? unlimitedPayment : microPayment}/>
                  </div>
                  <div className='sub-title'>{t("rmb.reference")}：$1 = ￥{item.exchange_rate}{item.rmb ? ', 约￥' + item.rmb : '0.00'} </div>
              </div>
          </div>
      </CheckList.Item>
    ))
  }
 
  return (
    <div>
      <Popup
        visible={paymentMethodsShow}
        onMaskClick={() => {
          dispatch(setPaymentMethodsShow(false))
        }}>
        <div className='methods-action'>
            <div className='menu'>
                <div className='title'>{t("rmb.payment_methods_pop_up.payment_methods")}</div> 
                <div className='cancel' onClick={()=>{dispatch(setPaymentMethodsShow(false))}}>取消</div>
            </div>
            <div className='list'>
                <CheckList activeIcon={<CheckCircleFill style={{fontSize: 20}} color='#FF3841'/>} onChange={handlePaymentChange} defaultValue={[currentPaymentIndex]}><PaymentMethodsItem  /></CheckList> 
            </div>
          </div>
        </Popup>
    </div>
  )
}
