import { Button, Checkbox } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import './index.less'
import { formatNumberToUSD } from '@utils/index.js'
import { handleAmount, handleDot } from '@utils/utils'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutline, QuestionCircleOutline } from 'antd-mobile-icons'

export default function RechargeMoney(props) {
  const { amount, charge, chargeSymbol, freeLimitAvailable, hiddenMask, submitRecharge, showInstantDepositNotice, autoEnterIn, setAutoEnterIn } = props
  const [instantAmount, setInstantAmount] = useState(0)
  const [standardAmount, setStandardAmount] = useState(0)
  const { t } = useTranslation()
  const { vipInfo } = props
  const { current_amount_available, is_allowed = false } = vipInfo
  const currentAmountAvailable = parseFloat(current_amount_available)

  useEffect(() => {
    if (parseFloat(amount) > currentAmountAvailable) {
      setInstantAmount(currentAmountAvailable)

      setStandardAmount((parseFloat(amount) - currentAmountAvailable).toFixed(2))
    } else {
      setInstantAmount(parseFloat(amount))
      setStandardAmount(0)
    }
  }, [])

  const checkChange = (val) => {
    setAutoEnterIn(val)
  }

  return (
    <div id="recharge-money">
      <CloseCircleOutline className="close-img" fontSize={36} color="#FFF" onClick={() => hiddenMask(false)}/>
      <div className="title d-flex align-items-center justify-content-center" >
      {t('recharge.title')}
      </div>
      <p className="amount">{handleAmount(formatNumberToUSD(amount))}</p>
      <ul className="money-section">
        <li className="item">
          <span className="label">{t('recharge.fee')}</span>
          <span className="money" style={{textDecoration: freeLimitAvailable > 0 ? "line-through" : "none"}}>{
            chargeSymbol + formatNumberToUSD(handleDot(charge || 0))
          }</span>
        </li>
        {is_allowed && <li className="item">
          <span className="label">
            {t('recharge.instant_deposit')}
            <QuestionCircleOutline className="question" color="#1F49DA" fontSize={18} onClick={showInstantDepositNotice}/>
          </span>
          <span className="money">{
            chargeSymbol + formatNumberToUSD(handleDot(instantAmount || 0))
          }</span>
        </li>}
        {is_allowed && <li className="item">
          <span className="label">{t('recharge.standard_deposit')}</span>
          <span className="money">{
            chargeSymbol + formatNumberToUSD(handleDot(standardAmount || 0))
          }</span>
        </li>}
      </ul>
      {currentAmountAvailable > 0 && is_allowed && <div className="friendly-reminder enjoy">
           {t('recharge.friendly_reminder.instant')}  
      </div>
      }
      <Button
        type="warning"
        className="warning-btn btn-width d-flex align-items-center justify-content-center"
        onClick={() => {
          hiddenMask()
          submitRecharge(false)
        }}
      >
          {t('recharge.confirm_recharge')}
      </Button>

      <div className='confirmAuto'>
        <Checkbox className="pickRadio" style={{
            '--adm-color-primary': '#FF3841',
            '--icon-size': '16px'
          }} onChange={checkChange} checked={autoEnterIn}>
            <div>
              <p dangerouslySetInnerHTML={{__html: t("transactionDetail.text5") }}></p>
            </div>
          </Checkbox>
      </div>
    </div>
  )
}