import {
  SET_APP_GUIDE_STATUS
} from '../actions/ActionTypes'

const initialState = {
  appGuideStatus: ''
}


const guide = (state = initialState, action) => {
  let newState = {
    ...state
  }
  switch(action.type) {
    case SET_APP_GUIDE_STATUS:
      newState.appGuideStatus = action.status
      return newState
    default:
      return state
  }
}

export default guide