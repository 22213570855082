import {
  SET_SAVING_ACCOUNT_INFO,
  SET_SAVING_ACCOUNT_TOKEN,
  SET_REFERRAL_CODE_FROM,
  SET_AUTO_ENTERIN_SAVING_ACCOUNT
} from '../actions/ActionTypes'
import jieshao from '@assets/images/savingAccount/faq/jieshao.png'
import shouyi from '@assets/images/savingAccount/faq/shouyi.png'
import zhuanchu from '@assets/images/savingAccount/faq/zhuanchu.png'
import zhuanru from '@assets/images/savingAccount/faq/zhuanru.png'


const initialState = {
  savingAccountInfo: JSON.parse(sessionStorage.getItem('savingAccountInfo')) || {},
  savingAccountToken: sessionStorage.getItem('savingAccountToken') || '',
  faq: [
    {
      icon: jieshao,
      count: 3,
      content: 3
    },
    {
      icon: zhuanru,
      count: 3,
      content: 3
    },
    {
      icon: zhuanchu,
      count: 2,
      content: 3
    },
    {
      icon: shouyi,
      count: 3,
      content: 3
    }
  ],
  referralCodeFrom: localStorage.getItem('referralCodeFrom') || '',
  autoEnterIn: false
}
const savingAccount = (state = initialState, action) => {
  switch(action.type) {
    case SET_SAVING_ACCOUNT_INFO:
      return {
        ...state,
        savingAccountInfo: action.savingAccountInfo
      }
    case SET_SAVING_ACCOUNT_TOKEN:
      return {
        ...state,
        savingAccountToken: action.savingAccountToken
      }
    case SET_REFERRAL_CODE_FROM:
      return {
        ...state,
        referralCodeFrom: action.referralCodeFrom
      }
    case SET_AUTO_ENTERIN_SAVING_ACCOUNT:
      return {
        ...state,
        autoEnterIn: action.autoEnterIn
      }
    default:
      return state
  }
}

export default savingAccount