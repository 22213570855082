import "./index.less"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Popup, Divider, Toast, Button } from "antd-mobile"
import { CloseOutline, DownFill } from "antd-mobile-icons"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "react-copy-to-clipboard"

const staticPath = 'https://static1.front.youworld.us/gs/tutorials'

const topImg = `${staticPath}/bankCard/toutu.png`
const topImg_en = `${staticPath}/bankCard_en/toutu.png`

const icon1 = `${staticPath}/bankCard/icon_tongyong.png`
const icon2 = `${staticPath}/bankCard/icon_boa.png`
const icon3 = `${staticPath}/bankCard/icon_chase.png`
const icon4 = `${staticPath}/bankCard/icon_wellsfargo.png`

// 通用教程
const tongyong1 = `${staticPath}/bankCard/tongyong1.png`
const tongyong2 = `${staticPath}/bankCard/tongyong2.png`
const tongyong3 = `${staticPath}/bankCard/tongyong3.png`

const tongyong1_en = `${staticPath}/bankCard_en/tongyong1.png`
const tongyong2_en = `${staticPath}/bankCard_en/tongyong2.png`
const tongyong3_en = `${staticPath}/bankCard_en/tongyong3.png`

// boa
const boa1 = `${staticPath}/bankCard/boa1.png`
const boa2 = `${staticPath}/bankCard/boa2.png`
const boa3 = `${staticPath}/bankCard/boa3.png`

const boa1_en = `${staticPath}/bankCard_en/boa1.png`
const boa2_en = `${staticPath}/bankCard_en/boa2.png`
const boa3_en = `${staticPath}/bankCard_en/boa3.png`

// chase
const chase1 = `${staticPath}/bankCard/chase_1.png`
const chase2 = `${staticPath}/bankCard/chase_2.png`
const chase3 = `${staticPath}/bankCard/chase_3.png`
const chase4 = `${staticPath}/bankCard/chase_4.png`

const chase1_en = `${staticPath}/bankCard_en/chase_1.png`
const chase2_en = `${staticPath}/bankCard_en/chase_2.png`
const chase3_en = `${staticPath}/bankCard_en/chase_3.png`
const chase4_en = `${staticPath}/bankCard_en/chase_4.png`

// wellsfargo
const wellsfargo1 = `${staticPath}/bankCard/wellsfargo_1.png`
const wellsfargo2 = `${staticPath}/bankCard/wellsfargo_2.png`
const wellsfargo3 = `${staticPath}/bankCard/wellsfargo_3.png`
const wellsfargo4 = `${staticPath}/bankCard/wellsfargo_4.png`
const wellsfargo5 = `${staticPath}/bankCard/wellsfargo_5.png`

const wellsfargo1_en = `${staticPath}/bankCard_en/wellsfargo_1.png`
const wellsfargo2_en = `${staticPath}/bankCard_en/wellsfargo_2.png`
const wellsfargo3_en = `${staticPath}/bankCard_en/wellsfargo_3.png`
const wellsfargo4_en = `${staticPath}/bankCard_en/wellsfargo_4.png`
const wellsfargo5_en = `${staticPath}/bankCard_en/wellsfargo_5.png`

export default function BankCardTutorial(props) {
  const { t } = useTranslation()
  const [type, setType] = useState(1)
  const [typePopShow, setTypePopShow] = useState(false)
  const [routAccount, setRoutAccount] = useState(false)
  const [routing, setRouting] = useState("")
  const [account, setAccount] = useState("")
  const history = useHistory()
  const selectedLang = useSelector((state) => state.user.selectedLang)
  const userInfo = useSelector((state) => state.user.userInfo)
  const isChinese = selectedLang === "zh_CN"

  const onClose = () => {
    history.goBack()
  }

  const titles = [
    { titleId: 1, icon: icon1 },
    { titleId: 2, icon: icon2 },
    { titleId: 3, icon: icon3 },
    { titleId: 4, icon: icon4 },
  ]

  const showAccount = () => {
    const { default_financial_account = {} } = userInfo
    const { account_number, routing_number } = default_financial_account
    setRouting(routing_number)
    setAccount(account_number)

    setRoutAccount(true)
  }

  const handleCopy = () => {
    Toast.show({
      content: t('home.copySucceed'),
      duration: 2000,
    })
    return
  }

  const selectType = (val, showIcon) => {
    const data = titles[val - 1]
    return (
      <div className="selectTypeItem">
        {
          showIcon ? 
          <div className="itemLeft">
            <img src={data.icon} alt="" />
            {t(`bankCardTutorial.types.${val}`)}
          </div>
          :
          <div className="itemLeft">
            <div className="itemLeftImg">
              <img src={data.icon} alt="" />
            </div>
            <div className="itemLeftText">
              {t(`bankCardTutorial.types.${val}`)}
            </div>
          </div>
        }
        {/* <div className="itemLeft">
          <div>
            <img src={data.icon} alt="" />
          </div>
          <div className="itemLeftText">
            {t(`bankCardTutorial.types.${val}`)}
          </div>
        </div> */}


        {showIcon ? (
          <div className="itemRight">
            <DownFill color="#D9D9D9" />
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }

  return (
    <div id="tutorial-bankCard">
      <div className="close">
        <CloseOutline onClick={onClose} fontSize={24} />
      </div>
      <div className="topImg">
        <img className="firstImg" src={isChinese ? topImg : topImg_en} alt="" />
      </div>
      <div className="topCard">
        <div className="selectType" onClick={() => setTypePopShow(true)}>
          {selectType(type, true)}
        </div>
      </div>

      <div className="content">
        {/* 通用 */}
        {type === 1 && (
          <div>
            <img
              src={isChinese ? tongyong1 : tongyong1_en}
              alt=""
            />
            <img
              onClick={showAccount}
              src={isChinese ? tongyong2 : tongyong2_en}
              alt=""
            />
            <img
              src={isChinese ? tongyong3 : tongyong3_en}
              alt=""
            />
          </div>
        )}

        {/* boa */}
        {type === 2 && (
          <div>
            <img src={isChinese ? boa1 : boa1_en} alt="" />
            <img
              onClick={showAccount}
              src={isChinese ? boa2 : boa2_en}
              alt=""
            />
            <img src={isChinese ? boa3 : boa3_en} alt="" />
          </div>
        )}
        {/* chase */}
        {type === 3 && (
          <div>
            <img src={isChinese ? chase1 : chase1_en} alt="" />
            <img src={isChinese ? chase2 : chase2_en} alt="" />
            <img
              onClick={showAccount}
              src={isChinese ? chase3 : chase3_en}
              alt=""
            />
            <img src={isChinese ? chase4 : chase4_en} alt="" />
          </div>
        )}

        {/*  */}
        {type === 4 && (
          <div>
            <img
              src={isChinese ? wellsfargo1 : wellsfargo1_en}
              alt=""
            />
            <img
              onClick={showAccount}
              src={isChinese ? wellsfargo2 : wellsfargo2_en}
              alt=""
            />
            <img
              src={isChinese ? wellsfargo3 : wellsfargo3_en}
              alt=""
            />
            <img
              src={isChinese ? wellsfargo4 : wellsfargo4_en}
              alt=""
            />
            <img
              src={isChinese ? wellsfargo5 : wellsfargo5_en}
              alt=""
            />
          </div>
        )}

        <div className="bottomBtn">
          <div className="closeBtn" onClick={onClose}>
            {t(`bankCardTutorial.BottomBtn`)}
          </div>
        </div>
      </div>

      {/* 选择pop */}
      <Popup
        visible={typePopShow}
        className="checkTypePopup"
        bodyClassName="typePopContent"
        onMaskClick={() => setTypePopShow(false)}
      >
        <div className="popItems">
          {titles.map((item, index) => {
            return (
              <div
                key={item.titleId}
                className="popItem"
                onClick={() => {
                  setType(item.titleId)
                  setTypePopShow(false)
                }}
              >
                {selectType(item.titleId, false)}
                <Divider style={{ borderColor: "#F8F8F8" }} />
              </div>
            )
          })}
          <div
            className="selectTypeItem cancel"
            onClick={() => setTypePopShow(false)}
          >
            {t("bankCardTutorial.cancel")}
          </div>
        </div>
      </Popup>

      {/* 点击route/account显示的浮框 */}
      <Popup
        visible={routAccount}
        onMaskClick={() => {
          setRoutAccount(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          height: "300px",
        }}
        className="accountNumberPopup"
      >
        <div className="accountNumberPopup-inner">
          <Button
            className="closeDialog"
            onClick={() => {
              setRoutAccount(false)
            }}
          ></Button>
          <div className="list-card">
            <div className="card-name textAlignLeft">Routing Number</div>
            <div className="d-flex align-items-center justify-content-between trackContainer">
              <div className="card-num">{routing}</div>
              <div className="trackReturn" hidden>
                弹窗 routing number
              </div>
              <CopyToClipboard text={routing} onCopy={handleCopy}>
                <button className="card-btn">
                {t('common.copy')}
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="list-card">
            <div className="card-name textAlignLeft">Account Number</div>
            <div className="d-flex align-items-center justify-content-between trackContainer">
              <div className="card-num">{account}</div>
              <div className="trackReturn" hidden>
                弹窗 Account number
              </div>
              <CopyToClipboard text={account} onCopy={handleCopy}>
                <button className="card-btn">
                {t('common.copy')}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}
