import { combineReducers } from 'redux'
import account from './account'
import rmb from './rmb'
import order from './order'
import guide from './guide'
import user from './user'
import password from './password'

import faq from './faq'
import transaction from './transaction'
import tabbar from './tabbar'
import giftCard from './giftCard'
import reward from './reward'
import transfer  from './transfer'

import common  from './common'
import savingAccount from './savingAccount'
import plaid from './plaid'

const appReducer = combineReducers({
  account,
  rmb,
  order,
  guide,
  transaction,
  user,
  faq,
  tabbar,
  common,
  password,
  reward,
  giftCard,
  transfer,
  savingAccount,
  plaid
})

const rootReducer = (state, action) => {
  // 重置store
  if (action.type === 'SET_STORE_RESSET') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer