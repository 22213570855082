import './index.less'
import React from 'react'
import { NavBar, Button } from 'antd-mobile'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ClockCircleFill } from 'antd-mobile-icons'

export default function AddBankResult() {
    const history = useHistory()

    const goHome = () => {
        history.push('/')
    }

    return (
        <div id="add-bank-result">
             <NavBar className="nav" onBack={goHome}/>
             <ClockCircleFill className="waiting-icon" fontSize="70" color="#1F49DA"/>
             <div className="title">{t('addBank.result.title')}</div>
             <div className="tip">{t('addBank.result.tip')}</div>
             <div className="steps">
                <p><Trans i18nKey={t(`addBank.result.steps.0`)}/></p>
                <p><Trans i18nKey={t(`addBank.result.steps.1`)}/></p>
                <p><Trans i18nKey={t(`addBank.result.steps.2`)}/></p>
             </div>
             <Button onClick={goHome}>{t('addBank.result.btn')}</Button>
        </div>
    )
}