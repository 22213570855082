import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { t } from "i18next"
import "./index.less"

import KYCSuccess from "@comps/KYC/KYCSuccess"
import KYCFooter from "@comps/KYC/KYCFooter"
import { scrollToTop } from "@utils/utils"

let timer = null

export default function UploadPendingReview(props) {
  const countSaver = useRef(5);
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [disabled] = useState(false)
  let [time, setTime] = useState(countSaver.current)
  const search = new URLSearchParams(history.location.search)


  useEffect(() => {
    scrollToTop()
    // 倒计时开始
    if (time) {
      interval()
    }
  }, [])

  const interval = () => {
    timer = setInterval(() => {
      if (countSaver.current === 0) {
        clearInterval(timer)
        history.push('/')
        return
      }

      countSaver.current = countSaver.current - 1;
      setTime(countSaver.current)
    }, 1000)
  }

  const onSubmit = async () => {
    // 返回首页
    setLoading(true)
    clearInterval(timer)
    history.push("/")
    setLoading(false)
  }

  return (
    <div>
      <KYCSuccess 
        showTime={true}
        title={t('KYC.KYCSuccessTitle')}
        time={time}
        currentTime={search.get('time')}
      />
      <KYCFooter btnText={t('KYC.KYCSuccessBtn')} isLoading={loading} btnSubmit={onSubmit} disabled={disabled} />
    </div>
  )
}
