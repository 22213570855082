import React, { useState, forwardRef, useImperativeHandle} from "react"
import { ImageUploader } from "antd-mobile"
import { AddOutline } from "antd-mobile-icons"
import { t } from "i18next"
import "./index.less"
import camera from "@assets/images/activation/icon_paishe_white.png"
import wechat from "@assets/images/certificate/WechatIMG43.png"
import { compressedFile } from "@utils/utils"

/**
 * 单张图片
 * @param {*} props
 * title 标题, subTitle 副标题, bgImg 背景图, footerLeft 底部左边文字, footerRight 底部右边文字,
 * reUploadText 重新上传图片文字,reUploadImg 重新上传图片图标自定义, 
 * isHas 是否已上传图片 true | false, showTitle 是否已上传图片 true | false, 
 * onlyOne true
 * @returns
 * uploadImg 上传图片, showExample 显示示例,
 * @示例
 *<CommonUploadImg 
    title={"拍照上传您的驾照"}
    subTitle={"保持光线充足｜避免产生反光、阴影｜保证证件清晰完整"}
    isHas={isHas}
    bgImg={dlFrontImage}
    uploadImg={this.chuanOne}
    footerLeft="点击上传驾照正面图片"
    footerRight="查看示例"
    showExample={() => this.setState({visible: true})}
    reUploadImg={camera}
    reUploadText={"点此重新拍摄"}
    showTitle={true}
  />
 *
 */

/**
 * 多张图片情况
 * @param {*} props
 * title 标题, subTitle 副标题, bgImg 背景图, 
 * showTitle 是否已上传图片 true | false, 
 * showKycAddress 是否显示地址信息 true | false, 
 * addressInfo 地址信息
 * maxCount 最大上传数量 默认=2
 * onlyOne false
 * @returns
 * uploadImg 上传图片, showExample 显示示例,
 * @示例
 *<CommonUploadImg 
    title={"拍照上传您的驾照"}
    subTitle={"保持光线充足｜避免产生反光、阴影｜保证证件清晰完整"}
    isHas={isHas}
    bgImg={dlFrontImage}
    uploadImg={this.chuanOne}
    showKycAddress={true}
    addressInfo={""}
  />
 *
 */
const CommonUploadImg = forwardRef((props, ref) => {
  const {
    showTitle,
    title,
    subTitle,
    uploadImg,
    reUploadText,
    reUploadImg,
    showKycAddress,
    addressInfo,
    onlyOne,
  } = props
  const [picture, setPicture] = useState(undefined)
  const [isHas, setIsHas] = useState(true)
  const maxCount = 2
  const [fileList, setFileList] = useState([])

  const resetFileList = () => {
    setFileList([])
  }

  useImperativeHandle(ref, () => ({
    resetFileList
  }))

  const onUploadImg = async (e) => {
    // if (e.target?.files[0]) {
    //   let reader = new FileReader()
    //   const file = e.target?.files[0]
    //   reader?.readAsDataURL(file)
    //   reader.onload = function () {
    //     document.querySelector(".tps-img").src = reader?.result
    //   }
    //   const compressImg = await compressedFile(file)
    //   setPicture(compressImg)
    //   uploadImg && uploadImg(compressImg)
    // }

    if (e.target?.files?.length > 0) {
      setIsHas(false)

      let reader = new FileReader()
      const file = e.target?.files[0]
      reader?.readAsDataURL(file)
      
      reader.onload = () => {
        document.querySelector(".tps-img").src = reader?.result
      }
      const compressImg = await compressedFile(file)
      const objectUrl = URL.createObjectURL(compressImg)

      const newImg = [{ url: objectUrl, compressImg }]
      setPicture(compressImg)
      
      uploadImg && uploadImg(newImg)
    } else {
      !picture && setIsHas(true)
    }
  }

  const mockUpload = async (files) => {
    const compressImg = await compressedFile(files)

    const objectUrl = URL.createObjectURL(compressImg)

    return { url: objectUrl, compressImg }
  }

  const uploadChange = (files) => {
    setFileList(files)
    uploadImg && uploadImg(files)
  }

  return (
    <div className="common-upload-image">
      {showTitle && <p className="item-title">{title}</p>}
      {showTitle && (
        <p>
          <span className="item-subtitle">{subTitle}</span>
        </p>
      )}

      {showKycAddress && (
        <div className="kyc-active-address">
          <p>{t("KYC.KYCActiveAddress")}</p>
          <p>{addressInfo || ""}</p>
        </div>
      )}

      {!onlyOne && (
        <div className="imageUpload">
          <ImageUploader
            style={{ "--cell-size": "4.2rem" }}
            value={fileList}
            onChange={uploadChange}
            upload={mockUpload}
            multiple={false}
            maxCount={maxCount}
            columns={maxCount}
            showUpload={fileList.length < maxCount}
          >
            <div className="uploadBtn">
              <AddOutline fontSize={70} color="#e0e0e0" />
            </div>
          </ImageUploader>
        </div>
      )}

      {onlyOne && (
        <div className="upload-item">
          <img src={wechat} className={["bg-image", !isHas ? "bg-opacity" : ""].join(" ")} alt="" />
          <input type="file" capture="camera" accept="image/*" className="uploadInput" onChange={onUploadImg} />
          {!isHas && (
            <>
              <img src="" alt="" className="tps-img" />
              <div className="reUpload-btn">
                <input type="file" capture="camera" className="files" accept="image/*" onChange={onUploadImg} />
                <div
                  className="action"
                  onClick={() => {
                    document.querySelector(".files").click()
                  }}
                >
                  <img src={reUploadImg || camera} alt="" />
                  {reUploadText || t("steps.IDCheckClickToReUpload")}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {/* <div className="upload-item">
        <img className={["bg-image", picture ? "bg-opacity" : ""].join(" ")} src={bgImg} alt="" />
        <input type="file" accept="image/*" onChange={onUploadImg} className="uploadInput" />
        <div className="footer-text">
          <p>{footerLeft}</p>
          <p onClick={() => showExample(true)}>{footerRight}</p>
        </div>
        {picture && (
          <>
            <img src="" alt="" className="tps-img" />
            <div className="reUpload-btn">
              <input type="file" className="files" accept="image/*" onChange={onUploadImg} />
              <div
                className="action"
                onClick={() => {
                  document.querySelector(".files").click()
                }}
              >
                <img src={reUploadImg || camera} alt="" />
                {reUploadText}
              </div>
            </div>
          </>
        )}
      </div> */}
    </div>
  )
})

export default CommonUploadImg
