
import request from "@utils/request"

export const submitTransactionRefunRequest = (params) => {
  return request({
    method: "POST",
    url: `/app/account/request-refund`,
    params
  })
}

export const searchAccountHistory = (data) => {
  return request({
    method: "POST",
    url: '/app/account/search-account-history',
    data
  })
}

export const getPendingPaymentStatus = (data) => {
  return request({
    method: "POST",
    url: '/app/account/get-pending-payment-status',
    data
  })
}

// 上传小票图片
export const uploadActivityReceiptReq = (data) => {
  return request({
    method: "POST",
    url: `/app/account/upload-activity-receipt`,
    data
  })
}

// 提交上传小票申请
export const submitActivityReceipt = (data) => {
  return request({
    method: "POST",
    url: `/app/account/submit-activity-receipt`,
    data
  })
}