import React from 'react'
import './index.less'
import { CloseOutline } from 'antd-mobile-icons'

export default function KYCHeader(props) {
  const { title, subtitle, close } = props

  return (
    <div id="kyc-header">
      <div className="close-action">
        <CloseOutline onClick={close} fontSize={24}/>
      </div>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}