import './index.less'
import React, { useEffect, useState, createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Mask, NavBar, Swiper, Toast, PullToRefresh } from 'antd-mobile'
// import alipay from "@assets/images/rmb/icons/alipay.png"
// import wechat from "@assets/images/rmb/icons/order_wechat.png"
// import union from "@assets/images/rmb/icons/union.png"
import question from "@assets/images/rmb/order/question.png"
import cs from "@assets/images/rmb/order/cs.png"
import CommonPop from '@comps/CommonPop'

import { Link, useHistory } from 'react-router-dom'
import { RightOutline} from 'antd-mobile-icons'
import RmbTutorial from './Tutorial'
import TransactionFlow from './TransactionFlow'
import TransactionStatus from './TransactionStatus'
import { setIsAllList, setCurrentAllListTabType, geTransationDetailByCode, initTransactionDetailAction, setsubmitActivityReceipt } from '@actions/transaction'
import { Trans, useTranslation } from 'react-i18next'
import PaymentMethodsList from '@comps/PaymentMethodsList'
import { setPaymentMethodsShow, setPaymentLink, setRmbToUsdValue, setPaymenFrom, setSwitchRmbUnabledPop, setRmbPaymentSuccess } from '@actions/rmb'
import { timers, contactService } from '@utils/utils'
import { setCurrentCardInfo } from '@actions/giftCard'
// import  { formatNumberToUSD } from '@utils'
import tillstarLoading from '@assets/images/common/tillstar_loading.gif'
import commonPopIcon from '@assets/images/common/notice.png'

const BaseContent = createContext()

// const orderBaseMatchData = {
//   "wechat": {
//     icon: wechat
//   },
//   "unionpay": {
//     icon: union
//   },
//   "alipay": {
//     icon: alipay
//   }
// }

const NoticePop = (props) => {
  const { setIsShowNoticePop } = props
  const { t } = useTranslation()

  return (
    <div id="notice-pop">
      <img className='header-icon' src={commonPopIcon} alt=''></img>
      <div className='title' dangerouslySetInnerHTML={{__html: t('rmb.toast.used')}} />
      <button onClick={() => setIsShowNoticePop(false)}>{t('common.pop.btn')}</button>
    </div>
  )
}


function SwiperFaqRecommend(faq) {
  return  faq.map((item, index) => (
    <Swiper.Item key={index}>
        <div className='question'> Q: {item.q}</div>
        <div className='answer'> A: {item.a}</div>
    </Swiper.Item>
  ))
}

// 订单结果底部 操作退款
function OperaterOrderAction(props) {
  const { setIsRiskShow, setIsShowNoticePop, isBtnLoading, getDetailByCode } = props
// 5003超时的文本处理
  const {transactionDetail, history,  dispatch, paymentMethods, freezeStatus, t} = useContext(BaseContent)
  const actions = [5000, 5001, 5003, 5004, 5008, 5015, 5017, 6000, 6001, 6004, 6005, 6006, 6007,6008, 6009, 6010, 6012, 6014,8010, 8011, 9002]
  const isShowAction = !actions.includes(transactionDetail.status)

  const textHightLight = [5005, 5007, 5011, 5012, 5013, 5014, 5016, 6011, 6013, 8003, 8006, 8009, 9001].includes(transactionDetail.status)
  const textNormal = [5003, 5009, 6002, 6003, 8001, 8004, 8007].includes(transactionDetail.status)
  const [operaterOrderClick, setOperaterOrderClick] = useState(false)

  let btnStyle = {
    color: "#BDBDBD",
    border: "1px solid #bdbdbd"
  }
  
  if(textNormal)  btnStyle = {
    color: "#1F49DA",
    border: "1px solid #1F49DA"
  }
  if(textHightLight)  btnStyle = {
    color: "#FF3841",
    border: "1px solid #FF3841"
  }

  if (transactionDetail.is_gift_card) {
    if (transactionDetail.status === 8011) {
      btnStyle = {
        color: "#1F49DA",
        border: "1px solid #1F49DA"
      }
    } else if (transactionDetail.status === 8010){
      btnStyle = {
        color: "#FF3841",
        border: "1px solid #FF3841"
      }
    }
  }

  const operaterOrderTips = [5002, 5003, 5005, 5009, 5012, 5013, 5017, 8001, 8002, 8003, 8004, 8005, 8006, 8007, 8008, 8009].includes(transactionDetail.status)

  let tipsStyle = {
    background: '#F2F5FF',
    color: '#1F49DA'
  }
  if([5005, 8001, 8002, 8003, 8004, 8005, 8006, 8007, 8008, 8009].includes(transactionDetail.status)) tipsStyle={
    background: '#FFF6EB',
    color: '#F9941E'
  }
  
  if(freezeStatus) {
    btnStyle = {
      color: "#BDBDBD",
      border: "1px solid #BDBDBD"
    }
  }
  return (
    <div>
      {operaterOrderTips && ([5003, 5012, 5013, 5017].includes(transactionDetail.status) && transactionDetail.funding_source !== 2) && <div className='operater-order-tips' style={tipsStyle}>
        {
          <Trans i18nKey={(transactionDetail.status === 5003 && transactionDetail.funding_source === 1) ?  t(`transaction_detail.bottom_action.tips.5003_1`) :  t(`transaction_detail.bottom_action.tips.${transactionDetail.status}`)} values={{
            upload_duration: transactionDetail.upload_duration,
            pay_duration: transactionDetail.pay_duration
          }}/>
        }
      </div>}
      
      <div className='operater-order-action'>
        <div className='cs' onClick={contactService}>
          <img src={cs} alt=''/> <div dangerouslySetInnerHTML={{__html: isShowAction ? t('transaction_detail.bottom_action.cs2') : t('transaction_detail.bottom_action.cs')}}></div>
        </div>
        {
          isShowAction && <Button
          loading={isBtnLoading} 
          style={btnStyle} 
          onClick={()=>bindOrderAction(t, transactionDetail, history,  dispatch, paymentMethods, freezeStatus, setIsRiskShow, operaterOrderClick, setOperaterOrderClick, setIsShowNoticePop, getDetailByCode, contactService)}>{t(`transaction_detail.bottom_action.btn.${transactionDetail.status}`)} </Button>
        }
        {
          transactionDetail.is_gift_card && [8011, 8010].includes(transactionDetail.status) && <Button style={btnStyle} onClick={()=>bindOrderAction(t, transactionDetail, history,  dispatch, paymentMethods, freezeStatus, setIsRiskShow, null, null, null, getDetailByCode, contactService)}>{t(`transaction_detail.bottom_action.btn.${transactionDetail.status}`)} </Button>
        }
      </div>
    </div>
  )
}
async function bindOrderAction(t, transactionDetail, history,  dispatch, paymentMethods, freezeStatus, setIsRiskShow, operaterOrderClick, setOperaterOrderClick, setIsShowNoticePop, getDetailByCode, contactService) {
    if(freezeStatus) return;
    if([5006, 5010, 5017, 8002, 8005, 8008].includes(transactionDetail.status)) {
      return;
    }
    if (transactionDetail.status === 5002) {
      dispatch(setSwitchRmbUnabledPop(true))
      return
    }
    
    if (transactionDetail.is_gift_card) {
      if (transactionDetail.status === 8011) {
        dispatch(setCurrentCardInfo({
          card_key: transactionDetail.associated_order?.card_key,
          brand_id: transactionDetail.associated_order?.brand_id
      }))
        history.push(`/gcPayment?card_name=${transactionDetail.associated_order?.card_name}`)
      } else if (transactionDetail.status === 8010) {
        contactService()
      }
      return
    }

    if ([5016, 9001].includes(transactionDetail.status)) {
      contactService()
      return
    }
    
    if([6013, 6011].includes(transactionDetail.status)) {
      contactService()
      return;
    }
    if(transactionDetail.status === 6002) {
      history.push(`/rmb?amount=${transactionDetail.amount}`)
      return
    }
    if(transactionDetail.status === 6003) {
      if (transactionDetail.type === 6 && !transactionDetail.isRefundable) {
        setIsRiskShow(true)
        return
      }
      if (operaterOrderClick) {
        return
      }
      setOperaterOrderClick(true)
      const res = await getDetailByCode()
      setOperaterOrderClick(false)
      const { detail } = res.response_data
      if(detail.status === 6007) {
        dispatch(initTransactionDetailAction(detail))
        setIsShowNoticePop(true)
      } else {
        history.push('/rmbRefund?code='+ transactionDetail.code)
      }
      return
    }
    if([5003, 5005, 5007, 5009, 5011, 8001, 8003, 8004, 8006, 8007, 8009].includes(transactionDetail.status)) {
      dispatch(setsubmitActivityReceipt(''))
      history.push(`/receipt?code=${transactionDetail.code}`)
      return
    }

    if([5012, 5013, 5014].includes(transactionDetail.status)) {
      // 弹出付款方式
      const tempValue = parseFloat(transactionDetail.amount)
      paymentMethods.map((item) => {
        item.rmb = (tempValue * item.exchange_rate).toFixed(2)
        return item
      })
      dispatch(setPaymentMethodsShow(true))
      dispatch(setRmbToUsdValue(paymentMethods))
    }
}
const RiskTrade = (props) =>  {
  const {t, transactionDetail} = useContext(BaseContent)
  return (
    <div>
      {
        transactionDetail.is_risk && 
          <div className='risk'>
            <div className='risk-tips'>
              <div dangerouslySetInnerHTML={{__html: t('transaction_detail.risk_text.title')}}></div>
            </div>
            <div className='risk-sub-tips'>
              <div dangerouslySetInnerHTML={{__html: t('transaction_detail.risk_text.sub')}}></div>
            </div>
          </div>
      }
    </div>
  )
}

const RelatedTransaction = () => {
  const { history, t, transactionDetail, associatedItem} = useContext(BaseContent)
  const isGiftCardStatus = [9001, 9002].includes(transactionDetail?.status) || (transactionDetail.is_gift_card && [8010, 8011].includes(transactionDetail?.status))
  
  let valueStyleText = {
    color: '#1F49DA'
  }

  if([6009, 6010, 6011, 6012, 6013, 6014].includes(associatedItem?.status)) {
    valueStyleText = {
      color: '#FF3841'
    }
  }

  if(isGiftCardStatus) {
    valueStyleText = {
      color: '#333'
    }
  } 
  const lightShow= [6009, 6010, 6011, 6012, 6013, 6014].includes(associatedItem?.status)
  const isConsumptionTextShow = RegExp(/50/).test(associatedItem?.status)
  const keyValue = (!lightShow && RegExp(/60/).test(associatedItem?.status))
  
  const toRelatedCard = ()=> { 
    if (isGiftCardStatus) {
      history.push({
        pathname: `/gcResult`,
        search: `reference=${associatedItem.order_no}`,
        from: 'nonConfirm'
      })
    } else {
      history.push('/rmbOrder?code=' + associatedItem.code)
    }
    history.go(0)
  }
  
  if(associatedItem && transactionDetail.status !== 6008) {
    const system_timestamp = timers(new Date(associatedItem.system_timestamp))
    return (
      <div className='related-transaction' onClick={()=>toRelatedCard()}>
        <div className='re-title'>
          { isConsumptionTextShow && t(`transaction_detail.related_transaction.50.title`)}
          { keyValue && t(`transaction_detail.related_transaction.60.title`)}
          { isGiftCardStatus && t(`transaction_detail.related_transaction.${transactionDetail?.status}.title`)}
          { associatedItem.status === 8011 && transactionDetail.status === 6014 && t(`transaction_detail.related_transaction.${transactionDetail.status}_${associatedItem.status}.title`) }
          {(!isConsumptionTextShow && !keyValue && !isGiftCardStatus && associatedItem.status !== 8011) && t(`transaction_detail.related_transaction.${associatedItem.status}.title`)}
        </div>
        <div className='re-value'>
          { isGiftCardStatus &&
            <div className='re-des' style={valueStyleText}>
              <span style={{ color: "#333" }} dangerouslySetInnerHTML={{__html: t(`transaction_detail.related_transaction.${transactionDetail.status}.value`, { card_name: associatedItem.card_name }) }} />
              <RightOutline fontSize={10} color="#999"/>
            </div> 
          }

          {
            transactionDetail.status === 8001 && <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.6014.value`)} values={{amount: associatedItem.amount}}> {t(`transaction_detail.related_transaction.6014_1.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          {
            (![8001, 8011].includes(transactionDetail.status) && lightShow)&& <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.${associatedItem.status}.value`)} values={{amount: associatedItem.amount}}> {t(`transaction_detail.related_transaction.${associatedItem.status}.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          {
            isConsumptionTextShow && <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.50.value`)} values={{amount: associatedItem.amount}}> {t(`transaction_detail.related_transaction.50.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          {
            keyValue &&  <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.60.value`)} values={{amount: associatedItem.amount}}> {t(`transaction_detail.related_transaction.60.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          {
            associatedItem.status === 8011 && transactionDetail.status === 6014 && <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.${transactionDetail.status}_${associatedItem.status}.value`)} values={{amount: transactionDetail.amount}}> {t(`transaction_detail.related_transaction.${transactionDetail.status}_${associatedItem.status}.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          {
            associatedItem.status === 6014 && transactionDetail.status === 8011 && <div className='re-des' style={valueStyleText}> <Trans i18nKey={ t(`transaction_detail.related_transaction.${transactionDetail.status}_${associatedItem.status}.value`)} values={{amount: transactionDetail.amount}}> {t(`transaction_detail.related_transaction.${transactionDetail.status}_${associatedItem.status}.value`)}</Trans> <RightOutline fontSize={10} color="#999"/> </div>
          }
          
          
          <div className='timestamp'>{system_timestamp}</div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

function Order(){
    const [transactionDetail, setTransactionDetail] = useState({})
    const associatedItem = transactionDetail?.associated_activity || transactionDetail?.associated_order
    const faqCn = useSelector(state => state.transaction.faq_cn)
    const faqEn = useSelector(state => state.transaction.faq_en)

    const faq = window.localStorage.getItem("selectedLang") === 'zh_CN' ? faqCn: faqEn
    const dispatch = useDispatch()
    const history = useHistory()
    const {t} = useTranslation()
    const paymentLink = useSelector(state => state.rmb.paymentLink)
    const isPayUrl = useSelector(state => state.rmb.is_pay_url)
    const pay_type = useSelector(state => state.rmb.pay_type)
    const [isRiskShow, setIsRiskShow] = useState(false)
    const [isShowNoticePop, setIsShowNoticePop] = useState(false)
    const [isBtnLoading, setIsBtnLoading] = useState(false)
    const params = new URLSearchParams(history.location.search)
    const code = params.get('code')
    // const from = params.get('from')
    const showFaq = [6002,6003,6012,6013,6014].includes(transactionDetail.status)
    const paymentMethods = useSelector(state => state.rmb.paymentMethods)
    const userInfo = useSelector(state => state.user.userInfo)
    const isRecordSearchList = useSelector(state => state.transaction.isRecordSearchList)
    const freezeStatus = userInfo.default_financial_account?.status === 2
    const showRelatedTransactionDown = [9001, 9002].includes(transactionDetail.status)
    const [isDisplayLoadingMask, setIsDisplayLoadingMask] = useState(true)
    const rmbPaymentSuccess = useSelector(state => state.rmb.rmbPaymentSuccess)

    useEffect(() => {
      if(paymentLink !== '') {
        if (isPayUrl) {
            window.location.href = paymentLink
        } else if(pay_type === 'unionpay') {
            const container = document.createElement('div')
            container.setAttribute('id', 'unionpay')
            container.style.display = 'none'
            container.innerHTML = paymentLink
            document.getElementById('root').appendChild(container)
            document.getElementById('pay_form').parentElement.setAttribute('id', 'payFormBox')
            const unionpayStyle = document.querySelector('#unionpay style').innerHTML
            document.querySelector('#unionpay style').innerHTML = unionpayStyle.replace(/div/g, '#payFormBox')
            document.getElementById('pay_form').submit()
            container.remove()
        } else if (pay_type === 'alipay'){
            const dom = document.createElement('div')
            dom.innerHTML = paymentLink
            const script = document.createElement('script')
            script.innerHTML = dom.getElementsByTagName('script')[0].innerHTML
            document.body.appendChild(script)
        }
      }
    },[paymentLink])
    
    const back = () => {
      if(history.length <= 1) {
        history.push('/')     
        return
      }

      if(rmbPaymentSuccess.status) {
        dispatch(setRmbPaymentSuccess({ status: false }))
        history.push('/rmb')     
        return
      }

      history.goBack()

      // const payment_from = JSON.parse(window.sessionStorage.getItem('pay_from')).from
      // console.log(payment_from, transactionDetail.status, 'payment_from')
      // return
      // if (from === 'giftCard') {
      //   history.goBack()
      //   return 
      // }

      // if (payment_from === 'transaction') {
      //   history.push(isRecordSearchList ? '/recordSearchList' : '/recordList')
      //   return
      // }

      // history.push('/rmb')
      
    }

    const getDetailByCode = async () => {
      return await geTransationDetailByCode({activityCode: code}, 'check')
    }
    
    const initData = async () => {
      const res = await getDetailByCode()
      if (res.status_code === 100000) {
        setIsDisplayLoadingMask(false)
        const { detail } = res.response_data
        setTransactionDetail(detail)
        dispatch(initTransactionDetailAction(detail))
        //  支付完成进页面之后刷新
        // rmbPaymentSuccess && window.location.reload()
      } else if (res.status_code === 301006) {
        Toast.show({
          content: t('record_list.card_info.token_expired'),
          duration: 2000,
        })
        dispatch(setIsAllList(false))
        dispatch(setCurrentAllListTabType(0))
        history.push(isRecordSearchList ? '/recordSearchList' : '/recordList')
      }
    }

    useEffect(()=> {
      document.documentElement.scrollTop = 0
      window.scrollTo(0, 0)
      dispatch(setPaymentLink({code: "", link: ""}))
      if(code) {
        initData()
      }
      dispatch(setPaymenFrom({from:'transaction', code}))
      sessionStorage.setItem('pay', JSON.stringify({code}))
      return () => {
        dispatch(initTransactionDetailAction({}))
        dispatch(setRmbPaymentSuccess({ status: false }))
      }
    }, [])

    
    
    return (
        <div id='rmb-payment-order'>
          <BaseContent.Provider value={{transactionDetail, associatedItem, history, t, paymentMethods, dispatch, freezeStatus}}>
          <div className='nav-bar'>
            <NavBar onBack={back}>{t('transaction_detail.nav_bar')}</NavBar>
          </div>
          { isDisplayLoadingMask && <div><img className='tillstar-loading-icon' src={tillstarLoading} alt="" /></div>}
          <PullToRefresh onRefresh={() => initData('pull')} renderText={() => <div><img className='tillstar-loading-icon' src={tillstarLoading} alt="" /></div>}>
          {transactionDetail.code && 
            <>
              <TransactionStatus transactionDetail={transactionDetail} t={t} history={history} paymentMethod={paymentMethods} dispatch={dispatch}/>
              <RiskTrade t={t} />
            </>
          }
          {transactionDetail.status === 6003 && <RmbTutorial />}
          {showFaq &&
            <div className='faq'>
              <div className='title'>
                <div className='left'>
                  <img alt='' src={question}></img>
                  <label>{t('faq.left_des')}</label>
                </div>
                <Link to={'/faq'}>
                  <div className='right'>{t('faq.right_des')}<RightOutline/></div>
                </Link>
              </div>
              <div className='list'>
                  <Swiper loop>{SwiperFaqRecommend(faq)}</Swiper>
              </div>
            </div>
          }
          {associatedItem && !showRelatedTransactionDown && <RelatedTransaction/>}
          {transactionDetail.code && 
            <>
              <TransactionFlow className={showRelatedTransactionDown ? '': 'up'} transactionDetail={transactionDetail} t={t} history={history} paymentMethod={paymentMethods} dispatch={dispatch}/>
              <OperaterOrderAction getDetailByCode={getDetailByCode} isBtnLoading={isBtnLoading} setIsRiskShow={setIsRiskShow} setIsShowNoticePop={setIsShowNoticePop}/>
            </>
          }
          {associatedItem && showRelatedTransactionDown && <RelatedTransaction/>}
          <PaymentMethodsList setIsBtnLoading={setIsBtnLoading} isBtnLoading={isBtnLoading} transactionDetail={transactionDetail}/>
            <CommonPop isRiskShow={isRiskShow} setIsRiskShow={setIsRiskShow}/>
          </PullToRefresh>
          <Mask visible={isShowNoticePop} onMaskClick={() => setIsShowNoticePop(false)}>
            <NoticePop setIsShowNoticePop={setIsShowNoticePop}/>
          </Mask>
          </BaseContent.Provider>
        </div>
    )
}
export default Order