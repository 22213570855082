// import { createStore, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
// import reducer from './reducer.js'

// const enhancer = applyMiddleware(thunk)

// export default createStore(reducer, enhancer)
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

const initialState = {
  
};

const composeEnhancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;

const store = createStore(
    rootReducer, 
    initialState,
    composeEnhancers(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

export default store;