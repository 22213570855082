import React, { useEffect, useState, useMemo } from 'react'
import './index.less'
import { NavBar, Toast } from 'antd-mobile'
import { updateUserAddress } from '@api/user'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import AddressForm from '@comps/AddressForm'

export default function Address () {
    const [specificAddress, setSpecificAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [postcode, setPostcode] = useState('')

    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const [currentAddress, setCurrentAddress] = useState({})
    const [isSyncAddress, setIsSyncAddress] = useState(false)

    const isClicked = useMemo(() => specificAddress && city && state && postcode, [specificAddress, city, state, postcode])

    useEffect(() => {
        try {
            document.documentElement.scrollTop = 0
            window.scrollTo(0, 0)
            const { address } = qs.parse(location.search.slice(1))
            setCurrentAddress(address)
            setPostcode(address.postcode)
            setSpecificAddress(address.address_1)
            setCity(address.city)
            setState(address.state)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const updateAddress = async () => {
        if (!isClicked) return
        if(specificAddress.length < 4 || specificAddress.length > 40 || city.length < 4 || city.length > 30  || state.length !== 2 || postcode.length !== 5) {
            Toast.show({
              content: t('steps.AddressErrInvalid'),
              duration: 2000
            })
            return
        }
        const res = await updateUserAddress({
            address_id: currentAddress.address_id,
            address_1: specificAddress,
            city,
            state,
            postcode,
            is_sync_address: isSyncAddress
        })
        switch (res.status_code) {
            case 100000:
                res.response_data && history.goBack()
            break;
            case 300001: //Service call failed
            Toast.show({
              content: t('steps.SSNErr'),
              duration: 4000,
            })
            break;
          case 300008: //Your account is not qualified for this function.
            history.push('/')
            break;
          case 301004: //No logged in user.
            Toast.show({
              content: t('general.redirectLogin'),
              duration: 4000,
            })
            history.push('/login')
            break;
          case 301018: //This step has been passed, please proceed to the next step.
            Toast.show({
              content: t('steps.guideStepInfoErr'),
              duration: 4000,
            })
            break;
          case 301026: 
            Toast.show({
              content: t("address.poboxNotAllowed"),
              duration: 4000,
            })
            break;
          case 300009: //The format/value of parameter is not correct.
          case 300002: //Invalid parameter
          default:
            Toast.show({
              content: t('steps.AddressErrInvalid'),
              duration: 4000,
            })
            break;
        }
    }

    return (
        <div id="edit-address">
            <NavBar className="nav-bar" onBack={() => history.goBack()}>
                {t(`address.${currentAddress.type}.title`)}
            </NavBar>
            <AddressForm
                specificAddress={specificAddress}
                setSpecificAddress={setSpecificAddress}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                postcode={postcode}
                setPostcode={setPostcode}
            />
            <div className="address-bottom">
                <div className="is-synchronous d-flex align-items-center adm-space-justify-between">
                    <span className="is-synchronous-tip">{t(`address.${currentAddress.type}.tip`)}</span>
                    <span className={`is-synchronous-btn d-flex align-items-center justify-content-center ${isSyncAddress ? 'active' : ''}`} onClick={() => setIsSyncAddress(!isSyncAddress)}>
                        {t('steps.AddressSyncYes')}
                    </span>
                </div>
                <div className="update-address-section">
                    <button className={`update-address ${isClicked ? 'active': ''}`} onClick={updateAddress}>{t('address.saveAddress')}</button>
                </div>
            </div>
        </div>
    )
}