import React, { useState } from "react"
import "./index.less"
import { useHistory } from "react-router-dom"
import { NoticeBar, Space } from "antd-mobile"
import {
  SoundOutline,
  RightOutline,
} from "antd-mobile-icons"

import { useTranslation } from "react-i18next"

export default function StopTakingNotice(props) {
  const [show] = useState(false)

  // const showStopTakingPop =  useSelector(state => state.common.showStopTakingPop)
  // const stopTakingDate =  useSelector(state => state.common.stopTakingDate)

  const history = useHistory()
  const { t } = useTranslation()

  const goDetails = () => {
    history.push('/StopTaking');
  }
  
  return (
    <div className="stop-taking-content">
      {show && (
        <NoticeBar
          style={{
            "--background-color": "#FFF9FA",
            "--height": "44px",
            "--icon-font-size": "12px",
            "--font-size": "14px",
            "--text-color": "#333",
          }}
          icon={
            <span className="redText">
              <SoundOutline color="#FF3841" fontSize={12} />
              <span>{ t("stopTaking.left_text") }</span>
            </span>
          }
          content={
            <div className="stop-taking-text">
              <span>
                { t("stopTaking.center_text") }
              </span>
            </div>
          }
          color="alert"
          extra={
            <Space style={{ "--gap": "12px" }}>
              <span className="redText" onClick={goDetails}>
                { t("stopTaking.right_text") } <RightOutline color="#FF3841" fontSize={12} />
              </span>
            </Space>
          }
        />
      )}

      
    </div>
  )
}
