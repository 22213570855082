import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { CloseOutline } from 'antd-mobile-icons'
import { Button, Toast } from "antd-mobile"
import { startActivationProcess } from '@api/active'
import activats_cn from "@assets/images/tip/tip_bg_cn.png"
import activats_en from "@assets/images/tip/tip_bg_en.png"
import "./index.less"
import { connect } from 'react-redux'
import { t } from "i18next"

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang,
    userInfo: store.user.userInfo
  }
}

class Tip extends Component {
  state = {
    stepStatus: {},
    isSSN: false,
    activationProcessStatusCode: 100000
  }
  async componentDidMount() {
    const res = await startActivationProcess({
      Authorization: localStorage.getItem('token')
    })
    const { response_data, status_code } = res
    if (response_data && status_code === 100000) {
      const { id, step_status, is_ssn } = response_data
      localStorage.setItem('AuthorizationID', id)
      this.setState({
        stepStatus: JSON.parse(step_status),
        isSSN: is_ssn,
        activationProcessStatusCode: status_code
      })
    }
  }

  handleActivate = () => {
    const { stepStatus, activationProcessStatusCode, isSSN } = this.state
    const verifyStatus = this.props.userInfo.identity_verification_status
    if (activationProcessStatusCode === 100000) {
      if (verifyStatus === 1 && !isSSN) {
        this.props.history.push('/kycBegin')
        return;
      }
      if (verifyStatus === 0) {
        this.props.history.push('/kycBegin')
        return;
      }

      if (!stepStatus.name) {
        this.props.history.push('/kycBegin')
      } else if (!stepStatus.ssn) {
        this.props.history.push('/stepOne')
      } else if (!stepStatus.address) {
        this.props.history.push('/xStepTwo')
      } else if (!stepStatus.email) {
        this.props.history.push('/xStepThree')
      } else if (!stepStatus.kyc) {
        this.props.history.push('/xStepFour')
      }
    } else if (activationProcessStatusCode === 300008) {
      this.props.history.push('/')
    } else {
      Toast.show({
        content: t('steps.guideActionFailed'),
        duration: 4000,
      })
    }
  }

  render() {
    const { onClose, selectedLang, userInfo } = this.props
    const verifyStatus = userInfo.identity_verification_status

    return (
      <>
        <div className="tip_dialog">
          <div className="close">
            <CloseOutline onClick={onClose} fontSize={24} />
          </div>
          <div className={["dialog__header", selectedLang !== 'zh_CN' ? "is_en" : null].join(' ')} >
            <p dangerouslySetInnerHTML={{ __html: t(`home.ActivateillstarTitle`, { minute: 3 }) }} />
            <p>{t('home.ActivateillstarSubTitle')}</p>
          </div>
          <div className="dialog__content">
            <img src={selectedLang === 'zh_CN' ? activats_cn : activats_en} alt="" />
          </div>
          <div className="dialog__footer">
            <Button
              type="warning"
              className="activate-btn d-flex align-items-center justify-content-center"
              onClick={this.handleActivate}
            >
              {verifyStatus === 0 ? (
                t('home.Activateillstar')
              ) : null}
              {verifyStatus === 1 ? t('home.Continueactivating') : null}
              {verifyStatus === 3 ? t('home.Reactivate') : null}
            </Button>
            <div className="activate-tip" onClick={onClose}>
              {t('home.Notyet')}
            </div>
          </div>

        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, null)(withRouter(Tip))
