import React, { useEffect, useState } from 'react'
import './index.less'
import { NavBar } from 'antd-mobile'
import { FillinOutline } from 'antd-mobile-icons'
import { getUserAddress } from '@api/user'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

const AddressList = (props) => {
    const { title, address = {}, editAddress } = props
    const { t } = useTranslation()
    
    return (
        <div className="address">
                <div className="address-title">
                    {title}
                </div>
                <div className="address-detail">
                    <div className="item">
                        <span className="text-section">{address.postcode}</span>
                        <span className="action-section" onClick={editAddress}>
                            <FillinOutline fontSize={16} color="#267CFF"/>
                            <span className="edit">{t('address.edit')}</span>
                        </span>
                    </div>
                    <div className="item">{address.state}</div>
                    <div className="item">{address.city}</div>
                    <div className="item">{address.address_1}</div>  
                </div>
            </div>
    )
}

export default function Address () {
    const [address, setAddress] =  useState({})
    const { t } = useTranslation()
    const history = useHistory()

    const initData = async () => {
        try {
            const res = await getUserAddress()
            if (res.status_code === 100000) {
                setAddress(res.response_data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        document.documentElement.scrollTop = 0
        window.scrollTo(0, 0)
        initData() 
    }, [])

    const editAddress = (address, type) => {
        history.push({
            pathname: `/editAddress`,
            search: qs.stringify({ address: {
                ...address,
                type
            } })
        })
    }

    return (
        <div id="my-address">
            <NavBar className="nav-bar" onBack={() => history.goBack()}>
                {t('address.title')}
            </NavBar>
            { address.billing_address && 
                <AddressList 
                    title={t('address.billdingAddress.title')}
                    address={address.billing_address}
                    editAddress={() => editAddress(address.billing_address, 'billdingAddress')}
                />
            }
            { address.shipping_address &&
                <AddressList
                    title={t('address.shippingAddress.title')}
                    address={address.shipping_address}
                    editAddress={() => editAddress(address.shipping_address, 'shippingAddress')}
                />
            }          
        </div>
    )
}