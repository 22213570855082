import React, { useState, useEffect, useMemo } from 'react'
import { Mask, Toast } from 'antd-mobile'
import { updateActivationAddress } from '@api/active'
import './index.less'
import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import Tip from '@comps/Tip'
import AddressForm from '@comps/AddressForm'
import { useHistory } from 'react-router-dom'
import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'

export default function KycAddress() {
  const history = useHistory()
  const [billing_address_1, setBillingAddress] = useState('')
  const [billing_city, setBillingCity] = useState('')
  const [billing_state, setBillingState] = useState('')
  const [billing_postcode, setBillingPostcode] = useState('')
  const billing_country = 'USA'
  const [shipping_address_1, setShippingAddress] = useState('')
  const [shipping_city, setShippingCity] = useState('')
  const [shipping_state, setShippingState] = useState('')
  const [shipping_postcode, setShippingPostcode] = useState('')
  const shipping_country = 'USA'
  const [submitting, setSubmitting] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [isSyncAddress, setIsSyncAddress] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  
  const list = [
    { name: t('steps.navNameAndDob'), active: false },
    { name: t('steps.navSSN'), active: false },
    { name: t('steps.navAddresses'), active: true },
    { name: t('steps.navEmail'), active: false },
    { name: t('steps.navID'), active: false },
  ]

  useEffect(() => {
    scrollToTop()
    window.addEventListener('resize', hideFooter, true)
    return () => {
      window.removeEventListener('resize', hideFooter, true)
    }
  }, [])

  const hideFooter = () => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
  }

  const handleSendCode = async () => {
    const activationToken = localStorage.getItem('AuthorizationID')
    let formatData = {
      billing_address_1,
      billing_city,
      billing_state,
      billing_postcode,
      billing_country,
      verificationRequestId: activationToken,
    }
    if (!isSyncAddress) {
      formatData = {
        ...formatData,
        shipping_address_1,
        shipping_city,
        shipping_state,
        shipping_postcode,
        shipping_country,
      }
     } else {
      formatData = {
        ...formatData,
        shipping_address_1: billing_address_1,
        shipping_city: billing_city,
        shipping_state: billing_state,
        shipping_postcode: billing_postcode,
        shipping_country: billing_country
      }
    }

    if(formatData.billing_address_1.length < 4 || formatData.billing_address_1.length > 40 || formatData.shipping_address_1.length < 4 || formatData.shipping_address_1.length > 40) {
      Toast.show({
        content: t('steps.AddressErrInvalid'),
        duration: 2000
      })
      return
    }

    setSubmitting(true)
    setIsSubmitLoading(true)
    let res = await updateActivationAddress(formatData)
    setIsSubmitLoading(false)
    setSubmitting(false)
    switch (res?.status_code) {
      case 100000:
        history.push("xStepThree");
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.SSNErr'),
          duration: 4000,
        })
        break;
      case 300008: //Your account is not qualified for this function.
        history.push("/")
        break;
      case 301004: //No logged in user.
        Toast.show({
          content: t('general.redirectLogin'),
          duration: 4000,
        })
        history.push("/login");
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErr'),
          duration: 4000,
        })
        setShowDialog(true)
        break;
      case 301026: 
        Toast.show({
          content: '请您输入正确的地址，不可以使用PO BOX地址哦',
          duration: 4000,
        })
        break;
      case 300009: //The format/value of parameter is not correct.
      case 300002: //Invalid parameter
      default:
        Toast.show({
          content: t('steps.AddressErrInvalid'),
          duration: 4000,
        })
        break;
    }
  }

  const isNextStepDisable = useMemo(() => {
    let nextStepDisable = !!(billing_postcode.length === 5 && billing_state && billing_city && billing_address_1)

    if (!isSyncAddress) {
      nextStepDisable = !!(nextStepDisable && shipping_state && shipping_postcode.length === 5 && shipping_city && shipping_address_1)
    }

    return nextStepDisable
  }, [isSyncAddress, billing_postcode, billing_state, billing_city, billing_address_1,shipping_state, shipping_postcode, shipping_city, shipping_address_1])

  return (
    <div className="stepTwo">
      <Mask visible={showDialog} disableBodyScroll>
        <Tip onClose={() => setShowDialog(false)}/>
      </Mask>
      <StepHeader
        title={t('steps.AddressTitle')}
        list={list}
      />
      <AddressForm
          specificAddress={billing_address_1}
          setSpecificAddress={setBillingAddress}
          city={billing_city}
          setCity={setBillingCity}
          state={billing_state}
          setState={setBillingState}
          postcode={billing_postcode}
          setPostcode={setBillingPostcode}
      />

      <div className="is-synchronous d-flex align-items-center adm-space-justify-between">
          <span className="is-synchronous-tip">{t('steps.AddressSync')}</span>
          <span className={`is-synchronous-btn d-flex align-items-center justify-content-center ${isSyncAddress ? 'active' : ''}`} onClick={() => setIsSyncAddress(!isSyncAddress)}>
              {t('steps.AddressSyncYes')}
          </span>
      </div>

      {!isSyncAddress && (
        <div className="address-email">
          <p className="tip1">{t('steps.AddressShippingAddress')}</p>
          <AddressForm
            specificAddress={shipping_address_1}
            setSpecificAddress={setShippingAddress}
            city={shipping_city}
            setCity={setShippingCity}
            state={shipping_state}
            setState={setShippingState}
            postcode={shipping_postcode}
            setPostcode={setShippingPostcode}
          />
        </div>
      )}
      <StepFooter
        isLoading={isSubmitLoading}
        disabled={!isNextStepDisable || submitting}
        btnSubmit={handleSendCode}
        btnText={t('steps.nextButton')}
        licenseInfoBottom={t('steps.guideFPB')}
      />
    </div>

  )
}