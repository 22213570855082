import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'

export const timeFormat = (times) => {
  return times <  10 && times > 0 ? '0'+ times : times
}

export const formatInputNum = (num) => {
  if(num === undefined || null) return ''
  num = num.replace(/^0+[0-9]+/g, '0')
  num = num.replace(/[^\d.]/g, '')
  num = num.replace(/^\./g, '')
  num = num.replace(/\.{2,}/g, '.')
  num = num.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  num = num.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  return num
}

export const formatNumberToUSD = (num) => {
  if(num === undefined || null) return ''
  return num.toString().replace(/(\d)(?=(\d{3})+(?:\.\d*)?$)/g, '$1,')
}

export const transferTime = (time, newformat = "") => {
  let newTime = ""
  let format = ""
  let subFormat = ""
  if(newformat) {
    subFormat = "MM-DD HH:mm"
    newTime = dayjs(time).format(subFormat)
    return newTime
  }

  dayjs.extend(relativeTime)
  const now = dayjs().format("YYYY-MM-DD")
  const oldTime = dayjs(time).format("YYYY-MM-DD")

  const nowYear = dayjs().format("YYYY-MM-DD")
  const oldYear = dayjs(time).format("YYYY-MM-DD")

  const fromNow = dayjs(now).diff(oldTime, 'd')
  const fromNowYear = dayjs(nowYear).diff(oldYear, 'y')

  // 当天
  if(fromNow === 0) {
    format = subFormat ? subFormat : "HH:mm"
    newTime = dayjs(time).format(format)
  }

  if(fromNow > 0 && fromNowYear === 0) {
    format = subFormat ? subFormat : "MM-DD"
    newTime = dayjs(time).format(format)
  }

  if(fromNow > 0 && fromNowYear > 0) {
    format = subFormat ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"
    newTime = dayjs(time).format(format)
  }

  return newTime
}

export const formatNum = (num) => {
  //1. 可能是字符串，转换为浮点数
  //2. 乘以100 小数点向右移动两位
  //3. Math.round 进行四舍五入
  //4. 除以100 小数点向左移动两位 实现保留小数点后两位
  let value = Math.round(parseFloat(num) * 100) / 100
  // 去掉小数点 存为数组
  let valueStr = value.toString()
  let arrayNum = valueStr.split('.')
  //只有一位（整数）
  if (arrayNum.length === 1) {
      return valueStr + '.00'
  }
  if (arrayNum.length > 1) {
      //小数点右侧 如果小于两位 则补一个0
      if (arrayNum[1].length < 2) {
          return valueStr + '0'
      }
      return value
  }
}