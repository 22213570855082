import React, { useEffect, useState } from "react"
import { Button, Toast, Mask, Skeleton } from "antd-mobile"
import { RightOutline, CameraOutline, GiftOutline } from "antd-mobile-icons"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { t } from "i18next"
import ReactLoading from "react-loading"
import Avatar from "react-avatar"
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { getUserInfo } from "@api/user"
import { reqLogout } from "@api/login"
import { getPermissionByCode } from "@api/user"
import { getModuleStatus } from "@api/common"

import { setActionToken, setPasswordRelatedChannel } from "@actions/password"
import { setUserInfo } from "@actions/user"
import { setSavingAccountToken } from "@actions/savingAccount"
import { setIsAllList } from "@actions/transaction"
import { contactService, scrollToTop } from "@utils/utils"
import "./index.less"

import iconVIP1 from "@assets/images/mine/icon_VIP1.png"
import iconVIP2 from "@assets/images/mine/icon_VIP2.png"
import iconVIP3 from "@assets/images/mine/icon_VIP3.png"
import iconVIP4 from "@assets/images/mine/icon_VIP4.png"
import quanbu from "@assets/images/mine/order/quanbu.png"
import chenggong from "@assets/images/mine/order/chenggong.png"
import tuikuan from "@assets/images/mine/order/tuikuan.png"
import biaoji from "@assets/images/mine/order/biaoji.png"
import pin from "@assets/images/mine/personal_pin.svg"
import account from "@assets/images/mine/personal_link.svg"
import freezen from "@assets/images/mine/personal_freezen.svg"
import faq from "@assets/images/mine/personal_faq.svg"
import doc from "@assets/images/mine/personal_doc.svg"
import cs from "@assets/images/mine/personal_cs.svg"
import address from "@assets/images/mine/personal_address.svg"
import pic_touxiang from "@assets/images/mine/avatar.svg"

import Tip from "@comps/Tip"
import SvgIcon from "@comps/SvgIcon"

function Mine() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const version = localStorage.getItem("version")
  /** 隐藏giftcard
  const orderList = [
    {
      icon: quanbu,
      name: t("account.Allorders"),
      tagKey: "all",
    },
    {
      icon: chenggong,
      name: t("account.Successfulorder"),
      tagKey: "success",
    },
    {
      icon: tuikuan,
      name: t("account.refundorders"),
      tagKey: "refund",
    },
    {
      icon: biaoji,
      name: t("account.Markorder"),
      tagKey: "marked",
    },
  ]
 */
  const colorList = [
    '#FF3841',
    '#4369ED',
    '#7660FF',
    '#DF70DB',
    '#D6DF70',
    '#FFD43D',
    '#F9941E',
    '#4369ED',
    '#85B918',
    '#08B1C3'
  ]

  const [isSetPassword, setIsSetPassword] = useState(false)
  const [isFreeze, setIsFreeze] = useState(false)
  const [isLoginOuting, setIsLoginOuting] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [isLoadingShow, setIsLoadingShow] = useState(false)

  const [verifyStatus, setVerifyStatus] = useState(0)

  const [lastName, setLastName] = useState("")
  const [mobile, setMobile] = useState("")
  const [firstName, setFirstName] = useState("")
  const [color, setColoe] = useState("#fff")

  const [activeFinancialAccounts, setActiveFinancialAccounts] = useState({})
  const [userInfo, setUserInfos] = useState({})
  const [level, setLevel] = useState(null)

  const firstList = [
    {
      icon: account,
      name: t("mine.relatedAccount"), // "关联银行账户"
      extra: activeFinancialAccounts === 0 ? t("mine.noRelatedAccount") : "", // "暂未关联"
      link: "management",
    },
    {
      icon: pin,
      name: t("mine.tradePassword"), // "交易密码设置"
      extra: isSetPassword ? "" : t("account.Temporarilynotset"),
      link: "tradePassword",
    },
    {
      icon: address,
      name: t("mine.myAddress"), // "管理地址"
      extra: "",
      link: "myAddress",
    },
    {
      icon: freezen,
      name: t("account.Accountisfrozen"), // 实名认证
      extra: isFreeze === 2 ? t("account.hasbeenfrozen") : t("account.SecureAccount"), // 保护账户安全
      link: "freezeAccount",
    },
  ]

  const secondList = [
    {
      icon: faq,
      name: t("mine.faq"), // "常见问题FAQ"
      extra: "",
      link: "/faq",
    },
    {
      icon: cs,
      name: t("account.Contactus"), // "联系客服"
      extra: "",
      link: "cs",
    },
    {
      icon: doc,
      name: t("mine.serviceAgreement"), //"用户服务协议"
      extra: "",
      link: "/mine/policy",
    },
  ]

  const goBack = () => {
    const routerName = location?.state?.router
    const length = history?.length
    if (length <= 2) {
      history.push("/")
    } else {
      if (routerName === "setting") {
        history.push("/")
      } else {
        history.goBack()
      }
    }
  }
  /** 隐藏giftcard
   const orders = (tagKey) => {
     history.push(`/gcOrder?tagKey=${tagKey}`)
   }
  */
  useEffect(() => {
    scrollToTop()

    const searchParams = new URLSearchParams(location.search.split("?")[1])

    if (searchParams.get("showContact")) {
      contactService()
    }

    initData()
  }, [])

  const initData = async () => {
    try {
      setIsLoadingShow(true)
      const userInfoRes = await getUserInfo()
      setIsLoadingShow(false)

      let userInfo, mobile, verifyStatus, firstName, lastName, isSetPassword, isFreeze, activeFinancialAccounts
      if (userInfoRes?.response_data) {
        userInfo = userInfoRes.response_data
        mobile = userInfo?.mobile
        verifyStatus = userInfo?.identity_verification_status
        firstName = userInfo?.first_name
        lastName = userInfo?.last_name
        isSetPassword = userInfo?.is_transaction_pin_set
        isFreeze = userInfo?.default_financial_account?.status
        activeFinancialAccounts = userInfo?.active_financial_accounts
        const colorIdx = userInfo?.mobile.charAt(userInfo?.mobile.length - 1)
        const vipLevel = userInfo?.vip_level
        // vip icon
        let vipImg
        if (vipLevel === 1) {
          vipImg = <img src={iconVIP1} alt="" />
        } else if (vipLevel === 2) {
          vipImg = <img src={iconVIP2} alt="" />
        } else if (vipLevel === 3) {
          vipImg = <img src={iconVIP3} alt="" />
        } else if (vipLevel === 4) {
          vipImg = <img src={iconVIP4} alt="" />
        } else {
          vipImg = null
        }

        setLevel(vipImg)
        setColoe(colorList[colorIdx])
        setUserInfos(userInfo)
        setMobile(mobile)
        setVerifyStatus(verifyStatus)
        setFirstName(firstName)
        setLastName(lastName)
        setIsSetPassword(isSetPassword)
        setIsFreeze(isFreeze)
        setActiveFinancialAccounts(activeFinancialAccounts)
      }
    } catch (err) {
      console.log("err", err)
    }
  }

  // 退出登录
  const loginOut = async () => {
    try {
      if (isLoginOuting) return
      setIsLoginOuting(true)

      const res = await reqLogout()
      if (res.status_code === 100000) {
        setIsLoginOuting(false)

        resetLoginData()
        history.push("/")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const resetLoginData = () => {
    dispatch(setIsAllList(false))
    dispatch(setUserInfo({}))
    dispatch(setActionToken(""))
    dispatch(setSavingAccountToken(""))
    localStorage.clear()
    sessionStorage.clear()
    document.cookie = "name=add;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }

  const itemClick = (item) => {
    if (item.link === "cs") {
      contactService()
      return
    }
    if (item.link === "management") return manageAccount()
    if (item.link === "tradePassword") return handleTradePassword()
    if (item.link === "myAddress") return manageAddress()
    if (item.link === "freezeAccount") return handleFreezeAccount()

    history.push(item.link)
  }

  // 交易密码
  const handleTradePassword = () => {
    if (isFreeze === 2) {
      Toast.show({
        content: t("realAuthentication.dollaraccountfrozen"),
        duration: 1000,
      })
      return
    }

    if (!isSetPassword) {
      dispatch(
        setPasswordRelatedChannel({
          channel: "normalSetting",
          type: "set",
          url: "/mine",
          backUrl: "/mine",
        })
      )
      history.push("/verifyCode")
      return
    }

    history.push("/tradePassword")
  }

  // 账户冻结
  const handleFreezeAccount = async () => {
    const isGoNext = await verifyAccountStatus()

    if (!isGoNext) return
    history.push("/freezeAccount")
  }

  //管理账户
  const manageAccount = async () => {
    const isGoNext = await verifyAccountStatus()

    if (!isGoNext) return
    try {
      const res = await getPermissionByCode({ permissionCode: "permission_external_account" })
      if (res.status_code === 300008) return
      history.push("/management")
    } catch (error) {
      console.log(error)
    }
  }

  const manageAddress = async () => {
    const isGoNext = await verifyAccountStatus()
    if (!isGoNext) return
    history.push("/myAddress")
  }

  const verifyAccountStatus = async () => {
    if (isFreeze === 2) {
      Toast.show({
        content: t("realAuthentication.dollaraccountfrozen"),
        duration: 1000,
      })
      return false
    }
    if (
      verifyStatus === 3 ||
      (verifyStatus === 4 && activeFinancialAccounts === 0 && [4, 9].includes(userInfo?.identity_verification_request_status))
    ) {
      Toast.show({
        content: t("account.settingPageAccountFailedErr"),
        duration: 1000,
      })
      return false
    }
    if ([0, 1].includes(verifyStatus)) {
      let result = await getModuleStatus("module_kyc")
      if (result.status_code === 300012) return false

      setShowDialog(true)
      return false
    }
    if (verifyStatus === 2) {
      Toast.show({
        content: t("account.settingPageAccountInOpeningErr"),
        duration: 2000,
      })
      return false
    }
    return true
  }

  const goEditInfo = () => {
    if (isFreeze === 2) {
      Toast.show({
        content: t("realAuthentication.dollaraccountfrozen"),
        duration: 1000,
      })
      return false
    }
    history.push("/mine/personInfo")
  }

  const goUpdateAvatar = () => {
    if (isFreeze === 2) {
      Toast.show({
        content: t("realAuthentication.dollaraccountfrozen"),
        duration: 1000,
      })
      return false
    }
    history.push("/mine/updateAvatar")
  }

  const copy = () => {
    Toast.show({
      content: t('common.copy_tip'),
      duration: 1000,
    })
  }

  return (
    <div id="mine-page">
      <Mask visible={showDialog} disableBodyScroll>
        <Tip onClose={() => setShowDialog(false)} verifyStatus={verifyStatus} />
      </Mask>

      <Mask visible={isLoadingShow}>
        <ReactLoading className="loading" type={"spinningBubbles"} color={"#fff"} />
      </Mask>

      <div className="mine-top">
        <div className="close" onClick={goBack}>
          <SvgIcon iconClass="close" />
        </div>

        <div className={["mine-top-avatar", verifyStatus !== 4 ? "light-height" : ""].join(" ")}>
          {userInfo && userInfo?.avatar ? (
            <img src={userInfo?.avatar} alt="" />
          ) : (firstName || lastName) ? (
            <Avatar
              name={`${firstName || ""} ${lastName || "."}`}
              size={"2.13333rem"}
              textSizeRatio={2.22}
              round="50%"
              color={color}
            />
          ) : (
            <img src={pic_touxiang} alt="" />
          )}

          {/* {verifyStatus !== 4 ? (
            <img src={pic_touxiang} alt="" />
          ) : (
            (firstName || lastName) && (
              <Avatar name={`${firstName || ""} ${lastName || "."}`} size={"2.13333rem"} textSizeRatio={2.22} round="50%" color={color} />
            )
          )} */}

          <span className="camera" onClick={() => goUpdateAvatar()}>
            <CameraOutline />
          </span>
        </div>
        <div className="userDetail">
          {verifyStatus !== 4 ? (
            <span className="phone">{mobile?.replace(/(\d{3})\d*(\d{4})/, "$1****$2")}</span>
          ) : (
            <span className="phone">
              {firstName} {"  "}
              {lastName || ""}
            </span>
          )}

          {!firstName && !lastName && verifyStatus === 4 ? <Skeleton.Title style={{ margin: 0 }} /> : level}
          {/* || <img src={iconVIP1} alt="" /> */}
        </div>

        {/* todo 昵称 */}

        {userInfo?.tillstar_id && <CopyToClipboard text={userInfo?.tillstar_id} onCopy={() => copy()}>
          <div className="nick-name">
            {"@" + userInfo?.tillstar_id || ""}
          </div>
        </CopyToClipboard>}
        <Button className="edit-info" onClick={() => goEditInfo()}>
          {t("mine.editInfo")}
        </Button>
      </div>
      {/* 分享奖励点数隐藏 */}
      {/* <div className="share-item">
        <div className="share-left">
          <div className="share-icon">
            <GiftOutline />
          </div>
          <span className="share-title">{t("mine.shareTitle")}</span>
        </div>
        <Button className="share-button" onClick={() => history.push({pathname: "/savingAccount/shareRules",state: { router: 'mine' } })}>
          {t("mine.shareBtn")}
        </Button>
      </div> */}
      {/* 礼卡订单隐藏 */}
      {/* <div className="my-order">
        <ul className="order">
          <li className="order-item order-item-first">
            <div className="order-title">
              <p dangerouslySetInnerHTML={{__html: t("account.MyorderTitle") }}></p>
            </div>
          </li>
          {orderList.map((item, index) => (
            <li className="order-item trackContainer" key={`order-item-${index}`} onClick={() => orders(item.tagKey)}>
              <img src={item.icon} alt="" />
              <span className="trackReturn">{item.name}</span>
            </li>
          ))}
        </ul>
      </div> */}

      <div className="module-title">{t("mine.safeSetting")}</div>
      <div className="module-list">
        {firstList.map((item, index) => {
          return (
            <div className={["list-item", isFreeze === 2 ? "opacity-6" : ""].join(" ")} key={`list-item-${index}`} onClick={() => itemClick(item)}>
              <div className="item-left">
                <img src={item.icon} alt="" />
                <p>{item.name}</p>
              </div>
              <div className="item-right">
                <p>{item.extra}</p>
                <RightOutline />
              </div>
            </div>
          )
        })}
      </div>

      <div className="module-title">{t("mine.help")}</div>
      <div className="module-list">
        {secondList.map((item, index) => {
          return (
            <div className="list-item" key={`list-item-${index}`} onClick={() => itemClick(item)}>
              <div className="item-left">
                <img src={item.icon} alt="" />
                <p>{item.name}</p>
              </div>
              <div className="item-right">
                <p>{item.extra}</p>
                <RightOutline />
              </div>
            </div>
          )
        })}
      </div>

      <div className="btn-container">
        <Button type="warning" className="warning-btn d-flex align-items-center justify-content-center" onClick={loginOut}>
          {t("account.SignOut")}
        </Button>

        <p className="versions">{t("mine.version", { version })}</p>
      </div>
    </div>
  )
}

export default Mine
