// 转账
import request from "@utils/request";
// 当前公共请求地址前缀
const url_prefix = "/account";

// account/deposit-check
// 充值预检
export const depositCheck = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/deposit-check`,
    data
  });
};

// 充值
// export const rechargeMoney = (data) => {
//   return request({
//     method: "POST",
//     url: `${url_prefix}/deposit`,
//     data
//   });
// };
export const getPhysicalCardApplyStatus = ()=> {
  return request({
    method: "GET",
    url: `/app/account/get-physical-card-apply-status`,
  });
}
export const submitPhysicalCardApplyStatus = ()=> {
  return request({
    method: "POST",
    url: `/app/account/submit-physical-card-apply`,
  });
}


export const rechargeMoney = (data) => {
  return request({
    method: "POST",
    url: `/app/account/account-transaction-request`,
    data
  });
};

// 检查余额是否充足
export const checkAccount = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/gs-account-transfer-check`,
    data
  });
};

// 通讯录列表
export const searchAccountList = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/search-account-by-phone`,
    data
  });
};

// 转账
export const accountTransfer = (data) => {
  return request({
    method: "POST",
    url: `/app/account/create-account-transfer`,
    data
  });
};

// 交易记录
export const accountTransactionsList = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/get-transactions`,
    data
  });
};


export const accountTransactionsLists = (data) => {
  return request({
    method: "POST",
    url: `/app/account/search-account-history`,
    data
  });
};

// 手续费
export const accountFee = (data) => {
  return request({
    method: "POST",
    url: `/app/account/get-free-limit-status-for-fee`,
    data
  });
};

// 一天最多的转账次数
export const accountTransferLimit = (data) => {
  return request({
    method: "POST",
    url: `/app/account/get-limit-by-code`,
    data
  });
};

// 验证交易密码
export const verify = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/verify-transaction-pin`,
    data
  });
};
// 最近联系人
export const Recently = (data) => {
  return request({
    method: "GET",
    url: `/app/user/get-recent-transfer-user`,
    data
  });
};

// 新的订单列表
export const getRecentAccountHistoryList = (data) => {
  return request({
    method: "POST",
    url: `/app/account/get-recent-account-history-list`,
    data
  })
}

// 全部订单列表
export const getAllAccountHistoryList = (data) => {
  return request({
    method: "POST",
    url: `/app/account/get-all-account-history-list`,
    data
  })
}

// 新的订单列表
export const getInstantCreditLimit = () => {
  return request({
    method: 'GET',
    url: `/app/account/get-instant-credit-limit`
  })
}

export const getBindRequestList = (data) => {
  return request({
    method: 'POST',
    url: `/app/account/get-bind-request-list`,
    data
  })
}

export const submitBindBankAccountRequest = (data) => {
  return request({
    method: 'POST',
    url: `/app/account/submit-bind-bank-account-request`,
    data
  })
}

export const verifyMicroCredit = (data) => {
  return request({
    method: 'POST',
    url: `/app/account/verify-micro-credit`,
    data
  })
}

export const deleteBindRequest = (data) => {
  return request({
    method: 'POST',
    url: `/app/account/delete-bind-request`,
    data
  })
}

// 获取站内信列表
export const getNotifyList = (data) => {
  return request({
    method: 'POST',
    url: `/app/messages/get-all-notify-msgs`,
    data
  })
}

// 站内信全部已读
export const readAllNotify = (data) => {
  return request({
    method: 'POST',
    url: `/app/messages/one-click-read`,
    data
  })
}

// 获取特定类型站内信列表，同时标记已读
export const getTypeNotifyList = (data) => {
  return request({
    method: 'POST',
    url: `/app/messages/get-notify-msg-by-category`,
    data
  })
}

// 根据用户id 校验是否为第一次转账
export const firstAccountTransfer = (data) => {
  return request({
    method: "POST",
    url: `/app/account/check-first-account-transfer`,
    data
  });
};

// 多人转账
export const accountMultTransfer = (data) => {
  return request({
    method: "POST",
    url: `/app/account/create-c2c-multi-person-transfer`,
    data
  });
};