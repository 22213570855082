import request from "@utils/request";

// 当前公共请求地址前缀
// const url_prefix = "/api";

export const reqSendCode = ({
  countryCode, 
  mobile,
  language
}) => {
  return request({
    method: "POST",
    url: `/app/auth/request-otp`,
    data: {
      countryCode,
      mobile,
      language
    },
  });
};

export const reqLogin = (countryCode, mobile, otp) => {
  return request({
    method: "POST",
    url: `/app/auth/login`,
    data: {
      countryCode,
      mobile,
      otp
    },
  });
};

export const reqLogout = () => {
  return request({
    method: "POST",
    url: "/app/auth/logout",
  })
}

// 获取当前用户验证相关信息
export const getVerifyStatus = (countryCode, mobile, verifyCode) => {
  return request({
    method: "POST",
    url: `/get-verify-status`,
    data: {
      countryCode,
      mobile,
      verifyCode
    },
  });
};

export const getCountryList = () => {
  return request({
    method: "GET",
    url: `/app/static/country-list`,
  });
};

export const getPolicyByCode = (code) => {
  return request({
    method: 'GET',
    url: `app/static/get-policy-by-code/${code}`,
  })
}

// user/get-verify-status

// export const reqLoginUser = (phone, password) => {
//   return request({
//     method: "POST",
//     url: `${url_prefix}/user`,
//     data: {
//       phone,
//       password,
//     },
//   });
// };

// export const reqVerifyToken = () => {
//   return request({
//     method: "POST",
//     url: `${url_prefix}/verify`,
//   });
// };
