// import {
//   // reqLoginUser,
//   // reqVerifyToken,
//   // reqSendCode,
//   // reqLogin
// } from "@api/login";
import {
  reqSendCode
} from '@api/login'
import {
  AUTH_SUCCESS,
  ERROR_MSG,
  RESET_USER,
  SET_APP_GUIDE_STATUS
} from "./actionTypes";


// 授权成功的同步action
const authSuccess = (user) => ({
  type: AUTH_SUCCESS,
  data: user
})
// 错误提示信息的同步action
const errorMsg = (msg) => ({
  type: ERROR_MSG,
  data: msg
})

// 重置用户的同步action
export const resetUser = (msg) => ({
  type: RESET_USER,
  data: msg
})


// 异步action
export const getUser = (phone, password) => {
  return async (dispatch) => {
    // 执行异步操作 - 登录
    // const result = await reqLoginUser(phone, password);
    // 调用dispatch方法触发redux更新
    // const action = getUserSuccess(result.user);
    // dispatch(action);
    // return result.user.token;
  };
};

// 异步action
export const verifyToken = () => {
  // return async (dispatch) => {
  //   try {
  //     const user = await reqVerifyToken();
  //     const action = getUserSuccess(user);
  //     dispatch(action);
  //   } catch (e) {
  //     return e;
  //   }
  // };
};


// // 登陆异步action
// export const login = (user) => {

//   const {username, password} = user
//   // 做表单的前台检查, 如果不通过, 返回一个errorMsg的同步action
//   if(!username) {
//     return errorMsg('用户名必须指定!')
//   } else if(!password) {
//     return errorMsg('密码必须指定!')
//   }

//   return async dispatch => {
//     // 发送注册的异步ajax请求
//     /*const promise = reqLogin(user)
//     promise.then(response => {
//       const result = response.data  // {code: 0/1, data: user, msg: ''}
//     })*/
//     const response = await reqLogin(user)
//     const result = response.data
//     if(result.code===0) {// 成功
//       console.log(222)
//       // getMsgList(dispatch, result.data._id)
//       // 分发授权成功的同步action
//       // dispatch(authSuccess(result.data))
//     } else { // 失败
//       // 分发错误提示信息的同步action
//       dispatch(errorMsg(result.msg))
//     }
//   }
// }


// 获取验证码action
export const getCode = (user) => {
  const {
    mobile,
  } = user

  // 做表单的前台检查, 如果不通过, 返回一个errorMsg的同步action
  if (!mobile) {
    return errorMsg('请输入手机号!')
  }
  return async dispatch => {
    // 发送获取验证码的异步ajax请求
    const response = await reqSendCode(user)
    // console.log('000000000')
    // console.log(response)
    // debugger
    if (response.statusCode === "200") { // 成功
      // 分发授权成功的同步action
      dispatch(authSuccess("发送验证码成功"))
    } else { // 失败
      // 分发错误提示信息的同步action
      dispatch(errorMsg("发送验证码失败"))
    }
  }
}

// 登陆异步action
export const login = (user) => {
  console.log(333333)
  console.log(user)
  const {
    mobile
  } = user

  // 做表单的前台检查, 如果不通过, 返回一个errorMsg的同步action
  if (!mobile) {
    return errorMsg('请输入手机号!')
  }

  return async dispatch => {
    // 发送注册的异步ajax请求
    // const promise = reqLogin(user)
    // promise.then(response => {
    //   const result = response.data // {code: 0/1, data: user, msg: ''}
    // })
    console.log({
      ...user
    })
    const response = await reqSendCode(user)
    console.log(response)
    // const result = response.data
    if (response.statusCode === 200) { // 成功
      // getMsgList(dispatch, result.data._id)
      // 分发授权成功的同步action
      dispatch(authSuccess("发送验证码成功"))
    } else { // 失败
      // 分发错误提示信息的同步action
      dispatch(errorMsg("发送验证码失败"))
    }
  }
}

export const setAppGuideStatus = (status) => {
  return {
    type: SET_APP_GUIDE_STATUS,
    status
  }
}