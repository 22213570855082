import React, { useEffect, useState, useRef } from 'react'
import './index.less'
import { verifyTransactionPin, getCardImage } from '@api/user'
import { NumberKeyboard, PasscodeInput, Toast, Mask } from 'antd-mobile'
import { useTranslation, Trans } from 'react-i18next'
import ReactLoading from "react-loading"

export default function VerifyTranPwd(props) {
  const [error, setError] = useState(false)
  const {
    openFreezePasswordPop, forgetPassword, setPasswordActionToken, actionType
  } = props
  const [failedTimes, setFailedTimes] = useState(0)
  const [remainderTimes, setRemainderTimes] = useState(0)
  const [isLoadingShow, setIsLoadingShow] = useState(false)
  const passcodeInputRef = useRef()
  const { t } = useTranslation()
  const [password, setPassword] = useState('')

  useEffect(() => {
    passcodeInputRef.current.focus()
  }, [])

  const onPasswordChange = async (code) => {
    if(code.length === 6) {
      setPassword(code)
      passcodeInputRef.current.blur()
      let actionCode = ''
      switch (actionType) {
        case 'giftCard': 
          actionCode = 'Payment_VerifyCurrentPin'
          break;
        case 'checkSavingAccount': 
          actionCode = 'DisplaySavingsAccount_VerifyCurrentPin'
          break;
        case 'savingAccountTransferIn': 
          actionCode = 'SavingsAccountDeposit_VerifyCurrentPin'
          break;
        case 'savingAccountTransferOut': 
          actionCode = 'SavingsAccountWithdraw_VerifyCurrentPin'
          break;
        case 'cashBackRedeem': 
          actionCode = 'CashbackRedeemCash_VerifyCurrentPin'
          break;
        default:
          actionCode = 'DisplayCardImage_VerifyCurrentPin'
          break;
      }
      setIsLoadingShow(true)
      const { status_code, response_data: { token, total_limit, failed_times } } = await verifyTransactionPin({
        actionCode, transactionPin: code
      })
      setIsLoadingShow(false)
      switch (status_code) {
        case 100000:
          if (setPasswordActionToken) {
            setPasswordActionToken(token)
          }
          Toast.show({
            content: t('home.pinVerifiedSuccess'),
          })       
          setTimeout(() => {
            props.closeVerifyTranPwd({token})
          }, 1000)
          if (props.getCardImage && actionCode === 'DisplayCardImage_VerifyCurrentPin') {
            const { response_data } = await getCardImage('DisplayCardImage_DisplayImage', token)
            props.getCardImage(response_data)
          }
          break;
        case 301025:
          setError(true)
          setFailedTimes(failed_times)
          setRemainderTimes(total_limit - failed_times)
          break;
        case 301016:
          openFreezePasswordPop()
          setError(true)
          break;
        default:
          setError(true)
          break;
      }
    } else if (code.length === 5) {
      setPassword(error ? '' : code)
      setError(false)
    } else {
      setPassword(code)
      setError(false)
    }
  }

  return (
      <div className='password-dialog'>
        <div className="password-title"> {t("common.Verifypassword")}</div>
        <div className="password-tip">
          <p>{t("transfer.securityaccount")}</p>
          <p>{t("transfer.Pleaseenterinformation")}</p>
        </div>
        {error && <div className='xss'>
                  <Trans i18nKey={t(`password.passwordIncorrect`)}  values={{ failedTimes, remainderTimes }}></Trans>
                    </div>}
        <div className="edit-box d-flex align-items-center justify-content-center">
          <div className='verification-code'>
            <PasscodeInput 
              ref={passcodeInputRef}
              value={password}
              seperated 
              onChange={onPasswordChange}
              keyboard={<NumberKeyboard randomOrder={true}/>} 
              error={error} 
              style={{
              '--cell-size': '32px',
              '--cell-gap': '8px',
            }}/>
          </div>
        </div>
        <div className="forget-pwd-tip" onClick={forgetPassword}>
          {t("transfer.Forgotpassword")}
        </div>
        <Mask visible={isLoadingShow}>
          <ReactLoading className='loading' type={"spinningBubbles"} color={"#fff"}/>
        </Mask>
      </div>
    )
}
