import React, { useState, useEffect } from "react"
import { NavBar, PullToRefresh, List, Badge, Popup, Toast } from "antd-mobile"
import "./index.less"
import { useHistory } from "react-router-dom"
import tillstarLoading from "@assets/images/common/tillstar_loading.gif"
import clearAll from "@assets/images/notify/icon_notice_clear.png"
import notice1 from "@assets/images/notify/icon_notice_email.png"
import notice2 from "@assets/images/notify/icon_notice_activity.png"
import notice3 from "@assets/images/notify/icon_notice_bonus.png"
import notice4 from "@assets/images/notify/icon_notice_sales.png"
import notice5 from "@assets/images/notify/icon_notice_fengkong.png"
import { transferTime } from "@utils"
import { getNotifyList, readAllNotify } from "@api/account"

export default function Messages() {
  const history = useHistory()
  const [clearConfirm, setClearConfirm] = useState(false)
  const [readLoading, setReadLoading] = useState(false)
  const [list, setList] = useState([])
  const [msgCount, setNums] = useState(0)

  const back = () => {
    history.goBack()
  }

  const initData = async (type) => {
    const res = await getNotifyList({})
    if (res.status_code !== 100000) return

    const { category } = res.response_data
    let nums = 0
    category.forEach((item) => {
      nums += item.notify_num
    })
    setNums(nums)
    setList(category)
  }

  const itemClick = (item) => {
    if (item.id) {
      history.push(`/messages/list?type=${item.id}`)
    }
  }

  // 未读消息标记已读
  const clearAllMsg = async () => {
    // 如果还在loading, 则不在执行
    if (readLoading) {
      return
    }

    setReadLoading(true)
    await readAllNotify({})
    setClearConfirm(false)
    initData()
  }

  useEffect(() => {
    initData()
  }, [])

  const clearAllPopShow = () => {
    // 如果没有未读消息，则提示
    if (msgCount === 0) {
      Toast.show({
        content: "当前无未读消息",
        duration: 2000,
      })
      return
    }

    setClearConfirm(true)
  }

  const getImg = (type = 1) => {
    let imgs
    if (type === 1) imgs = notice1

    if (type === 2) imgs = notice2

    if (type === 3) imgs = notice3

    if (type === 4) imgs = notice4

    if (type === 5) imgs = notice5
    return imgs || notice1
  }

  const getText = (type) => {
    let text
    if (type === 1) text = "系统通知"

    if (type === 2) text = "动账通知"

    if (type === 3) text = "奖励通知"

    if (type === 4) text = "活动通知"

    if (type === 5) text = "风控通知"
    return text || ""
  }

  return (
    <div className="message-Ppge">
      <Popup
        visible={clearConfirm}
        onMaskClick={() => {
          setClearConfirm(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <div className="popContent">
          <div className="title">将所有消息已读？</div>
          <div className="clickRead" onClick={clearAllMsg}>
            标记为已读
          </div>
          <div className="blockCenter"></div>
          <div className="clickCancel" onClick={() => setClearConfirm(false)}>
            取消
          </div>
        </div>
      </Popup>

      <div className="nav-bar">
        <NavBar onBack={back}>
          <div className="titleBar" onClick={() => false}>
            <span className="title">消息中心</span>
            <img onClick={() => clearAllPopShow()} className="clearImg" src={clearAll} alt="" />
          </div>
        </NavBar>
      </div>

      <div>
        <PullToRefresh
          onRefresh={() => initData("pull")}
          renderText={() => (
            <div>
              <img className="tillstar-loading-icon" src={tillstarLoading} alt="" />
            </div>
          )}
        >
          <div className="message-list">
            <List style={{ "--border-top": 0, "--border-bottom": 0, "--border-inner": 0 }}>
              {list.map((item, index) => {
                return (
                  <List.Item
                    prefix={
                      <span className="message-icon">
                        <Badge content={item.notify_num > 99 ? "99+" : item.notify_num} style={{ "--right": 0 }}>
                          <img src={getImg(item.id)} alt="" />
                        </Badge>
                      </span>
                    }
                    onClick={() => itemClick(item)}
                    clickable={false}
                    key={item.id}
                  >
                    <div className="message-item">
                      <div className="message-item-line">
                        <span className="message-title">{getText(item.id)}</span>
                        <span className="message-time">{item.latest_msg?.notify_at ? transferTime(item.latest_msg?.notify_at) : ""}</span>
                      </div>
                      <div className="message-desc">{item.latest_msg?.subject || "暂无通知"}</div>
                    </div>
                  </List.Item>
                )
              })}
            </List>
          </div>
        </PullToRefresh>
      </div>
    </div>
  )
}
