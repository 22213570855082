import React from 'react'
import { Button } from 'antd-mobile'
import { InformationCircleOutline } from 'antd-mobile-icons'
import './index.less'

export default function StepFooter(props) {
    const { notice, disabled, btnSubmit, btnText, licenseInfoBottom, isLoading } = props

    return (
        <div id="page-footer" className='page-footer'>
            {notice && <p className="notice">
                <InformationCircleOutline className="icon" fontSize={14} color="#F9941E" />
                <span dangerouslySetInnerHTML={{ __html: notice }}></span>
            </p>}
            <Button
                block5
                className='next-button'
                shape='rounded'
                color='danger'
                disabled={disabled}
                onClick={btnSubmit}
                loading={isLoading}
                type='submit'
            >
                {btnText}
            </Button>
            {/* <Button block  color='primary' size='large'>
            提交
          </Button> */}
            <p>
                {licenseInfoBottom}
            </p>
        </div>
    )
}
