import React from 'react'
import './index.less'
import { CloseOutline, CloseCircleFill } from 'antd-mobile-icons'
import { GetQueryString } from "@utils/utils"
import { t } from 'i18next'

function PlaidFail(props) {
  const goBack = () => {
    window.history.back(-1)
  }
  const relateOthers = () => {
    props.history.replace(`/relateStart`)
  }
  return (
    <main className="plaidFail">
      <CloseOutline className="closeIcon" fontSize={14} onClick={goBack}/>
      <CloseCircleFill fontSize={80} color="#000"/>
      <p className="p1">{t('plaid.fail.linkAccountFailed')}</p>
      <p className="p2">{t('plaid.fail.linkAccountFailedFrozen')}（{GetQueryString('msg')}）</p>
      <div className="button buttonRed" onClick={relateOthers}>{t('plaid.fail.linkAccountFailedOthers')}</div>
      <div className="button buttonWhite" onClick={goBack}>{t('plaid.fail.linkAccountFailedBack')}</div>
    </main>
  )
}

export default PlaidFail