import React, { useState, useEffect } from 'react'
import MyHeader from '@comps/MyHeader'
import MyTabbar from '@comps/MyTabbar'
import { Toast, Button, Loading } from 'antd-mobile'
import DeleteIcon from '@assets/images/home/icon_delete@3x.png'
import { accountFee } from '@api/account'
import { getBalance, getPermissionByCode } from '@api/user'
import Tip from '@comps/Tip'
import qs from 'qs'
import './index.less'
import { formatNumberToUSD, formatNum } from "@utils"
import { setPasswordRelatedChannel } from '@actions/password'
import { setAmount, setTransferOrigin } from '@actions/transfer'
import { setLoginFromPage } from '@actions/common'
import { t } from 'i18next'
import { handleDot, scrollToTop } from '@utils/utils'
import StopTakingNotice from "@comps/StopTakingNotice"
import { getModuleStatus } from "@api/common"
import loadable from '@loadable/component'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const CommonTipPop = loadable(() => import('@comps/CommonTipPop'))

const MIN_AMOUNT = 0.01
const MAX_AMOUNT = 100000.00
const MAX_INPUT_AMOUNT = 1000000.00
const listData = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'X']

export default function Account(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userInfo = useSelector(state => state.user.userInfo)
  const amount = useSelector(state => state.transfer.amount)
  // const showStopTakingPop = useSelector(state => state.common.showStopTakingPop)
  const [transferAmount, setTransferAmount] = useState('0')
  const [suffix, setSuffix] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isBuer, setIsBuer] = useState(false)
  const [visible, setVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [stopTakingPop, setStopTakingPop] = useState(false)
  const [transferFeeInfo, setTransferFeeInfo] = useState({})

  const { identity_verification_status, identity_verification_request_status } = userInfo
  const tipShow = identity_verification_status === 4 && [5, 10].includes(identity_verification_request_status)

  const getAccountFee = async () => {
    try {
      const feeRes = await accountFee({ feeCode: 'card_to_card' }) //获取手续费
      if (feeRes.status_code === 100000) {
        setTransferFeeInfo(feeRes?.response_data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    scrollToTop()
    if (location.from === '/login' && amount) {
      setTransferAmount(amount)
      setIsDisabled(false)
    }
    const { amount: lAmount } = qs.parse(location.search.slice(1))
    if(lAmount) {
      setTransferAmount(formatNum(lAmount))
      setIsDisabled(false)
    }
    userInfo && userInfo.user_id && getAccountFee()
  }, [])

  const setNumberHandle = (inputVal) => {
    const instring = inputVal
    let prvcontent = transferAmount.toString()
    let content = ''

    if (instring === 'X') {
      if (prvcontent === '0.00' || prvcontent.length === 1) {
        setTransferAmount('0')
        setSuffix('')
        setIsDisabled(true)
        return
      }

      if (prvcontent[prvcontent.length - 1] === ' ' && prvcontent[prvcontent.length - 3] === ' ') {
        prvcontent = prvcontent.slice(0, prvcontent.length - 3)
      } else {
        prvcontent = prvcontent.slice(0, prvcontent.length - 1)
      }
      content = prvcontent
    } else if (instring === '.') {
      if (!prvcontent || prvcontent === '0.00') {
        content = 0 + instring
      } else if (prvcontent.indexOf('.') === -1) {
        content = prvcontent + instring
      } else {
        return
      }
    } else {
      content = prvcontent === '0.00' ? content + instring : prvcontent + instring
      if (parseFloat(content) >= MAX_INPUT_AMOUNT || (content.split('.')[1] && content.split('.')[1].length > 2)) {
        return
      }
    }
    if (Number(content.split('')[0]) === 0) {
      if (Number(content.split('')[1]) === 0) return
      content.split('')[1] > 0 && (content = content.slice(1))
    }
    const curAmount = content.includes('.') ? formatNum(content) : content
    const num = parseFloat(content)
    setTransferAmount(content)
    setSuffix(new Array(curAmount.toString().length - content.length).fill('0').join(''))
    setIsDisabled(!(content.length > 0 && num > 0 && num < MAX_INPUT_AMOUNT))
  }

  // 收款
  const collectMoney = async () => {
    // 停服维护
    let result = await getModuleStatus('module_c2c_transfer');
    if (result.status_code === 300012) return  
    
    if (Object.keys(userInfo).length === 0) {
      dispatch(
        setLoginFromPage({
          pathname: "/account",
          search: ""
        })
      )

      history.push({
        pathname: '/login',
        from: '/account'
      })
      return
    }
    Toast.show({
      content: t('common.comingSoon'),
      duration: 1000,
    })
  }

  // 关闭弹窗
  const handleCloseTip = () => {
    setIsBuer(false)
  }

  // 转账
  const transferAccount = async () => {
    if (parseFloat(transferAmount) < MIN_AMOUNT) {
      Toast.show({
        content: t('transfer.minTip', {
          min: MIN_AMOUNT
        }),
        duration: 2000,
      })
      return
    }

    if (parseFloat(transferAmount) > MAX_AMOUNT) {
      Toast.show({
        content: t('transfer.maxTip', {
          max: MAX_AMOUNT
        }),
        duration: 2000,
      })
      return
    }
    if (isDisabled || submitting) {
      return
    }

    if (Object.keys(userInfo).length === 0) {
      dispatch(
        setLoginFromPage({
          pathname: "/account",
          search: ""
        })
      )
      dispatch(setAmount(transferAmount))
      history.push({
        pathname: '/login',
        from: '/account'
      })
      return
    }
    // 停服维护
    setSubmitting(true)
    const [result, res] = await Promise.all([getModuleStatus('module_c2c_transfer'), getPermissionByCode({ permissionCode: 'permission_c2c_transfer'})])
    if (result.status_code === 300012) return
    setSubmitting(false)

    const { 
      default_financial_account,
      active_financial_accounts,
      is_transaction_pin_set
    } = userInfo

    if (default_financial_account?.status === 2) { //账号已冻结
      Toast.show({
        content: t('common.freeze_tip'),
        duration: 2000,
      })
      return
    }

    if (identity_verification_status === 3 || (identity_verification_status === 4 && active_financial_accounts === 0 && [4, 9].includes(identity_verification_request_status))) { //激活成功,开卡失败
      Toast.show({
        content: t('transfer.accountExceptionErr'),
        duration: 1000,
      })
      return
    }

    if ([0, 1].includes(identity_verification_status)) { //激活弹框
      setIsBuer(true)
      return
    }

    if (identity_verification_status === 2) { //账号激活中,耐心等待
      Toast.show({
        content: t('transfer.accountInOpeningErr'),
        duration: 2000,
      })
      return
    }

    // 是否有转账权限
    if (res.status_code === 300008) return
    
    
    if (identity_verification_status === 4) {
      //获取账户余额 
      setSubmitting(true)
      const balanceRes = await getBalance() 
      setSubmitting(false)
      if (balanceRes.status_code === 100000) {
        const balance = parseFloat(balanceRes?.response_data?.balance)
        const poundage = parseFloat(transferFeeInfo.fee?.amount)
        const currentAmount = parseFloat(transferAmount)
        const totalAmount = transferFeeInfo.freeLimitAvailable > 0 ? currentAmount : currentAmount + poundage
        if (balance < totalAmount) { //余额不足
          setVisible(true)
          return 
        }
      }
      
      if (is_transaction_pin_set) { //是否设置密码
        setTimeout(() => {
          dispatch(setTransferOrigin('/account'))
          history.push({
            pathname: `/transferAccounts`,
            search: qs.stringify({ amount: handleDot(transferAmount) })
          })
        }, 500)
      } else {
        dispatch(setPasswordRelatedChannel({
          channel: 'transfer',
          type: 'set',
          url: '/account',
          backUrl: '/account',
        }))
        history.push('/verifyCode')
      }
    }
  }

  const toRecharge = () => {
    history.push(`/recharge`)
  }


  return (
    <div id="account-page">

      {/* 停服公告 */}
      <div className='topNotice'>
        <StopTakingNotice stopTakingPop={stopTakingPop} 
          setStopTakingPop={setStopTakingPop} 
          moduleCode="module_c2c_transfer"
        />
      </div>

      <MyHeader tipShow={tipShow} titleShow={false} />
      <div className="input trackContainer">
        <span>{`$${formatNumberToUSD(transferAmount)}`}</span>
        <span className="grayNumber">{suffix}</span>
        <div className='trackReturn' hidden>输入的金额</div>
      </div>
      <ul className="calculator">
        {listData.map((item, index) => {
          return (
            <li key={index} onClick={(e) => setNumberHandle(item, e)}>
              {index === 11 ? <img src={DeleteIcon} alt="" /> : <span>{item}</span>}
            </li>
          )
        })}
      </ul>

      <div className="btn-wrapper d-flex align-items-center justify-content-between">
        <div
          className={[
            'd-flex align-items-center justify-content-center add-btn trackContainer',
            isDisabled || submitting ? '' : 'add-btn1',
          ].join(' ')}
          onClick={collectMoney}
        >
            {t('transfer.collections')}
            <div className='trackReturn' hidden>收款</div>
        </div>
        
        <div
          className={[
            'd-flex align-items-center justify-content-center cash-btn trackContainer',
            isDisabled || submitting ? '' : 'cash-btn1',
          ].join(' ')}
          onClick={transferAccount}
        >
            {
            submitting ? 
            <Loading color="#fff" /> 
            : t('transfer.transfers')
            }
            <div className='trackReturn' hidden>转款</div>
        </div>
      </div>
      {isBuer && <Tip onClose={handleCloseTip} verifyStatus={identity_verification_status} />}
      <MyTabbar/>
      <CommonTipPop isVisible={visible} setIsVisible={setVisible} content={
        <>
          <div className='title'>{t('common_pop.transfer_limit.title')}</div>
          <div className='subtitle' dangerouslySetInnerHTML={{__html: t('common_pop.transfer_limit.sub')}}/>
          <Button className='l-btn' onClick={()=>setVisible(false)}>{t('common_pop.transfer_limit.l_btn')}</Button>
          <Button className='r-btn' onClick={toRecharge}>{t('common_pop.transfer_limit.r_btn')}</Button>
        </>
      }></CommonTipPop>
    </div>
  )
}


