import React, { Component } from 'react'
import { Button, NavBar, Toast, NumberKeyboard, PasscodeInput } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { withRouter } from 'react-router-dom'
import { setTradePassword } from '@api/user'
import { scrollToTop } from "@utils/utils"
import ExceptionPop from '@comps/ExceptionPop'
import './index.less'
import { connect } from 'react-redux'
import { setActionToken } from '@actions/password'
import { t } from 'i18next'

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
    actionToken: store.password.actionToken,
  }
}

const mapDispatchToProps = {
  setActionToken,
}
class SettingPasswordB extends Component {
  state = {
    title: '设置密码',
    isSubmit: false,
    isExceptionPopVisible: false,
    isSubmitLoading: false,
    password: ''
  }
  passcodeInputRef = React.createRef()

  componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.passcodeInputRef?.current.focus()
  }
  submitPassword = async () => {
    const { actionToken } = this.props
    const { password } = this.state
      this.setState({
        isSubmitLoading: true
      })
      const res = await setTradePassword('UpdateTransactionPin_SetNewPin', actionToken, password)
      this.setState({
        isSubmitLoading: false
      })
      switch (res.status_code) {
        case 100000: 
          this.setState({
            isSubmit: false
          })
          if (res.response_data) {
            this.props.setActionToken(res.response_data.token)
          }
          this.props.history.push('/settingPasswordC')
          break;
        // case 300001: 
        //   this.passcodeInputRef.current.focus()
        //   Toast.show({
        //     content: '请避免使用重复/连续数字作为密码哦',
        //   })
        //   break;
        // case 300002: 
        //   this.passcodeInputRef.current.focus()
        //   Toast.show({
        //     content: '请避免使用重复/连续数字作为密码哦',
        //   })
        //   break;
        case 301006: 
          this.setState({
            isExceptionPopVisible: true,
          })
          break;
        case 301015: 
          this.passcodeInputRef?.current.focus()
          this.setState({
            error: true
          })
          Toast.show({
            content: '请避免使用重复/连续数字作为密码哦',
          })
          break;
        default:
          this.setState({
            error: true
          })
          Toast.show({
            content: '请避免使用重复/连续数字作为密码哦',
          })
          break;
      }


  }
  goBack = () => {
    const sess = sessionStorage.getItem("recognition")
    const returnLink = this.props.passwordRelatedChannel.url

    if (sess && !['/management', '/associatedBank'].includes(returnLink)) {
      this.props.history.replace({
        pathname: `/activeStatus`
      })
    } else if (returnLink) {
      if (returnLink === '/mine') {
        this.props.history.push({ pathname: '/mine', state: { router: "setting" } })
      } else if (returnLink === '/associatedBank') {
        this.props.history.push(this.props.passwordRelatedChannel.backUrl)
      } else {
        returnLink === '/recordList' && this.props.setActionToken(null)
        this.props.history.push(returnLink)
      }
    } else {
      this.props.history.push('/setting')
    }
  }

  onPasswordChange = (code) => {
    if (code.length === 6) {
      this.passcodeInputRef?.current.blur()
      this.setState({
        isSubmit: true,
        password: code
      })
    } else if (code.length === 5) {
      this.setState({
        password: this.state.error ? '' : code,
        error: false,
        isSubmit: false
      })
    } else {
      this.setState({
        password: code,
        error: false,
        isSubmit: false
      })
    }
  }
  render() {
    const { passwordRelatedChannel } = this.props
    const { error, password, isSubmit, isExceptionPopVisible, isSubmitLoading} = this.state

    return (
      <div id="setting-password" className='setting-password-b-page'>
        <section className="setting-password-b d-flex flex-column align-items-center">
          <div className="header">
            <NavBar
              style={{
                '--height': '36px',
                '--background': '#ffffff',
              }}
              back={null}
              right={<CloseOutline onClick={this.goBack} fontSize={24}/>}
            >
              {passwordRelatedChannel.channel === 'normalSetting' && t("setPassword.Setpassword")}
            </NavBar>
          </div>
          <div className="title-warp-password-b">
            {passwordRelatedChannel.channel && passwordRelatedChannel.channel !== 'normalSetting' && <p className="special-tip">{t(`password.set_title.${passwordRelatedChannel.channel}`)}</p>}
            <p className="title-warp-password-b-two">
              {t("setPassword.Settransactionpin")}
            </p>
            <span style={{ color: "#999999" }}> 
              {t("setPassword.paymentsandsecurity")}
            </span>
          </div>
          <div className='verification-code'>
            <PasscodeInput
              ref={this.passcodeInputRef}
              length={6}
              value={password}
              seperated
              keyboard={<NumberKeyboard randomOrder={true}/>}
              onChange={this.onPasswordChange}
              error={error}
              style={{
              '--cell-gap': '12px',
            }}/>
          </div>
          <div className="varification-tips-b">
            <div 
              className="setting-password-b-times"
              dangerouslySetInnerHTML={{__html: t('setPassword.repeatedorconsecutive')}}
            >
            </div>
          </div>
          <div className="btn-container">
            <Button
              type="warning"
              loading={isSubmitLoading}
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center']}
              onClick={this.submitPassword}
              disabled={!isSubmit}
            >
              {t("setPassword.determine")}
            </Button>
          </div>
        </section>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingPasswordB))
