import { Collapse, ErrorBlock, Image } from 'antd-mobile';
import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {  NavBar } from 'antd-mobile'
import { scrollToTop } from '@utils/utils'

import './index.less'

export default function Detail() {
  const history = useHistory();
  const search = new URLSearchParams(history.location.search)
  const faq = useSelector(state => state.faq)
  const faqBanner = useSelector(state => state.faq.faq_banner)
  const {t} = useTranslation();
    
  useEffect(() => {
    scrollToTop()
  }, [])
  
  if(faq[search?.get('type')] === undefined || search?.get('index') === undefined ) {
    return <ErrorBlock status='empty' />
  }
  const detail = faq[search?.get('type')][search?.get('index') - 1]
  

  if(detail === undefined) {
    return <ErrorBlock status='empty' />
  }
  const type = search?.get('type')
  const currentItemIndex = search?.get('index')

  const TutorialsDetail = () =>{
    return (
      <div className='tutorials-detail'>

      </div>
    )
  }
  
  const QaDetail = () => {
    const itemClicked = (item) => {
      if([2,3].includes(parseInt(currentItemIndex)) && item === 2) {
        history.push('/tutorials?key=rmbPayment')
      } else if(parseInt(currentItemIndex) === 1 && item === 2) {
        history.push('/tutorials?key=VIPLevel')
      } else if(parseInt(currentItemIndex) === 5 && item === 3) {
        history.push('/tutorials?key=buyCard')
      } else if (parseInt(currentItemIndex) === 6) {
        history.push('/tutorials?key=refundRules')
      } else {
        history.push('/tutorials?key=linkApplePay')
      }
    }
    return (
      <div className='qa-detail'>
        <NavBar onBack={()=>history.goBack(1)}>FAQ</NavBar>
        <div className='qa-list-item-top'>
          <img src={faqBanner} alt=''  className='faq-banner'/>
          <div className='item-title'>
              <Image src={detail.icon} width={18} height={18} fit='contain' />{t(`faq.${type}.title.${currentItemIndex-1}`)}
          </div>
        </div>
        <Collapse accordion className='qa-list-item-content'>
          {
            detail.content.map((item, index)=>{
              return <Collapse.Panel key={index} title={t(`faq.${type}.${currentItemIndex}.${item}.title`)}>
                {/* {} */}
                <span dangerouslySetInnerHTML={{__html:t(`faq.${type}.${currentItemIndex}.${item}.des`)}}></span>
                 {
                   ((parseInt(currentItemIndex) === 1 && item === 2) || (parseInt(currentItemIndex) === 2 && (item === 2 || item === 3)) || (parseInt(currentItemIndex) === 3 && item === 2) || (parseInt(currentItemIndex) === 5 && item === 3) ) && <span className='nav-text' onClick={()=>itemClicked(item)}>{t(`faq.${type}.${currentItemIndex}.${item}.nav_text`)}</span>
                 }
                 {
                   (parseInt(currentItemIndex) === 2 && item === 3) && <span style={{'cursor': 'pointer'}} dangerouslySetInnerHTML={{__html:t(`faq.${type}.${currentItemIndex}.${item}.des_tips`)}}></span>
                 }
              </Collapse.Panel>
            })
          }
        </Collapse>
      </div>
    )
  }

  return (
    <div>
      {type === 'qa'
      ? <QaDetail t={t}/>
      : <TutorialsDetail />
      }
    </div>
  )
}
