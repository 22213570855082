import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { TabBar } from "antd-mobile"
import "./index.less"
import home1 from "@assets/images/tabbar/home.png"
import home2 from "@assets/images/tabbar/active-home.png"
import price1 from "@assets/images/tabbar/price.png"
import price2 from "@assets/images/tabbar/active-price.png"
import discovery1 from "@assets/images/tabbar/discovery.png"
import discovery2 from "@assets/images/tabbar/active-discovery.png"
import record1 from "@assets/images/tabbar/record.png"
import record2 from "@assets/images/tabbar/active-record.png"
import recordBadge from "@assets/images/tabbar/record_badge.png"
import { useDispatch, useSelector } from "react-redux"
import { getTabBarBadge } from "@actions/tabbar"
import { t } from "i18next"

export default function Bottom() {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const tabbar = useSelector((state) => state.tabbar.tabbar)
  const userInfo = useSelector((state) => state.user.userInfo)
  const tabbarStatus = useSelector((state) => state.tabbar.tabbar_status)
  const dispatch = useDispatch()

  const setRouteActive = (value) => {
    history.push(value)
    if (value === "/recordList") {
      if (Object.keys(userInfo).length) dispatch(getTabBarBadge())
    }
  }

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      dispatch(getTabBarBadge())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(userInfo).length && tabbarStatus) {
      dispatch(getTabBarBadge())
    }
  }, [tabbarStatus])

  const tabs = [
    {
      key: "/",
      title: t('general.navHome'),
      icon: (active) => (
        <div className="trackContainer">
          <img
            src={active ? home2 : home1}
            style={{ width: "22px", height: "22px", maxWidth: "32px" }}
            alt=""
          />
          <div className="trackReturn" hidden>
            首页
          </div>
        </div>
      ),
    },
    {
      key: "/account",
      title: t('general.navTransfer'),
      icon: (active) => (
        <div className="trackContainer">
          <img
            src={active ? price2 : price1}
            style={{ width: "22px", height: "22px", maxWidth: "32px" }}
            alt=""
          />
          <div className="trackReturn" hidden>
            转款
          </div>
        </div>
      ),
    },
    // 隐藏礼卡tab页面展示
    // {
    //   key: "/giftCard",
    //   title: t('general.navGiftCard'),
    //   icon: (active) => (
    //     <div className="trackContainer">
    //       <img
    //         src={active ? discovery2 : discovery1}
    //         style={{ width: "22px", height: "22px", maxWidth: "32px" }}
    //         alt=""
    //       />
    //       <div className="trackReturn" hidden>
    //         礼卡
    //       </div>
    //     </div>
    //   ),
    // },
    {
      key: "/recordList",
      title: t('general.navAccount'),
      icon: (active) => (
        <div className="trackContainer">
          <img
            src={active ? record2 : record1}
            style={{ width: "22px", height: "22px", maxWidth: "32px" }}
            alt=""
          />{" "}
          {tabbar[3] > 0 && Object.keys(userInfo).length > 0 && (
            <img
              src={recordBadge}
              className="badge"
              style={{ width: "12px", height: "12px" }}
              alt=""
            ></img>
          )}
          <div className="trackReturn" hidden>
            明细
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="t-bottom">
      <div className="tabbar-bottom">
        <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  )
}