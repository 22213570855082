import axios from "./axios";
import dayjs from "dayjs";
import level from '@province-city-china/level'

function handleClickFun(fn) {
    let that = this;
    if (that.onoff) {
        that.onoff = false;
        fn();
        setTimeout(function () {
            that.onoff = true;
        }, 1500)
    } else {
        //如果一直走else分支可能是你没有在页面的data下面挂载onoff:true,不然一直都会走else
        console.log("请稍后点击")
    }
}

function handleDot(num) {
    if (num === undefined || null) return
    const numStr = num.toString()
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length === 1 ? `${numStr}0` : numStr
    } else {
        return numStr + '.00'
    }
}

function handleAmount(amount) {
    if (amount === undefined || null) return ''
    const amountArr = amount.split(".")
    if (amountArr.length === 1) return `$${amountArr[0]}.00`
    if (amountArr.length === 2) {
        if (amountArr[1].length === 0) {
            return `$${amountArr[0]}.00`
        } else if (amountArr[1].length === 1) {
            return `$${amount}0`
        } else {
            return `$${amount}`
        }
    }
}

function timers(timer, isShowSecond = true) {
    let year = timer.getFullYear();      //转换成年
    let month = timer.getMonth() + 1; //转换成月 ，月注意要加1，因为外国月份是0 - 11月
    let day = timer.getDate();  //转换成日
    let hours = timer.getHours();  //转换成时
    let minutes = timer.getMinutes();  //转换成分
    let second = timer.getSeconds();  //转换成秒

    let str = `${supplement(year)}-${supplement(month)}-${supplement(day)} ${supplement(hours)}:${supplement(minutes)}`;
    if (isShowSecond) str += `:${supplement(second)}`
    return str
}


function localTimers(timer, isShowSecond = true) {
    let year = timer.getFullYear();      //转换成年
    let month = timer.getMonth() + 1; //转换成月 ，月注意要加1，因为外国月份是0 - 11月
    let day = timer.getDate();  //转换成日
    let hours = timer.getHours();  //转换成时
    let minutes = timer.getMinutes();  //转换成分
    let second = timer.getSeconds();  //转换成秒

    let str = `${supplement(year)}/${supplement(month)}/${supplement(day)} ${supplement(hours)}:${supplement(minutes)}`;
    if (isShowSecond) str += `:${supplement(second)}`
    return str
}

const timeTransfer = (time, formatStr = "YYYY-MM-DD") => {
    // const newTime = new Date(time)

    // console.log('newTime',newTime)
    // const localTime = timers(new Date(time), false)

    // console.log('localTime',localTime)
    const timed = dayjs(time).format(formatStr)

    return timed
}

function supplement(timer) {
    if (timer < 10) {
        return timer = '0' + timer;
    } else {
        return timer
    }
}
function inArray(search, array) {
    for (let i in array) {
        if (array[i] === search) {
            return true;
        }
    }
    return false;
}

function transformImageFile(file, callback, compressThreshold = 2, isPictureCompress = true, pictureQuality = 0.92) {
    /**
     * 针对图片进行压缩,如果图片大小超过压缩阈值,则执行压缩,否则不压缩
     */
    //判断是否是图片类型
    //const {compressThreshold = 5, isPictureCompress = false, pictureQuality = 0.92} = this.props;
    const fileSize = file.size / 1024 / 1024;
    //console.log('before compress, the file size is : ', fileSize + "M");
    //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
    if ((fileSize >= compressThreshold) && isPictureCompress) {
        //判断浏览器内核是否支持base64图片压缩
        if (typeof (FileReader) === 'undefined') {
            return file;
        } else {
            try {
                return new Promise(resolve => {
                    //声明FileReader文件读取对象
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        // 生成canvas画布
                        const canvas = document.createElement('canvas');
                        // 生成img
                        const img = document.createElement('img');
                        img.src = reader.result;
                        img.onload = () => {
                            const ctx = canvas.getContext('2d');
                            //原始图片宽度、高度
                            let originImageWidth = img.width, originImageHeight = img.height;
                            //默认最大尺度的尺寸限制在（1920 * 1080）
                            let maxWidth = 1920, maxHeight = 1080, ratio = maxWidth / maxHeight;
                            //目标尺寸
                            let targetWidth = originImageWidth, targetHeight = originImageHeight;
                            //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
                            if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
                                //超过最大宽高比例
                                if ((originImageWidth / originImageHeight) > ratio) {
                                    //宽度取最大宽度值maxWidth,缩放高度
                                    targetWidth = maxWidth;
                                    targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth));
                                } else {
                                    //高度取最大高度值maxHeight,缩放宽度
                                    targetHeight = maxHeight;
                                    targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
                                }
                            }
                            // canvas对图片进行缩放
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            // 清除画布
                            ctx.clearRect(0, 0, targetWidth, targetHeight);
                            // 绘制图片
                            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                            // quality值越小,图像越模糊,默认图片质量为0.92
                            const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', pictureQuality);
                            // 去掉URL的头,并转换为byte
                            const imageBytes = window.atob(imageDataURL.split(',')[1]);
                            // 处理异常,将ascii码小于0的转换为大于0
                            const arrayBuffer = new ArrayBuffer(imageBytes.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < imageBytes.length; i++) {
                                uint8Array[i] = imageBytes.charCodeAt(i);
                            }
                            let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
                            let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' })
                            resolve(newFile)
                        }
                    }
                    reader.onerror = () => {
                        return file
                    }
                }).then(res => {
                    callback(res)
                    return res
                }).catch(() => {
                    return file
                });
            } catch (e) {
                //压缩出错,直接返回原file对象
                return file
            }
        }
    } else {
        //不需要压缩，直接返回原file对象
        return file
    }
}

function compressedFile(file, compressThreshold = 2, pictureQuality = 0.92, maxWidth = 1920, maxHeight = 1080) {
    const isPictureCompress = true
    const fileSize = file.size / 1024 / 1024
    return new Promise(resolve => {

        if ((fileSize >= compressThreshold) && isPictureCompress && typeof (FileReader) !== 'undefined') {
            //声明FileReader文件读取对象
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                // 生成canvas画布
                const canvas = document.createElement('canvas');
                // 生成img
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    const ctx = canvas.getContext('2d');
                    //原始图片宽度、高度
                    let originImageWidth = img.width, originImageHeight = img.height;
                    //默认最大尺度的尺寸限制在（1920 * 1080）
                    let ratio = maxWidth / maxHeight;
                    //目标尺寸
                    let targetWidth = originImageWidth, targetHeight = originImageHeight;
                    //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
                    if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
                        //超过最大宽高比例
                        if ((originImageWidth / originImageHeight) > ratio) {
                            //宽度取最大宽度值maxWidth,缩放高度
                            targetWidth = maxWidth;
                            targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth));
                        } else {
                            //高度取最大高度值maxHeight,缩放宽度
                            targetHeight = maxHeight;
                            targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
                        }
                    }
                    // canvas对图片进行缩放
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;
                    // 清除画布
                    ctx.clearRect(0, 0, targetWidth, targetHeight);
                    // 绘制图片
                    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                    // quality值越小,图像越模糊,默认图片质量为0.92
                    const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', pictureQuality);
                    // 去掉URL的头,并转换为byte
                    const imageBytes = window.atob(imageDataURL.split(',')[1]);
                    // 处理异常,将ascii码小于0的转换为大于0
                    const arrayBuffer = new ArrayBuffer(imageBytes.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < imageBytes.length; i++) {
                        uint8Array[i] = imageBytes.charCodeAt(i);
                    }
                    let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
                    let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' })
                    resolve(newFile)
                }
            }
            reader.onerror = () => {
                resolve(file)
            }
        } else {
            resolve(file)
        }
    })
}
/**
 * 获取url的指定参数
 * @param {String} name 参数名
 */
function GetQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
};

async function ImageInterceptor(url) {
    let response = await axios(url, { responseType: 'arraybuffer' })
    return 'data:image/png;base64,' + window.btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""))
}

const os = (function () {
    const ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        //   isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (isAndroid && !/(?:Mobile)/.test(ua)) ||
            (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian,
        isWechat = ua.toLowerCase().match(/MicroMessenger/i) && ua.toLowerCase().match(/MicroMessenger/i)[0] === "micromessenger"

    return {
        isTablet,
        isPhone,
        isAndroid,
        isPc,
        isWechat,
    }
})()

const setZendeskIframeHeight = (zendeskDom) => {
    const currentHeight = zendeskDom?.style?.height
    if (currentHeight && currentHeight !== '0px' && os.isPhone) {
        zendeskDom.style.height = `calc(100vh - 84px)`
    }
}

const resizeContactServiceHeight = () => {
    const zendeskDom = document.querySelector('iframe[data-product="web_widget"] + div')

    if (zendeskDom) {
        setZendeskIframeHeight(zendeskDom)
        return
    }

    let zendeskDomInterval = setInterval(() => {
        const zendeskDom = document.querySelector('iframe[data-product="web_widget"] + div')

        if (zendeskDom) {
            setZendeskIframeHeight(zendeskDom)
            clearInterval(zendeskDomInterval)
            zendeskDomInterval = null
        }
    }, 1000)
}

const contactService = () => {
    window.zE('messenger', 'open')
    resizeContactServiceHeight()
}

const scrollToTop = () => {
    document.documentElement.scrollTop = 0 // ios置顶
    window.scrollTo(0, 0) //ios之外置顶
}

const cityProviceData = () => {
    let options = level;
    for (let i = 0; i < level.length; i++) {
        options[i]['label'] = options[i]['value'] = options[i]['name']
        for (let j = 0; j < level[i].children.length; j++) {
            options[i].children[j]['label'] = options[i].children[j]['value'] = options[i].children[j]['name']
            options[i].children[j]['children'] = []
        }
    }
    return options
}
function flatten(arr) {
    return arr.reduce(function (pre, cur) {
        return pre.concat(Array.isArray(cur) ? flatten(cur) : cur)
    }, [])
}
export {
    handleClickFun,
    handleDot,
    handleAmount,
    inArray,
    transformImageFile,
    compressedFile,
    timers,
    GetQueryString,
    ImageInterceptor,
    localTimers,
    contactService,
    scrollToTop,
    timeTransfer,
    cityProviceData,
    flatten,
}

