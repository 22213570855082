import './index.less'
import React, { useEffect, useState } from 'react'
import { ImageUploader, NavBar, Toast, ImageViewer, Button} from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { uploadActivityReceipt, deleteActivityReceipt, setReceipts, geTransationDetailByCode, setsubmitActivityReceipt } from '@actions/transaction'
import { compressedFile } from "@utils/utils"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { submitActivityReceipt, uploadActivityReceiptReq } from '@api/transaction'

export default function TransactionReceipt() {
  const back = () => {
    window.history.back(-1)
  }
  const dispatch = useDispatch();
  const list = useSelector(state => state.transaction.receipt)
  const transactionDetail = useSelector(state => state.transaction.transaction_detail)
  const receiptStatus = useSelector(state => state.transaction.receipt_status)
  const history = useHistory()
  
  const searchParams = new URLSearchParams(history.location.search)
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [imgIndex, setImgIndex] = useState(0)
  
  const  mockUpload = async (file) => {
    // 上传数据
    setIsLoading(true)
    const compressImg = await compressedFile(file)
    const objectUrl = URL.createObjectURL(compressImg)
    const formData = new FormData()
    formData.append('receipt', compressImg)
    formData.append('activityCode', searchParams.get('code'))
    const res = await uploadActivityReceiptReq(formData)
    setIsLoading(false)
    dispatch(uploadActivityReceipt({
      url: objectUrl,
      receipt_id: res.response_data.receipt_id
    }))
    return {
      url: objectUrl,
    }
  }

  const setViewImg = (index) => {
    setVisible(true)
    setImgIndex(index)
  }
  
  const submitReceipt = async () => {
    if(list.length === 0 || isLoading) return
    setIsLoading(true)
    const res = await geTransationDetailByCode({activityCode: transactionDetail.code}, 'check')
    setIsLoading(false)
    const { status, upload_expire_at } = res.response_data?.detail || {}
    if (status === 5009 && (new Date() - new Date(upload_expire_at)) > 0) {
      Toast.show({
        content: '您提交交易凭证已超出24小时，所以暂不能切换人民币支付',
      })
    }
    const receiptIds = list.map((item)=>{
      return item.receipt_id
    });
    const params = {
      'activityCode': searchParams.get('code'),
      'receiptIds': receiptIds
    }
    setIsLoading(true)
    const resReceipt = await submitActivityReceipt(params)
    setIsLoading(false)
    dispatch(setsubmitActivityReceipt(resReceipt.response_data))
  }

  useEffect(() => {
    dispatch(setReceipts([]))
  }, [])

  useEffect(()=>{
    if(receiptStatus !== '') {
      if(receiptStatus === false) {
        Toast.show({
          icon: 'fail',
          content: '上传失败，请重新上传',
        })
      } 
      if(receiptStatus.length === 0) {
        Toast.show({
          icon: 'fail',
          content: 'Receipt already uploaded.',
        })
      }
      if(receiptStatus) {
        history.goBack()
        // history.push('/rmbOrder?code=' + searchParams.get('code'))
      } 
    }
    return () => {
      ImageViewer.clear()
    }
  }, [receiptStatus])

  const {t} = useTranslation();
  return (
    <div id='transaction-receipt'>
      <div className='nav-bar'>
        <NavBar onBack={back}>{t('receipt.nav_bar')}</NavBar>
      </div>
      <div className='transaction-receipt-content'>
        <div className='title'>{t('receipt.title')}</div>
        <ImageUploader
            upload={mockUpload}
            maxCount={8}
            showFailed={false}
            style={{ '--cell-size': '1.87rem' }}
            onDelete={(res) => {
              dispatch(deleteActivityReceipt(res.url))
            }}
            onPreview={!visible && setViewImg}
            preview={false}
          />
      </div>
      <Button loading={isLoading} onClick={submitReceipt} style={{backgroundColor: (list.length === 0 || (list.length > 0 && isLoading)) ?  '#FF8F94' : '#FF3841' }}>{t('receipt.btn')}</Button>

      {visible && imgIndex >=0 && <ImageViewer.Multi
        images={list.map((s) => s.url)}
        visible={visible}
        defaultIndex={imgIndex}
        onClose={() => {
          setVisible(false)
        }}
      />}
    </div>
  )
}