import React from "react";
import PropTypes from "prop-types";

const SvgIcon = props => {
  const { iconClass, fill } = props;

  return (
    <svg className={iconClass}>
      <use xlinkHref={"#icon-" + iconClass} fill={fill} />
    </svg>
  )
}

SvgIcon.propTypes = {
  // svg名字
  iconClass: PropTypes.string.isRequired,
  // 填充颜色
  fill: PropTypes.string
};

SvgIcon.defaultProps = {
  fill: "currentColor"
};

export default SvgIcon;
