import React, { useState, useEffect } from 'react'
import { NavBar } from 'antd-mobile'
import './index.less'

import { useHistory } from 'react-router-dom'

const staticPath = 'https://static1.front.youworld.us/gs/tutorials'

const tabsData = {
  key: 'rmbPayment',
  title: '人民币消费',
  en_title: 'RMB Payment',
  imgsCn: [
    `${staticPath}/rmb/cn_top.png`,
    `${staticPath}/rmb/cn_1.png`,
    `${staticPath}/rmb/cn_2.png`,
    `${staticPath}/rmb/cn_3.png`,
    `${staticPath}/rmb/cn_4.png`,
    `${staticPath}/rmb/cn_5.png`,
    `${staticPath}/rmb/cn_6.png`,
  ],
  imgsEn: [
    `${staticPath}/rmb/en_top.png`,
    `${staticPath}/rmb/en_1.png`,
    `${staticPath}/rmb/en_2.png`,
    `${staticPath}/rmb/en_3.png`,
    `${staticPath}/rmb/en_4.png`,
    `${staticPath}/rmb/en_5.png`,
    `${staticPath}/rmb/en_6.png`,
  ]
}

// const staticPath = 'https://static1.front.youworld.us/gs/RMBPayment'

// const tabsData = {
//   key: 'rmbPayment',
//   title: '人民币消费',
//   en_title: 'RMB Payment',
//   imgsCn: [
//     `${staticPath}/explain/cn_1.png`,
//     `${staticPath}/explain/cn_2.png`,
//     `${staticPath}/explain/cn_3.png`,
//     `${staticPath}/explain/cn_4.png`,
//     `${staticPath}/explain/cn_5.png`
//   ],
//   imgsEn: [
//     `${staticPath}/explain/en_1.png`,
//     `${staticPath}/explain/en_2.png`,
//     `${staticPath}/explain/en_3.png`,
//     `${staticPath}/explain/en_4.png`,
//     `${staticPath}/explain/en_5.png`
//   ]
// }

export default function PaymenmtExplain() {
  const history = useHistory()
  const [lang, setLang] = useState('en_US')

  useEffect(() => {
    window.scrollTo(0, 0)
    setLang(localStorage.getItem('selectedLang'))
  }, [])

  const back = () => {
    history.goBack()
  }

  return (
    <div id='rmb-paymenmt-explain'>
      <NavBar onBack={back}> {lang === 'en_US'? 'Buy USD Money' : '人民币消费'}</NavBar>
  
      <div className="tab-content">
        {
          lang === 'en_US'
          ? tabsData.imgsEn.map((imgsItem, imgsIndex) => <img src={imgsItem} alt='' key={imgsItem}/>)
          : tabsData.imgsCn.map((imgsItem, imgsIndex) =>  <img src={imgsItem} alt='' key={imgsItem}/>)
        }
      </div>
    </div>
  )
  
}
