import React, { useState, useEffect } from 'react'
import { NavBar, Button, Toast, Mask } from 'antd-mobile'
import { ExclamationCircleFill, CloseCircleOutline, DeleteOutline, AddOutline, RightOutline } from 'antd-mobile-icons'
import './index.less'
import { usePlaidLink } from 'react-plaid-link'

import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { getUserInfo } from '@api/user'
import {
  getExternalAccounts,
  removeExternalAccount,
  generateTokenReAuth,
  putPublickTokenReAuth,
} from '@api/plaid'
import { setPasswordRelatedChannel } from '@actions/password'
import { setVerifyBankFrom } from '@actions/account'
import { getModuleStatus } from "@api/common"

import bankIcon from '@assets/images/associatedBank/bank.png'

const MAX_BANK_COUNT = 3

function BankList(props) {
    const { account, setExternalItemClicked, setIsShowDeleteAccountPop, createToken } = props
    const isPlaid = account.external_link_service_provider === 'Plaid'
    let accountStatusDesc = '' 
    if (account.status === 6 ) {
        accountStatusDesc = isPlaid && [4, 7].includes(account.external_account_status) ?
        t(`selectBank.bankInfo.${account.status}_${account.external_account_status}.desc`)
        : t(`selectBank.bankInfo.${account.status}.desc`)
    }
    
    
    const reAuthorized = isPlaid && account.external_account_status === 4

    const deleteBank = () => {
        setExternalItemClicked(account)
        setIsShowDeleteAccountPop(true)
    }

    const reAuthorizedPlaid = async (acc) => {
        setExternalItemClicked(account)
        await createToken(acc.id)
    }

    return (
        <div className="account-list">
            <div className="info-detail">
                <img className="bank-logo" src={account.institution_logo || bankIcon} alt="" />
                <div className="bank-info">
                    <div className="bank-account">
                        {account.name} {account.number}
                        {
                            reAuthorized && <span className="warning-tip tip">{t(`selectBank.bankInfo.${account.status}_${account.external_account_status}.tip`)}</span>
                        }
                        {
                            isPlaid && account.status === 6 && ![4, 7].includes(account.external_account_status) && <span className="error-tip tip">{t(`selectBank.bankInfo.${account.status}.tip`)}</span>
                        }
                        
                    </div>
                    <div className="balance-info">{accountStatusDesc}</div>
                </div>
                <DeleteOutline className="delete-icon" color="#383838" fontSize={20} onClick={deleteBank}/>
            </div>
            { reAuthorized && <div className="re-authorize" onClick={() => reAuthorizedPlaid(account)}>
                <div className="action-info">
                    {t(`selectBank.bankInfo.${account.status}_${account.external_account_status}.action`)}
                    <RightOutline color="#F9941E" fontSize={10}/>
                </div>
            </div>}
        </div>
    )
}

const DeleteAccountPop = (props) => {
    const { removeBankAccount, setIsShowDeleteAccountPop, account } = props
    return (
        <div id="delete-account-pop">
            <CloseCircleOutline className="close-icon" fontSize={32} color="#FFF" onClick={() => setIsShowDeleteAccountPop(false)}/>
            <ExclamationCircleFill className="notice-icon" fontSize={60} color="#FF3841"/>
            <div className="title">{t('management.deleteAccountPop.title')}</div>
            <div className="account-info">
                <img src={account.institution_logo || bankIcon} alt="" />
                <div className="bank-name">{account.name} {account.number}</div>
            </div>
            <Button className="remove-btn" onClick={removeBankAccount}>{t('management.deleteAccountPop.btn')}</Button>
        </div>
    )
}

export default function Management() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [accounts, setAccounts] = useState([])
    const [externalItemClicked, setExternalItemClicked] = useState({})
    const [token, setToken] = useState('')
    const [isShowDeleteAccountPop, setIsShowDeleteAccountPop] = useState(false)
    const [updateTokenCount, setUpdateTokenCount] = useState(0)

    const onSuccess = async (publicToken, metadata) => {
        try {
            const { account_id, institution: {
                institution_id,
                name
            }, accounts } = metadata
            
            const res = await putPublickTokenReAuth({
                externalLinkServiceProviderId: 1,
                bindParams: {
                    publicToken,
                    accountId: account_id,
                    institutionId: institution_id,
                    institutionName: name,
                    accounts,
                },
                paramsType: 'J',
                externalAccountId: externalItemClicked.id
            })
            if (res.status_code === 100000) {
                Toast.show({
                    content: t('linkedAccounts.reAuthorizeSuccessfully'),
                    duration: 1000
                })
                getExternalBanks()
            }
        } catch (error) {
          console.log(error)
        }
    }
    const onExit = () => {
        Toast.show({
            content: t('linkedAccounts.reAuthorizeFailed'),
            duration: 1000
        })
    }

    const createToken = async (externalAccountId) => {
        try {
            const linkToken = await generateTokenReAuth({
                externalAccountId
            })
            if (linkToken.status_code === 100000) {
                setToken(linkToken?.response_data?.link_token)
                setUpdateTokenCount(updateTokenCount + 1)
            }
        } catch (error) {
          console.log(error)
        }
    }

    const { open, ready } = usePlaidLink({
        token,
        onSuccess,
        onExit
    })

    useEffect(() => {
        if (ready && token) {
            open()
        }
    }, [ready, updateTokenCount])

    const goBack = () => {
        history.goBack()
    }

    const getExternalBanks = async () => {
        try {
            const res = await getExternalAccounts()
            if (res.status_code === 100000) {
                const bankAccounts = res.response_data.data
                setAccounts(bankAccounts)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const removeBankAccount = async () => {
      try {
        const res = await removeExternalAccount({ externalAccountId: externalItemClicked.id })
        if (res.status_code === 100000) {
            setIsShowDeleteAccountPop(false)
            Toast.show({
                content: t('selectBank.removeSuccess'),
                duration: 1000
            })
            getExternalBanks()
        }
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
        document.documentElement.scrollTop = 0
        window.scrollTo(0, 0)

        dispatch(setVerifyBankFrom(''))
        getExternalBanks()
    }, [])


    // 添加银行账户
  const addBank = async () => {
      try {
        dispatch(setVerifyBankFrom('/management'))
        const res = await getUserInfo()
        if (res.status_code === 100000) {
            const result = await getModuleStatus('module_external_account')
            if (result.status_code === 300012) return false
            if (res.response_data?.is_transaction_pin_set) {
                history.push(`/associatedBank`)
            } else {
                dispatch(setPasswordRelatedChannel({
                    channel: "addBank",
                    type: "set",
                    url: "/associatedBank",
                    backUrl: "/management",
                }))
                history.push(`/verifyCode`)
            }
        }
      } catch (error) {
          console.log(error)
      }
    }

    return (
        <div id="account-lists-section">
            <NavBar
                style={{
                    "--height": "48px",
                    "--background": "#FFF"
                }}
                onBack={goBack}
            >
            {t('management.navTitle')}
            </NavBar>
            <div className="account-lists-container">
                <div className="account-lists">
                    {
                        accounts.length > 0 && accounts.map((item, index) => <BankList
                        history={history}
                        account={item}
                        key={index}
                        setExternalItemClicked={setExternalItemClicked}
                        setIsShowDeleteAccountPop={setIsShowDeleteAccountPop}
                        createToken={createToken}
                        />)
                    }
                    {
                        accounts.length < MAX_BANK_COUNT && <div className="add-bank" onClick={addBank}>
                            <div className="add-icon">
                                <AddOutline color="#FF3841" fontSize={28}/>
                            </div>
                            <div className="add-tip">{t('selectBank.addBankTip')}</div>
                        </div>
                    }
                </div>
            </div>
            <Mask visible={isShowDeleteAccountPop}>
                <DeleteAccountPop
                    account={externalItemClicked}
                    setIsShowDeleteAccountPop={setIsShowDeleteAccountPop}
                    removeBankAccount={removeBankAccount}
                />
            </Mask>
        </div>
    )
}
