import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, NavBar, Toast, NumberKeyboard, PasscodeInput } from 'antd-mobile'
import { LeftOutline, CloseOutline } from 'antd-mobile-icons'
import { againSetTradePassword } from '@api/user'
import { setIsTransactionPinSet } from '@actions/user'
import ExceptionPop from '@comps/ExceptionPop'
import { scrollToTop } from "@utils/utils"
import './index.less'
import { connect } from 'react-redux'
import { t } from "i18next";
import { setActionToken } from '@actions/password'

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
    actionToken: store.password.actionToken,
  }
}

const mapDispatchToProps = {
  setActionToken,
  setIsTransactionPinSet
}
class SettingPasswordC extends Component {
  state = {
    title: '设置密码',
    password: '',
    isSubmit: true,
    isHas: true,
    isExceptionPopVisible: false,
    isSubmitLoading: false
  }

  passcodeInputRef = React.createRef()

  componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.passcodeInputRef.current.focus()
    if (sessionStorage.getItem("recognition")) {
      this.setState({
        isHas: false
      })
    } else {
      this.setState({
        isHas: true
      })
    }
  }
  submitPassword = async () => {
    const sess = sessionStorage.getItem("recognition")
    const { actionToken } = this.props
    const { password } = this.state
    this.setState({
      isSubmitLoading: true
    })
    const res = await againSetTradePassword('UpdateTransactionPin_ConfirmNewPin', actionToken, password)
    this.setState({
      isSubmitLoading: false
    })
    switch (res.status_code) {
      case 100000: 
        this.props.setIsTransactionPinSet()
        const returnLink = this.props.passwordRelatedChannel.url
        Toast.show({
          content: t("setPassword.SettocompleteToast"),
        })
        if (sess && !['/associatedBank'].includes(returnLink)) {
          this.setState({
            flag: true,
            isSubmit: true
          })
        } else if (returnLink) {
          this.setState({
            isHas: true
          })
          this.props.history.go(-2)
          // if (returnLink === '/mine') {
          //   this.props.history.push({ pathname: '/mine', state: { router: "setting" } })
          // } else {
          //   this.props.history.go(-2)
          // }
        } else {
          this.props.history.push('/setting')
        }
        break;
      // case 300001: 
      //   this.passcodeInputRef.current.focus()
      //   Toast.show({
      //     content: '请避免使用重复/连续数字作为密码哦',
      //   })
      //   break;
      // case 300002: 
      //   this.passcodeInputRef.current.focus()
      //   Toast.show({
      //     content: '请避免使用重复/连续数字作为密码哦',
      //   })
      //   break;
      case 301006: 
        this.setState({
          isExceptionPopVisible: true,
        })
        break;
      case 301007:
        this.passcodeInputRef.current.focus()
        this.setState({
          error: true,
          isSubmit: true,
        })
        break;
      default:
        this.passcodeInputRef.current.focus()
        this.setState({
          error: true,
          isSubmit: true,
        })
        break;
    }
  }
  close = () => {
    const sess = sessionStorage.getItem('recognition')
    const returnLink = this.props.passwordRelatedChannel.url
    if (sess && !['/management', '/associatedBank'].includes(returnLink)) {
      this.props.history.push('/')
    } else if (returnLink) {
      if (returnLink === '/mine') {
        this.props.history.push({ pathname: '/mine', state: { router: "setting" } })
      } else if (returnLink === '/associatedBank') {
        this.props.history.push(this.props.passwordRelatedChannel.backUrl)
      } else {
        returnLink === '/recordList' && this.props.setActionToken(null)
        this.props.history.push(returnLink)
      }
    } else {
      this.props.history.push('/setting')
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  onPasswordChange = (code) => {
    if (code.length === 6) {
      this.passcodeInputRef.current.blur()
      this.setState({
        password: code,
        isSubmit: false
      })
    } else if (code.length === 5) {
      this.setState({
        password: this.state.error ? '' : code,
        error: false,
        isSubmit: true
      })
    } else {
      this.setState({
        password: code,
        error: false,
        isSubmit: true
      })
    }
  }
  
  render() {
    const { passwordRelatedChannel } = this.props
    const { 
      isSubmit, error, password, isExceptionPopVisible,
      isSubmitLoading, 
    } = this.state
    return (
      <div className='setting-password-c-page'>
        <div className="header">
          <NavBar
            style={{
              '--height': '36px',
              '--background': '#ffffff',
            }}
            backArrow={<LeftOutline fontSize={20}/>}
            onBack={this.goBack}
            right={<CloseOutline onClick={this.close} fontSize={24}/>}
          >
            {passwordRelatedChannel.channel === 'normalSetting' && t("setPassword.Setpassword")}
          </NavBar>
        </div>
        <section className="setting-password-c d-flex flex-column align-items-center">
          <div className="title-warp">
            <p>
              {t("setPassword.Reconfirmtransactionpin")}
            </p>
            { error ?
            <div className='error-message'>
              {t("setPassword.twiceisinconsistent")}
            </div> :
            <div className='subtitle'>
              {t("setPassword.paymentsandsecurity")}
            </div>}
          </div>
          
          <div className='verification-code'>
            <PasscodeInput
              ref={this.passcodeInputRef}
              length={6}
              value={password}
              seperated
              keyboard={<NumberKeyboard randomOrder={true}/>}
              onChange={this.onPasswordChange}
              error={error}
              style={{
              '--cell-gap': '12px',
            }}/>
          </div>
          <div className="setting-password-c-varification-tips">
            <div 
              className="tips-info"
              dangerouslySetInnerHTML={{__html: t('setPassword.repeatedorconsecutive')}}
            >
            </div>
         
          </div>
          <div className="btn-container">
            <Button
              type="warning"
              loading={isSubmitLoading}
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center']}
              onClick={this.submitPassword}
              disabled={isSubmit}
            >
              <span style={{ display: "block" }}>
                {t("setPassword.Settocomplete")}
                {passwordRelatedChannel.channel === 'addBank' &&
                  <span>,&nbsp;
                    {t("setPassword.Immediatelyadd")}
                  </span>}
              </span>
            </Button>
          </div>
        </section>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>

    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingPasswordC))
