import React, { Component } from 'react'
import { Input } from 'antd-mobile'
import { timeFormat } from '@utils'
import { scrollToTop } from "@utils/utils"

import './index.less'
class SettingVerification extends Component {
  state = {
    list: [{
      ref: React.createRef(),
      value: ''
    }, {
      ref: React.createRef(),
      value: ''
    }, {
      ref: React.createRef(),
      value: ''
    }, {
      ref: React.createRef(),
      value: ''
    }, {
      ref: React.createRef(),
      value: ''
    }, {
      ref: React.createRef(),
      value: ''
    }],
    currentIndex: 0,
    currentValue: '',
    tiems: 60,
    maxLength: 6
  }
  // 键盘输入验证码
  handleButton = (val, index) => { 
    this.setState({
      currentIndex: index,
      currentValue: val,
    })
    //显示输入值，限制1位。
    if(val.length>1){
      val=val.slice(0,1)         
    }
    let listA = this.state.list
    listA[index].value = val  

    //光标自动右移--->
    if(index !== 5 && val !== '' ){
      listA[index + 1].ref.current.focus() 
    } 

    this.setState({
      list: listA
    })
     
  }
   //删除验证码,光标退格
  handleDel = (e) => { 
    const BACK_SPACE = 8   
    const isBackSpaceKey = e.keyCode === BACK_SPACE  
    const {currentValue,currentIndex,list} = this.state 
    list[currentIndex].value = currentValue   
    if (isBackSpaceKey && currentValue === '' && currentIndex !== 0) {
      list[currentIndex - 1].ref.current.focus()   
    } 
  }
  setTimes = () => {
    let times = 60
    let intervalY = setInterval(() => {
      if (times === 0) {
        clearInterval(intervalY)
      } else {
        times--
        this.setState({ times })
      }
    }, 1000)
  }
  componentWillMount() {
    this.setTimes()
  }
  componentDidMount = () => {
    scrollToTop()
  }
  render() {
    const { list, times, maxLength } = this.state
    const mobile = '' //电话号码 
    return (
      <section className="setting-password d-flex flex-column align-items-center">
        <div className="title-warp">
          <p>
          输入短信验证码
          </p>
          <div className="sub-title">
          验证码已发送到您的手机{mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}
          </div>
        </div>
        <div className="verification-warp">
          <div className="lista">
            {list.map((item, index) => {
              return (
                <Input key={index} value={item.value} ref={item.ref} type="tel"               
                onKeyDown={maxLength ? this.handleDel : null}
                onChange={(val) => {                
                  this.handleButton(val, index)
                }}></Input>
              )
            })}
          </div>
        </div>
        <div className="varification-tips">
          <div className="times">
            {timeFormat(times)} s后重新发送
          </div>
        </div>
      </section>
    )
  }
}

export default SettingVerification
