import {
    SET_PLAID_ORIGIN
  } from '../actions/ActionTypes'
  
  const initialState = {
    plaidOrigin: sessionStorage.getItem('plaidOrigin') || ''
  }
  
  
  const plaid =  (state = initialState, action) => {
    let newState = {
      ...state
    }
    switch(action.type) {
      case SET_PLAID_ORIGIN:
        newState.plaidOrigin = action.payload
        return newState
      default:
        return state
    }
  }

  export default plaid