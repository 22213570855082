import {
  SET_TABBAR_BADGE,
  GET_TABBAR_BADGE
} from '../actions/ActionTypes'


const initialState = {
  tabbar: [0,0,0,0],
  tabbar_status: true
}
const tabbar = (state = initialState, action) => {
  switch(action.type) {
    case SET_TABBAR_BADGE:
      return {
        ...state,
        tabbar_status: action.status
      }
    case GET_TABBAR_BADGE: 
      return {
        ...state,
        tabbar: action.tabbar,
        tabbar_status: false
      }
    default:
      return state
  }
}

export default tabbar
