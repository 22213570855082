import React from "react"
import { useDispatch, useSelector } from "react-redux"
import "./index.less"
import { Link } from "react-router-dom"
import { Mask } from "antd-mobile"
import {
  CloseCircleOutline,
} from "antd-mobile-icons"
import commonPopIcon from "@assets/images/common/notice.png"
import { useTranslation } from "react-i18next"
import { setStopTaking } from '@actions/common'

export default function StopTakingPop(props) {
  // const [show, setShow] = useState(true);
  const dispatch = useDispatch()
  const showStopTakingPop = useSelector(state => state.common.showStopTakingPop);
  const stopTakingStartDate = useSelector(state => state.common.stopTakingStartDate)
  const stopTakingEndDate = useSelector(state => state.common.stopTakingEndDate)

  const { t } = useTranslation()

  const hide = () => {
    dispatch(setStopTaking({ showStopTakingPop: false, stopTakingStartDate: "", stopTakingEndDate: ""}))
  }

  return (
    <div id="stop-taking-pop">
      {/* 弹框  showStopTakingPop */}
      <Mask visible={showStopTakingPop}>
        <div className="common-pop">
          <div className="close">
            <CloseCircleOutline color="#fff" fontSize={36} onClick={hide} />
          </div>
          <div>
            <img className="common-icon" src={commonPopIcon} alt=""></img>
          </div>
          <div className="common-content">
            <div
              className="common-title"
              dangerouslySetInnerHTML={{
                __html: t("stopTaking.pop_text", { startTime: stopTakingStartDate, endTime: stopTakingEndDate }),
                // `系统升级维护中<br/>
                // 将于 ${stopTakingDate} 恢复服务`,
              }}
            />
            <div className="s-r-btn">
              <Link to={`/StopTaking`} 
                className="l-btn"
                onClick={hide}
              >
                {t('stopTaking.check_notic')}
              </Link>
              <div className="r-btn">
                <div onClick={hide}>
                  {/* 我知道了 */}
                  {t('stopTaking.i_know')}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </Mask>
    </div>
  )
}
