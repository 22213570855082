import { NumberKeyboard, PasscodeInput, Mask } from 'antd-mobile'
import React, { Component } from 'react'
import './index.less'
import { withRouter } from 'react-router-dom'
import { rechargeMoney, verify } from "@api/account"
import { CloseOutline } from 'antd-mobile-icons'
import { connect } from 'react-redux'
import { setPasswordRelatedChannel } from '@actions/password'
import { handleDot } from '@utils/utils'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { setTransferOrigin } from '@actions/transfer'
import ReactLoading from "react-loading"

const mapDispatchToProps = {
  setPasswordRelatedChannel,
  setTransferOrigin
}

const handleAmount = (amount = '', freeLimitAvailable, fee, balance) => {
  const realAmount = freeLimitAvailable === 0 ? parseFloat(amount) + parseFloat(fee) : parseFloat(amount)
  if (parseFloat(balance) >= realAmount) {
    return handleDot(parseFloat(amount))
  } else {
    return handleDot(parseFloat(amount) - parseFloat(fee))
  }
}

const TitleWarpB = (props) => {
  return (
    <div className="title-wrapper">
      <p>{t("transfer.Entertransactionpassword")}</p>
      <p>{t("transfer.securityaccount")}</p>
      <p>{t("transfer.Pleaseenterinformation")}</p>
    </div>
  )
}

class WidthDrawPassword extends Component {
  state = {
    password: '',
    error: false,
    failedTimes: 0,
    remainderTimes: 0,
    isLoadingShow: false
  }

  passcodeInputRef = React.createRef()

  get realAmount () {
    const { amounts, service, balance } = this.props
    return handleAmount(amounts, service?.response_data?.freeLimitAvailable, service?.response_data?.fee?.amount, balance)
  }

  getChildData(password) {
    this.props.parent.getChildData(password)
  }
  onPasswordChange = async (code) => {
    if (code.length === 6) {
      this.passcodeInputRef.current.blur()
      this.setState({
        isLoadingShow: true,
        password: code
      })
      const { bAcount, openFreezePasswordPop } = this.props
      const verifs = await verify({ transactionPin: code, actionCode: 'AccountWithdraw_VerifyCurrentPin' })
      if (verifs.status_code === 100000) {
        const withdraw = await rechargeMoney({
          externalAccountId: bAcount?.id,
          amount: this.realAmount,
          debitCreditIndicator: "D",
          token: verifs.response_data.token,
          actionCode: "AccountWithdraw_SubmitRequest"
        })
        this.setState({
          isLoadingShow: false
        })
        if (withdraw.status_code === 100000) {
          this.props.setTransferOrigin('/withdraw')
          this.props.history.push(`/transactionDetail?code=${withdraw.response_data.code}`)
        }
      } else if (verifs.status_code === 301025) {
        const { total_limit, failed_times } = verifs.response_data
        this.setState({
          isLoadingShow: false,
          failedTimes: failed_times,
          remainderTimes: total_limit - failed_times,
          error: true
        })
      } else if (verifs.status_code === 301016){
        openFreezePasswordPop()
        this.setState({
          error: true,
          isLoadingShow: false
        })
      } else {
        this.setState({
          error: true,
          isLoadingShow: false
        })
      }
    } else if (code.length === 5) {
      this.setState({
        password: this.state.error ? '' : code,
        error: false
      })
    } else {
      this.setState({
        password: code,
        error: false
      })
    }
  }

  render() {
    const { perTransFee } = this.props
    const { error, password, failedTimes, remainderTimes, isLoadingShow } = this.state

    return (
      <div className="rechargePassword">
          <div className="overlayContent">
            <CloseOutline
                onClick={() => {
                  this.props?.hiddenMask()
                }}
                className="close-img"
                color="#FFF"
                fontSize={16}
            />
            <div>
              <TitleWarpB
                isRepeat={false}
                amount={this.realAmount}
                perTransFee={perTransFee}
              />

              {error &&
                <div className="pass"> <Trans i18nKey={t(`password.passwordIncorrect`)}  values={{ failedTimes, remainderTimes }}></Trans></div>
                }

              <PasscodeInput
                ref={this.passcodeInputRef}
                className="passcode-input"
                value={password}
                error={error}
                onChange={this.onPasswordChange}
                seperated
                keyboard={<NumberKeyboard randomOrder={true}/>}
              />
              <div className="varification-tips tipp">
                <div
                  className="unvla tyop"
                  onClick={() => {
                    this.props.setPasswordRelatedChannel({
                      channel: 'normalSetting',
                      type: 'reset',
                      url: '/withdraw',
                      backUrl: '/withdraw',
                    })
                    this.props.history.push("/resetTransactionPasswordA");
                  }}
                >
                  {t("transfer.Forgotpassword")}
                </div>
              </div>
            </div>
          </div>
          <Mask visible={isLoadingShow}>
            <ReactLoading className='loading' type={"spinningBubbles"} color={"#fff"}/>
          </Mask>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(withRouter(WidthDrawPassword))
