import "./index.less"
import React, { useEffect, useState } from "react"
import { Button, Swiper, Toast, Input, Mask, NavBar } from "antd-mobile"

import cs from "@assets/images/rmb/icons/cs.png"
import order from "@assets/images/rmb/icons/order.png"

import { RightOutline } from "antd-mobile-icons"

import { useDispatch, useSelector } from "react-redux"

import {
  getBannerContent,
  getPaymentLink,
  submitTutorialsBehavior,
  getTutorialsBehavior,
  setPaymentLink,
  setPaymentMethodsShow,
  // setRmbBalancePop,
  setPayStatus,
  getRiskControlInfo,
} from "@actions/rmb"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import PaymentMethodsList from "@comps/PaymentMethodsList"
import CommonPop from "@comps/CommonPop"
import { getTabBarBadge } from "@actions/tabbar"
import StopTakingNotice from "@comps/StopTakingNotice"
import { getModuleStatus } from "@api/common"

import { formatNumberToUSD } from "@utils"
import { contactService, scrollToTop } from '@utils/utils'

import stepsEn from '@assets/images/rmb/steps_en.png'
import stepsCn from '@assets/images/rmb/steps_cn.png'

const bannerContentClicked = (item, history) => {
  if (item.page_url === null) return
  if (
    item.page_url.indexOf("wwww") > -1 ||
    item.page_url.indexOf("http") > -1
  ) {
    window.location.replace(item.page_url)
  } else {
    history.push(item.page_url)
  }
}

const BannerContentItem = (props) => {
  const { banners } = props
  return (
    <Swiper autoplay autoplayInterval={4000} loop>
      {banners &&
        banners.map((item, index) => (
          <Swiper.Item key={index}>
            <img
              alt=''
              style={{
                width: "100%",
              }}
              src={item.material_link}
              onClick={() => bannerContentClicked(item, props.history)}
            />
          </Swiper.Item>
        ))}
    </Swiper>
  )
}

export default function RmbPayment() {
  const { t } = useTranslation()
  const paymentMethods = useSelector((state) => state.rmb.paymentMethods)
  const currentPaymentMethod = useSelector(
    (state) => state.rmb.currentPaymentMethod
  )
  const paymentMethodsIcon = useSelector(
    (state) => state.rmb.paymentMethodsIcon
  )
  const paymentLink = useSelector((state) => state.rmb.paymentLink)

  const banners = useSelector((state) => state.rmb.bannerContent)
  const popContentCode = useSelector((state) => state.common.pop_content_code)
  const userInfo = useSelector((state) => state.user.userInfo)
  const amountRiskPop = useSelector((state) => state.rmb.amount_risk_pop)
  const [value, setValue] = useState('')
  const [rmb, setRmb] = useState('0.00')
  const [isRiskPopVisible, setIsRiskPopVisible] = useState(false)
  const [isWechatPaymentPopShow, setIsWechatPaymentPopShow] = useState(false)

  // const showStopTakingPop = useSelector(
  //   (state) => state.common.showStopTakingPop
  // )
  const [stopTakingPop, setStopTakingPop] = useState(false)

  const paymentMethodsShow = useSelector(
    (state) => state.rmb.paymentMethodsShow
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const isPayUrl = useSelector((state) => state.rmb.is_pay_url)
  const pay_type = useSelector((state) => state.rmb.pay_type)
  // const code = useSelector((state) => state.rmb.code)

  const paymentParams = {
    paymentMethodId: currentPaymentMethod?.id || "",
    amount: parseFloat(value).toFixed(2),
    paymentCurrencyCode: "CNY",
    exchangeTargetCurrencyCode: "USD",
    pay_type: currentPaymentMethod.pay_type,
  }
  useEffect(() => {
    scrollToTop()
    if (searchParams?.get("status") === "fail") {
      Toast.show({
        content: t("rmb.fail"),
      })
      dispatch(setPayStatus(false))
    }
    if (searchParams?.get("amount")) {
      setValue(searchParams?.get("amount"))
    }
    dispatch(getBannerContent("rmb_page_top"))
    dispatch(setPaymentLink({ code: "", link: "" }))
    dispatch(getTutorialsBehavior())
    if (Object.keys(userInfo).length > 0) dispatch(getTabBarBadge())
    dispatch(
      getRiskControlInfo({
        moduleCode: "rmb_pay_button",
      })
    )
  }, [])

  useEffect(() => {
    if (paymentLink !== "") {
      if (isPayUrl) {
        window.location.href = paymentLink
      } else if (pay_type === "unionpay") {
        const container = document.createElement("div")
        container.setAttribute("id", "unionpay")
        container.style.display = "none"
        container.innerHTML = paymentLink
        document.getElementById("root").appendChild(container)
        document
          .getElementById("pay_form")
          .parentElement.setAttribute("id", "payFormBox")
        const unionpayStyle =
          document.querySelector("#unionpay style").innerHTML
        document.querySelector("#unionpay style").innerHTML =
          unionpayStyle.replace(/div/g, "#payFormBox")
        document.getElementById("pay_form").submit()
        container.remove()
      } else if (pay_type === "alipay") {
        const dom = document.createElement("div")
        dom.innerHTML = paymentLink
        const script = document.createElement("script")
        script.innerHTML = dom.getElementsByTagName("script")[0].innerHTML
        document.body.appendChild(script)
      }
      // history.push('/rmbOrder?code=' + code)
      dispatch(setPayStatus(true))
      setPayLoadingStatus(false)
    }
  }, [paymentLink])

  useEffect(() => {
    setPayLoadingStatus(false)
  }, [popContentCode])

  const os = (function () {
    const ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      // isChrome = /(?:Chrome|CriOS)/.test(ua),
      isTablet =
        /(?:iPad|PlayBook)/.test(ua) ||
        (isAndroid && !/(?:Mobile)/.test(ua)) ||
        (isFireFox && /(?:Tablet)/.test(ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian,
      isWechat = ua.toLowerCase().match(/MicroMessenger/i) && ua.toLowerCase().match(/MicroMessenger/i)[0] === "micromessenger"

    return {
      isTablet,
      isPhone,
      isAndroid,
      isPc,
      isWechat,
    }
  })()

  function handleChange(value) {
    // let value = e.target.value
    value = value.replace(/[^\d.]/g, "")
    value = value.replace(/^\./g, "")
    value = value.replace(/\.{2,}/g, ".")
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
    value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
    let valueLength = value.length
    if (!value.includes(".") && value !== "") {
      //首位是0的话去掉
      value = parseFloat(value)
    }
    if (value >= 10000) {
      Toast.show({
        content: t("rmb.max_value_tip"),
      })
      return
    }
    if (value === 0 && valueLength === 4) {
      Toast.show({
        content: t("rmb.min_value_tip"),
      })
      return
    }
    setValue(value)
    setRmb((value * currentPaymentMethod.exchange_rate).toFixed(2))
    paymentMethods.map((item, index) => {
      item.rmb = (value * item.exchange_rate).toFixed(2)
      return item
    })
  }
  async function submitPaymentOrder(tabbar) {
    if (!(value && parseFloat(value) > 0)) {
      return
    }

    let result = await getModuleStatus("module_instant_consumption_coin")
    if (result.status_code === 300012) return

    if (amountRiskPop.is_risk) {
      setIsRiskPopVisible(true)
      return
    }
    if (
      userInfo?.default_financial_account?.status &&
      userInfo?.default_financial_account?.status !== 1
    ) {
      Toast.show({
        content: t("rmb.toast.freeze"),
      })
      return
    }
    if (tabbar[3] > 0) {
      Toast.show({
        content: t("rmb.toast.uncomsumption"),
      })
      return
    }

    if (parseFloat(value) < 0.01) {
      return
    }

    if (
      currentPaymentMethod.pay_type === "wechat" &&
      !os.isPc &&
      !os.isWechat
    ) {
      setIsWechatPaymentPopShow(true)
      return
    }

    if (!payLoadingStatus) {
      // if (balance && parseFloat(value) <= balance) {
      // console.log(' ============', )
      //   dispatch(setRmbBalancePop(true))
      // } else {
        setPayLoadingStatus(true)
        dispatch(getPaymentLink(paymentParams))
      // }
    }
  }

  // let balance = parseFloat(userInfo.default_financial_account?.balance?.balance)
  const tutorialsBanner = useSelector((state) => state.rmb.tutorials_banner)
  const tutorialsShow = useSelector((state) => state.rmb.tutorialsShow)
  const payStatus = useSelector((state) => state.rmb.tutorialsShow)
  // const stepsBanner = useSelector((state) => state.rmb.steps_banner)

  const selectedLang = useSelector(state => state.user.selectedLang);

  const tabbar = useSelector((state) => state.tabbar.tabbar)

  const [payLoadingStatus, setPayLoadingStatus] = useState(false)

  

  const submitBehavior = () => {
    dispatch(submitTutorialsBehavior())
  }

  return (
    <div id="rmb-payment">
      <NavBar onBack={() => history.push("/")}>{t("rmb.nav_bar")}</NavBar>

      {/* 停服公告 */}
      <div className="topNotice">
        <StopTakingNotice
          stopTakingPop={stopTakingPop}
          setStopTakingPop={setStopTakingPop}
          moduleCode="module_instant_consumption_coin"
        />
      </div>

      <Mask visible={tutorialsShow}>
        <div className="tutorials-mask">
          <Link to={"/tutorials?key=rmbPayment"}>
            {" "}
            <img src={tutorialsBanner} alt="" />{" "}
          </Link>
          <div
            className="tutorials-tips"
            dangerouslySetInnerHTML={{ __html: t("rmb.tutorials_tip") }}
          ></div>
          <div className="got" onClick={() => submitBehavior()}>
            {t("rmb.tutorials_btn")}
          </div>
        </div>
      </Mask>
      <div className="swiper">
        <BannerContentItem banners={banners} history={history} />
      </div>
      <div className="input-section">
        <div className="user-cost">
          <label className="symbol">$</label>
          <Input
            type="text"
            value={formatNumberToUSD(value)}
            placeholder={t("rmb.input_placeholder")}
            onChange={handleChange}
          />
        </div>
        <div className="calculate">
          <label className="exchange-rate">
            {t("rmb.reference")}：1:{currentPaymentMethod.exchange_rate}
          </label>
          {rmb && (
            <label
              className="total"
              style={{ color: rmb > 0 ? "#1F49DA" : "#999" }}
            >
              {t("rmb.about")}:￥{formatNumberToUSD(rmb)}
            </label>
          )}
        </div>
      </div>
      {currentPaymentMethod.pay_type && (
        <div className="method">
          <label className="title">{t("rmb.payment_methods")}</label>
          <div
            className="value"
            onClick={() => dispatch(setPaymentMethodsShow(true))}
          >
            <img
              className="icon"
              src={paymentMethodsIcon[currentPaymentMethod.pay_type].icon}
              alt=''
            ></img>
            <label>
              {t(
                `rmb.${paymentMethodsIcon[currentPaymentMethod.pay_type].title}`
              )}
            </label>
            <RightOutline color="#333" />
          </div>
        </div>
      )}
      <div className="steps">
        <div className="title">{t("rmb.step_decription")}</div>
        <img alt="" src={selectedLang === 'zh_CN' ? stepsCn: stepsEn}></img>
        <div className="title">{t("rmb.caution")}</div>
        <ul>
          <li>{t("rmb.caution_des_1")}</li>
          <li>{t("rmb.caution_des_2")}</li>
          {/* 隐藏礼卡文案 */}
          {/* <li dangerouslySetInnerHTML={{ __html: t("rmb.caution_des_3") }}></li> */}
        </ul>
      </div>
      <div className="keep-operater">
        <div className="limit">{t("rmb.pay_cost")}</div>
        <div className="actions">
          <div className="item" onClick={contactService}>
            <img alt="" src={cs}></img>
            <label>{t("rmb.customer_service")}</label>
          </div>

          <div className="item" onClick={() => history.push("/recordList")}>
            <img alt="" src={order}></img>
            <label>{t("rmb.order")}</label>
          </div>

          {payStatus ? (
            <button style={{ opacity: 0.6 }}>{t("rmb.in_pay")}</button>
          ) : (
            <Button
              loading={payLoadingStatus}
              onClick={() => submitPaymentOrder(tabbar)}
              style={{ opacity: parseFloat(value) > 0 ? 1 : 0.6 }}
            >
              {t("rmb.pay")}
            </Button>
          )}
        </div>
      </div>
      <PaymentMethodsList paymentMethodsShow={paymentMethodsShow} />
      <CommonPop
        amountRiskPop={isRiskPopVisible}
        onRiskControlClose={() => setIsRiskPopVisible(false)}
        isWechatPaymentPopShow={isWechatPaymentPopShow}
        setIsWechatPaymentPopShow={setIsWechatPaymentPopShow}
      />
    </div>
  )
}
