import {
  CHOOESS_RMB_METHODS,
  GET_PAYMENT_METHODS,
  GET_BANNER_CONTENT,
  SET_CURRENT_PAYMENT_METHODS,
  GET_PAYMENT_LINK,
  GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS,
  PAYMENT_METHOD_SHOW,
  SET_RMB_TO_USD_VALUE,
  GET_TUTORIALS_BEHAVIOR,
  SET_TUTORIALS_BEHAVIOR,
  SET_RMB_BALANCE_POP,
  SET_SWITCH_RMB_POP,
  SET_AMOUNT_RISK_POP,
  SET_PAY_STATUS,
  SET_PAYMENT_FROM,
  SET_COMMON_POP_CONTENT,
  SET_SWITCH_RMB_UNABLED_POP,
  SET_HOME_THREE_GUIDE,
  SET_RMB_PAYMENT_SUCCESS
} from './ActionTypes'

import request from "@utils/request";

export const setPaymenFrom = (payment) => {
  sessionStorage.setItem('pay_from', JSON.stringify(payment));
  return {
    type: SET_PAYMENT_FROM,
    payment
  }
}

export const setRmbBalancePop = (status) => {
  return {
    type: SET_RMB_BALANCE_POP,
    status
  }
}

export const setPayStatus = (status)=> {
  return {
    type: SET_PAY_STATUS,
    status
  }
}
export const setSwitchRmbPop = (status) => {
  return {
    type: SET_SWITCH_RMB_POP,
    status
  }
}

export const setSwitchRmbUnabledPop = (status) => {
  return {
    type: SET_SWITCH_RMB_UNABLED_POP,
    status
  }
}

export const setAmountRiskPop = (status) => {
  return {
    type: SET_AMOUNT_RISK_POP,
    status
  }
}

export const setRmbToUsdValue = (payload) => {
  return {
    type: SET_RMB_TO_USD_VALUE,
    payment_methods: payload
  }
}

export const setPaymentMethodsShow = (payload) => {
  return {
    type: PAYMENT_METHOD_SHOW,
    status: payload
  }
}

function setRmbMethods(payload) {
  return {
    type: CHOOESS_RMB_METHODS,
    status: payload
  }
}

export const setPaymentMethods = (paymentMethods) => {
  return {
    type: SET_CURRENT_PAYMENT_METHODS,
    paymentMethods
  }
}

export const getPaymentMethods = () => {
  let platformId = 3
  const ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) === "micromessenger") {
    platformId = 1
  }
  if (ua.match(/Alipay/i) === "alipay") {
    platformId = 2
  }
  const data = {
    "paymentCurrencyCode": "CNY",
    "exchangeTargetCurrencyCode": "USD",
    "isAvailableOnly": true,
    "platformId": platformId
  }
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/account/get-payment-methods`,
      data
    }).then((res) => {
      const payment_methods = res.response_data.payment_methods
      const action = initPaymentMethodsAction(payment_methods)
      dispatch(action)
    });
  }
}
export const replaceByInstantConsumptionCoin = (data) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/account/replace-by-instant-consumption-coin`,
      data
    }).then((res) => {
      const { pay_link, code, is_pay_url }  = res.response_data
      const pay = {
        link: pay_link,
        code,
        is_pay_url,
        pay_type: data.pay_type
      }
      const action = initPaymentLinkAction(pay)
      dispatch(action)
    });
  }
}

export const getTutorialsBehavior = () => {
  const data = {
    "behaviorCode": "first_click_rmb"
  }
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/user/get-behavior-data`,
      data
    }).then((res) => {
      const status = res.response_data.status
      dispatch({
        type: GET_TUTORIALS_BEHAVIOR,
        status
      })
    });
  }
}
export const submitTutorialsBehavior = () => {
  const data = {
    "behaviorCode": "first_click_rmb"
  }
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/user/submit-behavior-data`,
      data
    }).then((res) => {
      const status = res.response_data.status
      dispatch({
        type: SET_TUTORIALS_BEHAVIOR,
        status
      })
    });
  }
}

export const getBannerContent = (bannerPlaceholderCode) => {
  // rmb_page_top
  const data = {
    "bannerPlaceholderCode": bannerPlaceholderCode,
    "countryCode": !window.localStorage.getItem("selectedLang") ? 'CHN' : (window.localStorage.getItem("selectedLang") === 'zh_CN' ? 'CHN' : 'USA')
  }
  if(bannerPlaceholderCode === "rmb_page_top") {
    return (dispatch) => {
      request({
        method: "POST",
        url: `/app/content/banner-content`,
        data
      }).then((res) => {
        const banner_list = res.response_data.banner_list
        const action = initBannerContentAction(banner_list)
        dispatch(action)
      });
    }
  } else {
    return request({
      method: "POST",
      url: `/app/content/banner-content`,
      data
    })
  }

}

export const getPaymentLink = (data) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/account/get-payment-link`,
      data
    }).then((res) => {
      if (res.status_code === 100000) {
        const { pay_link, is_pay_url} = res.response_data
        if(pay_link) {
          const code = res.response_data.code
          const pay = {
            code,
            "link": pay_link,
            is_pay_url,
            pay_type: data.pay_type
          }
          sessionStorage.setItem('pay',JSON.stringify(pay));
          sessionStorage.setItem('pay_from', JSON.stringify({
            // from: "payment"
            from: 'transaction'
          }));
          const action = initPaymentLinkAction(pay)
          dispatch(action)
        }
      } else {
        dispatch({
          type: SET_COMMON_POP_CONTENT,
          status_code: res.status_code,
          params: res.response_data
        })
      }
    })
  }
}
export const setPaymentLink = (pay) => {
  sessionStorage.setItem('pay', JSON.stringify(pay));
  sessionStorage.setItem('pay_from', JSON.stringify({
    from: "payment"
  }));
  return {
    type: GET_PAYMENT_LINK,
    pay
  }
}
export const getInstantConsumptionCoinUsageStatus = (data) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/user/get-instant-consumption-coin-usage-status`,
      data
    }).then((res) => {
      const rmb_is_used = res.response_data
      const action = initConsumptionCoinUsageStatus(rmb_is_used)
      dispatch(action)
    });
  }
}
export const initConsumptionCoinUsageStatus = (rmb_is_used) => ({
  type: GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS,
  rmb_is_used
})

export const initPaymentMethodsAction = (payment_methods) => ({
  type: GET_PAYMENT_METHODS,
  payment_methods
})

export const initBannerContentAction = (banner) => ({
  type: GET_BANNER_CONTENT,
  banner
})
export const initPaymentLinkAction = (pay) => ({
  type: GET_PAYMENT_LINK,
  pay
})

export const getRiskControlInfo = (data) => {
  return (dispatch) => {
    request({
      method: 'POST',
      url: `/app/account/get-risk-control-info-by-code`,
      data
    }).then((res) => {
      const payload = res.response_data
      const action = setAmountRiskPop(payload)
      dispatch(action)
    });
  }
}
export const RMB = {
  setRmbMethods
}

export const showHomeThreeGuide = (homeThreeGuide) => ({
  type: SET_HOME_THREE_GUIDE,
  homeThreeGuide
})

export const setRmbPaymentSuccess = (payload) => {
  sessionStorage.setItem('rmbPaymentSuccess', JSON.stringify(payload));
  return ({
    type: SET_RMB_PAYMENT_SUCCESS,
    payload
  })
}