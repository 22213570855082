import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import license from "@assets/images/certificate/dl_sample.png"
import lssued from "@assets/images/certificate/id_sample.png"

import nonssnlicense from "@assets/images/certificate/non_ssn_dl_sample.png"
import nonssnlssued from "@assets/images/certificate/non_ssn_id_sample.png"
import permit from "@assets/images/certificate/pr_sample.png"
import addr from "@assets/images/certificate/addr_sample.png"
import nonssnExample1 from "@assets/images/certificate/nonssn_example1.png"
import nonssnExample2 from "@assets/images/certificate/nonssn_example2.png"
import nonssnExample3 from "@assets/images/certificate/nonssn_example3.png"
import nonssnExample4 from "@assets/images/certificate/nonssn_example4.png"

import './index.less'
import { t } from 'i18next'

class ActivateAlert extends Component {

  render() {
    const { licensed, lssueds, permits, from = '', address } = this.props
    return (
      <div className="samples">
        <p className='sample-title'>{t('steps.generaMask')}</p>
        <p className='sample-describe'>{t('steps.generaMaskdescribe')}<br />
          {t('steps.generaMaskdescribetwo')}<br />
          {t('steps.generaMaskdescribethree')}</p>
        <div className='license'>
          {licensed ?
            <img src={from === 'nonssn' ? nonssnlicense : license} alt="" />
            : null}
          {
            lssueds ? <img src={from === 'nonssn' ? lssueds : lssued} alt="" /> : null
          }
          {
            address ? <img className='nonbill' src={addr} alt="" /> : null
          }
          {
            permits ? <img src={permit} alt="" /> : null
          }
        </div>
      </div>
    )
  }
}

export default withRouter(ActivateAlert)
