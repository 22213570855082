import React, { useEffect } from "react"
import "./index.less"
import imgBgCard from "@assets/images/card/card.png"
import { EyeInvisibleOutline, EyeFill } from "antd-mobile-icons"

let timeOut = null;

export default function HomeVisaCardDialog(props) {
  const { newCard, leftBottom, cardNumber, unFold, hideInfo, cardEyeStatus, verifyPwd, onHide } = props
  let temp = (cardNumber || "").split("")
  temp.splice(4, 0, " ")
  temp.splice(9, 0, " ")
  temp.splice(14, 0, " ")

  let cardNumberWithSpace = temp.join("")
  let tempFinal = cardNumberWithSpace ? cardNumberWithSpace.split(" ") : []

  const changeEye = () => {
    !cardEyeStatus && verifyPwd({ type: "", url: "" })
  }

  useEffect(() => {
    if(newCard) {
      // 5 分钟自动隐藏
      timeOut = setTimeout(() => {
        onHide(false)
      }, 1000 * 60 * 5)
    }

    return () => {
      clearTimeout(timeOut)
      timeOut = null
    }
  }, [newCard])

  return (
    <div className={["home-visa-newcard-wrapper", unFold ? "packDown" : ""].join(" ")}>
      {newCard ? (
        <div>
          <img src={newCard} alt="" className="newCardImage" />
        </div>
      ) : (
        <div>
          <img src={imgBgCard} alt="" className="imgBgCard" />
          <div className="visa-content d-flex flex-column justify-content-between">
            {
              <div className="content-top">
                <span className="eyes">
                  {unFold &&
                    (!cardEyeStatus ? (
                      <EyeFill fontSize={18} color="#fff" onClick={() => changeEye()} />
                    ) : (
                      <EyeInvisibleOutline fontSize={18} color="#fff" onClick={() => changeEye()} />
                    ))}
                </span>
              </div>
            }
            {!hideInfo && (
              <div className="bank-num">
                {tempFinal.map((c, i) => {
                  if (i < 3) {
                    return (
                      <div key={`cardnum_${i}`} className="num xin">
                        {"****"}
                      </div>
                    )
                  }
                  return (
                    <div key={`cardnum_${i}`} className="num">
                      {c || "****"}
                    </div>
                  )
                })}
              </div>
            )}
            {!hideInfo && <div className="content-bottom textAlignLeft">{leftBottom}</div>}
          </div>
        </div>
      )}
    </div>
  )
}
