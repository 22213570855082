import React, { useState, useEffect, useRef } from 'react'
import { NavBar, Mask, Input, Button, DatePicker, Toast, Dropdown, Loading, Empty } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RightOutline, CloseCircleOutline } from 'antd-mobile-icons'
import { useSelector } from 'react-redux'
import './index.less'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { getStatementList, getBilledStatement, sendBilledStatement } from '@api/user'
import imgNoRecord from '@assets/images/record/img-no-record.png'
import { scrollToTop, timeTransfer } from '@utils/utils'
import ReactLoading from "react-loading"

// import PDF from 'react-pdf-js';
import { Document, Page, pdfjs } from 'react-pdf'
// 本地加载
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const StatementListItem = (props) => {
  const { item, showContent } = props
  const selectedLang = useSelector((state) => state.user.selectedLang)

  return (
    <div className='list-item' onClick={() => showContent(item)}>
      <div className='list-left'>
        <div className='date'>
          {/* {item.time} 月账单 */}
          {selectedLang === 'zh_CN' ? `${item.billing_month} ` : `${allMonths[Number(item.billing_month) - 1]} `}
          {t('statementList.statement_title')}
        </div>
        <div className='title'>
          {timeTransfer(item.billing_period_start, 'MM/DD')}-{timeTransfer(item.billing_period_end, 'MM/DD')}
        </div>
      </div>
      <RightOutline color='#333' fontSize={14} />
    </div>
  )
}

const SendEmailPop = (props) => {
  const { setIsVisible, sendEmail, sendLoading } = props
  const userInfo = useSelector((state) => state.user.userInfo)
  const [email, setEmail] = useState(userInfo.email)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  let regex = new RegExp(
    // '^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$',
    '^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$'
  )

  const emailCheck = (email) => {
    if(!regex.test(email)) {
      setIsEmailError(true)
      setIsDisable(true)
      return
    }

    sendEmail(email)
  }

  return (
    <div id='email-pop-container'>
      <CloseCircleOutline className='close' color='#fff' fontSize={36} onClick={() => setIsVisible(false)} />
      <h1 className='title'>{t('statementList.email_pop.title')}</h1>
      <div className='email-section'>
        <Input
          className={['email', isEmailError ? 'error' : ''].join(' ')}
          value={email}
          type='email'
          onChange={(val) => {
            if (!val) {
              setIsDisable(true)
              // setIsEmailError(false)
              setEmail(val)
              return
            }
            
            setIsDisable(false)
            setIsEmailError(false)
            setEmail(val)
          }}
        />
        {isEmailError && <div className='email-error'>{t('statementList.email_pop.error_msg')}</div>}
      </div>
      <Button className='send-email' disabled={isDisable} loading={sendLoading} onClick={() => emailCheck(email)}>
        {t('statementList.email_pop.send_btn')}
      </Button>
    </div>
  )
}

const StatementPop = (props) => {
  const { setIsVisible, showSendEmail, statement } = props
  const [totalPage, setTotalPages] = useState(1)
  const [loadDisabled, setLoadDisabled] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pdfFile, setPdfFile] = useState(null)
  const Dom = document.querySelector("#statement-pop-container")
  const clientWidth = Dom ? Dom.clientWidth : 700
  //计算缩放比例(595为浏览器控制台获取到的原pdf的canvas属性的宽度，该属性导致pdf宽度无法适配页面宽度,页面最宽为700)
  const scale = ((clientWidth > 700 ? 700 : clientWidth) / 595 - 0.1).toFixed(2)

  // const onDocumentLoadSuccess = (totalPage) => {
  //   setTotalPages(totalPage);
  //   setCurrentPage(1);
  // };

  useEffect(() => {
    const { billing_id } = statement

    getFile(billing_id)
  }, [statement])
  
  const getFile = async (billing_id) => {
    setLoadDisabled(true)
    const res = await getBilledStatement({ billing_id }, { responseType: "arraybuffer" })
    const blob = new Blob([res], {type: 'application/pdf'});
    setPdfFile(blob)
  }

  // react-pdf
  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoadDisabled(false)
    setTotalPages(numPages)
    setCurrentPage(1)
  }

  return (
    <div id='statement-pop-container'>
      <CloseCircleOutline className='close' color='#fff' fontSize={36} onClick={() => setIsVisible(false)} />

      <div className='pdfView'>
        { loadDisabled && <ReactLoading className='loading' type={"spinningBubbles"} color={"#fff"}/>}

        <div hidden>{currentPage}</div>
        {pdfFile && <Document
          file={pdfFile} //PDF文件在此
          onLoadSuccess={onDocumentLoadSuccess}
          noData={<Empty description='暂无报告' />}
        >
          {new Array(totalPage).fill('').map((cur, index) => {
            return (
              <div key={`x${index}`}>
                <Page scale={Number(scale)} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
              </div>
            )
          })}
        </Document>}

        {/* {pdfFile && <iframe src={pdfFile} width={'100%'} frameBorder={0} />} */}

        {/* <PDF scale={0.61} file={pdfFile} onDocumentComplete={onDocumentLoadSuccess} page={currentPage} loading={<Loading />} />

        {totalPage > 1 &&
          new Array(totalPage).fill('').map((cur, index) => {
            if (index >= 1) {
              return <PDF page={index} key={`x${index}`} file={pdfFile} scale={0.61} />;
            }
          })} */}
      </div>
      <Button className='send-email' disabled={loadDisabled} loading={loadDisabled} onClick={showSendEmail}>
        {t('statementList.email_pop.btn_txt')}
      </Button>
    </div>
  )
}

export default function StatementList() {
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [sendLoading, setEendLoading] = useState(false)
  const [isShowEmailPop, setIsShowEmailPop] = useState(false)
  const [isShowStatementPop, setIsShowStatementPop] = useState(false)
  const [visible, setVisible] = useState(false)
  const [statement, setStatement] = useState(null)
  const [billDate, setBillDate] = useState(null)
  const [list, setList] = useState([])
  const [year, setYear] = useState(dayjs().format('YYYY'))
  const DropdownRef = useRef(null)
  const now = new Date()
  const selectedLang = useSelector((state) => state.user.selectedLang)

  useEffect(() => {
    scrollToTop()
    // fetch()
  }, [])

  const fetch = async () => {
    // 获取账单数据
    setLoading(true)
    // 账单列表
    const res = await getStatementList({ year })

    setLoading(false)
    if(res.status_code === 100000) {
      const { statements, current_billing_date } = res.response_data

      setList(statements.list || [])
      setBillDate(current_billing_date)
      return
    }
    setList([])
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    setList([])
    // 重新获取账单列表
    fetch()
  }, [year])

  const confirmYearChange = (val) => {
    const checkYear = dayjs(val).format('YYYY')
    setYear(checkYear)
  }

  const showContent = (item) => {
    setStatement(item)
    setIsShowStatementPop(true)
  }

  const sendEmail = async (email) => {
    // 发送邮件
    if(!statement || !statement.billing_id) return
    const { billing_id } = statement
    setEendLoading(true)

    const res = await sendBilledStatement({ billing_id, email })

    if(res.status_code === 100000) {
      setIsShowEmailPop(false)
      setEendLoading(false)
      Toast.show({
        content: t('statementList.email_pop.send_success'),
        duration: 1000
      })
    }
  }

  const showSendEmail = () => {
    setIsShowStatementPop(false)
    setIsShowEmailPop(true)
  }

  // 显示账单
  if (isShowStatementPop) {
    return <StatementPop statement={statement} setIsVisible={setIsShowStatementPop} showSendEmail={showSendEmail} />
  }

  return (
    <div id='statement-list'>
      <NavBar
        style={{
          '--height': '56px',
          '--background': '#ffffff',
          marginBottom: '16px'
        }}
        onBack={goBack}>
        {t('statementList.title')}
      </NavBar>

      <div className='statement-list-content'>
        <div className='list-item' onClick={() => history.push('/pendingStatement')}>
          <div className='list-left'>
            <div className='date'>{t('statementList.pending_title')}</div>
            <div className='title'>
              {selectedLang === 'zh_CN' ? '账单日' : ''}
              {billDate && timeTransfer(billDate, selectedLang === 'zh_CN' ? 'MM/DD' : 'MMMM DD, YYYY')}
            </div>
          </div>
          <RightOutline color='#333' fontSize={14} />
        </div>
      </div>

      <div className='selectYear'>
        <Dropdown ref={DropdownRef} activeKey={null} onChange={() => setVisible(!visible)}>
          <Dropdown.Item
            key='sorter'
            title={year}
            onClick={() => {
              setVisible(true)
            }}></Dropdown.Item>
        </Dropdown>

        <DatePicker
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          precision='year'
          defaultValue={now}
          max={now}
          onConfirm={confirmYearChange}
        />
      </div>

      <div className='statement-list-content'>
        {loading && (
          <div className='alignCenter'>
            {t('common.loading')}
            <Loading />
          </div>
        )}
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <StatementListItem item={item} key={`stement_${index}`} showContent={showContent} hide={() => setIsShowStatementPop(false)} />
            )
          })}

        {list.length === 0 && !loading && (
          <div className='no-message'>
            <img src={imgNoRecord} alt='' />
            <p>{t('statementList.no_record')}</p>
          </div>
        )}
      </div>

      <Mask visible={isShowEmailPop}>
        <SendEmailPop
          setIsVisible={() => {
            setStatement(null)
            setIsShowEmailPop(false)
          }}
          sendLoading={sendLoading}
          sendEmail={sendEmail}
        />
      </Mask>
    </div>
  )
}
