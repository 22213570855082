import React, { useState } from 'react'
import { Input, Mask, DatePicker } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'
import './index.less'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const allMonths = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
]

export default function Birthday(props) {
    const { setYear, setMonth, setDay } = props
    const selectedLang = useSelector(state => state.user.selectedLang)
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
    const [birthday, setBirthday] = useState('')
    const isEng = selectedLang === 'en_US'

    const today = new Date()
    const maxAge = new Date()
    const minAge = new Date()
    maxAge.setFullYear(today.getFullYear() - 100)
    minAge.setFullYear(today.getFullYear() - 18)

    const closeDatePicker = () => {
        setIsDatePickerVisible(false)
    }

    const handleCallback = (type, data) => {
        if (isEng) {
            switch (type) {
                case 'year':
                    return data
                case 'month':
                    return allMonths[data - 1]
                case 'day':
                    return data
                default:
                    return data
            }
        } else {
            switch (type) {
                case 'year':
                    return data + '年'
                case 'month':
                    return data + '月'
                case 'day':
                    return data + '日'
                default:
                    return data
            }
        }
    }

    return (
        <div className="birthday-container">
            <div className="birthday" onClick={() => setIsDatePickerVisible(true)}>
                <Input
                    placeholder={t('steps.birthday')}
                    value={birthday}
                    onChange={setBirthday}
                    readOnly={true}
                    style={{
                        "--font-size": "14px"
                    }}
                />
                <DownOutline className="choose-date" fontSize={24} color="#BBBBBB"/>
            </div>
            <Mask visible={isDatePickerVisible} onMaskClick={closeDatePicker}>
                <DatePicker
                    title={t('steps.selectBirthday')}
                    renderLabel={handleCallback}
                    visible={isDatePickerVisible}
                    mouseWheel={true}
                    onClose={closeDatePicker}
                    defaultValue={minAge}
                    max={minAge}
                    min={maxAge}
                    onConfirm={val => {
                        const dateObj = new Date(val)
                        const year = dateObj.getFullYear()
                        const month = dateObj.getMonth() + 1
                        const day = dateObj.getDate()
                        setBirthday(isEng ? dayjs(val).format('MM/DD/YYYY') : dayjs(val).format('YYYY/MM/DD'))
                        setYear(year)
                        setMonth(month)
                        setDay(day)
                    }}
                    confirmText={t('general.confirm')}
                    cancelText={t('general.cancel')}
                />
            </Mask>
        </div>
    )
}