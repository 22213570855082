import React, { useState } from 'react'
import './index.less'

import KYCHeader from "@comps/KYC/KYCHeader"
import Birthday from "@comps/Birthday"
import KYCFooter from "@comps/KYC/KYCFooter"
import KYCContactUs from "@comps/KYC/KYCContactUs"
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'

export default function ReconfirmBirthday() {
  const history = useHistory()
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')

  const toSubmit = () => {
    history.push('/uploadPendingReview')
  }

  return (
    <div id='reconfirm-birthday-container'>
      <KYCHeader title={t('KYC.reconfirmBirthdayTitle')} subtitle={t('KYC.reconfirmBirthdaySubtitle')} close={history.goBack}/>
      <Birthday year={year} month={month} day={day} setYear={setYear} setMonth={setMonth} setDay={setDay}/>
      <KYCContactUs />
      <KYCFooter btnSubmit={toSubmit} btnText={t('KYC.reconfirmBirthdayBtnText')}/>
    </div>
  )
}
