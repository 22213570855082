import React from 'react'
import './index.less'
import Header from '@comps/nav/Header'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

export default function Begin() {
  const history = useHistory()
  const uploadWithNoSSN = () => {
    history.push('/xStepSix?from=nonssn')
  }
  const commitWithSSN = () => {
    history.push('/steps')
  }

  return (
    <div id='kyc-begin'>
      <Header />
      <div className='ssn-select'>
        <span className='title' dangerouslySetInnerHTML={{ __html: t('KYC.btnSSNSelect.title') }}></span>
        <div onClick={commitWithSSN} >{t('KYC.btnSSNSelect.ssn')}</div>
        <div onClick={uploadWithNoSSN}>{t('KYC.btnSSNSelect.nonssn')}</div>
      </div>
    </div>
  )
}