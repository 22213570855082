import React from 'react'
import './index.less'
import { Button } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {setAppGuideStatus} from '@redux/actions'

const staticPath = 'https://static1.front.youworld.us/gs/landingPage/'

export default function LandingPage() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const params = new URLSearchParams(history.location.search)
    const index = params.get('index') || 1
    const imgPathPrefix =  `${staticPath}${index}`
  return (
    <div id='landing-page' className={`landing-page${index}`}>
        <img src={`${imgPathPrefix}-1.png`} alt=""/>
        <img src={`${imgPathPrefix}-2.png`} alt=""/>
        <img src={`${imgPathPrefix}-3.png`} alt=""/>
        <div className="share-bottom">
            <Button className="share-btn" onClick={() => {
              if (!localStorage.getItem('token')) {
                dispatch(setAppGuideStatus(true))
              }
              history.push('/login')
            }}>{t('landingPage.shareBtn')}</Button>
        </div>
    </div>
  )
}