export const CHOOESS_RMB_METHODS = 'CHOOESS_RMB_METHODS'
export const UPDATE_ORDER_STATUS_ID = 'UPDATE_ORDER_STATUS_ID' 
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST'
export const SET_KYC_ENTRANCE = 'SET_KYC_ENTRANCE'
export const SET_KYC_BEHAVIOR = 'SET_KYC_BEHAVIOR'
export const GET_KYC_BEHAVIOR = 'GET_KYC_BEHAVIOR'

// rmb
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const SET_CURRENT_PAYMENT_METHODS = 'SET_CURRENT_PAYMENT_METHODS'

export const SET_RMB_BALANCE_POP = 'SET_RMB_BALANCE_POP'
export const SET_SWITCH_RMB_POP = 'SET_SWITCH_RMB_POP'
export const SET_SWITCH_RMB_UNABLED_POP = 'SET_SWITCH_RMB_UNABLED_POP'
export const SET_AMOUNT_RISK_POP = 'SET_AMOUNT_RISK_POP'
export const SET_PAY_STATUS = 'SET_PAY_STATUS'


export const GET_BANNER_CONTENT = 'GET_BANNER_CONTENT'
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK'
export const GET_TUTORIALS_BEHAVIOR = 'GET_TUTORIALS_BEHAVIOR'
export const SET_TUTORIALS_BEHAVIOR = 'SET_TUTORIALS_BEHAVIOR'

export const SET_APP_GUIDE_STATUS = 'SET_APP_GUIDE_STATUS'

export const PAYMENT_METHOD_SHOW = 'PAYMENT_METHOD_SHOW'
export const SET_RMB_TO_USD_VALUE = 'SET_RMB_TO_USD_VALUE'
export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST'
export const SET_TRANSACTION_TYPE = 'SET_TRANSACTION_TYPE'
export const SET_TRANSACTION_SEARCH_LIST = 'SET_TRANSACTION_SEARCH_LIST'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const SET_PAYMENT_FROM = "SET_PAYMENT_FROM"
export const SET_RMB_PAYMENT_SUCCESS = 'SET_RMB_PAYMENT_SUCCESS'

// order
export const GET_ACTIVITY_BY_PAYMENT_ORDER = 'GET_ACTIVITY_BY_PAYMENT_ORDER'
export const GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS = 'GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS'
export const SUBMIT_ORDER_REFUND_REQUEST = 'SUBMIT_ORDER_REFUND_REQUEST'
export const GET_ORDER_REFUND_REASON = 'GET_ORDER_REFUND_REASON'

// transaction
export const GET_TRANSACTION_DETAIL_BY_CODE = 'GET_TRANSACTION_DETAIL_BY_CODE'
export const GET_TRANSACTION_REFUND_REASON = 'GET_TRANSACTION_REFUND_REASON'
export const SUBMIT_TRANSACTION_REFUND_REQUEST = 'SUBMIT_TRANSACTION_REFUND_REQUEST'

export const SET_TRANSACTION_LIST_DATE = 'SET_TRANSACTION_LIST_DATE'
export const SET_PENDING_TRANSACTION_POP = 'SET_PENDING_TRANSACTION_POP'
export const SET_IS_ALL_LIST =  'SET_IS_ALL_LIST'
export const SET_CURRENT_ALL_LIST_TAB_TYPE = 'SET_CURRENT_ALL_LIST_TAB_TYPE'
export const SET_IS_RECORD_SEARCH_LIST = 'SET_IS_RECORD_SEARCH_LIST'

// receipt
export const SET_RECEIPTS = 'SET_RECEIPTS'


// order receipt
export const UPLOAD_ACTIVITY_RECEIPT = 'UPLOAD_ACTIVITY_RECEIPT'
export const DELETE_ACTIVITY_RECEIPT = 'DELETE_ACTIVITY_RECEIPT'
export const SUBMIT_ACTIVITY_RECEIPT = 'SUBMIT_ACTIVITY_RECEIPT'

// user related
export const SWITCH_SELECTED_LANG = 'SWITCH_SELECTED_LANG'
export const SET_IS_FIRST_VISIT = 'SET_IS_FIRST_VISIT'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_IS_TRANSACTION_PIN_SET = 'SET_IS_TRANSACTION_PIN_SET'


// tab bar
export const SET_TABBAR_BADGE = 'SET_TABBAR_BADGE'
export const GET_TABBAR_BADGE = 'GET_TABBAR_BADGE'

// commmon
export const SET_COMMON_POP_CONTENT = 'SET_COMMON_POP_CONTENT'
export const SET_COMMONN_POP_STATUS = 'SET_COMMONN_POP_STATUS'
export const SET_IS_NO_PERMISSION = 'SET_IS_NO_PERMISSION'

// setting password related
export const SET_PASSWORD_RELATED_CHANNEL = 'SET_PASSWORD_RELATED_CHANNEL'
export const SET_ACTION_TOKEN = 'SET_ACTION_TOKEN'

// gift card related
export const SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA'
export const SET_REFERENCE = 'SET_REFERENCE'
export const SET_CURRENT_CARD_INFO = 'SET_CURRENT_CARD_INFO'
export const SET_SEARCH_QUERY_KEY = 'SET_SEARCH_QUERY_KEY'
export const SET_CHECKED_PAYMENT = 'SET_CHECKED_PAYMENT'

// transfer related
export const SET_AMOUNT = 'SET_AMOUNT'
export const SET_TRANSFER_ORIGIN = 'SET_TRANSFER_ORIGIN'

// saving account 
export const SET_SAVING_ACCOUNT_TOKEN = 'SET_SAVING_ACCOUNT_TOKEN'
export const SET_SAVING_ACCOUNT_INFO = 'SET_SAVING_ACCOUNT_INFO'
export const SET_REFERRAL_CODE_FROM = 'SET_REFERRAL_CODE_FROM'
// 设置自动转入savings-account
export const SET_AUTO_ENTERIN_SAVING_ACCOUNT = "SET_AUTO_ENTERIN_SAVING_ACCOUNT"

// redeem reward
export const SET_REDEEM_INFO = 'SET_REDEEM_INFO'
export const SET_REDEEM_TYPE = 'SET_REDEEM_TYPE'

// 停服公告通知
export const SET_STOP_TAKING = 'SET_STOP_TAKING'

// 重置store
export const SET_STORE_RESSET = 'SET_STORE_RESSET'

// related bank info
export const SET_CURRENT_BANK_INFO = 'SET_CURRENT_BANK_INFO'
export const SET_BANK_LIST = 'SET_BANK_LIST'
export const SET_VERIFY_BANK_FROM = "SET_VERIFY_BANK_FROM"

export const SET_SELECTED_RECHARGE_BANK = 'SET_SELECTED_RECHARGE_BANK'
export const SET_SELECTED_WITHDRAWAL_BANK = 'SET_SELECTED_WITHDRAWAL_BANK'
export const SET_SELECTED_BANK = "SET_SELECTED_BANK"

// 设置首页guide显示
export const SET_HOME_THREE_GUIDE = "SET_HOME_THREE_GUIDE"

// 积分兑换返回
export const SET_REDEEM_REWARD_SUMMARY = "SET_REDEEM_REWARD_SUMMARY"

// plaid related
export const SET_PLAID_ORIGIN = 'SET_PLAID_ORIGIN'

export const SET_LOGIN_FROM_PAGE = 'SET_LOGIN_FROM_PAGE'

export const SET_GIFTCARD_PAYMENT_FROM = "SET_GIFTCARD_PAYMENT_FROM"

export const SET_FROM_MSG = "SET_FROM_MSG"
export const SET_ACTIVE_FROM_MSG = "SET_ACTIVE_FROM_MSG"

export const SET_HOME_EYE_STATUS = "SET_HOME_EYE_STATUS"
