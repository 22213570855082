import {
  SET_APP_GUIDE_STATUS,
} from '../actions/ActionTypes'

import  {
  rmb
} from '../actions/index'

export const setAppGuideStatus = (status) => {
  return {
    type: SET_APP_GUIDE_STATUS,
    status
  }
}

export {
  rmb
}