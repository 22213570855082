import React, { Component } from 'react'
import './index.less'
import addsuccesspic from '@assets/images/account/addsuccesspic.png'
import { Button,Toast } from 'antd-mobile'
import SvgIcon from '@comps/SvgIcon'
import { setPasswordRelatedChannel } from '@actions/password'
import { connect } from 'react-redux'
import { getPermissionByCode } from '@api/user'
import { t } from 'i18next'

const mapDispatchToProps = {
  setPasswordRelatedChannel,
}
class ActiveStatus extends Component {
  constructor(props) {
    super(props)
    this.state={}
  }
  goBack = () => {
    localStorage.setItem('isFromLogin', 'false')
    this.props.history.push({
      pathname: '/',
      state: {
        isflase: true
      }
    })
  }
  addBankInfo = async () => {
    // 判断是否有权限
    const res = await getPermissionByCode({ permissionCode: 'permission_external_account'})
    if (res.status_code === 300008) return
    
    sessionStorage.setItem("recognition", "recognition")
    const { is_transaction_pin_set } = JSON.parse(window.localStorage.getItem('userInfo'))
    if (is_transaction_pin_set) {
      this.props.history.push({
        pathname: `/associatedBank`
      })
    } else {
      this.props.setPasswordRelatedChannel({
				channel: 'addBank',
				type: 'set',
				url: '/associatedBank',
				backUrl: '/recharge'
			})
      this.props.history.push(`/verifyCode`)
    }   
  }
  bindWait = () => {
    localStorage.setItem('isFromLogin', 'false')
    this.props.history.push({
      pathname: '/', state: {
        isflase: true
      }
    })
  }
  goBacked = () =>{
    Toast.show({
      content: t('common.comingSoon'),
      duration: 1000,
    })
  }
  render() {
    return (
      <div className="active-start">
        <div className="active-start-top textAlignLeft">
          <p>
            <span>
              {t('steps.SucceededTitle')}<br />
              {t('steps.SucceededTitle1')}
            </span>
          </p>
          <p>
            <span style={{ marginTop: "10px", display: "inline-block" }}>
              {t('steps.SucceededSubTitle')}<br />
            </span>
          </p>
        </div>
        <img src={addsuccesspic} alt="" className="active-bg" />
        <div className="active-start-close" onClick={this.goBack}>
          <SvgIcon iconClass="close" />
        </div>

        <div className="active-start-btn">
          <div className="next-button-container d-flex flex-column align-items-center">
               <Button
                className="next-button1 d-flex align-items-center justify-content-center"
                onClick={this.addBankInfo}>
                    {t('steps.SucceededNextButton')}
              </Button>
           <div className="bind" onClick={this.bindWait}>{t('steps.SucceededNextButton1')}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ActiveStatus)