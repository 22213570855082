import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import imgNoRecord from '@assets/images/record/img-no-record.png'
import noLogin from '@assets/images/record/no_login.png'

import MyTabbar from '@comps/MyTabbar'
import avatar from '@assets/images/myHeader/photo.png'
import {
  Tabs, InfiniteScroll,
  PullToRefresh, Swiper, Mask,
  SearchBar, Button, Popup,
  Dialog, Toast
} from 'antd-mobile'
import './index.less'
import { useTranslation } from 'react-i18next'
import {
  QuestionCircleOutline, StarFill, EyeInvisibleOutline,
  EyeOutline
} from 'antd-mobile-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import TransactionListItem from '@comps/TransactionListItem'
import tillstarLoading from '@assets/images/common/tillstar_loading.gif'
import leadingStep1 from '@assets/images/record/leading_step1.png'
import leadingStep2 from '@assets/images/record/leading_step2.png'
import leadingStep1EN from '@assets/images/record/leading_step1_en.png'
import leadingStep2EN from '@assets/images/record/leading_step2_en.png'
import switchIcon from '@assets/images/record/icon_switch.png'
import { getRecentAccountHistoryList, getAllAccountHistoryList } from '@api/account'
import { getUserBehavior, submitUserBehavior, getUserInfo } from '@api/user'
import { t } from 'i18next'
import CommonBottomPopup from '@comps/CommonBottomPopup'
import tillstar from '@assets/images/record/card/tillstar.png'
import rmb_consumption from '@assets/images/record/card/rmb_consumption.png'
import savings_account from '@assets/images/record/card/savings_account.png'
import rocket from '@assets/images/record/rocket.png'
import arrow from '@assets/images/record/card/arrow.png'
import guide_cn from '@assets/images/record/card/guide_cn.png'
import guide_en from '@assets/images/record/card/guide_en.png'
import { handleDot } from '@utils/utils'
import VerifyTranPwd from '@pages/Home/VerifyTranPwd'
import { setActionToken, setPasswordRelatedChannel } from '@actions/password'
import { setLoginFromPage } from '@actions/common'
import { setIsAllList, setCurrentAllListTabType } from '@actions/transaction'
import FreezePasswordPop from '@comps/FreezePasswordPop'

import StopTakingNotice from "@comps/StopTakingNotice"

import { getModuleStatus } from "@api/common"
import { formatNumberToUSD } from '@utils'

const QueryContext = createContext()

const tabLists = [
  {
    tabs: ['usd_all', 'usd_consumption', 'usd_recharge', 'usd_withdraw', 'usd_transfer'],
    bgImg: tillstar,
    amountColor: '#C35A69',
    type: 'tillstar',
    productCode: 'tillstar_usd_account'
  },
  {
    tabs: ['rmb_all', 'rmb_buy', 'rmb_use', 'rmb_refund'],
    bgImg: rmb_consumption,
    amountColor: '#5EA3B4',
    type: 'rmb_consumption',
    productCode: 'tillstar_rmb_consumption_coin'
  },
  // 隐藏储蓄账户
  // {
  //   tabs: ['savings_all', 'savings_transferIn', 'savings_transferOut'],
  //   bgImg: savings_account,
  //   amountColor: '#BB9E65',
  //   type: 'savings_account',
  //   productCode: 'tillstar_savings_account'
  // }
]

const TransactionLists = (props) => {
  const {
    t, userInfo, isDisplayLoadingMask,
    setIsDisplayLoadingMask,
  } = useContext(QueryContext)
  const {
    actionToken, currentAllListTabType, setNavTabKey,
    productCode, activityType, setCurrentBalance,
    setIsShowSavingsAccountBalance,
    isShowSavingsAccountBalance,
    // setIsShownBalance,
    showStopTakingPop, setStopTakingPop
  } = props
  const history = useHistory()
  const location = useLocation()
  const isAllList = useSelector(state => state.transaction.isAllList)
  const dispatch = useDispatch()
  const [processingOrder, setProcessingOrder] = useState(null)
  const [finishedOrder, setFinishedOrder] = useState(null)
  const [pagination, setPagination] = useState({})
  const [hasMore, setHasMore] = useState(true)

  const getOrderList = async (page) => {
    try {
      const params = {
        page,
        productCode,
        activityType
      }
      if (productCode === 'tillstar_savings_account' && actionToken) {
        params.token = actionToken
        params.actionCode = 'DisplaySavingsAccount_SubmitRequest'
      }

      const res = isAllList ? await getAllAccountHistoryList(params) : await getRecentAccountHistoryList({ page })
      if (res.status_code === 100000) {
        if (page === 1) {
          const { processing, list, pagination, balance, is_shown_balance } = res.response_data
          if (isAllList) {
            if (!is_shown_balance) dispatch(setActionToken(''))
            setCurrentBalance(balance)
            setIsShowSavingsAccountBalance(is_shown_balance)
            // setIsShownBalance(is_shown_balance)
          }
          setProcessingOrder(processing)
          setFinishedOrder(list)
          setPagination(pagination)
        } else {
          const { pagination, list } = res.response_data
          setFinishedOrder(finishedOrder.concat(list))
          setPagination(pagination)
        }
      } else if (res.status_code === 301006) {
        Toast.show({
          content: t('record_list.card_info.token_expired'),
          duration: 5000,
        })
        dispatch(setIsAllList(false))
        dispatch(setCurrentAllListTabType(0))
        dispatch(setActionToken(''))
      } else {
        setProcessingOrder([])
        setFinishedOrder([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const initData = async (type = 'init') => {
    setProcessingOrder(null)
    setFinishedOrder(null)
    setCurrentBalance({})
    await getOrderList(1)
    type === 'init' && setIsDisplayLoadingMask(false)
  }

  useEffect(() => {
    if (isAllList) {
      setNavTabKey(tabLists[currentAllListTabType].tabs[0])
    }
    if (Object.keys(userInfo).length) initData()
  }, [isAllList])

  useEffect(() => {
    if (Object.keys(userInfo).length) initData()
  }, [activityType, actionToken])

  useEffect(() => {
    const { current_page, page_size, total } = pagination
    setHasMore(current_page * page_size < total)
  }, [pagination])

  const loadMore = async () => {
    const { current_page } = pagination
    await getOrderList(current_page + 1)
  }

  return (
    <>
      {Object.keys(userInfo).length > 0 && isDisplayLoadingMask && <div><img className='tillstar-loading-icon' src={tillstarLoading} alt="" /></div>}
      <PullToRefresh onRefresh={() => initData('pull')} renderText={() => <div><img className='tillstar-loading-icon' src={tillstarLoading} alt="" /></div>}>
        {
          Object.keys(userInfo).length === 0 ? <div className="no-login">
            <img src={noLogin} alt='' />
            <button className="login-btn" onClick={() => {
              dispatch(
                setLoginFromPage({
                  pathname: "/recordList",
                  search: ""
                })
              )
              history.push({
                pathname: '/login',
                from: location.pathname
              })
            }}>{t('common.login_in')}</button>
          </div> :
            (processingOrder?.length || finishedOrder?.length) ?
              <>
                {
                  processingOrder?.length > 0 &&
                  <div className='list'>
                    <h2 className="list-title">{t('record_list.processing')}</h2>
                    <TransactionListItem transactionListData={processingOrder} t={t}
                      setStopTakingPop={setStopTakingPop}
                      showStopTakingPop={showStopTakingPop}
                      isShowBalance={productCode !== 'tillstar_usd_account'}
                    />
                  </div>
                }
                {
                  finishedOrder?.length > 0 &&
                  <div className='list'>
                    {!isAllList && <h2 className="list-title">{t('record_list.finished')}</h2>}
                    <TransactionListItem isShowSavingsAccountBalance={isShowSavingsAccountBalance} transactionListData={finishedOrder} t={t}
                      setStopTakingPop={setStopTakingPop}
                      showStopTakingPop={showStopTakingPop}
                      isShowBalance={productCode !== 'tillstar_usd_account'}
                    />
                  </div>
                }
              </> : (processingOrder === null && (finishedOrder === null || finishedOrder.length === 0)) ? <div className='no-record-text'>{t('common.loading')}</div> :
                <>
                  <div className='no-record'><img src={imgNoRecord} alt='' /></div>
                  <div className='no-record-text'>{t('transaction_list.no_transaction')}</div>
                </>
        }
        {finishedOrder && finishedOrder.length > 0 && <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />}
      </PullToRefresh>
    </>
  )
}

const CardInfoItem = (props) => {
  const {
    actionToken,
    info, setTipType, setIsAccountTipShow, currentBalance,
    verifyPwd, isShowSavingsAccountBalance, setIsShowSavingsAccountBalance
  } = props
  const { bgImg, type, amountColor } = info
  const { total_balance, available_balance, total_cashback_amount } = currentBalance
  const totalBalance = handleDot(total_balance || '0.00')
  const availableBalance = handleDot(available_balance || '0.00')

  return (
    <div className="card-info" style={{
      backgroundImage: `url(${bgImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <div className="top">
        <div className="balance">
          <span className="label">
            {t(`record_list.card_info.${type}.top_title`)}
            <QuestionCircleOutline fontSize={12} color="#828282" onClick={async () => {
              // 停机维护
              let result = await getModuleStatus('module_billing_detail');
              if (result.status_code === 300012) return

              setTipType(`${type}.top_tip`)
              setIsAccountTipShow()
            }} />
          </span>
          <span className="value" style={{ color: amountColor }}>
            <span className="symbol">$</span>
            {type === 'savings_account'
              ?
              isShowSavingsAccountBalance
                ? <>
                  <span className="amount">{formatNumberToUSD(totalBalance)}</span>
                  <EyeOutline color="#BB9E65" fontSize={16} onClick={async () => {
                    // 停机维护
                    let result = await getModuleStatus('module_billing_detail');
                    if (result.status_code === 300012) return

                    setIsShowSavingsAccountBalance(false)
                  }} />
                </>
                : <>
                  <span className="amount">******</span>
                  <EyeInvisibleOutline color="#BB9E65" fontSize={16} onClick={async () => {
                    if (actionToken) {
                      // 停机维护
                      let result = await getModuleStatus('module_billing_detail');
                      if (result.status_code === 300012) return
                      setIsShowSavingsAccountBalance(true)
                    } else {
                      verifyPwd()
                    }
                  }} />
                </>
              : <span className="amount">{formatNumberToUSD(totalBalance)}</span>}
          </span>
        </div>
      </div>
      {type !== 'rmb_consumption' && <div className="bottom">
        <div className="balance">
          <span className="label">
            {t(`record_list.card_info.${type}.bottom_title`)}
            <QuestionCircleOutline fontSize={12} color="#828282" onClick={async () => {
              // 停机维护
              let result = await getModuleStatus('module_billing_detail');
              if (result.status_code === 300012) return

              setTipType(`${type}.bottom_tip`)
              setIsAccountTipShow()
            }} />
          </span>
          <span className="value">
            {type === 'savings_account' ? <>
              <StarFill color="#F9941E" />
              <span className="amount">{total_cashback_amount !== undefined ? formatNumberToUSD(total_cashback_amount) : ''}</span>
            </> : <>
              <span className="symbol">$</span>
              <span className="amount">{formatNumberToUSD(availableBalance)}</span>
            </>}

          </span>
        </div>
      </div>}
    </div>
  )
}

const CardInfo = (props) => {
  const {
    isShowSavingsAccountBalance,
    setIsShowSavingsAccountBalance,
    currentScrollTop,
    currentAllListTabType,
    setTipType, setIsAccountTipShow,
    setCurrentAllListTabType,
    currentBalance, setNavTabKey,
    verifyPwd,
    actionToken, setStopTakingPop, showStopTakingPop
  } = props
  return (
    <Swiper
      className={["card-info-box", currentScrollTop !== 0 && 'hide'].join(' ')}
      indicator={() => null}
      defaultIndex={currentAllListTabType}
      loop
      onIndexChange={(index) => {
        setCurrentAllListTabType(index)
        setNavTabKey(tabLists[index].tabs[0])
      }}
    >
      {
        tabLists.map((item, index) =>
          <Swiper.Item key={index}>
            <CardInfoItem
              actionToken={actionToken}
              verifyPwd={verifyPwd}
              info={item}
              setTipType={setTipType}
              setIsAccountTipShow={setIsAccountTipShow}
              currentBalance={currentBalance}
              setIsShowSavingsAccountBalance={setIsShowSavingsAccountBalance}
              isShowSavingsAccountBalance={isShowSavingsAccountBalance}
              setStopTakingPop={setStopTakingPop}
              showStopTakingPop={showStopTakingPop}
            />
          </Swiper.Item>
        )
      }
    </Swiper>
  )
}

const NavTab = (props) => {
  const { navTabKey, tabsObj, setNavTabKey } = props
  return (
    <Tabs activeKey={navTabKey} onChange={(key) => setNavTabKey(key)}>
      {
        tabsObj.map((item, index) => (
          <Tabs.Tab title={t(`record_list.card_info.tabs.${item}`)} key={item}></Tabs.Tab>
        ))
      }
    </Tabs>
  )
}


export default function TList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userInfo = useSelector(state => state.user.userInfo)
  const isAllList = useSelector(state => state.transaction.isAllList)
  const currentAllListTabType = useSelector(state => state.transaction.currentAllListTabType)
  const [isDisplayLoadingMask, setIsDisplayLoadingMask] = useState(true)
  const [isLeadingStepVisible, setIsLeadingStepVisible] = useState(false)
  const selectedLang = useSelector(state => state.user.selectedLang)
  const [isAccountTipShow, setIsAccountTipShow] = useState(false)
  const [currentBalance, setCurrentBalance] = useState({
    total_balance: '--',
    available_balance: '--',
    total_cashback_amount: '--'
  })
  const [tipType, setTipType] = useState('tillstar.top_tip')
  const [navTabKey, setNavTabKey] = useState(tabLists[currentAllListTabType].tabs[0] || 'usd_all')
  const scrollBoxRef = useRef()
  const [currentScrollTop, setCurrentScrollTop] = useState(0)
  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const actionToken = useSelector(state => state.password.actionToken)
  const verifyTranPwdHandler = useRef()
  const isFreeze = userInfo.default_financial_account?.status === 2
  const [isGuideShow, setIsGuideShow] = useState(false)
  // const [isShownBalance, setIsShownBalance] = useState(false)
  const [isShowSavingsAccountBalance, setIsShowSavingsAccountBalance] = useState(false)
  const showStopTakingPop = useSelector(state => state.common.showStopTakingPop)
  const [stopTakingPop, setStopTakingPop] = useState(false)

  const setCurrentTabType = (type) => {
    dispatch(setCurrentAllListTabType(type))
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0 // ios置顶
    window.scrollTo(0, 0) //ios之外置顶
    init()

    return () => {
      setIsShowSavingsAccountBalance(false)
    }
  }, [])

  const init = async () => {
    try {
      if (Object.keys(userInfo).length) {
        const res = await getUserBehavior('first_record_list_leading')
        if (res.status_code === 100000) {
          if (!res.response_data.status) {
            setIsLeadingStepVisible(true)
            submitUserBehavior('first_record_list_leading')
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
    scrollBoxRef.current?.addEventListener('scroll', (e) => {
      setCurrentScrollTop(e.target.scrollTop)
    })
  }

  useEffect(async () => {
    if (isAllList && Object.keys(userInfo).length) {
      const res = await getUserBehavior('first_record_all_list_leading')
      if (res.status_code === 100000) {
        if (!res.response_data.status) {
          setIsGuideShow(true)
          submitUserBehavior('first_record_all_list_leading')
        }
      }
    }
  }, [isAllList])

  const setCurrentActionToken = (token) => {
    dispatch(setActionToken(token))
  }
  //验证交易密码
  const verifyPwd = async () => {
    if (isFreeze) {
      Toast.show({
        content: t('realAuthentication.dollaraccountfrozen'),
        duration: 1000,
      })
      return
    }
    const userInfoRes = await getUserInfo()

    if (userInfoRes.status_code === 100000) {
      const userInfo = userInfoRes?.response_data

      if (userInfo.is_transaction_pin_set) {
        !verifyTranPwdHandler.current && (verifyTranPwdHandler.current = Dialog.show({
          closeOnMaskClick: true,
          className: 'verifyPWDDialog',
          onClose: () => {
            verifyTranPwdHandler.current = null
          },
          content: <VerifyTranPwd
            actionType="checkSavingAccount"
            setPasswordActionToken={setCurrentActionToken}
            forgetPassword={forgetPassword}
            closeVerifyTranPwd={() => {
              verifyTranPwdHandler.current.close()
              setIsShowSavingsAccountBalance(true)
            }}
            openFreezePasswordPop={() => {
              verifyTranPwdHandler.current.close()
              setIsPasswordFreeze(true)
            }} />,
        }))
      } else {
        setTimeout(() => {
          dispatch(setPasswordRelatedChannel({
            channel: 'checkAccount',
            type: 'set',
            url: '/recordList',
            backUrl: '/recordList',
          }))
          history.push('/verifyCode')
        }, 2000)
      }
    }
  }

  const forgetPassword = () => {
    verifyTranPwdHandler.current.close()
    dispatch(setPasswordRelatedChannel({
      channel: 'normalSetting',
      type: 'reset',
      url: '/recordList',
      backUrl: '/recordList',
    }))
    history.push('/resetTransactionPasswordA')
  }

  const leadingSteps = [
    leadingStep1,
    leadingStep2
  ]

  const leadingStepsEN = [
    leadingStep1EN,
    leadingStep2EN
  ]

  const leadingStepsItems = selectedLang === 'zh_CN' ? leadingSteps.map((item, index) => (
    <Swiper.Item key={index}>
      <img src={item} alt="" />
    </Swiper.Item>
  )) : leadingStepsEN.map((item, index) => (
    <Swiper.Item key={index}>
      <img src={item} alt="" />
    </Swiper.Item>
  ))

  const handleAvatar = () => {
    if (Object.keys(userInfo).length > 0) {
      history.push('/mine')
    } else {
      dispatch(
        setLoginFromPage({
          pathname: "/recordList",
          search: ""
        })
      )
      history.push('/login')
    }
  }

  const handleSearch = () => {
    if (Object.keys(userInfo).length === 0) {
      dispatch(
        setLoginFromPage({
          pathname: "/recordList",
          search: ""
        })
      )
      history.push({
        pathname: '/login',
        from: location.pathname
      })
      return
    }

    history.push(`/recordSearchList`)
  }

  const switchListType = async () => {
    // 停机维护
    let result = await getModuleStatus('module_billing_detail');
    if (result.status_code === 300012) return


    if (Object.keys(userInfo).length === 0) {
      dispatch(
        setLoginFromPage({
          pathname: "/recordList",
          search: ""
        })
      )
      history.push({
        pathname: '/login',
        from: location.pathname
      })
      return
    }
    dispatch(setIsAllList(!isAllList))
    dispatch(setCurrentAllListTabType(0))
    // if (!isAllList) {
    //   if (actionToken) {
    //     dispatch(setIsAllList(true))
    //     return
    //   }
    //   verifyPwd()
    // } else {
    //   dispatch(setIsAllList(false))
    //   dispatch(setCurrentAllListTabType(0))
    // }
  }

  return (
    <div id="record-transaction-list">
      <QueryContext.Provider value={{ userInfo, t, dispatch, isDisplayLoadingMask, setIsDisplayLoadingMask }}>
        {/* 停服公告 */}
        <div className='topNotice'>
          <StopTakingNotice stopTakingPop={stopTakingPop}
            setStopTakingPop={setStopTakingPop}
            moduleCode="module_billing_detail"
          />
        </div>

        <div className="header-section">
          <SearchBar
            placeholder={t('record_list.search_placeholder')}
            showCancelButton
            style={{
              '--border-radius': '38px',
              // '--background': '#EEF3FF',
              '--height': '32px',
              '--padding-left': '12px',
              '--placeholder-color': '#999999'
            }}
            onFocus={handleSearch}
          />
          <div className="title-section">
            <div className="left-section">
              <span className="title">{isAllList ? t('record_list.all_list_title') : t('record_list.recently_list_title')}</span>
              <Button className="switch" onClick={switchListType}>
                <img src={switchIcon} alt="" />
                <span className="label">{isAllList ? t('record_list.check_recently_list') : t('record_list.check_all_lists')}</span>
              </Button>
            </div>
            <div className='trackContainer'>
              <img src={avatar} className="avatar" alt="" onClick={handleAvatar} />
              <div className='trackReturn' hidden>我的信息</div>
            </div>
          </div>
          {isAllList && <>
            {/* <div className="statement" onClick={() => history.push('/statementList')}>
              <div className="statement-left">
                <FileOutline color="#1F49DA"/>
                <div className="statement-txt">{t('record_list.statement')}</div>
              </div>
              <RightOutline color="#333333"/>
            </div> */}
            <CardInfo
              actionToken={actionToken}
              isShowSavingsAccountBalance={isShowSavingsAccountBalance}
              setIsShowSavingsAccountBalance={setIsShowSavingsAccountBalance}
              verifyPwd={verifyPwd}
              currentAllListTabType={currentAllListTabType}
              currentScrollTop={currentScrollTop}
              setTipType={setTipType}
              setIsAccountTipShow={() => setIsAccountTipShow(true)}
              setCurrentAllListTabType={setCurrentTabType}
              currentBalance={currentBalance}
              setNavTabKey={setNavTabKey}
              setStopTakingPop={setStopTakingPop}
              showStopTakingPop={showStopTakingPop}
            />
          </>
          }
        </div>
        <div className="lists-section">
          {isAllList && <NavTab
            navTabKey={navTabKey}
            tabsObj={tabLists[currentAllListTabType].tabs}
            setNavTabKey={setNavTabKey}
          />}
          <div className="transaction-lists" ref={scrollBoxRef}>
            <TransactionLists
              setNavTabKey={setNavTabKey}
              // setIsShownBalance={setIsShownBalance}
              isShowSavingsAccountBalance={isShowSavingsAccountBalance}
              actionToken={actionToken}
              productCode={tabLists[currentAllListTabType].productCode}
              currentAllListTabType={currentAllListTabType}
              activityType={navTabKey}
              setIsShowSavingsAccountBalance={setIsShowSavingsAccountBalance}
              setCurrentBalance={setCurrentBalance}
              setStopTakingPop={setStopTakingPop}
              showStopTakingPop={showStopTakingPop}
            />
          </div>
        </div>
        <MyTabbar />
        <Mask className="leading-mask" visible={isLeadingStepVisible} onMaskClick={() => setIsLeadingStepVisible(false)}>
          <Swiper className="leading-steps" loop>
            {leadingStepsItems}
          </Swiper>
          <button onClick={() => setIsLeadingStepVisible(false)}>{t('common.pop.btn')}</button>
        </Mask>
        <Popup visible={isAccountTipShow} closeOnMaskClick={true} onMaskClick={() => setIsAccountTipShow(false)}>
          <CommonBottomPopup
            title={t(`record_list.card_info.${tipType}.title`)}
            content={t(`record_list.card_info.${tipType}.content`)}
          />
        </Popup>
        {currentScrollTop > 0 && <div className="rocket-box" onClick={() => {
          scrollBoxRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }}>
          <img className="rocket" src={rocket} alt="tocket" />
          <span>{t('record_list.card_info.back_to_top')}</span>
        </div>}
        <Mask visible={isPasswordFreeze}>
          <FreezePasswordPop closeFreezePop={() => {
            setIsPasswordFreeze(false)
            history.push('/')
          }} />
        </Mask>
        <Mask visible={isGuideShow}>
          <div className="guide-box">
            <img className="guide-card" src={selectedLang === 'zh_CN' ? guide_cn : guide_en} alt="" />
            <img className="guide-arrow" src={arrow} alt="" />
            <p className="guide-tip">{t('record_list.card_info.guide_tip')}</p>
            <Button onClick={() => setIsGuideShow(false)}>{t('rmb.tutorials_btn')}</Button>
          </div>
        </Mask>
      </QueryContext.Provider>
    </div>
  )
}
