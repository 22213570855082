import React, { useState, useEffect } from "react"
import { NavBar } from "antd-mobile"
import { RightOutline } from "antd-mobile-icons"
import { t } from "i18next"
import { useHistory } from "react-router-dom"
import Avatar from "react-avatar"
import "./index.less"

import { getUserInfo } from "@api/user"

import { scrollToTop } from "@utils/utils"

import tillstarLoading from "@assets/images/common/tillstar_loading.gif"
import pic_touxiang from "@assets/images/mine/avatar.svg"

const colorList = ["#FF3841", "#4369ED", "#7660FF", "#DF70DB", "#D6DF70", "#FFD43D", "#F9941E", "#4369ED", "#85B918", "#08B1C3"]

export default function PersonInfo(props) {
  const history = useHistory()
  const [userInfo, setUserInfos] = useState({})
  const [displayLoadingMask, setDisplayLoadingMask] = useState(true)
  const [verifyStatus, setVerifyStatus] = useState(0)
  const [color, setColoe] = useState("#fff")

  useEffect(() => {
    scrollToTop()
    initData()
  }, [])

  const initData = async () => {
    try {
      setDisplayLoadingMask(true)
      const res = await getUserInfo()
      setDisplayLoadingMask(false)
      if (res.status_code === 100000) {
        const info = res.response_data
        const verifyStatus = info?.identity_verification_status
        const colorIdx = info?.mobile.charAt(info?.mobile.length - 1)

        setColoe(colorList[colorIdx])
        setVerifyStatus(verifyStatus)
        setUserInfos(info)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const goBack = () => {
    history.replace("/mine")
  }

  const editAvatar = () => {
    history.push("/mine/updateAvatar")
  }

  const editId = () => {
    history.push("/mine/updateTillstarID")
  }

  // const editName = () => {
  //   history.push("/mine/updateName")
  // }

  const authentication = () => {
    history.push("/mine/authentication")
  }

  const transPhone = (mobile) => {
    return mobile?.replace(/(\d{3})\d*(\d{4})/, "$1****$2")
  }

  return (
    <div id="personInfo">
      <NavBar
        style={{
          "--height": "36px",
          "--background": "#ffffff",
        }}
        onBack={goBack}
      >
        {t("mine.myInfo")}
      </NavBar>

      {displayLoadingMask && (
        <div>
          <img className="tillstar-loading-icon" src={tillstarLoading} alt="" />
        </div>
      )}
      <div className="module-list">
        <div className="list-item" onClick={() => editAvatar()}>
          <div className="item-left">
            <p>{t("mine.avatar")}</p>
          </div>
          <div className="item-right">
            {/* 头像 */}
            <div className="item-avatar">
              {userInfo && userInfo?.avatar ? (
                <img src={userInfo?.avatar} alt="" />
              ) : (userInfo?.first_name || userInfo?.last_name) ? (
                <Avatar
                  name={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                  size={"0.93333rem"}
                  textSizeRatio={2.22}
                  round="50%"
                  color={color}
                />
              ) : (
                <img src={pic_touxiang} alt="" />
              )}
            </div>
            <RightOutline />
          </div>
        </div>

        <div className="list-item" onClick={() => editId()}>
          <div className="item-left">
            <p>{t("mine.ID")}</p>
          </div>
          <div className="item-right">
            <p className="langName">@{userInfo?.tillstar_id}</p>
            <RightOutline />
          </div>
        </div>

        {/* <div className="list-item" onClick={() => editName()}>
          <div className="item-left">
            <p>{t("mine.name")}</p>
          </div>
          <div className="item-right">
            <p>{`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}</p>
            <RightOutline />
          </div>
        </div> */}

        <div className="list-item">
          <div className="item-left">
            <p>{t("mine.phone")}</p>
          </div>
          <div className="item-right">
            <p>{transPhone(userInfo?.mobile)}</p>
          </div>
        </div>

        <div className="list-item">
          <div className="item-left">
            <p>{t("mine.email")}</p>
          </div>
          <div className="item-right">
            <p>{userInfo?.email || t("account.noSetEmail")}</p>
          </div>
        </div>
      </div>

      <div className="module-list mrg_12">
        <div className="list-item" onClick={() => authentication()}>
          <div className="item-left">
            <p>{t("account.VerifyIdentity")}</p>
          </div>
          <div className="item-right">
            <p>{verifyStatus === 4 ? t("mine.authorized", { name: "" }) : t("mine.unauthorized")}</p>
            <RightOutline />
          </div>
        </div>
      </div>
    </div>
  )
}
