import React, { Component } from 'react';
import { Modal, Mask } from 'antd-mobile'
import { CloseCircleOutline } from 'antd-mobile-icons'
import { withRouter } from 'react-router-dom'
import { t } from "i18next"
import noticeIcon from '@assets/images/common/notice.png'
import './index.less'
import { connect } from 'react-redux'

const mapStateToProps = (store) => {
    return {
      passwordRelatedChannel: store.password.passwordRelatedChannel,
    }
  }

class ExceptionPop extends Component {
    constructor(props) {
        super(props)
        this.state={}
    }

    backUrl = () => {
        if (['set', 'reset', 'edit', 'freeze'].includes(this.props.passwordRelatedChannel.type)) {
            this.props.history.replace({
                pathname: this.props.passwordRelatedChannel.backUrl,
                search: this.props.passwordRelatedChannel.search
            })
        }
    }
    render() {
    const { visible, onClose, content } = this.props
      return (
        <Mask visible={visible}>
            <Modal
            visible={visible}
            content={
                <div className="exception-pop">
                    <div className='mask-close' onClick={onClose}>
                        <CloseCircleOutline fontSize={36} color="#FFF"/>
                    </div>
                    <div className='mask-center' style={{paddingBottom: "10px"}}>
                        <img className='notice-icon' src={noticeIcon} alt="" />
                        {content}
                    </div>
                </div>
            }
            closeOnAction={true}
            onClose={onClose}
            onAction={this.backUrl}
            actions={[
                {
                key: 'confirm',
                text: t("linkAccountSucceeded.GotIt"),
                className: 'confirm-btn'
                },
            ]}
            />
        </Mask>
      )
    }
}

export default connect(mapStateToProps, null)(withRouter(ExceptionPop))
