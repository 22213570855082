import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { geTransationDetailByCode } from '@actions/transaction'
import { setRmbPaymentSuccess } from '@actions/rmb'


export default function CallBack() {
  const history = useHistory()
  
  const params = new URLSearchParams(history.location.search.slice(1))
  const dispatch = useDispatch()

  useEffect(() => {
    const code = JSON.parse(window.sessionStorage.getItem('pay')).code
    const payment_from = JSON.parse(window.sessionStorage.getItem('pay_from')).from
    if(payment_from === 'transaction') {
      dispatch(geTransationDetailByCode({activityCode: code}))
      // 控制返回
      dispatch(setRmbPaymentSuccess({ status: true }))
      setTimeout(() => {
        window.location.href = `/rmbOrder?code=${code}`
      }, 1500)
    } else {
      if(params.get('status') === 'success') {
        // 控制返回
        dispatch(setRmbPaymentSuccess({ status: true }))
        window.location.href = `/rmbOrder?code=${code}`
      } else {
        history.push('/rmb?status=fail')
      }
    }
  }, [])
  
  return (
    <div></div>
  )
}
