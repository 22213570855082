// 转账
import request from "@utils/request";
// 当前公共请求地址前缀
const url_prefix = "/api";
 
// 设置交易密码
export const setTransactionPin = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/set-transaction-pin`,
    data
  });
};
 
// 修改交易密码
export const editTransactionPin = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/modify-transaction-pin`,
    data
  });
}; 


export const findPassowrdPin = (data) => {
  return request({
    method: "POST",
    url: `${url_prefix}/retrieve-transaction-pin`,
    data
  });
}; 

// 验证短信
export const checkVserify = (data) => {
  return request({
    method: "POST",
    url: `utility/check-verify-code`,
    data
  });
};

// 获取个人用户信息
export const getUserInfo = () => {
  return request({
    method: "GET",
    url: `/app/user/info`,
  })
}
 
export const getPhoneCode = (actionCode) => {
  return request({
    method: "POST",
    url: `/app/auth/request-otp-for-business`,
    data: {
      actionCode
    },
  });
};

export const verifyPhoneCode = (actionCode,token,otp) => {
  return request({
    method: "POST",
    url: `/app/auth/verify-otp-for-business`,
    data: {
      actionCode,
      token,
      otp
    },
  });
};

export const freezeAccount = (actionCode,token) => {
  return request({
    method: "POST",
    url: `/app/auth/freeze`,
    data: {
      actionCode,
      token
    },
  });
};

export const uploadDocumentInfo = (actionCode,token,firstName,lastName,identityNumber) => {
  return request({
    method: "POST",
    url: `/app/auth/verify-name-and-identity`,
    data: {
      actionCode,
      token,
      firstName,
      lastName,
      identityNumber
    },
  });
};
// 获取余额
export const getBalance = () => {
  return request({
    method: "GET",
    url: `/app/account/get-balance`,
  });
}

export const setTradePassword = (actionCode,token,transactionPin) => {
  return request({
    method: "POST",
    url: `/app/auth/initial-transaction-pin-set-new-pin`,
    data: {
      actionCode,
      token,
      transactionPin
    },
  });
}

export const againSetTradePassword = (actionCode,token,transactionPinConfirm) => {
  return request({
    method: "POST",
    url: `/app/auth/initial-transaction-pin-confirm-and-set-pin`,
    data: {
      actionCode,
      token,
      transactionPinConfirm
    },
  });
}

export const verifyTransactionPin = ({ 
  actionCode, transactionPin
}) => {
  return request({
    method: "POST",
    url: `/app/auth/verify-transaction-pin`,
    data: {
      actionCode,
      transactionPin
    },
  });
}

// 根据手机号搜索用户账户
export const searchBy = (data) => {
  return request({
    method: "POST",
    url: `/app/user/search-by-mobile`,
    data
  });
};

export const editTradePassword = (actionCode,token,transactionPin) => {
  return request({
    method: "POST",
    url: `/app/auth/update-transaction-pin-set-new-pin`,
    data: {
      actionCode,
      token,
      transactionPin
    }
  });
};

export const againEditTradePassword = (actionCode,token,transactionPinConfirm) => {
  return request({
    method: "POST",
    url: `/app/auth/update-transaction-pin-confirm-and-set-pin`,
    data: {
      actionCode,
      token,
      transactionPinConfirm
    }
  });
};

export const getCardImage = (actionCode,token) => {
  return request({
    method: "POST",
    url: `/app/account/get-card-image`,
    data: {
      actionCode,
      token
    }
  });
}

export const identityName = (data) => {
  return request({
    method: "POST",
    url: `/app/auth/update-name-in-identity-verification-request`,
    data
  });
};

export const updateLanguage = (data) => {
  return request({
    method: 'POST',
    url: `/app/user/update-preferred-language`,
    data
  });
};

export const getPermissionByCode = (data) => {
  return request({
    method: 'POST',
    url: `/app/auth/get-permission-by-code`,
    data
  })
}

// 获取地址
export const getUserAddress = () => {
  return request({
    method: 'GET',
    url: `/app/user/address`,
  })
}

// 更新用户地址
export const updateUserAddress = (data) => {
  return request({
    method: 'POST',
    url: `/app/user/update-address`,
    data
  })
}

export const getUserBehavior = (behaviorCode) => {
  return request({
    method: 'POST',
    url: `/app/user/get-behavior-data`,
    data: {
      behaviorCode
    }
  })
}

export const submitUserBehavior = (behaviorCode) => {
  return request({
    method: "POST",
    url: `/app/user/submit-behavior-data`,
    data: {
      behaviorCode
    }
  })
}

export const getReferralCode = (data) => {
  return request({
    method: 'POST',
    url: `app/user/get-referral-code`,
    data
  })
}
export const syncReferralCode = (data) => {
  return request({
    method: 'POST',
    url: `app/user/sync-referral-code`,
    data
  })
}

export const keepLogger = (data) => {
  return request({
    method: 'POST',
    url: `app/user/logger`,
    data
  });
};

// 根据搜索用户账户
export const searchByQuery = (data) => {
  return request({
    method: "POST",
    url: `/app/user/search-transfer-user`,
    data
  });
};

export const updateTillstarID = (data) => {
  return request({
    method: "POST",
    url: `/app/user/update-tillstar-id`,
    data
  })
}

export const updateAvatar = (data) => {
  return request({
    method: "POST",
    url: `/app/user/update-avatar`,
    data
  })
}

export const getIdentityVerificationResult = () => {
  return request({
    method: "GET",
    url: `/app/user/get-identity-verification-result`
  })
}

export const uploadVerificationAdditionalDocuments = (data) => {
  return request({
    method: "POST",
    url: `app/auth/upload-verification-additional-documents`,
    data
  })
}

/**
 * 账单相关接口
 */

// 账单列表
export const getStatementList = (data) => {
  return request({
    method: "POST",
    url: `app/trust-credit/get-statement-list`,
    data
  })
}

// 未出账单
export const getUnbilledStatement = (data) => {
  return request({
    method: "POST",
    url: `app/trust-credit/get-unbilled-statement`,
    data
  })
}

// 已出账单pdf
export const getBilledStatement = (data) => {
  return request({
    method: "GET",
    url: `app/trust-credit/get-billed-statement/${data.billing_id}`,
    responseType: "arraybuffer"
  })
}

export const sendBilledStatement = (data) => {
  return request({
    method: "POST",
    url: `app/trust-credit/send-billed-statement`,
    data
  })
}