import React, { useState, useEffect, useRef } from "react"
import { Mask, Toast } from "antd-mobile"
import { CloseCircleOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'
import "./index.less"

import KYCHeader from "@comps/KYC/KYCHeader"
import KYCFooter from "@comps/KYC/KYCFooter"
import KYCStepList from "@comps/KYC/KYCStepList"
import CommonUploadImg from "@comps/CommonUploadImg"
import KYCContactUs from "@comps/KYC/KYCContactUs"
import { scrollToTop, compressedFile } from "@utils/utils"

import passportImage from "@assets/images/certificate/pic_passport.png"
import example from "@assets/images/certificate/example.png"

import { getIdentityVerificationResult, uploadVerificationAdditionalDocuments } from "@api/user"
import ReactLoading from "react-loading"

export default function BillsOfLiving(props) {
  const history = useHistory()
  const uploadImg = useRef(null)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [pictures, setPictures] = useState([])
  const [authList, setAuthList] = useState({
    'name_and_dob': false,
    'ssn': false,
    'address': false,
    'identity_document': false,
    'selfie': false
  })
  const [indentityInfo, setIndentityInfo] = useState({})
  const [isShowStepList, setIsShowStepList] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  const [isShowUploadImgTitle, setIsShowUploadImgTitle] = useState(false)

  const [stepList, setStepList] = useState([])
  const [KYCStep, setKYCStep] = useState(0)
  const [currentStep, setCurrentStep] = useState('')
  const [currentStepSubtitle, setCurrentStepSubtitle]  = useState('')
  const [addressInfo, setAddressInfo]  = useState('')

  const getVerificationResult = async () => {
    setPageLoading(true)
    const res = await getIdentityVerificationResult()
    setPageLoading(false)
    if (res.status_code === 100000) {
      const resInfo = res.response_data
      const authListCopy = {...authList}
      const stepListCopy = []
      const resultStatus = resInfo.result_status
      setIndentityInfo(resInfo)
      setIsShowStepList(resultStatus?.length > 1)
      let firstIndex = 0
      if (resultStatus?.length) {
        resultStatus?.forEach((item) => {
          authListCopy[item.step] = true
          stepListCopy.push({ name: t(`KYC.${item.step}`), active: false })
          if (item.is_additional_documents_provided) firstIndex++
        })
        stepListCopy[firstIndex].active = true
      }
      const currentStepTxt = resultStatus[firstIndex]['step']
      handleSubtitle(resInfo, currentStepTxt)
      setKYCStep(firstIndex)
      setIsShowUploadImgTitle(['identity_document', 'selfie'].includes(currentStepTxt))
      setCurrentStep(currentStepTxt)
      setStepList(stepListCopy)
      setAuthList(authListCopy)
      currentStepTxt === 'address' && setAddressInfo(resultStatus[firstIndex].original_billing_address)
    } else if (res.status_code === 300003) {
      Toast.show({
        content: t(`error.KYCAbnormal.${res.status_code}`),
        duration: 3000,
      })
    }
  }

  const resetStepList = () => {
    stepList.map((step) => step.active = false)
  }

  const handleSubtitle = (indentityInfo, currentStepTxt) => {
    let currentStepSubtitle = ''
    if (currentStepTxt === 'identity_document') {
      currentStepSubtitle = t(`KYC.identity_document_${indentityInfo.kyc_document_type}Subtitle`)
    } else if (currentStepTxt === 'name_and_dob') {
      currentStepSubtitle = ['passport', 'driving_licence', 'residence_permit'].includes(indentityInfo.kyc_document_type) ? t(`KYC.name_and_dob_${indentityInfo.kyc_document_type}Subtitle`) : t(`KYC.name_and_dobSubtitle`)
    } else {
      currentStepSubtitle = t(`KYC.${currentStepTxt}Subtitle`)
    }
    setCurrentStepSubtitle(currentStepSubtitle)
  }

  useEffect(() => {
    if (indentityInfo['result_status']) {
      const currentStepTxt = indentityInfo['result_status'][KYCStep]['step']
      setCurrentStep(currentStepTxt)
      resetStepList()
      setDisabled(true)
      uploadImg.current.resetFileList()
      stepList[KYCStep] && (stepList[KYCStep].active = true)
      setIsShowUploadImgTitle(['identity_document', 'selfie'].includes(currentStepTxt))
      handleSubtitle(indentityInfo, currentStepTxt)
      currentStepTxt === 'address' && setAddressInfo(indentityInfo?.result_status[KYCStep].original_billing_address)
    }
  }, [KYCStep])

  useEffect(() => {
    scrollToTop()
    getVerificationResult()
  }, [])

  const onClose = () => {
    history.goBack()
  }

  const onSubmit = async () => {
    const compressImgs = await compressedFile(pictures, 0.02, 0.5, 154, 154)
    const currentPicture = compressImgs.map(({ compressImg }) => compressImg)
    // 上传数据
    setLoading(true)
    const formData = new FormData()
    formData.append('verificationRequestId', indentityInfo.verification_request_id)
    formData.append('step', currentStep)
    currentPicture.forEach((item) => formData.append('additionalDocuments[]', item))

    const res = await uploadVerificationAdditionalDocuments(formData)
    setLoading(false)
    switch (res.status_code) {
      case 100000: 
        const nextStep = +KYCStep + 1
        if (nextStep !== indentityInfo.result_status?.length) {
          setKYCStep(nextStep)
          setPictures([])
        } else {
          history.push(`/uploadPendingReview?time=${res.system_timestamp}`)
        }
        break;
      case 300003: 
      case 301029: 
      case 301030: 
      case 300010: 
        Toast.show({
          content: t(`error.KYCAbnormal.${res.status_code}`),
          duration: 3000,
        })
        break;
      default:
        
        break;
    }
  }

  const afterCompress = (files) => {
    setDisabled(files.length === 0)
    setPictures(files)
  }

  return (
    <div id="bills-Of-living">
      <Mask visible={pageLoading}>
        <ReactLoading className='loading' type={"spinningBubbles"} color={"#fff"}/>
      </Mask>

      <div className={["kyc-header", isShowStepList ? 'show-step-list' : ''].join(' ')}>
        <KYCHeader
          title={currentStep ? t(`KYC.${currentStep}Title`) : ""}
          subtitle={currentStepSubtitle}
          close={onClose}
        />
        {isShowStepList && <KYCStepList list={stepList} />}
      </div>
      <div>
        {/* 多图 */}
        <CommonUploadImg
          ref={uploadImg}
          isHas={pictures}
          bgImg={passportImage}
          uploadImg={afterCompress}
          showTitle={isShowUploadImgTitle}
          showKycAddress={currentStep === 'address'}
          onlyOne={currentStep === "selfie"}
          addressInfo={addressInfo}
          title={currentStep ? t(`KYC.${currentStep}UploadTitle`) : ""}
          subTitle={currentStep ? t(`KYC.${currentStep}UploadSubtitle`) : ""}
        />
      </div>

      <KYCContactUs />

      <KYCFooter btnText={KYCStep + 1 !== indentityInfo.result_status?.length ? t("KYC.nextStep") : t("KYC.submitText")} isLoading={loading} btnSubmit={onSubmit} disabled={disabled} />

      {visible && (
        <Mask
          visible={visible}
          onMaskClick={() => setVisible(false)}
          className="exampleMask"
        >
          <div className='exampleImage'>
            <div className="close" onClick={() => setVisible(false)}><CloseCircleOutline fontSize={36} /></div>
            <img className="example" src={example} alt="" />
          </div>
        </Mask>
      )}
    </div>
  )
}
