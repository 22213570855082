import React, { Component } from 'react'
import { Mask, Input, Toast } from 'antd-mobile'
import { sendEmailCode, verifyEmailCode } from '@api/active'
import requireImg from '@assets/images/home/require.png'
import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import Tip from '@comps/Tip'
import './index.less'

import { connect } from 'react-redux'
import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang
  }
}

class VerificationCode extends Component {
  state = {
    bool: true, //控制多次点击
    verifyCode: '',
    isBtnDisabled: true,
    isSendCode: true,
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: true },
      { name: t('steps.navID'), active: false },
    ],
    showError: false,
    flow: undefined,
    resendCountDown: 60,
    submitting: false,
    showDialog: false,
    verifyStatus: 1,
    email: sessionStorage.getItem('activationEmail'),
    isVerifyCodeFocus: false,
    clientHeight: 0,
    isSubmitLoading: false
  }
  
  hideFooter = () => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
    // const bottomBtn = document.querySelector('#page-footer')
    // if (bottomBtn) {
    //   bottomBtn.style.display = document.body.clientHeight < clientHeight ? 'none' : 'block'
    // }
  }

  componentDidMount() {
    scrollToTop()
    this.setState({
      clientHeight: document.body.clientHeight
    })
    window.addEventListener('resize', this.hideFooter, true)  
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    window.removeEventListener('resize', this.hideFooter, true)
  }
  
  resendCountDown = () => {
    this.countDownTimer = setInterval(() => {
      let time = this.state.resendCountDown
      if (time === 0) {
        clearInterval(this.countDownTimer)
        this.setState({resendCountDown: 60})
      } else {
        this.setState({resendCountDown: time - 1})
      }
    }, 1000)
  }

  handleCloseTip = () => {
    this.setState({
      showDialog: false,
    })
  }

  // 发送新的验证码
  sendCode = async () => {
    this.setState({
      verifyCode: '',
      isBtnDisabled: true
    })
    this.resendCountDown()
    this.setState({
      bool: false,
      showError:false
    })
    if (this.state.bool) {
      const activationToken = localStorage.getItem('AuthorizationID')
      const res = await sendEmailCode({
        email: this.state.email,
        verificationRequestId: activationToken, //接收验证码方式（SMS\EMAIL）
      })
      const { status_code } = res
      if (status_code === 100000) {
        Toast.show({
          content: t('steps.EmailOTPSendSuccess'),
          duration: 1500,
        })
        this.setState({
          bool: true,
        })
      } else {
        this.setState({
          bool: true,
        })
      }
    }
  }
 
  // 下一步
  handleBtnNext = async () => {
    this.setState({
      bool: false,
    })
    const activationToken = localStorage.getItem('AuthorizationID')
    if (this.state.bool) {
      const { verifyCode} = this.state
      this.setState({submitting: true, isSubmitLoading: true})
      
      const res = await verifyEmailCode({
        verificationCode: verifyCode,
        verificationRequestId: activationToken, //接收验证码方式（SMS\EMAIL）
      })

      this.setState({ isSubmitLoading: false})
  
      switch (res?.status_code) {
        case 100000:
          this.setState({
            bool: true,
          })
          this.props.history.push('/xStepFour')
          break;
        case 300001: //Service call failed
          this.setState({
            showError: true,
            bool: true,
            submitting: false
          })
          break;
        case 300008: //Your account is not qualified for this function.
          this.props.history.push("/");
          break;
        case 301004: //No logged in user.
          Toast.show({
            content: t('general.redirectLogin'),
            duration: 4000,
          })
          this.props.history.push("/login");
          break;
        
        case 301018: //This step has been passed, please proceed to the next step.
          Toast.show({
            content: t('steps.guideStepInfoErr'),
            duration: 4000,
          })
          this.setState({
            showDialog: true,
          })
          break;
        case 300003: //Email verification failed.
        case 300002: //Invalid parameter
        default:
          this.setState({
            showError: true,
            bool: true,
            submitting: false
          })
          break;
      }
    }
  }

  render() {
    const { isSubmitLoading, verifyCode,showDialog, verifyStatus, isBtnDisabled, list, showError, isVerifyCodeFocus, submitting, email } = this.state
    return (
      <div className="stepThree">
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={verifyStatus} />
        </Mask>
        <StepHeader
          title={t('steps.EmailTitle')}
          subTitle={t('steps.EmailDesc')}
          list={list}
        />
        <div className="message-container">
          <p dangerouslySetInnerHTML={{__html: t('steps.EmailOTPHint', {email: email})}}></p>
          <p>
            {t('steps.EmailNotReceive')}
            {this.state.resendCountDown === 60 || this.state.resendCountDown === 0?
                <span onClick={this.sendCode} style={{marginLeft: "4px", cursor: "pointer"}}>
                  {t('steps.EmailSendNew')}
                </span>
            :
                <span style={{marginLeft: "4px", color: "#999"}}>
                  {t('steps.EmailResendCount', {second: this.state.resendCountDown})}
                </span>
            }
          </p>
        </div>

        <div className="verify-code-container">
          <div className="verify-code-box">
            <img src={requireImg} alt="" />
            <Input
              className={["verify-code", isVerifyCodeFocus? "active": ""]}
              placeholder={t('steps.EmailOTPPlaceholder')}
              value={verifyCode}
              maxLength={6}
              onChange={(val) => {
                if(val.length > 6) {
                  val.substr(0, 6)
                }
                val = val.replace(/[^\d]/g, '')
                this.setState({
                  verifyCode: val,
                  isBtnDisabled: val.length < 6,
                  showError: false
                })
              }}
              onFocus={() => {
                this.setState({
                  isVerifyCodeFocus: true,
                })
              }}
              onBlur={() => {
                this.setState({
                  isVerifyCodeFocus: false,
                })
              }}
            />
          </div>
          {showError && <div className="error">{
            t('steps.EmailOTPIsWrong')
          }</div>}
        </div>
        <StepFooter
          isLoading={isSubmitLoading}
          disabled={isBtnDisabled || submitting}
          btnSubmit={this.handleBtnNext}
          btnText={t('steps.nextButton')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(VerificationCode)
