import {
    SET_CHECKOUT_DATA,
    SET_REFERENCE,
    SET_CURRENT_CARD_INFO,
    SET_SEARCH_QUERY_KEY,
    SET_CHECKED_PAYMENT,
    SET_GIFTCARD_PAYMENT_FROM
} from '../actions/ActionTypes'

import tillstar from '@assets/images/giftCard/confirm/tillstar.png'
import alipay from '@assets/images/giftCard/confirm/alipay.png'
import wechat from '@assets/images/giftCard/confirm/wechat.png'
import unionpay from '@assets/images/giftCard/confirm/union.png'

const initialState = {
    checkoutData: JSON.parse(localStorage.getItem('checkoutData')) || {},
    paymentsInfo: {
        tillstar: {
            name: 'Tillstar美金支付',
            en_name: "Tillstar Wallet",
            title: '美金余额',
            en_title: "Balance",
            icon: tillstar,
            exchange_rate: 6.8117,
            currency: '$'
        },
        alipay: {
            name: '支付宝',
            en_name: "Alipay",
            title: '支付宝',
            en_title: '支付宝',
            icon: alipay,
            exchange_rate: 6.8117,
            currency: localStorage.getItem('selectedLang') === 'zh_CN'? '约 ¥' : "About ¥"
        },
        wechat: {
            name: '微信支付',
            en_name: "Wechat",
            title: '微信钱包',
            en_title: 'Wechat',
            icon: wechat,
            exchange_rate: 6.8117,
            currency: localStorage.getItem('selectedLang') === 'zh_CN'? '约 ¥' : "About ¥"
        },
        unionpay: {
            name: '银联支付',
            en_name: "Union pay",
            title: '银联支付',
            en_title: '银联支付',
            icon: unionpay,
            exchange_rate: 6.8117,
            currency: localStorage.getItem('selectedLang') === 'zh_CN'? '约 ¥' : "About ¥"
        }
    },
    errorTipInfo: {
        500001: '礼卡信息似乎出现了错误，请稍后再试哦',
        500002: '您的订单信息似乎出现了异常，请稍后再试哦',
        500003: '您的订单信息似乎出现了异常，请稍后再试哦',
        500004: '礼卡信息似乎出现了错误，请稍后再试哦',
        500005: '您的订单支付已经超时，请刷新后尝试哦',
        500006: '礼卡信息似乎出现了错误，请稍后再试哦',
        500007: '您当前仅可申请一次退款，如有疑问请联系客服哦',
        500008: '你的订单暂时不可取消退款，请刷新页面后再试哦',
        302008: '钱包余额不足'
    },
    reference: sessionStorage.getItem('reference') || '',
    currentCardInfo: JSON.parse(localStorage.getItem('currentCardInfo')) || {},
    search_query_key: sessionStorage.getItem('search_query_key') || '',
    checkedPayment: sessionStorage.getItem('checkedPayment') || 'tillstar',
    giftCardPaymentFrom: JSON.parse(sessionStorage.getItem('giftCardPaymentFrom') || null) || false
}
const giftCard = (state = initialState, action) => {
    switch(action.type) {
        case SET_CHECKOUT_DATA:
            return {
                ...state,
                checkoutData: action.payload
            }
        case SET_REFERENCE:
            return {
                ...state,
                reference: action.payload
            }
        case SET_CURRENT_CARD_INFO:
            return {
                ...state,
                currentCardInfo: action.payload
            }
        case SET_SEARCH_QUERY_KEY:
            return {
                ...state,
                search_query_key: action.payload
            }
        case SET_CHECKED_PAYMENT:
            return {
                ...state,
                checkedPayment: action.payload
            }
        case SET_GIFTCARD_PAYMENT_FROM:
            return {
                ...state,
                giftCardPaymentFrom: action.payload
            }
        default:
          return state
      }
}

export default giftCard