import React, { useState, useEffect } from 'react';
import { NavBar, InfiniteScroll, Loading, Ellipsis } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import './index.less';
import { t } from 'i18next';
import imgNoRecord from '@assets/images/record/img-no-record.png'

import { formatNumberToUSD } from "@utils"
import { handleDot, scrollToTop, timeTransfer } from "@utils/utils"

import { 
  getUnbilledStatement
} from "@api/user";

const StatementListItem = (props) => {
  const { item } = props;

  return (
    <div className='list-item'>
      <div className='list-left'>
        <div className='date'>
          <Ellipsis direction='end' content={item.description} />
        </div>
        <div className='title'>
          {timeTransfer(item.postDate, "MM-DD HH:mm")}
        </div>
      </div>
      <div className='list-right'>
        <div className='amount'>{handleDot(formatNumberToUSD(item.amount))}</div>
        {!item.is_settled && <div className='pending'>{t("statementList.pending_desc")}</div>}
        {item.is_settled && <div className='pending'> </div>}
      </div>
    </div>
  );
};

export default function StatementList() {
  const history = useHistory();
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [pagination, setPagination] = useState({})
  const [expenseList, setExpenseList] = useState([]);

  useEffect(() => {
    scrollToTop()
    getList({ page: 1 })
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const getList = async ({ page }) => {
    try {
      if(!page) return
      setLoading(true)
      const res = await getUnbilledStatement({
        page: page || 1,
      })
      setLoading(false)
      if (res.status_code === 100000) {
        const { pagination, list, unbilled_balance } = res.response_data

        const { current_page, page_size, total } = pagination
        let currentSearchList = current_page === 1 ? [] : expenseList

        let newList = currentSearchList.concat(list)

        setPagination(pagination)
        setHasMore(current_page * page_size < total)
        setTotal(unbilled_balance)
        setExpenseList(newList)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadMore = async () => {
    const { current_page } = pagination

    await getList({
      page: current_page + 1,
    })
  }


  return (
    <div id='statement-padding'>
      <NavBar
        style={{
          '--height': '56px',
          '--background': '#ffffff',
          marginBottom: '16px'
        }}
        onBack={goBack}>
        {t('statementList.pending_title')}
      </NavBar>

      <div className='statement-padding-content'>
        <div className='paddingTitle'>
          <div className='title'>{t("statementList.pending_total")}</div>
          <div className='amount'>{handleDot(formatNumberToUSD(total))}</div>
        </div>

        {expenseList.map((item, index) => {
          return (
            <StatementListItem item={item} key={`stement_${index}`} />
          )
        })}

        {
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <span></span>
          </InfiniteScroll>
        }

        {loading && <div className="alignCenter">{t("common.loading")}<Loading /></div>}

        {expenseList.length === 0 && !loading && (
          <div className="no-message">
            <img src={imgNoRecord} alt="" />
            <p>{t("statementList.no_record")}</p>
          </div>
        )}
      </div>

    </div>
  );
}
