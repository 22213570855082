import {
    SET_AMOUNT,
    SET_TRANSFER_ORIGIN
  } from './ActionTypes'
  
  export const setAmount = (payload)=> {
    return {
      type: SET_AMOUNT,
      payload
    }
  }

  export const setTransferOrigin = (payload)=> {
    localStorage.setItem('transferOrigin', payload)
    return {
      type: SET_TRANSFER_ORIGIN,
      payload
    }
  }