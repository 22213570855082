import React, { useState } from "react"
import './index.less'
import Header from '@comps/nav/Header'
import KYCHeader from '@pages/kyc-auth/non-ssn/components/Header'
import { DatePicker, Form, Input, Cascader, Toast } from "antd-mobile"

import dayjs from "dayjs"
import { DownOutline } from "antd-mobile-icons"

import StepFooter from "@pages/Home/Steps/StepFooter"
import { t } from 'i18next'
import { cityProviceData } from "@utils/utils"
import { autoCompleteNonSSNBill } from "@api/netki"
import pinyin from "pinyin"
import { flatten } from "@utils/utils"
import { useHistory } from "react-router-dom"
const options = cityProviceData()

export default function BillAdressEdit() {
  const [visible, setVisible] = useState(false)
  const [areaVisible, setAreaVisible] = useState(false)
  const [dobDate, setDobDate] = useState(false)
  const [areaValue, setAreaValue] = useState([])
  const [areaSelectValue, setAreaSelectValue] = useState([])
  const [uploading, setUploading] = useState(false)
  const [passport, setPassport] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const history = useHistory()

  const [form] = Form.useForm();

  const submitBillInformation = async () => {
    form.validateFields(['street', 'detail']).then(async (response) => {
      const dob = dobDate ? dobDate.replace(/\//g, "-") : ""
      if (areaSelectValue.length < 2 || firstName.trim().length < 1 || lastName.trim().length < 1 || passport.trim().length < 1 || dob === '') {
        Toast.show({
          content: t('KYC.nonssn.bill.reCommit'),
          duration: 1000,
        })
        return
      }
      setUploading(true)
      const p = flatten(pinyin(areaSelectValue[0].name, {
        style: "NORMAL"
      })).join(' ').toUpperCase();

      const c = pinyin(areaSelectValue[1].name, {
        style: "NORMAL"
      }).join(' ').toUpperCase();

      const f = pinyin(firstName.trim(), {
        style: "NORMAL"
      }).join(' ').toUpperCase();

      const l = pinyin(lastName.trim(), {
        style: "NORMAL"
      }).join(' ').toUpperCase();

      const s = pinyin(response.street.trim(), {
        style: "NORMAL"
      }).join(' ').toUpperCase();

      const d = pinyin(response.detail.trim(), {
        style: "NORMAL"
      }).join(' ').toUpperCase();

      const code = areaSelectValue[1].code
      const data = {
        "firstName": f,
        "lastName": l,
        "dob": dob,
        "passportNumber": passport.trim(),
        "address": {
          "country": "CHN",
          "postCode": code,
          "province": p,
          "city": c,
          "street": s,
          "detail": d,
        }
      }
      const res = await autoCompleteNonSSNBill(data);
      if (res?.status_code === 100000) {
        history.push('/submitApplication')
        setUploading(false)
      } else {
        Toast.show({
          content: t('KYC.nonssn.bill.reCommit'),
          duration: 1000,
        })
        setUploading(false)
        return
      }
    }).catch(err => {
      Toast.show({
        content: t('KYC.nonssn.bill.reCommit'),
        duration: 1000,
      })
      setUploading(false)
    });

  }

  const maxDate = new Date();
  const minDate = new Date("1942-01-01");

  return (
    <div id="bill-address-edit">
      <Header />
      <KYCHeader title={t('KYC.nonssn.bill.title')} subTitle={t('KYC.nonssn.bill.subTitle')} />
      <Form form={form}>
        <Form.Item name="username" rules={[{ required: true, message: '姓名不能为空' }]}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'inline-block', 'margin-right': "20px" }}>
              <div className="title-label">{t('KYC.nonssn.bill.firstName')} </div>
              <div className="item">
                <span className="prefix">*</span>
                <Input placeholder={t('KYC.nonssn.bill.firstName')} onChange={(e) => setFirstName(e)} value={firstName}></Input>
              </div>
            </div>
            <div style={{ display: 'inline-block' }}>
              <div className="title-label">{t('KYC.nonssn.bill.lastName')}</div>
              <div className="item">
                <span className="prefix">*</span>
                <Input placeholder={t('KYC.nonssn.bill.lastName')} onChange={(e) => setLastName(e)} value={lastName}></Input>
              </div>
            </div>
          </div>
        </Form.Item>
        <Form.Item name="date" requiredMarkStyle={true}>
          <div className="title-label">{t('KYC.nonssn.bill.datebirth')} </div>
          <div className="item"
            onClick={() => {
              setVisible(true)
            }}
          >
            <span className="prefix">*</span>
            <span className="content">{dobDate || t('KYC.nonssn.bill.birthPlaceHolder')}</span>
            <DownOutline></DownOutline>
          </div>
          <DatePicker max={maxDate} min={minDate} value={maxDate} onCancel={() => {
            setVisible(false)
          }} visible={visible} precision="day" onConfirm={(val) => {
            setVisible(false)
            setDobDate(dayjs(val).format('YYYY/MM/DD'))
          }}
          />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: t('KYC.nonssn.bill.passportTips') }]}>
          <div className="title-label">{t('KYC.nonssn.bill.passport')}</div>
          <div className="item">
            <span className="prefix">*</span>
            <Input placeholder="A12345678" value={passport} onChange={(e) => setPassport(e)}></Input>
          </div>
        </Form.Item>
        <Form.Item rules={[{ required: true, message: t('KYC.nonssn.bill.billTips') }]}>
          <div className="title-label">{t('KYC.nonssn.bill.address')}</div>
          <div className="item"
            onClick={() => {
              setAreaVisible(true)
            }}
          >
            <span className="prefix">*</span>
            <span className="content">{areaValue.join('/') || t('KYC.nonssn.bill.billDefaultTips')}</span>
            <DownOutline></DownOutline>
          </div>
          <Cascader options={options} visible={areaVisible}
            onClose={() => {
              setAreaVisible(false)
            }}
            value={areaValue}
            onConfirm={setAreaValue}
            onSelect={(val, extend) => {
              // console.log('onSelect', val, extend.items)
              setAreaSelectValue(extend.items)
            }}
          >
          </Cascader>
        </Form.Item>
        <Form.Item name="street" rules={[{ required: true, message: t('KYC.nonssn.bill.streetTips') }]}>
          <div className="item">
            <span className="prefix">*</span>
            <Input placeholder={t('KYC.nonssn.bill.street')}></Input>
          </div>
        </Form.Item>
        <Form.Item name="detail" rules={[{ required: true, message: t('KYC.nonssn.bill.detailTips') }]}>
          <div className="item">
            <span className="prefix">*</span>
            <Input placeholder={t('KYC.nonssn.bill.detail')}></Input>
          </div>
        </Form.Item>
        <StepFooter
          isLoading={uploading}
          btnText={t('steps.nextButton')}
          btnSubmit={submitBillInformation}
          licenseInfoBottom={t('steps.guideFPB')}
        />
      </Form>

    </div>
  )
}