import './index.less'
import React, { useEffect, useState } from 'react'
import { NavBar, TextArea, Toast, Button } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import { submitTransactionRefunRequest } from '@api/transaction'
import { getRefundReasonId } from '@actions/transaction'

import { useDispatch, useSelector } from 'react-redux'
import CommonPop from '@comps/CommonPop'

export default function Refund() {
  const [submitEnabled, setSubmitEnabled] = useState('')
  const [refundType, setRefundType] = useState(undefined)
  const [comments, setComments] = useState(undefined)
  const refundReasons = useSelector(state => state.transaction.refund_reasons)
  const history = useHistory()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(history.location.search)
  const [isSubmitRefund, setIsSubmitRefund] = useState(false)
  
  useEffect(() => {
    dispatch(getRefundReasonId())
  }, [])

  const back = () => {
     history.goBack(1)
  }
  const RefundListItem = (props) => {
    return props.reasons.map((item) => (
      <div key={item.id} className={item.id === refundType ? 'item enabled': 'item'} style={{}} onClick={()=>{
        setRefundType(item.id)
      }}>
        {item.msg}
      </div>
    ))
  }

  
  useEffect(()=>{
    if((refundType !== 3 && refundType !== undefined) || (refundType === 3 && comments !== undefined && comments.length > 0)) {
      setSubmitEnabled(' enabled')
      return;
    } else {
      setSubmitEnabled('')
    }
  }, [refundType, comments])
  

  const handleCommentsChanged = (value) => {
    if (value.length > 20) {
      Toast.show({
        content: '请输入20个以内的字符哦',
        duration: 2000,
      })
    }
    setComments(value)
  }
  const submitRefund = async ()=>{
    if(submitEnabled === '') {
      return;
    }
    let params = {
      activityCode: searchParams.get('code'),
      refundReasonId: refundType,
    }
    if(refundType === 3 && comments !== undefined && comments.length > 0) {
      params = {...params, additionalInfo: comments}
    }
    if (isSubmitRefund) {
      return 
    }
    setIsSubmitRefund(true)
    const res = await submitTransactionRefunRequest(params)
    setIsSubmitRefund(false)
    if (res.response_data.code) { 
      Toast.show({
        content: '退款申请成功，正在退款',
        duration: 2000,
      })
      params.activityCode = res.response_data.code
      history.location.search = `?code=${res.response_data.code}`
    }
    // dispatch(geTransationDetailByCode(params))
    setTimeout(() => {
      history.replace({
        pathname: '/rmbOrder',
        search: `?code=${res.response_data.code}`
      })
    })
  }

  return (
    <div id='refund'>
      <div className='nav-bar'>
        <NavBar onBack={back}>申请退款</NavBar>
      </div>
      <div  className='refund-content'>
        <div className='flow'>
          退款申请通过后 <br/>
          此笔退款将在3分钟内原路退回
        </div>
        <div className='list'>
          <div className='refund-title'>请选择退款原因</div>
          {
            refundReasons.length > 0 && <RefundListItem  reasons={refundReasons}/>
          }
        </div>
        {refundType === 3 && <div className='comments'>
          <div className='refund-title'>其他原因备注</div>
          <div className='comments-text'>
            <TextArea
              placeholder="填写退款原因，或其他原因详细说明"
              showCount
              maxLength={20}
              onChange={handleCommentsChanged}
            />
          </div>
        </div>}
        <Button className={'submit' + submitEnabled} loading={isSubmitRefund} onClick={()=>submitRefund()} >提交</Button>
      </div>
      <CommonPop />
    </div>
  )
}