import React, { useState, useEffect } from 'react'
import { Toast, Mask } from 'antd-mobile'
import { CloseCircleOutline } from 'antd-mobile-icons'
import { usePlaidLink } from 'react-plaid-link'
import { generateTokenReAuth, putPublickTokenReAuth } from '@api/plaid'

import noticeIcon from '@assets/images/common/notice.png'
import bank from '@assets/images/associatedBank/bank.png'
import './index.less'
import { t } from 'i18next'


export default function RelateExceptionPop(props) {
  const [token, setToken] = useState('')
  const [updateTokenCount, setUpdateTokenCount] = useState(0)
  const {
    showRelateExceptionPop, externalItemClicked, closeRelateExceptionPop,
    getExternalFunction, removeBankAccount
  } = props
  
  const onSuccess = async (publicToken, metadata) => {
    try {
      closeRelateExceptionPop()
      const { account_id, institution: {
        institution_id,
        name
      }, accounts } = metadata

      const res = await putPublickTokenReAuth({
        externalLinkServiceProviderId: 1,
        bindParams: {
          publicToken,
          accountId: account_id,
          institutionId: institution_id,
          institutionName: name,
          accounts,
        },
        paramsType: 'J',
        externalAccountId: externalItemClicked.id
      })
      if (res.status_code === 100000) {
        Toast.show({
          content: t('linkedAccounts.reAuthorizeSuccessfully'),
          duration: 1000
        })
        getExternalFunction()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onExit = () => {
    closeRelateExceptionPop()
    Toast.show({
      content: t('linkedAccounts.reAuthorizeFailed'),
      duration: 1000
    })
  }

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit
  })

  useEffect(() => {
      if (ready && token) {
        open()
      }
  }, [ready, updateTokenCount])

  const createToken = async () => {
    try {
      const linkToken = await generateTokenReAuth({
        externalAccountId: externalItemClicked.id
      })
      if (linkToken.status_code === 100000) {
        setToken(linkToken?.response_data?.link_token)
        setUpdateTokenCount(updateTokenCount + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  let title = '', subtitle = '', tip = '', lBtn = ''
  if (externalItemClicked.external_account_status === 4) {
    title = t('relateExceptionPop.reAuthorized.title')
    subtitle = t('relateExceptionPop.reAuthorized.subtitle')
    tip = t(`relateExceptionPop.reAuthorized.tip.${externalItemClicked.external_account_status}`)
    lBtn = t('relateExceptionPop.reAuthorized.lBtn')
  } else {
    title = t('relateExceptionPop.abnormal.title')
    subtitle = t('relateExceptionPop.abnormal.subtitle')
    tip = t(`relateExceptionPop.abnormal.tip.${externalItemClicked.external_account_status}`)
    lBtn = t('relateExceptionPop.abnormal.lBtn')
  }

  return (
    <Mask visible={showRelateExceptionPop}>
        <div className="RelateExceptionPop">
          <CloseCircleOutline className="close" color="#FFF" fontSize={36} onClick={closeRelateExceptionPop}/>
          <img className='noticeIcon' src={noticeIcon} alt="" />
          <div className="row1 d-flex align-items-center justify-content-center">
            <img src={externalItemClicked.institution_logo || bank} className="bank" alt="" />
            <span className="bankText">{externalItemClicked.name} {externalItemClicked.number}</span>
          </div>
          <p className="p1">{title}</p>
          <p className="p1">{subtitle}</p>
          <p className="p2">{tip}</p>
          <div className="row2">
            <div className="button whiteBtn" onClick={closeRelateExceptionPop}>{lBtn}</div>
            {
              externalItemClicked.external_account_status === 4 ? 
              <div className="buttonClear button redBtn" onClick={createToken}
              >{t('relateExceptionPop.reAuthorized.rBtn')}</div>
              :
              <div className="button redBtn" onClick={removeBankAccount}>{t('relateExceptionPop.abnormal.rBtn')}</div>
            }
          </div>
        </div>
    </Mask>
  )
}
