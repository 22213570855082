import React, { useEffect } from "react"
import "./index.less"
import { NavBar } from "antd-mobile"
import { RightOutline } from 'antd-mobile-icons'
import { scrollToTop } from "@utils/utils"

import { t } from "i18next"
import { useHistory } from "react-router"

export default function Policy() {
  const history = useHistory()

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <div id="policy-container">
        <NavBar onBack={history.goBack} style={{
            '--height': '58px',
        }}>{t('mine.policyNavTitle')}</NavBar>
        <div className="policy-items">
            <div className="policy-item" onClick={() => {
                history.replace({
                    pathname: "/login/Policy",
                    query: {
                        code: "terms_and_conditions",
                        from: "mine/policy"
                    }
                })
            }}>
                <span>{t('mine.TAndC')}</span>
                <RightOutline color="#91919F" fontSize={16}/>
            </div>
            <div className="policy-item" onClick={() => {
                history.replace({
                    pathname: "/login/Policy",
                    query: {
                        code: "privacy_policy",
                        from: "mine/policy"
                    }
                })
            }}>
                <span>{t('mine.privacyPolicy')}</span>
                <RightOutline color="#91919F" fontSize={16}/>
            </div>
            <div className="policy-item" onClick={() => {
                history.replace({
                    pathname: "/login/Policy",
                    query: {
                        code: "ach_authorization_agreement",
                        from: "mine/policy"
                    }
                })
            }}>
                <span>{t('mine.ach')}</span>
                <RightOutline color="#91919F" fontSize={16}/>
            </div>
        </div>
    </div>
  )
}