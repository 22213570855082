import { Grid, NavBar } from 'antd-mobile'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { scrollToTop } from '@utils/utils'
import './index.less'

const FaqItem = (props)=> {
  const  {list, t} = props

  return list.map((item, index)=> {
    if (item.type === 'qa' && index === 0) return null //隐藏VIP等级
    return (
      <Grid.Item key={index}>
        <Link to ={ item.type === 'qa' ?  (index === 5 ? '/tutorials?key=refundRules' : '/faqDetail?type=' + item.type + '&index=' + (index +1)) :`/tutorials?key=${item.key}` }>
          <div className='faq-item'>
            <img alt='' src={item.logo}></img>
            <div className='text' dangerouslySetInnerHTML={{ __html: t(`faq.${item.type}.title.${index}`) }}/>
        </div>
        </Link>
      </Grid.Item>
    )
  })
}


export default function QA() {  
  const faqTutorial = useSelector(state => state.faq.tutorials)
  const qa = useSelector(state => state.faq.qa)
  const faqBanner = useSelector(state => state.faq.faq_banner)
  const divideStyle = {
    border: '9px solid #f7f7f7',
    borderStyle: 'solid none none none'
  }
  const { t } = useTranslation()

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <div id='faq'>
       <NavBar onBack={()=> window.history.back(-1)}>FAQ</NavBar>
      <img src={faqBanner} alt='' className='faq-banner'></img>
      <div className='faq-section'>
        <div className='main-title'>{t('faq.tutorials.title_txt')}</div>
        <Grid columns={4} >
          <FaqItem list={faqTutorial} t={t} />
        </Grid>
      </div>
      <div className='faq-section' style={divideStyle}>
        <div className='main-title'>FAQ</div>
        <Grid columns={4} style={
          {
            "--gap-vertical": "18px"
          }
        }>
          <FaqItem list={qa} t={t} />
        </Grid>
      </div>
    </div>
  )
}
