/**
 *
 * 转账组件
 */
import { Mask, NumberKeyboard, PasscodeInput, Toast, Button } from "antd-mobile"
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react"
import "./index.less"
import { CloseOutline } from 'antd-mobile-icons'
import { verify, accountTransfer, accountMultTransfer } from "@api/account"
import { formatNumberToUSD } from "@utils/index.js"
import { handleDot } from "@utils/utils"
import { setPasswordRelatedChannel } from "@actions/password"
import { useHistory } from "react-router-dom"
import { useTranslation, Trans } from "react-i18next"
import ReactLoading from "react-loading"
import { useDispatch } from "react-redux"
import { CloseCircleOutline } from "antd-mobile-icons"

const handleAmount = (amount) => {
  if (amount.indexOf(".") === -1) {
    return `$${amount}.00`
  } else {
    return amount.split(".")[1].length === 1 ? `$${amount}0` : `$${amount}`
  }
}

const TitleWarpB = (props) => {
  const { t } = useTranslation()

  return (
    <div className="warpBox">
      <div className="title-warp">
        <p>{t("transfer.Entertransactionpassword")}</p>
        <div className="sub-title">
          <span>{t("transfer.securityaccount")}</span>
          <br />
          <span>{t("transfer.Pleaseenterinformation")}</span>
        </div>
      </div>
    </div>
  )
}

const WithContent = (props) => {
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [password, setPassword] = useState("")
  const [isLoadingShow, setIsLoadingShow] = useState(false)
  const { contentVisible, handleChange, panet, panets, fee, feeSymbol, freeLimitAvailable, openFreezePasswordPop } = props
  const { users } = panet
  const [failedTimes, setFailedTimes] = useState(0)
  const [remainderTimes, setRemainderTimes] = useState(0)
  const { t } = useTranslation()
  const passcodeInputRef = useRef()

  useEffect(() => {
    setPassword('')
    setError(false)

    return () => {
      setPassword('')
      setError(false)
    }
  }, [])

  const onPasswordChange = async (code) => {
    if (code.length === 6) {
      passcodeInputRef.current.blur()
      setIsLoadingShow(true)
      setPassword(code)
      const verifse = await verify({ transactionPin: code, actionCode: "Card2CardTransfer_VerifyCurrentPin" })
      let tokense = verifse.response_data.token

      if (verifse.status_code === 100000) {
        if (users.length > 1) {
          multTransfer(tokense)
          return
        }

        singleTransfer(tokense)
      } else if (verifse.status_code === 301025) {
        setError(true)
        const verifyRes = verifse.response_data
        const { total_limit, failed_times } = verifyRes
        setIsLoadingShow(false)
        setFailedTimes(failed_times)
        setRemainderTimes(total_limit - failed_times)
      } else if (verifse.status_code === 301016) {
        setIsLoadingShow(false)
        openFreezePasswordPop()
        setError(true)
      } else {
        setIsLoadingShow(false)
        setError(true)
      }
    } else if (code.length === 5) {
      setPassword(error ? "" : code)
      setError(false)
    } else {
      setError(false)
      setPassword(code)
    }
  }

  // 多人转账
  const multTransfer = async (tokense) => {
    const amount = panet.amount
    const toAccounts = users.map((s) => s.id)
    const message = panets

    const transfer = await accountMultTransfer({
      toAccounts,
      amount,
      token: tokense,
      actionCode: "Card2CardTransfer_SubmitRequest",
      message,
    })
    setIsLoadingShow(false)
    if (transfer.status_code === 100000) {
      history.push(`/recordList`)
    } else if ([300001, 300002, 302002, 302018, 302021, 302022, 302023, 302024].includes(transfer.status_code)) {
      Toast.show({
        content: t(`error.transfer.${transfer.status_code}`),
        duration: 3000,
      })
    }
  }

  // 单人转账
  const singleTransfer = async (tokense) => {
    let toAccount = panet?.lists?.id
    let amount = panet.amount

    const transfer = await accountTransfer({
      toAccount,
      amount,
      token: tokense,
      actionCode: "Card2CardTransfer_SubmitRequest",
      message: panets,
    })
    setIsLoadingShow(false)
    if (transfer.status_code === 100000) {
      history.push(`/transactionDetail?code=${transfer.response_data.code}`)
    } else if ([300001, 300002, 302002, 302018, 302021, 302022, 302023, 302024].includes(transfer.status_code)) {
      Toast.show({
        content: t(`error.transfer.${transfer.status_code}`),
        duration: 3000,
      })
    }
  }

  return (
    <div id="transaction-password-user">
      {contentVisible && (
        <div className="TransactionPasswordUser">
          <Mask visible={contentVisible}>
            <div className="overlayContent">
              <CloseOutline
                onClick={() => {
                  handleChange(!contentVisible)
                }}
                className="close-img"
                color="#FFF"
                fontSize={16}
              />
              <div className="verification-code">
                <TitleWarpB
                  isRepeat={false}
                  parent={panet}
                  panets={panets}
                  fee={fee}
                  feeSymbol={feeSymbol}
                  freeLimitAvailable={freeLimitAvailable}
                />
                {error && (
                  <div className="xss">
                    <Trans i18nKey={t(`password.passwordIncorrect`)} values={{ failedTimes, remainderTimes }}></Trans>
                  </div>
                )}
                <PasscodeInput
                  ref={passcodeInputRef}
                  className="verification"
                  value={password}
                  error={error}
                  onChange={onPasswordChange}
                  seperated
                  keyboard={<NumberKeyboard randomOrder={true} />}
                />

                <div className="varification-tips">
                  <div
                    className="unvla ttop"
                    onClick={() => {
                      dispatch(
                        setPasswordRelatedChannel({
                          channel: "normalSetting",
                          type: "reset",
                          url: "/transferAccounts",
                          backUrl: "/transferAccounts",
                          search: history.location.search,
                        })
                      )
                      history.push("/resetTransactionPasswordA")
                    }}
                  >
                    {t("transfer.Forgotpassword")}
                  </div>
                </div>
              </div>
            </div>
          </Mask>
        </div>
      )}
      <Mask visible={isLoadingShow}>
        <ReactLoading className="loading" type={"spinningBubbles"} color={"#fff"} />
      </Mask>
    </div>
  )
}

const TransferMoney = (props) => {
  const { passwordMask, freeLimitAvailable, fee, feeSymbol, params, handleChange, visible, loading } = props
  const { users, amount } = params
  const { t } = useTranslation()
  const payFeeCount = users.length - freeLimitAvailable

  return (
    <div>
      {visible && (
        <Mask visible={visible}>
          <div id="transfer-money">
            <CloseCircleOutline className="close-img" fontSize={36} color="#FFF" onClick={() => handleChange(false)} />
            <div className="title d-flex align-items-center justify-content-center">
              {t("transfer.confirmTransferMoney")}
            </div>
            <div className="amount-item">
              <p className="amount">{amount ? handleAmount(formatNumberToUSD(amount)) : ""}</p>
              {users.length > 1 && <span className="count">x{users.length}</span>}
            </div>

            <ul className="money-section">
              <li className="item">
                <span className="label">{t("recharge.fee")} { payFeeCount > 0 ? "" : t("transfer.derate") }</span>
                {fee && <span className="money" style={{ textDecoration: payFeeCount <= 0 ? "line-through" : "none" }}>
                  {feeSymbol + formatNumberToUSD(handleDot(fee || 0))}
                  {payFeeCount > 0 ? 
                    payFeeCount === 1 ? "" : `*${payFeeCount}` 
                  : users.length > 1 ? `*${users.length}` : ""}
                </span>}
              </li>
            </ul>

            {freeLimitAvailable > 0 && <div className="friendly-reminder enjoy">{t("transfer.freeofFeeInterest", { count: payFeeCount > 0 ? freeLimitAvailable : users.length })}</div>}
            <Button
              type="warning"
              className="warning-btn btn-width d-flex align-items-center justify-content-center"
              loading={loading}
              onClick={() => {
                passwordMask(true)
                handleChange(false)
              }}
            >
              {t("transfer.confirmTransferButton")}
            </Button>
          </div>
        </Mask>
      )}
    </div>
  )
}

// 主体
const TransactionPasswordUser = forwardRef((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [moneyVisible, setMoneyVisible] = useState(false)
  const [loading] = useState(false)
  const { panet, panets, handleChange, openFreezePasswordPop, feeRes, feeLimit } = props

  useImperativeHandle(ref, () => {
    return {
      show: () => {
        setMoneyVisible(true)
      }
    }
  })

  const passwordMask = (bool) => {
    setPasswordVisible(bool)
    !bool && handleChange(bool)
  }

  return (
    <div>
      <TransferMoney
        params={panet}
        handleChange={setMoneyVisible}
        visible={moneyVisible}
        fee={feeRes?.amount}
        loading={loading}
        feeSymbol={feeRes?.currency?.symbol}
        freeLimitAvailable={feeLimit}
        passwordMask={passwordMask}
      />

      {/* 密码输入弹框 */}
      {passwordVisible && <WithContent
        panet={panet}
        handleChange={passwordMask}
        contentVisible={passwordVisible}
        panets={panets}
        fee={feeRes?.amount}
        feeSymbol={feeRes?.currency?.symbol}
        freeLimitAvailable={feeLimit}
        openFreezePasswordPop={openFreezePasswordPop}
      />}
    </div>
  )
})

export default TransactionPasswordUser
