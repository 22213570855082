import './index.less'
import React, { useState } from 'react'
import { NavBar, Button, Popup, Input, Toast } from 'antd-mobile'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import tutorial_cn from '@assets/images/addBank/tutorial.png'
import tutorial_en from '@assets/images/addBank/tutorial_en.png'
import { InformationCircleFill } from 'antd-mobile-icons'
import CommonBottomPopup from '@comps/CommonBottomPopup'
import { submitBindBankAccountRequest } from '@api/account'
import { useSelector } from 'react-redux'

export default function AddBank() {
    const history = useHistory()
    const [infoType, setInfoType] = useState('account_number')
    const [isInfoTipShow, setIsInfoTipShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [accountNumber, setAccountNumber] = useState('')
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('')
    const [routingNumber, setRoutingNumber] = useState('')
    const [nickname, setNickname] = useState('')
    const selectedLang = useSelector(state => state.user.selectedLang)
    const tutorial = selectedLang === 'zh_CN' ? tutorial_cn: tutorial_en

    const showTipInfo = (type) => {
        setInfoType(type)
        setIsInfoTipShow(true)
    }

    const submitBindBankAccount = async () => {
        if (nickname.length === 0) {
            Toast.show({
                content: t('addBank.nickname_error'),
                duration: 3000,
            })
            return
        }
        if (accountNumber.length === 0 || confirmAccountNumber.length === 0 || accountNumber !== confirmAccountNumber || routingNumber.length !== 9 ) {
            Toast.show({
                content: <Trans i18nKey={t('addBank.account_error')}/>,
                duration: 3000,
            })
            return
        }

        setLoading(true)
        const res = await submitBindBankAccountRequest({
            account_number: accountNumber,
            routing_number: routingNumber,
            account_nickname: nickname,
            confirm_account_number: confirmAccountNumber
        })
        setLoading(false)
        switch (res.status_code) {
            case 100000: 
                history.push('/addBankResult')
                break;
            case 302032: 
                Toast.show({
                    content: t('addBank.waiting_verify'),
                    duration: 3000,
                })
                break;
            case 302033: 
                Toast.show({
                    content: t('addBank.bank_existed'),
                    duration: 3000,
                })
                break;
            case 302034: 
            case 302041:
                Toast.show({
                    content: <Trans i18nKey={t('addBank.account_error')}/>,
                    duration: 3000,
                })
                break;
            default:
                Toast.show({
                    content: t('common.serviceException'),
                    duration: 3000,
                })
                break;
        }       
    }

    return (
        <div id="add-bank">
             <NavBar className="nav" onBack={() => history.goBack()}/>
             <div className="header">
                 <div className="title">{t('addBank.title')}</div>
                 <div className="sub_title">{t('addBank.sub_title')}</div>
                 <div className="tip">{t('addBank.tip')}</div>
                 <img className={["tutorial", selectedLang !== 'zh_CN' ? "tutorial_en" : ""].join(" ")} src={tutorial} alt="" />
             </div>
             <div className="bank-info">
                <div className="bank-input">
                    <div className="label-title">
                        <span>Account number</span>
                        <InformationCircleFill fontSize={12} color="#E1E1E1" onClick={() => showTipInfo('account_number')}/>
                    </div>
                    <Input className="account-input" value={accountNumber} type="password" placeholder={t('addBank.enter_account_number_placeholder')} onChange={(val) => {
                        val = val.replace(/[^0-9]/g, '')
                        setAccountNumber(val.slice(0, 22))
                    }} οnpaste={(e) => {
                        e.preventDefault()
                    }}/>
                    <Input className="account-input" value={confirmAccountNumber} type="password" placeholder={t('addBank.enter_confirm_account_number_placeholder')} onChange={(val) => {
                        val = val.replace(/[^0-9]/g, '')
                        setConfirmAccountNumber(val.slice(0, 22))
                    }} οnpaste={(e) => e.preventDefault()}/>
                </div>
                <div className="bank-input">
                    <div className="label-title">
                        <span>Routing number</span>
                        <InformationCircleFill fontSize={12} color="#E1E1E1" onClick={() => showTipInfo('routing_number')}/>
                    </div>
                    <Input className="account-input" value={routingNumber} placeholder={t('addBank.enter_routing_number_placeholder')} onChange={(val) => {
                        val = val.replace(/[^0-9]/g, '')
                        setRoutingNumber(val.slice(0, 9))
                    }}/>
                </div>
                <div className="bank-input">
                    <div className="label-title">
                        <span>{t('addBank.enter_bank_nickname')}</span>
                    </div>
                    <Input className="account-input" value={nickname} placeholder={t('addBank.enter_bank_nickname_placeholder')} onChange={(val) => {
                        val = val.replace(/[^a-zA-Z0-9 ]/g, '')
                        setNickname(val.slice(0, 20))
                    }}/>
                </div>
             </div>
             <div className="bottom-btn">
                <Button onClick={submitBindBankAccount} loading={loading}>{t('addBank.btn')}</Button>
             </div>
             <Popup visible={isInfoTipShow} closeOnMaskClick={true} onMaskClick={() => setIsInfoTipShow(false) }>
                <CommonBottomPopup
                    title={t(`addBank.account_tips.${infoType}.title`)}
                    content={t(`addBank.account_tips.${infoType}.content`)}
                />
            </Popup>
        </div>
    )
}