import React from "react"
import "./index.less"
import { CloseOutline, CheckCircleFill } from "antd-mobile-icons"
import { t } from "i18next"
import { useHistory } from "react-router-dom"
import { timers } from "@utils/utils"


export default function KYCSuccess(props) {
  const { showTime = false, title, subTitle, subTitle1, time, currentTime } = props
  const history = useHistory()
  
  const handleGotoHome = () => {
    history.push("/")
  }

  return (
    <div id="kyc-success">
      <div className="close-action">
        <CloseOutline fontSize={24} onClick={handleGotoHome} />
      </div>

      <div className="kyc-success-container">
        <CheckCircleFill fontSize={72} color="#1F49DA" />
        <p className="kyc-success-title">{title || t("steps.SubmittedTitle")}</p>
        <p className="kyc-success-subtitle">{subTitle || t("steps.SubmittedDesc")}</p>
        <p className="kyc-success-subtitle">{subTitle1 || t("steps.SubmittedDesc1")}</p>
      </div>

      {showTime && (
        <div className="kyc-success-container">
          <div className="kyc-success-item">
            <p className="kyc-success-time">{t("steps.SubmittedAt")}</p>
            <p className="kyc-success-number">{timers(new Date(currentTime))}</p>
          </div>
        </div>
      )}
      <div className="gotoHome">
        {t("steps.SubmittedRedirect", {
          second: time,
        })}
      </div>
    </div>
  )
}
