
import {
  SUBMIT_TRANSACTION_REFUND_REQUEST,
  GET_TRANSACTION_REFUND_REASON,
  UPLOAD_ACTIVITY_RECEIPT,
  DELETE_ACTIVITY_RECEIPT,
  SUBMIT_ACTIVITY_RECEIPT,
  SET_TRANSACTION_LIST_DATE,
  GET_TRANSACTION_DETAIL_BY_CODE,
  GET_TRANSACTION_LIST,
  SET_TRANSACTION_TYPE,
  SET_TRANSACTION_SEARCH_LIST,
  SET_SEARCH_QUERY,
  SET_PENDING_TRANSACTION_POP,
  SET_RECEIPTS,
  SET_COMMON_POP_CONTENT,
  SET_IS_ALL_LIST,
  SET_CURRENT_ALL_LIST_TAB_TYPE,
  SET_IS_RECORD_SEARCH_LIST
} from './ActionTypes'
import request from "@utils/request";

import { searchAccountHistory } from '@api/transaction'

export const setPendingTransactionPop = (status)=> {
  return {
    type: SET_PENDING_TRANSACTION_POP,
    status
  }
}

export const setTransactionType = (transaction_type) => {
  return {
    type: SET_TRANSACTION_TYPE,
    transaction_type
  }
}
export const geTransationDetailByCode = (data, type = '') => {
  if(type === 'check') {
   return request({
      method: "POST",
      url: '/app/account/get-account-history-by-code',
      data
    })
  }
  return (dispatch) => {
    request({
      method: "POST",
      url: '/app/account/get-account-history-by-code',
      data
    }).then((res) => {
      dispatch(initTransactionDetailAction(res.response_data.detail))
    });
  }
}

export const geTransationDetailByCodeWithToken = (data, type = '') => {
  if(type === 'check') {
   return request({
      method: "POST",
      url: '/app/account/get-account-history-by-code-with-token',
      data
    })
  }
  return (dispatch) => {
    request({
      method: "POST",
      url: '/app/account/get-account-history-by-code-with-token',
      data
    }).then((res) => {  
      dispatch(initTransactionDetailAction(res.response_data.detail))
    });
  }
}

export const getTransactionList = (data) => {
  return async (dispatch) => {
    const res = await searchAccountHistory(data)
    let transaction = res.response_data
    if(data.year === undefined) { 
      dispatch(initTransactionSearchList(transaction, data))
    } else {
      dispatch(initTransactionList(transaction))
    }
  }
}
export const setTransactionSearchQuery = (payload) => {
  sessionStorage.setItem('search_query', payload)
  return {
    type: SET_SEARCH_QUERY,
    payload
  }
}


export const initTransactionDetailAction = (transaction) => {
  return {
    type: GET_TRANSACTION_DETAIL_BY_CODE,
    transaction
  }
}
export const initTransactionList = (transaction) => {
  return {
    type: GET_TRANSACTION_LIST,
    transaction
  }
}
export const initTransactionSearchList = (transaction, params) => {
  return {
    type: SET_TRANSACTION_SEARCH_LIST,
    transaction,
    params
  }
}
export const setTransactionListDate = (transaction_list) => {
  return {
    type: SET_TRANSACTION_LIST_DATE,
    transaction_list
  }
}

// 获取退款原因
export const getRefundReasonId = (data) => {
  return (dispatch) => {
    request({
      method: "GET",
      url: `/app/account/get-instant-consumption-coin-refund-reason`,
      data
    }).then((res) => {
      const reasons = res.response_data.refund_reasons
      const action = {
        type: GET_TRANSACTION_REFUND_REASON,
        reasons
      }
      dispatch(action)
    });
  }
}

// 提交退款申请
export const submitTransactionRefunRequest = (data) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/account/request-refund`,
      data
    }).then((res) => {
      const response = res.response_data
      if(res.status_code === 100000){
        const action = {
          type: SUBMIT_TRANSACTION_REFUND_REQUEST,
          response
        }
        dispatch(action)
      } else {
        dispatch({
          type: SET_COMMON_POP_CONTENT,
          status_code: res.status_code,
          params: res.response_data
        })
      }
    });
  }
}

// 上传小票图片
export const uploadActivityReceipt = ({ url, receipt_id }) => {
  return {
    type: UPLOAD_ACTIVITY_RECEIPT,
    receipt_id,
    url
  }
}

// 删除当前选择的小票
export const deleteActivityReceipt = (url) => {
  return (dispatch) => {
    const action = {
      type: DELETE_ACTIVITY_RECEIPT,
      url
    }
    dispatch(action)
  }
}
export const setReceipts = (receipts)=> {
  return {
    type: SET_RECEIPTS,
    receipts
  }
}

export const setsubmitActivityReceipt = (status) => {
  return {
    type: SUBMIT_ACTIVITY_RECEIPT,
    status      
  }
}

// 提交上传小票申请
export const submitActivityReceipt = (data) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: `/app/account/submit-activity-receipt`,
      data
    }).then((res) => {
      const status = res.response_data
      const action = {
        type: SUBMIT_ACTIVITY_RECEIPT,
        status      
      }
      dispatch(action)
    });
  }
}

export const setIsAllList = (payload)=> {
  sessionStorage.setItem('isAllList', payload)
  return {
    type: SET_IS_ALL_LIST,
    payload
  }
}

export const setCurrentAllListTabType = (payload)=> {
  sessionStorage.setItem('currentAllListTabType', payload)
  return {
    type: SET_CURRENT_ALL_LIST_TAB_TYPE,
    payload
  }
}

export const setIsRecordSearchList = (payload)=> {
  sessionStorage.setItem('isRecordSearchList', payload)
  return {
    type: SET_IS_RECORD_SEARCH_LIST,
    payload
  }
}