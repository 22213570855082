import React, { Component } from "react"
import notice from "@assets/images/mine/notice.png"
import { Button, Toast, NavBar, Modal } from "antd-mobile"
import "./index.less"
import { freezeAccount } from "@api/user"
import { scrollToTop } from "@utils/utils"
import { connect } from "react-redux"
import noticeIcon from "@assets/images/common/notice.png"
import { CloseCircleOutline } from "antd-mobile-icons"
import ExceptionPop from "@comps/ExceptionPop"
import { t } from "i18next"

const mapStateToProps = (store) => {
  return {
    actionToken: store.password.actionToken,
  }
}

class MoreErroPassword extends React.Component {
  render() {
    return (
      <>
        <section className="erro-warp">
          <div className="mask-close" onClick={this.props.closeDialog}>
            <CloseCircleOutline fontSize={36} color="#FFF" />
          </div>
          <img className="notice-icon" src={noticeIcon} alt="" />
          <article className="erro-bx">
            <div className="title">{t("freezeAccount.wanttoFreeze")}</div>
          </article>
        </section>
      </>
    )
  }
}

class FreezeAccountC extends Component {
  state = {
    title: "账户冻结",
    visible: false,
    isExceptionPopVisible: false,
  }
  freeze = async () => {
    const { actionToken } = this.props
    const res = await freezeAccount("FreezeUserCard_ActionConfirmed", actionToken)
    switch (res.status_code) {
      case 100000:
        Toast.show({
          content: t("freezeAccount.contactcustomerserviceto"),
        })
        setTimeout(() => {
          this.props.history.push("/")
        }, 1000)
        break
      case 301006:
        this.closeDialog()
        this.setState({
          isExceptionPopVisible: true,
        })
        break
      default:
        Toast.show({
          content: t("freezeAccount.SSNFailed"),
        })
        break
    }
  }

  forHome = () => {
    this.props.history.push("/")
  }

  componentWillMount() {
    document.title = this.state.title
  }
  componentDidMount = () => {
    scrollToTop()
  }
  goBack = () => {
    this.props.history.push("/resetTransactionPasswordA")
  }
  closeDialog = () => {
    this.setState({
      visible: false,
    })
  }
  openDialog = () => {
    this.setState({
      visible: true,
    })
  }
  render() {
    const { visible, isExceptionPopVisible } = this.state
    return (
      <div id="freezeAccountC-page" className="freezeAccountC-page">
        <NavBar
          style={{
            "--height": "36px",
          }}
          onBack={this.goBack}
        >
          {t("account.Isfrozen")}
        </NavBar>
        <div className="freezeAccountC d-flex flex-column align-items-center">
          <img src={notice} alt="" />
          <p dangerouslySetInnerHTML={{ __html: t("freezeAccount.abletouseittillunfreezing") }}></p>
          <div className="btn-container">
            <Button
              type="warning"
              className="freezeAccountC-btn warning-btn d-flex align-items-center justify-content-center "
              onClick={this.openDialog}
            >
              {t("freezeAccount.FreezeNow")}
            </Button>
            <div className="btn-wraning">
              <Button
                type="warning"
                className="warning-btn for-back d-flex align-items-center justify-content-center"
                onClick={this.forHome}
              >
                {t("freezeAccount.Cancel")}
              </Button>
            </div>
          </div>
        </div>
        <Modal
          visible={visible}
          content={<MoreErroPassword closeDialog={this.closeDialog} />}
          closeOnAction
          onAction={this.freeze}
          actions={[
            {
              key: "confirm",
              text: t("freezeAccount.Acknowleged"),
              className: "confirm-btn",
            },
          ]}
        />
        <ExceptionPop
          visible={isExceptionPopVisible}
          onClose={() => {
            this.setState({
              isExceptionPopVisible: false,
            })
          }}
          content={
            <div
              className="mask-title"
              style={{ fontWeight: "600" }}
              dangerouslySetInnerHTML={{ __html: t("transactionPin.reVerifyAfterLongTime") }}
            ></div>
          }
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(FreezeAccountC)
