import {
  SET_TABBAR_BADGE,
  GET_TABBAR_BADGE
} from './ActionTypes'


import request from "@utils/request";

export const setTabBarBadge = (status) => {
  return {
    type: SET_TABBAR_BADGE,
    status
  }
}

export const getTabBarBadge = ()=>{
  return (dispatch) => {
    request({
      method: "GET",
      url: `/app/account/count-pending-consumption-activities`,
    }).then((res) => {
      const count = res.response_data.count
      const tabbar = [0,0,0, parseInt(count)]
      dispatch({
        type: GET_TABBAR_BADGE,
        tabbar
      })
    });
  }
}
