import {
  GET_PAYMENT_METHODS,
  SET_CURRENT_PAYMENT_METHODS,
  GET_BANNER_CONTENT,
  GET_PAYMENT_LINK,
  GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS,
  PAYMENT_METHOD_SHOW,
  SET_RMB_TO_USD_VALUE,
  GET_TUTORIALS_BEHAVIOR,
  SET_TUTORIALS_BEHAVIOR,
  SET_RMB_BALANCE_POP,
  SET_AMOUNT_RISK_POP,
  SET_SWITCH_RMB_POP,
  SET_PAY_STATUS,
  SET_PAYMENT_FROM,
  SET_SWITCH_RMB_UNABLED_POP,
  SET_HOME_THREE_GUIDE,
  SET_RMB_PAYMENT_SUCCESS
} from '../actions/ActionTypes'
import alipay from "@assets/images/rmb/icons/alipay.png"
import wechat from "@assets/images/rmb/icons/wechat.png"
import union from "@assets/images/rmb/icons/union.png"
import tutorialsCn from '@assets/images/rmb/tutorials_cn.png'
import tutorialsEn from '@assets/images/rmb/tutorials_en.png'

import stepsEn from '@assets/images/rmb/steps_en.png'
import stepsCn from '@assets/images/rmb/steps_cn.png'

const initialState = {
  paymentMethods: [
    {
      pay_type: "unionpay",
      title: "银联",
      exchange_rate: "6.7694",
      img: union,
      rmb: "0.00"
    }
  ],
  code: "",
  payment_from: '',
  steps_banner: localStorage.getItem('selectedLang') === 'zh_CN'? stepsCn: stepsEn,
  tutorials_banner: localStorage.getItem('selectedLang') === 'zh_CN'? tutorialsCn: tutorialsEn,
  bannerContent: [],
  paymentLink: '',
  is_pay_url: false,
  tutorialsShow: false,
  paymentMethodsShow: false,
  payemntDefaultValue: true,
  rmb_balance_pop: false,
  switch_rmb_pop: false,
  switch_rmb_unabled_pop: false,
  amount_risk_pop: {
    is_risk: false,
    right_button: '',
    left_button: '',
  },
  pay_status: false,
  currentPaymentMethod: JSON.parse(localStorage.getItem('currentPaymentMethod')) || {},
  paymentMethodsIcon: {
    "wechat": {
      "icon" : wechat,
      "title" : "wechat"
    },
    "alipay": {
      "icon" : alipay,
      "title" : "alipay"
    },
    "unionpay": {
      "icon" : union,
      "title" : "unionpay"
    },
  },
  rmbIsUsed: false,
  homeThreeGuide: false,
  rmbPaymentSuccess: JSON.parse(sessionStorage.getItem('rmbPaymentSuccess')) || { status: false },
}


export const getAttribute = (o, path = '', defaultReturn = '') => {
  if (o === undefined) {
    return defaultReturn;
  }
  path = path.split('.');
  let current = o;
  for (let i = 0; i < path.length; i ++) {
    if (path[i] === '') {
      continue;
    } else {
      if (path[i].indexOf('[') === -1) {
        if (current[path[i]] === undefined) {
          return defaultReturn;
        } else {
          current = current[path[i]];
        }
      } else {
        let temp = path[i].replace('[', '').replace(']', '')
        if (current[temp] === undefined) {
          return defaultReturn;
        } else {
          current = current[temp];
        }
      } 
    }
  }
  return current;
}

const rmb = (state = initialState, action) => {
  let newState = {
    ...state
  }
  switch(action.type) {
    case GET_PAYMENT_METHODS:
      newState.paymentMethods = action.payment_methods
      let currentPaymentMethod = Object.keys(newState.currentPaymentMethod).length ? newState.currentPaymentMethod : newState.paymentMethods[0]
      newState.currentPaymentMethod = currentPaymentMethod || {}
      localStorage.setItem('currentPaymentMethod', JSON.stringify(currentPaymentMethod))
      newState.payemntDefaultValue = true
      return newState
    case SET_CURRENT_PAYMENT_METHODS:
      let paymentMethod = action.paymentMethods || {}
      localStorage.setItem('currentPaymentMethod', JSON.stringify(paymentMethod))
      newState.currentPaymentMethod = paymentMethod
      return newState
    case GET_BANNER_CONTENT:
      newState.bannerContent = action.banner
      return newState
    case GET_PAYMENT_LINK:
      newState.paymentLink = action.pay.link
      newState.code = action.pay.code
      newState.is_pay_url = action.pay.is_pay_url
      newState.payment_from = 'payment'
      newState.pay_status = true
      newState.pay_type = action.pay.pay_type
      return newState
    case GET_TUTORIALS_BEHAVIOR:
      return {
        ...state,
        tutorialsShow: !action.status
      }
    case SET_TUTORIALS_BEHAVIOR: 
      return {
        ...state,
        tutorialsShow: false
      }
    case PAYMENT_METHOD_SHOW:
      newState.paymentMethodsShow = action.status
      return newState
    case GET_INSTANT_CONSUMPTION_COIN_USAGE_STATUS:
      newState.rmbIsUsed = action.rmb_is_used
      return newState
    case SET_RMB_TO_USD_VALUE:
      newState.paymentMethods =  action.payment_methods
      localStorage.setItem('currentPaymentMethod', JSON.stringify({}))
      newState.currentPaymentMethod = {}
      newState.payemntDefaultValue = false
      return newState;
    case SET_RMB_BALANCE_POP:
      return {
        ...state,
        rmb_balance_pop: action.status
      }
    case SET_SWITCH_RMB_POP:
      return {
        ...state,
        switch_rmb_pop: action.status
      }
    case SET_SWITCH_RMB_UNABLED_POP:
      return {
        ...state,
        switch_rmb_unabled_pop: action.status
      }
    case SET_AMOUNT_RISK_POP:
      return {
        ...state,
        amount_risk_pop: action.status
      }
    case SET_PAY_STATUS: 
      return {
        ...state,
        pay_status: action.status
      }
    case SET_PAYMENT_FROM:
      return {
        ...state,
        payment_from: action.payment.from,
        code: action.payment.code,
      }
    case SET_HOME_THREE_GUIDE: 
      return {
        ...state,
        homeThreeGuide: action.homeThreeGuide
      }
    case SET_RMB_PAYMENT_SUCCESS:
      return {
        ...state,
        rmbPaymentSuccess: action.payload
      }
    default:
      return state
  }
}

export default rmb