import Button from 'plaid-threads/Button'
import React, { useState, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { generateToken } from '@api/plaid'

function Plaid(props) {
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const { history } = props

  const createToken = useCallback(async () => {
    setLoading(true)
    const linkToken = await generateToken()
    setLoading(false)
    if (linkToken.status_code === 100000) {
      setToken(linkToken.response_data.link_token)
    }
  }, [])

  const onSuccess = useCallback(() => {
    history.push('/plaidSuccess')
  }, [])

  const onExit = useCallback(() => {
    history.push('/plaidSuccess')
  }, [])

  const onEvent = useCallback(() => {
    history.push('/plaidSuccess')
  }, [])

  // generate link token on app load
  React.useEffect(() => {
    createToken()
  }, [createToken])
  return (
    <div className="App" >
      {loading ? <div className="spinner"></div> : 
        <PlaidLink token={token} onSuccess={onSuccess} onExit={onExit} onEvent={onEvent}/>
      }
    </div>
  ) 
}
function PlaidLink({ token, onSuccess, onExit, onEvent }) {
  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit,
    onEvent
  })

  return (
    <Button onClick={() => open()} disabled={!ready}>Link account</Button>
  )
}

export default Plaid
