import React, { Component } from "react"
import yanzheng from "@assets/images/mine/yanzheng.png"
import { NavBar } from "antd-mobile"
import { RightOutline } from "antd-mobile-icons"
import "./index.less"
import { connect } from "react-redux"
import { t } from "i18next"

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
  }
}
class ResetTransactionPasswordA extends Component {
  handleResetPassword = () => {
    this.props.history.push("/verifyCode")
  }

  goBack = () => {
    this.props.history.replace({
      pathname: this.props.passwordRelatedChannel.url,
      search: this.props.passwordRelatedChannel.search,
    })
  }

  componentDidMount = () => {
    document.documentElement.scrollTop = 0
    window.scrollTo(0, 0)
  }

  render() {
    const { passwordRelatedChannel } = this.props
    return (
      <div className="resetPasswordA-page">
        <NavBar
          style={{
            "--height": "36px",
            "--background": "#ffffff",
          }}
          onBack={this.goBack}
        >
          {passwordRelatedChannel.type === "freeze" ? 
            t("account.Accountisfrozen")
           : t("setPassword.Retrievepassword")
          }
        </NavBar>
        <section className="resetPasswordA d-flex flex-column align-items-center">
          <div className="title-warp-container">
            <p className="title-warp-resetPasswordA">
              {passwordRelatedChannel.type === "freeze" ? 
                t("freezeAccount.Verifyaccount")
               : t("setPassword.completeauthenticationpassword")
              }
            </p>
            <p className="sub-title-warp-resetPasswordA">
              {t("setPassword.securityaccountpleaseverification")}
            </p>
          </div>
          <div className="check-warp">
            <div className="check-left">
              <div className="check-img">
                <img src={yanzheng} alt="" />
              </div>
              <div className="check-content" onClick={this.handleResetPassword}>
                <div className="title-ch">
                  {t("setPassword.SMSverificationcode")}
                  {t("account.VerifyIdentity")}
                </div>
                <p className="sub-title">
                  {t("setPassword.UsingSMSverificationcode")}
                  +
                  {t("setPassword.Realidentification")}
                </p>
              </div>
            </div>
            <RightOutline fontSize={14} />
          </div>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(ResetTransactionPasswordA)
