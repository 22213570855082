import { Button, Mask } from "antd-mobile"
import React, { useState } from "react"
import "./index.less"
import { CloseOutline } from 'antd-mobile-icons'
import { formatNumberToUSD } from "@utils"
import { handleDot } from "@utils/utils"
import { t } from "i18next"

const handleAmount = (amount = "", freeLimitAvailable, fee, balance) => {
  const realAmount =
    freeLimitAvailable === 0
      ? parseFloat(amount) + parseFloat(fee)
      : parseFloat(amount)
  if (parseFloat(balance) >= realAmount) {
    return `$${handleDot(parseFloat(amount))}`
  } else {
    const realAmount = parseFloat(amount) - parseFloat(fee)
    return `$${handleDot(realAmount.toFixed(2))}`
  }
}
const TitleWarpB = (props) => {
  const isHas = props.service?.response_data?.freeLimitAvailable > 0
  return (
    <div className="title-warp">
      <div className="sub-title d-flex align-items-center justify-content-center">
        {t('withdraw.confirm_withdrawal_amount')}
      </div>
      <p>
        {formatNumberToUSD(
          handleAmount(
            props.amount,
            props.service?.response_data?.freeLimitAvailable,
            props?.service?.response_data?.fee?.amount,
            props.balance
          )
        )}
      </p>
      <div className="money-warp">
        <div className="sxf">
          {t('withdraw.poundage')}
          <span style={isHas ? { opacity: 1 } : { opacity: 0 }}>
            {t('withdraw.reducted')}
          </span>
        </div>
        <div
          className={"money money-b "}
          style={{ textDecoration: isHas ? "line-through" : "none" }}
        >
          {props?.service?.response_data?.fee?.currency?.symbol + formatNumberToUSD(
            handleDot(
              parseFloat(props?.service?.response_data?.fee?.amount)
            )
          )}
        </div>
      </div>
      <div className="tip d-flex align-items-center">
        {t('withdraw.first_monthly_withdrawals_poundage', {
          trans: props?.service?.response_data?.fee?.free_limit
        })}
        <div className="triangle"></div>
      </div>
    </div>
  )
}

export default function WithContent(props) {
  const [visible, setVisible] = useState(true)
  const { amount, perTransFee, service, hiddenMask, submitRecharge } = props

  return (
    <div id="withdraw-money" className="recharge-money">
      <Mask
        visible={visible}
        onMaskClick={() => {
          hiddenMask()
          setVisible(false)
        }}
      >
        <div className="overlayContent">
          <CloseOutline
            onClick={() => {
              hiddenMask()
              setVisible(false)
            }}
            className="close-img"
            color="#FFF"
            fontSize={16}
          />
          <TitleWarpB
            isRepeat={false}
            amount={amount}
            perTransFee={perTransFee}
            service={service}
            balance={props.balance}
          />

          <Button
            type="warning"
            className="recharge-btn warning-btn d-flex align-items-center justify-content-center"
            onClick={() => {
              setVisible(false)
              hiddenMask()
              submitRecharge(false)
            }}
          >
            {t('withdraw.to_confirm_withdrawal')}
          </Button>
        </div>
      </Mask>
    </div>
  )
}
