import {
  GET_ACTIVITY_BY_PAYMENT_ORDER,
  GET_ORDER_REFUND_REASON,
  SUBMIT_ORDER_REFUND_REQUEST,
  UPLOAD_ACTIVITY_RECEIPT,
  DELETE_ACTIVITY_RECEIPT,
  SUBMIT_ACTIVITY_RECEIPT
} from '../actions/ActionTypes'


const initialState = {
  transactionStatus: 'Loading',
  status: 0,
  order: {},
  refund_result: '',
  refund_reasons: [],
  receipt: [],
  receipt_status: ''
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_BY_PAYMENT_ORDER:
      return { 
        ...state,
        order: action.order
      }
    case SUBMIT_ORDER_REFUND_REQUEST:
      return { 
        ...state,
        refund_result: action.refund_result
      }
    case GET_ORDER_REFUND_REASON:
      return { 
        ...state,
        refund_reasons: action.reasons
      }

    case UPLOAD_ACTIVITY_RECEIPT:
      const newItem = {
        receipt_id: action.receipt_id,
        url: action.url
      }
      return { 
        ...state,
        receipt: [...state.receipt, newItem]
      }

    case DELETE_ACTIVITY_RECEIPT:
      return {
        ...state,
        receipt: state.receipt.filter((item) => item.url !== action.url)
      }
    case SUBMIT_ACTIVITY_RECEIPT:
      return {
        ...state,
        receipt_status: action.status
      }
    default:
      return state
  }
}

export default order
