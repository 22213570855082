import React from "react"
import { Button } from "antd-mobile"
import gongxiImg from "@assets/images/plaid/gongxi.png"
import chahuaImg from "@assets/images/plaid/chahua.png"
import { useSelector } from "react-redux"
import "./index.less"
import { useHistory } from "react-router-dom"
import { CloseOutline } from "antd-mobile-icons"
import { t } from "i18next"

export default function Success() {
  const userInfo = useSelector((state) => state.user.userInfo)
  const verifyBankFrom = useSelector((state) => state.account.verifyBankFrom)
  const selectedBank = useSelector((state) => state.account.selectedBank)
  const firstBindExternalAccount = userInfo.default_financial_account?.first_bind_external_account
  const history = useHistory()
  const isShowRecharge = firstBindExternalAccount && verifyBankFrom !== "/withdraw"

  const loginOrRegist = () => {
    if (selectedBank && selectedBank.from) {
      history.go(-4)
      // history.replace(selectedBank.from)
      return
    }
    // 回到绑卡前的页面
    if (isShowRecharge) {
      history.replace("/recharge")
    } else {
      // history.replace({
      //   pathname: verifyBankFrom || "/",
      // })
      history.go(-4)
    }
  }

  return (
    <div id="plaid-success" style={{ position: "relative" }}>
      <img src={gongxiImg} alt="" className="gongxiImg" />
      <CloseOutline className="closeIcon" fontSize={32} onClick={loginOrRegist} />
      <p>
        {t("linkAccountSucceeded.Title")}
      </p>
      {isShowRecharge && (
        <p>
          {t("linkAccountSucceeded.SubTitle")}
        </p>
      )}
      <img src={chahuaImg} alt="" className="chahuaImg" />
      <div className="btn-container">
        <Button type="warning" className="warning-btn d-flex align-items-center justify-content-center" onClick={loginOrRegist}>
          {isShowRecharge ? (
            t("linkAccountSucceeded.Deposit")
          ) : (
            t("linkAccountSucceeded.GotIt")
          )}
        </Button>
      </div>
    </div>
  )
}
