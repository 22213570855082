import React, { Component } from 'react'
import { Mask, Input, Toast } from 'antd-mobile'
import requireImg from '@assets/images/home/require.png'
import { sendEmailCode } from '@api/active'
import './index.less'
import StepHeader from '../Steps/StepHeader'
import StepFooter from '../Steps/StepFooter'
import Tip from '@comps/Tip'
import { scrollToTop } from "@utils/utils"
import { t } from 'i18next'
export default class StepThree extends Component {
  state = {
    bool: true, //控制多次点击
    value: '',
    isBtnDisabled: true,
    isSendCode: true,
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: true },
      { name: t('steps.navID'), active: false },
    ],
    submitting: false,
    showDialog: false,
    verifyStatus: 1,
    isEmailFocus: false,
    clientHeight: 0,
    isSubmitLoading: false
  }

  hideFooter = () => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded()
      }, 0)
    }
    // const bottomBtn = document.querySelector('#page-footer')
    // if (bottomBtn) {
    //   bottomBtn.style.display = document.body.clientHeight < clientHeight ? 'none' : 'block'
    // }
  }

  componentDidMount() {
    scrollToTop()
    this.setState({
      clientHeight: document.body.clientHeight
    })
    window.addEventListener('resize', this.hideFooter, true)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideFooter, true)
  }
  handleCloseTip = () => {
    this.setState({
      showDialog: false,
    })
  }

  handleSendCode = async () => {
    this.setState({
      bool: false,
    })
    const { value } = this.state
    var regex = new RegExp(
      // '^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$',
      '^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$'
    )

    if (!regex.test(value)) {
      Toast.show({
        content: t('steps.EmailInvalidErr'),
      })
      this.setState({
        bool: true,
      })
      return
    }
    if (this.state.bool) {
      const activationToken = localStorage.getItem('AuthorizationID')
      sessionStorage.setItem('activationEmail', value)
      
      this.setState({submitting: true, isSubmitLoading: true})
      
      const res = await sendEmailCode({
        email: value,
        verificationRequestId: activationToken, //接收验证码方式（SMS\EMAIL）
      })
      this.setState({
        isSubmitLoading: false
      })
  
      switch (res?.status_code) {
        case 100000:
          Toast.show({
            content: t('steps.EmailOTPSendSuccess'),
            duration: 2000,
          })
          setTimeout(() => {
            this.setState({
              bool: true,
            })
            this.props.history.push({
              pathname: '/verificationCode',
              query: { email: this.state.value },
            })
    
          }, 1500)
          break;
        case 300001: //Service call failed
          Toast.show({
            content: t('steps.SSNErr'),
            duration: 4000,
          })
          this.setState({
            bool: true,
            submitting: false
          })
          break;
        case 300008: //Your account is not qualified for this function.
          this.props.history.push("/");
          break;
        case 301002: // email used error.
          Toast.show({
            content: t('steps.emailUsedError'),
            duration: 4000,
          })
          this.setState({
            bool: true,
            submitting: false
          })
          break;
        case 301004: //No logged in user.
          Toast.show({
            content: t('general.redirectLogin'),
            duration: 4000,
          })
          this.props.history.push("/login")
          break
        case 301005: //email format error.
          Toast.show({
            content: t('steps.EmailInvalidErr'),
            duration: 4000,
          })
          this.setState({
            bool: true,
            submitting: false
          })
          break;
        case 301018: //This step has been passed, please proceed to the next step.
          Toast.show({
            content: t('steps.guideStepInfoErr'),
            duration: 4000,
          })
          this.setState({
            showDialog: true,
          })
          break;
        case 300009: //The format/value of parameter is not correct.
        case 3010014: //Fail to send email
        case 300002: //Invalid parameter
        default:
          Toast.show({
            content: t('steps.EmailOTPSendFail'),
            duration: 4000,
          })
          this.setState({
            bool: true,
            submitting: false
          })
          break;
      }
    }
  }

  render() {
    const { value, showDialog, verifyStatus, isBtnDisabled, list, submitting, isEmailFocus, isSubmitLoading } = this.state
    return (
      <div className="stepThree">
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={verifyStatus} />
        </Mask>
        <StepHeader
          title={t('steps.EmailTitle')}
          subTitle={t('steps.EmailDesc')}
          list={list}
        />
        <div className="content-tip" dangerouslySetInnerHTML={{__html: t(`steps.EmailOTPHint1`)}} />
        <div className="input-container">
          <img src={requireImg} alt="" />
          <Input
            className={["email-input", isEmailFocus ? "active" : ""]}
            placeholder={t('steps.EmailPlaceholder')}
            value={value}
            onChange={(val) => {
              this.setState({
                value: val,
              }, () => {
                this.setState({
                  isBtnDisabled: this.state.value ? false : true
                })
              })
            }}
            onFocus={() => {
              this.setState({
                isEmailFocus: true
              })
            }}
            onBlur={() => {
              this.setState({
                isEmailFocus: false
              })
            }}
          />
        </div>
        <StepFooter
          isLoading={isSubmitLoading}
          disabled={isBtnDisabled || submitting}
          btnSubmit={this.handleSendCode}
          btnText={t('steps.EmailSendOTP')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
      </div>
    )
  }
}

