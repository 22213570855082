import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import { Toast, NumberKeyboard } from "antd-mobile"

import "./index.less"
import { t } from "i18next"

const MINI_AMOUNT = 0.01
const MAX_AMOUNT = 99999

const customToFixed = (num, len = 2) => {
  num = num.toString()
  let index = num.indexOf(".")
  if (index !== -1) {
    num = num.substring(0, index + 1 + len)
  } else {
    num = num.substring(0)
  }
  return parseFloat(num).toFixed(len)
}

const StepNumberkey = forwardRef((props, ref) => {
  const {
    setKeyNumber,
    isNotSufficientBalance,
    bankAccount,
    isLoading,
    title,
    withDrawAll,
    recharge,
    isAbnormal,
    submitRecharge,
    balance,
    balanceAvailable,
    info,
    handleException,
    encode,
    setRemind
  } = props
  const [unFold, setUnFold] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [curNum, setCurNum] = useState([])
  const [selectedInd, setSelectedInd] = useState(null)
  const isNextDisabled = isLoading || nextDisabled || (bankAccount.length > 0 && isNotSufficientBalance)

  useImperativeHandle(ref, () => {
    return {
      handleUnFold: () => {
        setUnFold(true)
      },

      emptyAll: () => {
        setCurNum([])
        setKeyNumber("")
      }
    }
  })

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    init()
  }, [recharge])

  useEffect(() => {
    init()
  }, [withDrawAll])

  // 收起
  const handleFold = () => {
    setUnFold(false)
  }

  const init = () => {
    // 充值
    if (recharge === "" || recharge) {
      const amount = recharge

      if (bankAccount.length === 0) {
        setNextDisabled(amount === "")
        return
      }

      if (balanceAvailable < parseFloat(amount)) {
        setNextDisabled(true)
        return
      }

      setNextDisabled(amount === "" || parseFloat(amount) === 0 || parseFloat(amount) < MINI_AMOUNT || parseFloat(amount) > MAX_AMOUNT)
      setCurNum(amount?.split("")?.map((v) => (v === "." ? v : +v)))
      return
    }

    // 提现
    if (withDrawAll === "" || withDrawAll) {
      const amount = withDrawAll

      if (bankAccount.length === 0) {
        setNextDisabled(amount === "")
        return
      }

      setNextDisabled(amount === "" || parseFloat(amount) === 0 || parseFloat(amount) > parseFloat(balance))
      setCurNum(amount?.split("").map((v) => (v === "." ? v : +v)))
      return
    }
  }

  const delNumberHandle = () => {
    let newCurNum = curNum

    if (newCurNum.length === 0) return
    if (selectedInd - 1 >= 0) {
      // 表示从中间删除内容
      newCurNum.splice(selectedInd - 1, 1)
      setCurNum(newCurNum)
      setSelectedInd(selectedInd - 1)
    } else if (selectedInd === null) {
      newCurNum.pop()
      setCurNum(newCurNum)
    }

    if (newCurNum.length === 0) {
      setNextDisabled(true)
    } else {
      const listNum = parseFloat(newCurNum.join(""))
      setNextDisabled(listNum < MINI_AMOUNT || listNum > MAX_AMOUNT)
    }

    setKeyNumber(newCurNum.join(""))
  }

  const handleNextButton = () => {
    if (isNextDisabled) return

    if (bankAccount.length === 0) {
      Toast.show({
        content: t('common.link_account_first'),
        duration: 2000,
      })
      return
    }
    if (title === "提现") {
      setCurNum(
        String(withDrawAll)
          ?.split("")
          ?.map((item) => (item === "." ? item : +item))
      )
    }

    if (info?.response_data?.is_transaction_pin_set || bankAccount) {
      const num = curNum.join("")
      if (curNum.length !== 0 && curNum[0] >= 0 && num <= MAX_AMOUNT) {
        num >= MINI_AMOUNT && submitRecharge(true)
      }
    } else {
      if (curNum.length === 0 || curNum[0] === 0) return
    }
  }

  const inputChange = (string) => {
    let onecurNum = curNum
    onecurNum.push(string)
    const num = onecurNum.join("")
    const idx = num.indexOf(".")
    const length = num.length
    const tLength = num.split(".").length > 2

    if (isAbnormal) {
      onecurNum = []
      setCurNum(onecurNum)
      setKeyNumber("")
      handleException(encode)
      // const listNum = parseFloat(onecurNum.join(""))
      // setNextDisabled(listNum < MINI_AMOUNT || listNum > MAX_AMOUNT)
      setNextDisabled(true)
      return
    }

    // 点不能为第一位
    if (idx === 0) {
      setCurNum([])
      setKeyNumber("")
      return
    }

    // 不能一直输入0000
    if (idx === -1 && Number(num) === 0 && length > 1) {
      setCurNum([0])
      setKeyNumber("0")
      return
    }

    // 不能连着输入两个点
    if (tLength) {
      console.log('num.split(".")', num.split("."))
      let newNum = num.split(".").filter((s) => s !== "")
      const s = `${newNum[0].toString()}.${(newNum[1] || "").toString()}`
      const sNum = s.split("").map((v) => (v === "." ? v : +v))

      setCurNum(sNum)
      setKeyNumber(s)
      return
    }

    // 如果是最大值，则不需输入点
    if (Number(num) === MAX_AMOUNT && idx === 5) {
      const max = MAX_AMOUNT.toString()
        ?.split("")
        .map((v) => (v === "." ? v : +v))

      // 设置最大值
      setCurNum(max)
      setKeyNumber(max.join(''))
      return
    }

    // 正常输入，＜ 最大值
    if (!tLength && (idx === -1 || length - (idx + 1) <= 2) && Number(num) <= MAX_AMOUNT) {
      setCurNum(onecurNum)
      setKeyNumber(num)
      return
    }

    // 小数点后两位
    if (!tLength && idx >= 0 && length - (idx + 1) > 2) {
      let sNum = customToFixed(num)
      setCurNum(
        sNum
          .toString()
          ?.split("")
          .map((v) => (v === "." ? v : +v))
      )
      return
    }

    if(num > MAX_AMOUNT) {
      let nums = num.toString()?.split("").map((v) => (v === "." ? v : +v))
      nums = nums.slice(0,5)

      // 充值时候，输入超限
      setRemind && setRemind(3)
      // 设置最大值
      setCurNum(nums)
    }
  }

  return (
    <div className="stepOne">
      <div className="stepOne-bottom">
        {!unFold ? (
          <div className="next-button-container d-flex flex-column align-items-center">
            <div
              className={[
                "d-flex align-items-center justify-content-center",
                isNextDisabled ? "next-button2 disbaled" : "next-button2",
              ].join(" ")}
              onClick={handleNextButton}
            >
              {title}
            </div>
          </div>
        ) : null}

        {/* 用于挂载数字键盘 */}
        <div id="recharge-withdraw"></div>
        <NumberKeyboard
          visible={unFold}
          className={["recharge-withdraw-keyboard", isNextDisabled ? "keyboard-disabled" : ""].join(" ")}
          closeOnConfirm={true}
          onClose={handleFold}
          onDelete={delNumberHandle}
          customKey="."
          confirmText={title}
          onConfirm={handleNextButton}
          onInput={inputChange}
          getContainer={() => document.getElementById('recharge-withdraw')}
        />
      </div>
    </div>
  )
})

export default StepNumberkey
