import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './pages/App'
import store from './store'

// 引入公共样式
import './assets/style/common.less'
// 引入iconfont的样式
import './assets/style/iconfont.less'

import './icons'
import './lang/react-i18next-config'

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
)
