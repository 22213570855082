import React, { Component } from 'react'
import TransactionPasswordUser from '@comps/TransactionPasswordUser'
import { Button, NavBar, TextArea, Toast, Mask } from 'antd-mobile'
import avatar from "@assets/images/mine/3.png"
import qs from 'qs'
import './index.less'
import FreezePasswordPop from '@comps/FreezePasswordPop'
import { formatNumberToUSD } from '@utils'
import { t } from 'i18next'

class HeadWarp extends Component {
  render() {
    let transferAccount
    if (this.props.transferAccountUser?.lists) {
      const { transferAccountUser } = this.props
      transferAccount = transferAccountUser.lists
    }
    return (
      <>
        <div className="search-warp">
          <div className="u-list">
            {/* <Image
              src={avatar}     
              src=''
              width={48}
              height={48}
              style={{ borderRadius: 24 }}
              fit="fill"
            /> */}
            <img className='avatar' src={avatar} alt="" />
            <div className="name-list">
              <h3>{transferAccount?.first_name}*</h3>
              <div className="num-t">{transferAccount?.mobile}</div>
            </div>
          </div>
        </div>
      </>
    )
  }

}

class TransferAccountsUser extends Component {
  state = {
    keyword: '1381234',
    noUsers: false,
    visible: false,
    passwordAlert: false,
    transferAccountUser: '',
    notes: '',
    maskShow: false,
    amount: '',
    textValue: '',
    isPasswordFreeze: false
  }

  componentDidMount() {
    this.setState({
      transferAccountUser: qs.parse(this.props.location.search.slice(1))
    }, () => {

    })
  }

  getRemarkData(notes) {
    this.setState({
      notes
    })
  }

  getChildrenMsg(val) {
    this.setState({
      userValidate: false,
      passwordAlert: true
    })
  }

  handleTransferAccount = () => {
    this.setState({
      visible: true
    })
  }

  handleChange(bool) {
    this.setState({
      visible: bool
    })
  }

  shows = () => {
    this.setState({
      visible: false
    })
  }

  handleFreezeAccount = () => {
    this.props.history.push('/freezeAccountB')
  }

  onRef = (parms) => {
    // parms子组件 传递过来的参数
    console.log(parms)
  }

  getChildData(password) {
    // this.props.parent.getChildData(password)
    this.setState({
      passwordAlert: false,
    })

    this.accountTransfer(password)
  }

  hiddenMask = () => {
    this.setState({
      userValidate: false,
    })
  }

  goBack = () => {
    this.props.history.replace({
      pathname: 'transferAccounts',
      search: qs.stringify({ amount: this.state.transferAccountUser?.amount })
    })
  }

  handleAmount = amount => {
    if (amount === undefined) return
    if (amount.indexOf(".") === -1) {
      return `$${amount}.00`
    } else {
      return amount.split(".")[1].length === 1 ? `$${amount}0` : `$${amount}`
    }
  }

  render() {
    const { isPasswordFreeze, visible, transferAccountUser } = this.state
    let amount = this.handleAmount(transferAccountUser?.amount)
    return (
      <div id="transfer-account-user">
        <NavBar onBack={this.goBack} className="transfer-navbar">
          <span className='transactionAmount'>{formatNumberToUSD(amount)}</span>
        </NavBar>
        {visible &&
            <TransactionPasswordUser
              visible={visible}
              handleChange={this.handleChange.bind(this)}
              panet={transferAccountUser}
              panets={this.state.textValue} 
              openFreezePasswordPop={() => {
                this.setState({
                  visible: false,
                  isPasswordFreeze: true
                })
              }}/>
        }
        <section className="TransferAccounts d-flex flex-column align-items-center">
          <HeadWarp transferAccountUser={transferAccountUser} />
          <div className="remark-warp d-flex mark align-items-center">
            <div className="remark"> {t('transfer.note')}</div>
            <TextArea
              id="xxx"
              value={this.state.textValue} maxLength={20} rows={1} autoSize={{ minRows: 1, maxRows: 2 }} placeholder={t('transfer.Pleaseentercontent')} onChange={(val) => {
                this.setState({
                  textValue: val
                })
                if (this.state.textValue.length + 1 === 20) {
                  Toast.show({
                    content: t('transfer.canentercharacters'),
                    duration: 2000,
                  })
                }
              }} />
          </div>
          <Button
            type="success"
            className="submit-btn d-flex align-items-center justify-content-center"
            onClick={this.handleTransferAccount}
          >
              {t('transfer.to')}
          </Button>
          <Mask visible={isPasswordFreeze}>
              <FreezePasswordPop closeFreezePop={() => {
                this.setState({
                  isPasswordFreeze: false
                })
                this.props.history.push('/')
              }}/>
          </Mask>
        </section>
      </div >
    )
  }
}
export default TransferAccountsUser
