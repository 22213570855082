import {
    SET_CURRENT_BANK_INFO,
    SET_BANK_LIST,
    SET_VERIFY_BANK_FROM,
    SET_SELECTED_RECHARGE_BANK,
    SET_SELECTED_WITHDRAWAL_BANK,
    SET_SELECTED_BANK,
  } from '../actions/ActionTypes'
  
  const initialState = {
    currentBankInfo: JSON.parse(sessionStorage.getItem('currentBankInfo')) || {},
    bankList: JSON.parse(sessionStorage.getItem('bankList')) || [],
    verifyBankFrom: sessionStorage.getItem('verifyBankFrom') || '',
    selectedRechargeBank: localStorage.getItem('selectedRechargeBank') || '',
    selectedWithdrawalBank: localStorage.getItem('selectedWithdrawalBank') || '',
    selectedBank: JSON.parse(localStorage.getItem('selectedBank') && localStorage.getItem('selectedBank') !== "[object Object]" ? localStorage.getItem('selectedBank') : null) || {}
  }
  
  
const account = (state = initialState, action) => {
    const newState = {
      ...state
    }
    switch(action.type) {
      case SET_CURRENT_BANK_INFO:
        newState.currentBankInfo = action.payload
        return newState
      case SET_BANK_LIST:
        newState.bankList = action.payload
        return newState
      case SET_VERIFY_BANK_FROM:
        newState.verifyBankFrom = action.payload
        return newState
      case SET_SELECTED_RECHARGE_BANK:
        newState.selectedRechargeBank = action.payload
        return newState
      case SET_SELECTED_WITHDRAWAL_BANK:
        newState.selectedWithdrawalBank = action.payload
        return newState
      case SET_SELECTED_BANK:
        newState.selectedBank = action.payload
        return newState
      default:
        return state
    }
  }

  export default account