import React, { useEffect, useState } from "react"
import "./index.less"
import { NavBar, Mask, Image } from "antd-mobile"
import { CheckCircleOutline, RightOutline } from 'antd-mobile-icons'
import { scrollToTop } from "@utils/utils"
import { getUserInfo } from "@api/user"
import { getModuleStatus } from "@api/common"
import { t } from "i18next"
import { useHistory } from "react-router"

import SvgIcon from '@comps/SvgIcon'
import Tip from '@comps/Tip'

import Avatar from "react-avatar"

const colorList = ["#FF3841", "#4369ED", "#7660FF", "#DF70DB", "#D6DF70", "#FFD43D", "#F9941E", "#4369ED", "#85B918", "#08B1C3"]

export default function Authentication() {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')

  const [identityStatusText, setIdentityStatusText] = useState('')
  const [identityStatustip, setIdentityStatustip] = useState('')
  const [encryptMobile, setEncryptMobile] = useState(null)
  const [userInfo, setUserInfo] = useState({})

  const [showDialog, setShowDialog] = useState(false)

  const encryptMobileNumber = (mobile) => {
    if (mobile === null) return
    const mobileLength = mobile.length
    if (mobileLength === 11) {
      return mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    } else if (mobileLength === 10) {
      return mobile.replace(/^(\d{3})\d{4}(\d{3})$/, '$1****$2')
    }
    return mobile
  }

  const handleActive = async () => {
    const res = await getModuleStatus('module_kyc')
    if (res.status_code === 300012) return  
    setShowDialog(true)
  }

  const handleCloseTip = () => {
    setShowDialog(false)
  }

  const initData = async () => {
    const userInfoRes = await getUserInfo()
    if (userInfoRes.status_code === 100000) {
      const userInfo = userInfoRes.response_data || {}
      
      const { first_name, mobile, identity_verification_status, active_financial_accounts, identity_verification_request_status} = userInfo
      setFirstName(first_name)
      setUserInfo(userInfo)
      setEncryptMobile(encryptMobileNumber(mobile))
      if(identity_verification_status === 4 && active_financial_accounts === 0 && [4, 9].includes(identity_verification_request_status)) {
        setIdentityStatusText(t('mine.authenticationText'))
        setIdentityStatustip(t('mine.authenticationAbnormalTip'))
      } else {
        // 0:未实名;1:已实名;2:审核中
        if ([0, 1, 2].includes(identity_verification_status)) {
          setIdentityStatustip(t('mine.unauthenticationTip'))
        } else if (identity_verification_status === 3) {
          setIdentityStatustip(t('mine.authenticationFailedTip'))
        } else if (identity_verification_status === 4) {
          setIdentityStatusText(t('mine.authenticationText'))
          setIdentityStatustip(t('mine.authenticationTip'))
        }
      }
    }
  }

  useEffect(() => {
    scrollToTop()
    initData()
  }, [])

  return (
    <div id="authentication-container">
      <NavBar onBack={history.goBack} style={{
        "--height": "60px"
      }}>{t('mine.authenticationTitle')}</NavBar>
      <div className="authentication-content">
        <div className="user-info">
          <div className="user-info-detail">
          {![0, 1, 2, 3].includes(userInfo.identity_verification_status) && <div className="user-name">
              <div className="name">{`*${firstName}`}</div>
              <div className="status">
                <SvgIcon iconClass="personal_verify" />
                <span className="status-text">{identityStatusText}</span>
              </div>
            </div>
          }
            <div className={["mobile-number", [0, 1, 2, 3].includes(userInfo?.identity_verification_status) ? "strong" : ""].join(' ')}>{encryptMobile}</div>
            <div className="status-tip"dangerouslySetInnerHTML={{ __html: identityStatustip }}/>
          </div>
          {userInfo?.avatar ? (
            <Image src={userInfo?.avatar} width={64} height={64} fit="cover" style={{ borderRadius: 150 }} />
          ) : userInfo.first_name ? (
            <Avatar name={`${userInfo?.first_name} ${userInfo?.last_name}`} size="64" textSizeRatio={2.22} round="50%" color={colorList[userInfo?.mobile?.charAt(userInfo?.mobile?.length - 1)] || "#fff"} />
          ) : (
            <SvgIcon iconClass="avatar" />
          )}
        </div>
        <div className="user-info-bottom">
        {[0, 1].includes(userInfo.identity_verification_status) && <div className="to-activation" onClick={handleActive}>
            <span>{t(`mine.authenticationPrivateTip${userInfo.identity_verification_status}`)}</span>
            <RightOutline color="#FF3841" fontSize={12}/>
          </div>}
          {userInfo.identity_verification_status === 2 && <div className="under-view">
            <span className="">{t(`mine.authenticationPrivateTip2`)}</span>
          </div>}
          {userInfo.identity_verification_status === 3 && <div className="failed">
            <span className="">{t(`mine.authenticationPrivateTip3`)}</span>
          </div>}
          {userInfo.identity_verification_status === 4 && <div className="activation">
            <CheckCircleOutline color="#1F49DA" fontSize={12}/>
            <span className="">{t('mine.authenticationPrivateTip')}</span>
          </div>}
        </div>
      </div>
      <Mask visible={showDialog} disableBodyScroll>
        <Tip onClose={handleCloseTip} verifyStatus={userInfo.identity_verification_status} />
      </Mask>
    </div>
  )
}
