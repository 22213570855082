import React, { useEffect, useState, useRef } from "react"
import {
  NavBar,
  Input,
  Image,
  Button,
  Tag,
  TextArea,
  Popup,
  Checkbox,
  Mask,
  InfiniteScroll,
  Loading,
} from "antd-mobile"
import { withRouter, useHistory, useLocation } from "react-router-dom"
import { CloseOutline } from "antd-mobile-icons"
import { formatNumberToUSD } from "@utils"
import qs from "qs"
import { t } from "i18next"
import { Trans } from "react-i18next"
import UniqBy from "lodash/uniqBy"
import { scrollToTop, handleDot } from "@utils/utils"
import "./index.less"

import { searchByQuery, getBalance } from "@api/user"
import { accountFee, Recently, accountTransferLimit, firstAccountTransfer } from "@api/account"

import unFindImg from "@assets/images/mine/without.png"
import avatar from "@assets/images/mine/3.png"

import loadable from "@loadable/component"

import TransactionPasswordUser from "@comps/TransactionPasswordUser"
import FreezePasswordPop from "@comps/FreezePasswordPop"

const ConfirmTransfer = loadable(() => import("@comps/ConfirmTransfer"))
const CommonTipPop = loadable(() => import("@comps/CommonTipPop"))

// 转账总和是否超过余额
const isOverMax = (balance, checkUsers, amount, fee, limit) => {
  let allAmount = Number(amount) * checkUsers.length

  if (checkUsers.length - limit > 0) {
    const count = checkUsers.length - limit
    allAmount += count * Number(fee)
  }

  if (allAmount > Number(balance)) {
    return true
  }

  return false
}

function TransFerAccounts(props) {
  const history = useHistory()
  const location = useLocation()
  const InputRef = useRef()
  const ConfirmTransferRef = useRef()
  const [amount, setAmount] = useState("")
  const [value, setValue] = useState("")
  const [desc, setDesc] = useState("")
  const [poundage, setPoundage] = useState("")
  const [balance, setBalance] = useState("")

  const [checkIdx, setCheckIdx] = useState(null)
  const [dailyLimit, setDailyLimit] = useState(0)
  const [feeLimit, setFeeLimit] = useState(0)
  const [maxLimit, setMaxLimit] = useState(0)

  const [list, setList] = useState([])
  const [tags, setTags] = useState([])
  const [checkUsers, setCheckUsers] = useState([])
  const [contactList, setContactList] = useState([])
  const [pagination, setPagination] = useState({})
  const [checkFirstItem, setCheckFirstItem] = useState({})

  const [checked, setChecked] = useState(false)
  const [isBlus, setIsBlus] = useState(true)
  const [visible, setVisible] = useState("a")
  const [isHas, setisHas] = useState(false)
  // const [isDisplayLoadingMask, setisDisplayLoadingMask] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDailyLimitAvailable, setisDailyLimitAvailable] = useState(false)
  const [isShowConfirmTransfer, setisShowConfirmTransfer] = useState(false)
  const [isShowTransferAmountLimitPop, setisShowTransferAmountLimitPop] = useState(false)
  const [isShowMultipleTransferLimitPop, setisShowMultipleTransferLimitPop] = useState(false)
  const [isShowTransferUndevelopPop, setisShowTransferUndevelopPop] = useState(false)
  const [visiblePwd, setVisiblePwd] = useState(false)
  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [allowMult, setAllowMult] = useState(true)

  const diss = { display: "none" }
  const block = { display: "block" }
  let timeout = null

  useEffect(() => {
    scrollToTop()

    const { amount } = qs.parse(location.search.slice(1))
    setAmount(amount)
    fetchConfig()
    initData()

    return () => {
      setTags([])
      setCheckUsers([])
    }
  }, [])


  const fetchConfig = async () => {
    try {
      // setisDisplayLoadingMask(true)
      const freeInfo = await accountFee({ feeCode: "card_to_card" })
      const accountLimit = await accountTransferLimit({ limitCode: "c2c_maximum_number_of_transactions_allowed_for_24_hours" })
      const res = await getBalance()
      // setisDisplayLoadingMask(false)

      if(res.status_code === 100000) {
        setBalance(res.response_data?.balance)
      }

      if (freeInfo.status_code === 100000) {
        const { freeLimitAvailable, fee,  } = freeInfo.response_data
        setPoundage(fee)
        // 设置是否有手续费
        setisHas(freeLimitAvailable > 0 ? false : true)
        // 还可以免除手续费人数
        setFeeLimit(freeLimitAvailable)
      }

      if (accountLimit.status_code === 100000) {
        const { limit, limitAvailable, is_allowed_multi_person_transfer } = accountLimit.response_data
        // 当天可转账人数
        setDailyLimit(limit.value)
        // 当天转账人数是否超限
        setisDailyLimitAvailable(limitAvailable > 0 ? true : false)
        // 当天还可以最多转账人数
        setMaxLimit(limitAvailable)
        setAllowMult(is_allowed_multi_person_transfer)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 设置备注下面文字展示
  const commonSetTipText = (users) => {
    // 是否超过最多限制
    if(users.length > maxLimit) {
      setisDailyLimitAvailable(false)
    }else {
      setisDailyLimitAvailable(maxLimit > 0 ? true : false)
    }

    // 是否需要手续费
    if(users.length > feeLimit) {
      setisHas(true)
    }else {
      setisHas(feeLimit > 0 ? false : true)
    }
  }

  const onKeyUp = (e) => {
    // del
    if (tags.length > 0 && e.keyCode === 8) {
      if (!checked) {
        setChecked(true)
        setCheckIdx(tags.length - 1)
        return
      }
      let newTags = tags
      let users = checkUsers
      newTags.splice(checkIdx, 1)
      users.splice(checkIdx, 1)

      commonSetTipText(users)

      setTags(newTags)
      setCheckUsers(users)
      setChecked(false)
      setCheckIdx(null)
    }
    // enter 回车
    if (e.keyCode === 13) {
      if (value.length >= 3) {
        refreshData(value)
      }
    }
  }

  const checkChange = async (item, i, isCheck) => {
    let e = checkUsers

    if(isCheck) {
      // 每次校验最后push的用户是否转过
      const user = item

      // 如果是常用联系人，不需要校验
      const isHave = contactList.find((s) => { return s.id === user.id })

      if(!isHave) {
        const res = await firstAccountTransfer({ toAccount: user.id })
        if (res.status_code === 100000) {
          const { is_first_transfer } = res.response_data
          // 首次转账
          if (is_first_transfer) {
            setisShowConfirmTransfer(true)
            setCheckFirstItem(user)
            return
          }
        }
      }
      // 新增选择
      e.push(item)
    }else {
      e = e.filter((s) => { return s.id !== item.id })
    }

    const users = UniqBy(e, "id")
    if (users.length > 25) {
      setIsShowMultipleTransferLimitPop(true)
      return
    }

    commonSetTipText(users)
    setCheckUsers(users)
    const newTags = users.map((c) => c.user_name)
    setTags(newTags)

    if (value) {
      setValue("")
    }
  }

  const confirmPushUser = (item) => {
    let newUsers = checkUsers
    newUsers.push(item)
    const users = UniqBy(newUsers, "id")
    if (users.length > 25) {
      setIsShowMultipleTransferLimitPop(true)
      return
    }

    commonSetTipText(users)
    setCheckUsers(users)
    const newTags = users.map((c) => c.user_name)
    setTags(newTags)
    setCheckFirstItem({})

    if (value) {
      setValue("")
    }
  }

  const descChange = (e) => {
    setDesc(e)
  }

  useEffect(() => {
    let input = document.querySelector("#transfer-account .adm-input")
    input.setAttribute("size", value.length ? value.length + 1 : 5)

    if(!value) {
      setVisible(contactList.length > 0 ? "b" : "c")
      return
    }
    refreshData(value)
  }, [value])

  const inputChange = (e) => {
    // 不能第一位输入@
    e = e.replace(/^@/g, "")
    e = e.replace(/[\u4E00-\u9FA5]+$/, "")

    setValue(e)
    setChecked(false)
  }

  const refreshData = (e) => {
    if (e.length === 0) {
      setVisible(contactList.length > 0 ? "b" : "c")
      clearTimeout(timeout)
      timeout = null
      return
    }

    if (e.length >= 3) {
      clearTimeout(timeout)
      timeout = setTimeout(function () {
        getList({ page: 1 }, e)
      }, 500)
      return
    }

    // e.length == 0 && setVisible("b")
  }

  // 搜索结果列表获取
  const getList = async ({ page }, val) => {
    try {
      if (!val) {
        return
      }
      setLoading(true)
      const res = await searchByQuery({
        query: val,
        page,
      })
      setLoading(false)
      if (res.status_code === 100000) {
        setVisible("a")
        const { pagination, data } = res.response_data
        const { current_page, page_size, total } = pagination
        let currentSearchList = page === 1 ? [] : list
        let newList = currentSearchList.concat(data)
        setPagination(pagination)
        setHasMore(current_page * page_size < total)
        setList(newList)
        setIsBlus(newList && newList[0]?.id ? true : false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const checkOne = (c, i) => {
    InputRef.current.focus()

    setChecked(true)
    setCheckIdx(i)
  }

  const initData = async () => {
    try {
      // setisDisplayLoadingMask(true)
      const res = await Recently({ Authorization: localStorage.getItem("token") })
      // setisDisplayLoadingMask(false)

      if (res.status_code === 100000) {
        let currentContactList = res?.response_data?.data
        currentContactList = currentContactList.map((s) => {
          return {...s, is_show_mobile: true }
        })
        setContactList(currentContactList)
        setVisible(currentContactList.length > 0 ? "b" : "c")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setIsShowTransferAmountLimitPop = (status) => {
    setisShowTransferAmountLimitPop(status)
  }

  const setIsShowMultipleTransferLimitPop = (status) => {
    setisShowMultipleTransferLimitPop(status)
  }

  const setIsShowTransferUndevelopPop = (status) => {
    setisShowTransferUndevelopPop(status)
  }

  // 发起转账
  const handleTransferAccount = async () => {
    // 余额是否充足
    const isOver = isOverMax(balance, checkUsers, amount, poundage?.amount, feeLimit)

    if (isOver) {
      setIsShowTransferAmountLimitPop(true)
      return
    }

    setLoading(true)

    if(tags.length === 1) {
      setVisiblePwd(true)
      ConfirmTransferRef.current.show()
    }

    // 多人转账没有确认框
    if (tags.length > 1) {
      // 多人转账功能是否开启
      if(!allowMult) {
        setisShowTransferUndevelopPop(true)
      }
      
      allowMult && setVisiblePwd(true)
      allowMult && ConfirmTransferRef.current.show()
    }

    setLoading(false)
  }

  const handleChange = (bool) => {
    setVisiblePwd(bool)
  }

  const loadMore = async () => {
    const { current_page } = pagination
    await getList(
      {
        page: current_page + 1,
      },
      value
    )
  }

  const focusInput = () => {
    InputRef.current.focus()
  }

  return (
    <div id="transfer-account">
      <section className="transferAccounts d-flex flex-column align-items-center">
        <NavBar
          onBack={() =>
            history.replace({
              pathname: `/account`,
              search: qs.stringify({ amount }),
            })
          }
          backArrow={<CloseOutline fontSize={24} />}
          className="transfer-navbar"
        >
          <div className="transferTitle">
            {"$" + formatNumberToUSD(parseFloat(amount))}
            {tags.length > 1 && <span className="tag-count">x{tags.length}</span>}
          </div>
          <div className="transferDesc">{t("transfer.balance") + "$" + formatNumberToUSD(handleDot(parseFloat(balance || 0)))}</div>
        </NavBar>

        
        <div className="transfer-inputs">
          <div className="input-item">
            <div className="item-label">{ t("transfer.transferredto") }</div>
            <div className="tags-input" onClick={focusInput}>
              <div className="item-tags">
                {tags.map((c, i) => {
                  return (
                    <Tag
                      onClick={() => checkOne(c, i)}
                      color="#FF3841"
                      key={`tag_${i}`}
                      className="tags"
                      fill={checked && i === checkIdx ? "solid" : "outline"}
                      style={checked && i === checkIdx ? {} : { "--border-color": "#fff" }}
                    >
                      {c}
                      {checked && i === checkIdx ? "" : ","}
                    </Tag>
                  )
                })}
              </div>

              <div className={["input-area", checked && tags.length > 0 ? "checked" : "", tags.length > 0 ? "" : "width-all"].join(" ")}>
                <Input
                  ref={InputRef}
                  placeholder={tags.length > 0 ? "   " : t("transfer.transferPlaceholder")}
                  style={{}}
                  value={value}
                  onKeyUp={onKeyUp}
                  onChange={inputChange}
                  size={5}
                />
              </div>
            </div>
          </div>

          <div className="input-item">
            <span className="item-label">{ t("transfer.transferMessage") }</span>
            <div className="input-area text-area">
              <TextArea
                placeholder={t("transfer.messagePlaceHolder")}
                style={{}}
                rows={1}
                autoSize={{ minRows: 1, maxRows: 3 }}
                maxLength={200}
                onChange={descChange}
              />
            </div>
          </div>

          <div className={["prompts", !isDailyLimitAvailable ? "red" : ""].join(" ")}>
            {!isDailyLimitAvailable ? (
              <Trans i18nKey={t(`transfer.maxthecharge`)} values={{ dailyLimit }} />
            ) : isHas ? (
              // 超出每天免除手续费范围
              <p>
                <span>{t(`transfer.numberoffree`)}</span>
                <span className="red">{t("transfer.fee", { fee: poundage?.amount })}</span>
              </p>
            ) : (
              // 每天免除手续费范围内
              <Trans i18nKey={t(`transfer.beyondthecharge`)} values={{ dailyLimit: poundage?.free_limit, fee: poundage?.amount }} />
            )}
          </div>
        </div>

        <div className="search-warp">
          {/* no list */}
          {visible === "c" && (
            <div className="no-user">
              <p>{t("transfer.noUser")}</p>
            </div>
          )}

          {/* search list */}
          {visible === "a" && (
            <div className="contact-list">
              <ul className="user-list" style={isBlus ? block : diss}>
                {list?.map((item, index) => {
                  const isCheck = checkUsers.find((s) => { return s.id === item.id })
                  return (
                    <li key={index} className="user-list-item">
                      <Checkbox 
                        value={item} 
                        onChange={(e) => checkChange(item, index, e)} 
                        checked={isCheck ? true : false} 
                        style={{ "--gap": "12px", "--icon-size": "14px", "--adm-color-primary": "#FF3841" }}
                      >
                        <div className="item-list">
                          <img src={avatar || item.avatar} alt="" className="item-avatar" />
                          <div className="user-info">
                            <span className="name">{item.user_name || "* "}</span>
                            <span className="mobile">{item.is_show_mobile ? item.mobile : item.email}</span>
                          </div>
                        </div>
                      </Checkbox>
                    </li>
                  )
                })}

                {
                  <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                    <span></span>
                  </InfiniteScroll>
                }

                {loading && (
                  <div className="alignCenter">
                    {t("common.loading")}
                    <Loading />
                  </div>
                )}
                {list.length !== 0 && !loading && <div className="alignCenter">{ t("common.no_more") }</div>}
              </ul>
            </div>
          )}

          {visible === "a" && (
            <div className="unfind-user" style={isBlus ? diss : block}>
              <Image src={unFindImg} width={144} height={144} fit="fill" />
              <p>{t("common.no_search_user")}</p>
            </div>
          )}

          {/* 常用联系人 */}
          {visible === "b" && (
            <div className="contact-list">
              {contactList?.length > 0 && (
                <>
                  <div className="list-title">{t("transfer.recently")}</div>
                  <ul className="user-list">
                    {contactList?.slice(0, 10)?.map((item, index) => {
                      const isCheck = checkUsers.find((s) => { return s.id === item.id })
                      return (
                        <li key={index} className="user-list-item">
                          <Checkbox 
                            value={item} 
                            onChange={(e) => checkChange(item, index, e)} 
                            checked={isCheck ? true : false} 
                            style={{ "--gap": "12px", "--icon-size": "14px", "--adm-color-primary": "#FF3841" }}
                          >
                            <div className="item-list">
                              <img src={item.avatar || avatar} alt="" className="item-avatar" />
                              <div className="user-info">
                                <span className="name">{item.user_name || "* "}</span>
                                <span className="mobile">{item.mobile}</span>
                              </div>
                            </div>
                          </Checkbox>
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>

        <div className="submit-area">
          <Button
            type="success"
            className={tags.length > 0 ? "submit-btn" : "submit-btn dis-btn"}
            disabled={isDailyLimitAvailable && tags.length > 0 ? false : true}
            loading={loading}
            onClick={handleTransferAccount}
          >
            {t("transfer.transferButton")}
          </Button>
        </div>
      </section>

      {/* 确认转账popup */}
      <Popup visible={isShowConfirmTransfer}>
        <ConfirmTransfer
          setisShowConfirmTransfer={setisShowConfirmTransfer}
          // setVisiblePwd={setVisiblePwd}
          confirmPushUser={confirmPushUser}
          name={tags[0]}
          item={checkFirstItem}
          fullName={tags[0]}
        />
      </Popup>
      {/* 余额不足弹框 */}
      <CommonTipPop
        isVisible={isShowTransferAmountLimitPop}
        setIsVisible={setIsShowTransferAmountLimitPop}
        content={
          <>
            <div className="title">{t("common_pop.transfer_limit.title")}</div>
            <div className="subtitle" dangerouslySetInnerHTML={{ __html: t("common_pop.transfer_limit.sub") }} />
            <Button className="l-btn" onClick={() => setIsShowTransferAmountLimitPop(false)}>
              {t("common_pop.transfer_limit.l_btn")}
            </Button>
            <Button
              className="r-btn"
              onClick={() => {
                setIsShowTransferAmountLimitPop(false)
                history.push("/recharge")
              }}
            >
              {t("common_pop.transfer_limit.r_btn")}
            </Button>
          </>
        }
      ></CommonTipPop>
      {/* 最多转账人数上线 */}
      <CommonTipPop
        isVisible={isShowMultipleTransferLimitPop}
        setIsVisible={setIsShowMultipleTransferLimitPop}
        content={
          <>
            <div className="title">{t("common_pop.multiple_transfer_limit.title", { count: 25 })}</div>
            <Button className="r-btn" onClick={() => setIsShowMultipleTransferLimitPop(false)}>
              {t("common_pop.multiple_transfer_limit.r_btn")}
            </Button>
          </>
        }
      ></CommonTipPop>
      {/* 功能未开放弹框 */}
      <CommonTipPop
        isVisible={isShowTransferUndevelopPop}
        setIsVisible={setIsShowTransferUndevelopPop}
        content={
          <>
            <div className="title">{t("common_pop.multiple_transfer_undevelop.title")}</div>
            <div className="subtitle">{t("common_pop.multiple_transfer_undevelop.sub")}</div>
            <Button className="r-btn" onClick={() => setIsShowTransferUndevelopPop(false)}>
              {t("common_pop.multiple_transfer_undevelop.r_btn")}
            </Button>
          </>
        }
      ></CommonTipPop>

      <TransactionPasswordUser
        ref={ConfirmTransferRef}
        visible={visiblePwd}
        handleChange={handleChange}
        panet={{ lists: checkUsers[0], amount, users: checkUsers }}
        panets={desc}
        feeLimit={feeLimit}
        feeRes={poundage}
        openFreezePasswordPop={() => {
          setVisiblePwd(false)
          setIsPasswordFreeze(true)
        }}
      />

      <Mask visible={isPasswordFreeze}>
        <FreezePasswordPop
          closeFreezePop={() => {
            setIsPasswordFreeze(false)
            history.push("/")
          }}
        />
      </Mask>
    </div>
  )
}

export default withRouter(TransFerAccounts)
