import React, { useEffect, useState } from 'react'
import './index.less'
import { Steps, Image, Toast, ImageViewer, Mask } from 'antd-mobile'
import { PictureOutline } from 'antd-mobile-icons'
import rmbEnabled from "@assets/images/rmb/order/rmb_enabled.png"
import alipay from "@assets/images/rmb/icons/alipay.png"
import wechat from "@assets/images/rmb/icons/order_wechat.png"
import union from "@assets/images/rmb/icons/union.png"
import dotLight from "@assets/images/rmb/order/dot_light.png"
import balanceIcon from "@assets/images/rmb/order/flow_us_enabled.png"
import commonPopIcon from '@assets/images/common/notice.png'
import { Trans, useTranslation } from 'react-i18next'
import { timers, ImageInterceptor, handleDot } from '@utils/utils'
import { geTransationDetailByCode, setPendingTransactionPop } from '@actions/transaction'
import { getPendingPaymentStatus } from '@api/transaction'
import { useDispatch } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { formatNumberToUSD } from '@utils'

const { Step } = Steps

async function getImageData(url) {
  let baseImage = await ImageInterceptor(url)
  return baseImage
}

const WaitingPop = (props) => {
  return (
    <div id="waiting-pop">
      <img className='header-icon' src={commonPopIcon} alt=''></img>
      <div className="title">
        正在查询中，请稍后
      </div>
    </div>
  )
}

const ReceiptList = (props) => {
  const { transactionDetail } = props
  const listCount = transactionDetail.receipts.list.length
  const [ list, setList ]= useState(new Array(listCount).fill(''))
  const [ visibleIndex, setVisibleIndex] = useState(1)
  const [ visibleImage, setVisibleImage] = useState(false)

  useEffect(() => {
      let imageList = transactionDetail.receipts.list
      const listPromise = []
      imageList.forEach((item) => {
        listPromise.push(getImageData(item))
      })
      Promise.all(listPromise).then((res) => {
        setList(res)
      })
  },[])

  return (
    <div>
    { 
      list.map((item, index) => item ? <Image src={item} style={{ marginRight: 10 }} width={40} height={40} key={index} onClick={() => {
        setVisibleIndex(index)
        setVisibleImage(true)
      }}/> : <PictureOutline fontSize={48} key={index}/>)
    }
    { visibleImage && visibleIndex >=0 &&
      <ImageViewer.Multi
          images={list}
          visible={visibleImage}
          defaultIndex={visibleIndex}
          onClose={() => {
            setVisibleImage(false)
          }}
      />
    }
    </div>
  )
}

const copy = (t)=>{
  Toast.show({
    content: t('common.copy_tip'),
    duration: 1000,
  })
}

const ConsumptionOrUsdRefundTransaction = (props) => {
  const {transactionDetail, paymentMethod, t} = props
  const receiptStatus = (transactionDetail.receipts !== null && transactionDetail.receipts !== undefined)
  const stepStatus = receiptStatus ? 'wait' : 'process'
  const usdBalanceRefundStatus = RegExp(/80/).test(transactionDetail.status) 
  const tillstarRefundStatus = [6012, 6013, 6014].includes(transactionDetail.status)
  const consumptionStatus = [5003, 5005, 5006, 5007, 5008].includes(transactionDetail.status)
  const switchExpired = transactionDetail.funding_source === 1
  const currentTransactionSystemStamp = timers(new Date(transactionDetail.system_timestamp))
  return (
    <Steps direction='vertical'>
    {
      receiptStatus && <Step 
        title={
          <div className='step-process-title'>{ timers(new Date(transactionDetail.receipts?.system_timestamp))}</div>
        } 
        status='process'
        description={
          <div className='receipt'>
            <div className='des-title'>{t(`transaction_detail.usd_refund.receipt.${transactionDetail.status}`)}</div>
          
            {[5006, 5010, 8002,8005,8008].includes(transactionDetail.status) && <div className='receipt-check'>{t('transaction_detail.usd_refund.receipt.review')}</div> }
            {[5007, 5011,8003,8006,8009].includes(transactionDetail.status) && 
              <div className='receipt-check fail'>{t(`transaction_detail.usd_refund.receipt.review_failed_reason_id.${transactionDetail.receipts.review_failed_reason_id}`)}</div> 
            }
            {[5003, 5004, 5008, 5012, 5013, 5014, 5015, 5016, 5017, 8011].includes(transactionDetail.status) && <div className='receipt-check success'>{t('transaction_detail.usd_refund.receipt.success')}</div>}
            <div>
             <ReceiptList transactionDetail={transactionDetail} />
            </div>
          </div>
        }
        icon={<img src={dotLight} style={{width: '12px'}} alt=''/>}
        >
      </Step>
    }
    {
      (usdBalanceRefundStatus || tillstarRefundStatus) ? 
      <Step 
      title={
        <div className='step-title'>{currentTransactionSystemStamp}</div>
      }  
      icon={receiptStatus ? <span className="adm-step-icon-dot"></span> : <img src={dotLight} style={{width: '12px'}} alt='' />}
      status={stepStatus}
      description={
        <div className='decription'>
            {/* 商户退款 */ }
          <div className='des-title'><Trans i18nKey={t(`transaction_detail.usd_refund.step.${transactionDetail.status}`)} values={{merchant_name: transactionDetail.merchant?.name, amount: handleDot(formatNumberToUSD(transactionDetail.amount))}}/></div>
          <div className='check-out-order' style={{padding:'0px', margin: '0px'}}>
            
            { paymentMethod && (tillstarRefundStatus ?
              <div className='order-detail-item'>
                <label className='order-key'>{t('transaction_detail.usd_refund.base.method')}</label>
                <img alt='' className='icon' src={paymentMethod?.icon} />
                <label className='order-value'>{paymentMethod?.text}</label>
              </div>:
              <div className='order-detail-item'>
                <label className='order-key'>{t('transaction_detail.usd_refund.base.method')}</label>
                {transactionDetail.funding_source === 1 && <img alt='' className='icon' src={balanceIcon} />}
                <label className='order-value'>{t(`transaction_detail.funding_source.${transactionDetail.funding_source}`)}</label>
              </div>)
             }
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.usd_refund.base.code')}</label>
              <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
                <label className='order-value'>{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></label>
              </CopyToClipboard>
            </div>
            <div >
            {
              usdBalanceRefundStatus && <>
              <div className='order-detail-item'>
                <label className='order-key'>{t('transaction_detail.usd_refund.base.amount')}</label>
                <label className='order-value'>${handleDot(formatNumberToUSD(transactionDetail.amount))}</label>
              </div>
              <div className='order-detail-item'>
                <label className='order-key'>{t('transaction_detail.usd_refund.base.balance')}</label>
                <label className='order-value'>${handleDot(formatNumberToUSD(transactionDetail.balance))}</label>
              </div>
              </>
            }
            </div>
          </div>
        </div>
      }></Step>
    : <Step 
      title={
        <div className='step-title'>{currentTransactionSystemStamp}</div>
      }  
      icon={receiptStatus ? <span className="adm-step-icon-dot"></span> : <img src={dotLight} style={{width: '12px'}} alt='' />}
      status={stepStatus}
      description={
        <div className='decription'>
          <div className='des-title'><Trans i18nKey={t(`transaction_detail.consumption.step`)} values={{amount: handleDot(formatNumberToUSD(transactionDetail.amount))}}>{t(`transaction_detail.consumption.step`)} </Trans></div>
          <div className='check-out-order' style={{padding:'0px', margin: '0px'}}>
          { (consumptionStatus && !switchExpired) ? 
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.payment_info.method')}</label>
              <img alt='' className='icon' src={transactionDetail.funding_source === 1 ? balanceIcon : rmbEnabled}/>
              <label className='order-value'>{t(`transaction_detail.funding_source.${transactionDetail.funding_source}`)}</label>
            </div>
            : 
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.payment_info.method')}</label>
              <img alt='' className='icon' src={balanceIcon} />
              <label className='order-value'>{t('transaction_detail.consumption.method')}</label>
            </div>
          }
            {/* <div className='order-detail-item'>
              <label className='order-key'>付款时间</label>
              <label className='order-value'>{transactionDetail.pay_time}</label>
            </div> */}
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.payment_info.code')}</label>
              <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
                <label className='order-value'>{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></label>
              </CopyToClipboard>
            </div>
            <div >
            {
              Number(transactionDetail.amount) && 
              <div className='order-detail-item'>
                <label className='order-key'>{t('transaction_detail.payment_info.real_pay')}</label>
                <label className='order-value'>${handleDot(formatNumberToUSD(transactionDetail.amount))}</label>
              </div>
            }
            {!(consumptionStatus && !switchExpired)  && <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.consumption.tillstar_balance')}</label>
              <label className='order-value'>${handleDot(formatNumberToUSD(transactionDetail.balance))}</label>
            </div>}
            </div>
          </div>
        </div>
      }></Step>
      }
      <div className='customer-step-bottom'>
        <Step title='' status='wait'></Step>
      </div>
    </Steps>
  )
}


const RmbPurchaseTransaction = (props) => {
  const {transactionDetail, paymentMethod, t, rmbPurchaseStatus} = props
  const stepStatus = rmbPurchaseStatus ? 'process' : 'wait'
  const switchStatus = [6009,6010,6011].includes(transactionDetail.status);
  const currentTransactionSystemStamp = timers(new Date(transactionDetail.system_timestamp))
  return (
    <Steps direction='vertical'>
      <Step 
        title={
          <div className='step-title'>{currentTransactionSystemStamp}</div>
        }  
      icon={rmbPurchaseStatus && <img src={dotLight} style={{width: '12px'}} alt=''/>}
      status={stepStatus}
      description={
        <div className='decription'>
          <div className='des-title'><Trans i18nKey={t(`transaction_detail.rmb_purchase.step.${transactionDetail.status}`)} values={{amount: handleDot(formatNumberToUSD(transactionDetail.amount))}}>{t(`transaction_detail.rmb_purchase.step.${transactionDetail.status}`)} </Trans></div>
          <div className='check-out-order' style={{padding:'0px', margin: '0px'}}>
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.payment_info.method')}</label>
              <img alt='' className='icon' src={paymentMethod.icon} />
              <label className='order-value'>{paymentMethod.text}</label>
            </div>
            <div className='order-detail-item'>
              <label className='order-key'>{t('transaction_detail.payment_info.code')}</label>
              <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
                <label className='order-value'>{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></label>
              </CopyToClipboard>
            </div>
            <div >
              {
                (transactionDetail.exchange_rate !== null && transactionDetail.exchange_rate !== '0') &&
                <>
                  <div className='order-detail-item'>
                    <label className='order-key'>{switchStatus ? t('transaction_detail.payment_info.switch_exchange_rate') : t('transaction_detail.payment_info.exchange_rate')}</label>
                    <label className='order-value'>1: {transactionDetail.exchange_rate}</label>
                  </div>
                </>
              }
              { transactionDetail.status !== 6002 && <div className='order-detail-item'>
                <label className='order-key'>{switchStatus ? t('transaction_detail.payment_info.switch_real_pay') : t('transaction_detail.payment_info.real_pay')}</label>
                <label className='order-value'>${handleDot(formatNumberToUSD(transactionDetail.amount))}{
                  (transactionDetail.exchange_rate !== null&& transactionDetail.exchange_rate !== '0') && "(￥" + handleDot(formatNumberToUSD(transactionDetail.real_pay_amount)) + ")"
                }</label>
              </div>
      }
            </div>
          </div>
        </div>
      }></Step>
      <div className='customer-step-bottom'>
        <Step title='' status='wait'></Step>
      </div>
    </Steps>
  )
}


export default function TransactionFlow(props) {
  const transactionDetail = props.transactionDetail
  const dispatch = useDispatch()
  const [isShowWaitingPop, setIsShowWaitingPop] = useState(false)
  const history = useHistory()
  
  
  const orderBaseMatchData = {
    "wechat": {
      icon: wechat,
      text: "微信支付"
    },
    "unionpay": {
      icon: union,
      text: "银联支付"
    },
    "alipay": {
      icon: alipay,
      text: "支付宝支付"
    }
  }
  const {t} = useTranslation()
  const paymentMethod = orderBaseMatchData[transactionDetail.pay_type]
  const activeStatusList = [ 6002, 6003, 6004, 6005, 6006, 6007, 6009, 6010, 6011]
  const rmbPurchaseStatus = activeStatusList.includes(transactionDetail.status)
  const consumptionStatus = [5000, 5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009, 5010, 5011, 5012, 5013, 5014, 5015, 5016, 5017].includes(transactionDetail.status)
  const usdRefundStatus = [6012, 6013, 6014,8000, 8001, 8002, 8003, 8004, 8005, 8006, 8007, 8008, 8009, 8010, 8011].includes(transactionDetail.status)
  const giftCardStatus = [9002].includes(transactionDetail.status)

  const getDetailByCode = async (code) => {
    const currentCode = code ? code : transactionDetail.code
    const res = await geTransationDetailByCode({activityCode: currentCode }, 'check')
    const status = res.response_data.detail.status

    console.log('transactionDetail========',transactionDetail, status)

    if(status === transactionDetail.status) {
      dispatch(setPendingTransactionPop(true))
    } else {
      if (transactionDetail.status === 6001) {
        dispatch(geTransationDetailByCode({activityCode: transactionDetail.code}))
        window.location.reload()
      } else {
        history.push('/rmbOrder?code=' + currentCode)
        window.location.reload()
      }
    }
  }

  const checkTransaction = async () => {
    setIsShowWaitingPop(true)
    const res = await getPendingPaymentStatus({activityCode: transactionDetail.code})
    if (res.status_code === 100000) {
      await getDetailByCode(res.response_data.activity_code)
      setIsShowWaitingPop(false)
    } 
  }

  return (
    <div id='transaction-flow' style={giftCardStatus ? {marginTop: 0} : {}}>      
      <div>
      {
        [6001, 6008].includes(transactionDetail.status) && 
        <div className='check-action'>
          <div className='check-switch-status' onClick={checkTransaction}>{t('transaction_detail.rmb_purchase.pay_status')}</div>
          <div className='check-switch-status check-status' onClick={checkTransaction}>{t('transaction_detail.rmb_purchase.pay_status_tips')}</div>
        </div>
      }
      {
        !transactionDetail.is_gift_card && (consumptionStatus || usdRefundStatus)
        && <ConsumptionOrUsdRefundTransaction  paymentMethod={paymentMethod}  rmbPurchaseStatus={rmbPurchaseStatus} transactionDetail = {transactionDetail} t={t}/>
      }
      {
        rmbPurchaseStatus && <RmbPurchaseTransaction   paymentMethod={paymentMethod}  rmbPurchaseStatus={rmbPurchaseStatus} transactionDetail = {transactionDetail} t={t}/>
      }
      { giftCardStatus && <ul className="payment-detail">
                    <li>
                        <label>{ t("paymentDetails.method") }</label>
                        <span className="value">
                        {transactionDetail.funding_source === 1 && <img alt='' className='icon' src={balanceIcon} />}
                          { t("paymentDetails.tillstar") }
                        </span>
                    </li>
                    <li>
                        <label>{ t("paymentDetails.created_time") }</label>
                        <span className="value">{timers(new Date(transactionDetail.associated_order?.system_timestamp))}</span>
                    </li>
                    <li>
                        <label>{ t("paymentDetails.paid_time") }</label>
                        <span className="value">{timers(new Date(transactionDetail.system_timestamp))}</span>
                    </li>
                    <li>
                        <label>{ t("paymentDetails.transCode") }</label>
                        <CopyToClipboard text={transactionDetail.associated_order?.order_no} onCopy={()=>copy(t)}>
                          <span className="value">{transactionDetail.associated_order?.order_no}<span className='code-copy'>{t('common.copy')}</span></span>
                        </CopyToClipboard>
                    </li>
                    <li>
                        <label>{ t("paymentDetails.code") }</label>
                        <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
                          <span className="value">{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></span>
                        </CopyToClipboard>
                    </li>
                </ul>}
      { transactionDetail.is_gift_card && [8011, 8010].includes(transactionDetail.status) && <ul className="payment-detail">
          <li>
              <label>{ t("paymentDetails.refundAmount") }</label>
              <span className="value">
              {transactionDetail.currency?.symbol}{formatNumberToUSD(transactionDetail.amount)}
              </span>
          </li>
          <li>
              <label>{ t("paymentDetails.refundMethod") }</label>
              <span className="value">
                  {transactionDetail.funding_source === 1 && <img alt='' className='icon' src={balanceIcon} />}
                  {t(`transaction_detail.funding_source.${transactionDetail.funding_source}`)}
              </span>
          </li>
          <li>
              <label>{ t("paymentDetails.refundTime") }</label>
              <span className="value">{timers(new Date(transactionDetail.system_timestamp))}</span>
          </li>
          <li>
              <label>{ t("paymentDetails.code") }</label>
              <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
                <span className="value">{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></span>
              </CopyToClipboard>
          </li>
      </ul>}
      </div>
      <Mask visible={isShowWaitingPop} onMaskClick={() => setIsShowWaitingPop(false)}>
        <WaitingPop/>
      </Mask>
    </div>
  )
}