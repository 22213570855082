import React, { Component } from 'react'
import { Button, Toast, Modal } from 'antd-mobile'
import camera from "@assets/images/activation/icon_paishe_white.png"
import wechat from "@assets/images/certificate/WechatIMG43.png"
import { onfioUpload } from "@api/netki"
import { transformImageFile } from "@utils/utils"

import './index.less'
import StepHeader from '../Steps/StepHeader'
import StepFooter from './StepFooter'
import { t } from 'i18next'
import Header from '@comps/nav/Header'
import KYCHeader from '@pages/kyc-auth/non-ssn/components/Header'
import { uploadNonSSN } from '../../../api/netki'

export default class StepNine extends Component {
  state = {
    list: [
      { name: t('steps.navNameAndDob'), active: false },
      { name: t('steps.navSSN'), active: false },
      { name: t('steps.navAddresses'), active: false },
      { name: t('steps.navEmail'), active: false },
      { name: t('steps.navID'), active: true },
    ],
    picture: '',
    isHas: true,
    visible: false,
    uploading: false,
    from: ''
  }

  componentDidMount() {
    if (this.props.history.location.search.includes('nonssn')) {
      this.setState({
        from: 'nonssn'
      })
    } else {
      this.setState({
        from: ''
      })
    }
    this.scrollTop();
  }

  scrollTop = () => {
    let currentPosition, timer;
    timer = setInterval(function () {
      currentPosition = document.documentElement.scrollTop || document.body.scrollTop   /* 浏览器兼容处理  */
      currentPosition -= 10
      if (currentPosition > 0) {
        window.scrollTo(0, currentPosition)
      } else {
        window.scrollTo(0, 0)
        clearInterval(timer)
      }
    }, 1)
  }

  chuanOne = (e) => {
    if (e.target?.files?.length > 0) {
      let reader = new FileReader()
      reader?.readAsDataURL(e.target.files[0])
      reader.onload = function () {
        document.querySelector('.tpst').src = reader?.result
      }
      this.setState({
        picture: e.target?.files[0],
        isHas: false
      })
      transformImageFile(e.target?.files[0], this.afterCompress)
    } else {
      if (!this.state.picture) {
        this.setState({
          isHas: true
        })
      }
    }
  }
  authentication = async () => {
    const { picture, uploading, from } = this.state
    if (!picture || uploading) { return false; }
    let applicantId = localStorage.getItem('AuthorizationID')
    var formdata = new FormData();


    if (from === 'nonssn') {

      formdata.append('kycFile', picture)
      formdata.append('fileType', 'selfie')
      const res = await uploadNonSSN(formdata)
      if (res?.status_code === 100000) {
        this.props.history.push('/upload-nonssn');
      } else {
        Toast.show({
          content: t('steps.guideActionFailed'),
          duration: 4000,
        })
      }
      return;
    }
    formdata.append("live_photo", picture);
    formdata.append("verificationRequestId", applicantId);

    this.setState({
      uploading: true
    })
    const res = await onfioUpload(formdata)

    switch (res?.status_code) {
      case 100000:
        this.props.history.push('/submitApplication')
        break;
      case 300001: //Service call failed
        Toast.show({
          content: t('steps.SSNErr'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
      case 300008: //Your account is not qualified for this function.
        this.props.history.push("/");
        break;
      case 300010: //The file uploaded is too large.
        Toast.show({
          content: t('steps.fileUploadErr'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
      case 301020: //The face photo is not valid.
        this.setState({ uploading: false, visible: true })
        break;
      case 301018: //This step has been passed, please proceed to the next step.
        Toast.show({
          content: t('steps.guideStepInfoErrBackToHome'),
          duration: 4000,
        })
        this.props.history.push("/");
        break;
      case 301019: //Onfido service call failed.
      case 300002: //Invalid parameter
      default:
        Toast.show({
          content: t('steps.guideActionFailed'),
          duration: 4000,
        })
        this.setState({ uploading: false })
        break;
    }
  }

  afterCompress = (file) => {
    this.setState({
      picture: file,
      isHas: false
    });
  }

  render() {
    const { list, isHas, visible, picture, uploading, from } = this.state
    return (
      <div className="stepNine">

        {
          from === 'nonssn' ? <>
            <Header />
            <KYCHeader title={t('KYC.nonssn.selfie.title')} subTitle={t('KYC.nonssn.selfie.subTitle')} />
          </> : <StepHeader
            title={t('steps.IDCheckTitle')}
            subTitle={t('steps.IDCheckSubTitle')}
            list={list}
          />
        }
        <div className='document-type textAlignLeft' >
          <p className='type-title'>{t('steps.IDCheckUploadYourSelfie')}</p>
          <p>
            <span style={{
              fontFamily: 'Alibaba PuHuiTi',
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "174.2%",
              color: "#999999"
            }}>{t('steps.IDCheckPhotoHint1')}</span>
          </p>
          <div className="upload-image">
            <img src={wechat} className="holder-image" style={!isHas ? { opacity: "0" } : {}} alt="" />
            <input type="file" capture="camera" className='files' accept="image/*" style={{
              position: "absolute",
              top: "22%",
              left: "32%",
              width: "36%",
              height: "50%",
              opacity: 0
            }}
              onChange={this.chuanOne} />
            {!isHas &&
              <>
                <img src="" alt="" className='tpst' />
                <div className="bottom-btn">
                  <input type="file" capture="camera" className='files' accept="image/*" onChange={this.chuanOne} />
                  <div className="action" onClick={() => { document.querySelector('.files').click() }}>
                    <img src={camera} alt="" />
                    {t('steps.IDCheckClickToReUpload')}
                  </div>
                </div>
              </>}
          </div>
        </div>
        <StepFooter
          isLoading={uploading}
          disabled={!(picture || uploading)}
          btnSubmit={this.authentication}
          btnText={t('steps.IDCheckDone')}
          licenseInfoBottom={t('steps.guideFPB')}
        />
        <Modal
          visible={visible}
          content={
            <div style={{ textAlign: "center" }}>
              <p>
                <div dangerouslySetInnerHTML={{ __html: t('steps.IDCheckPhotoErr') }} />
              </p>
              <Button block shape='rounded' color='danger' style={{ width: "60%", margin: "0 auto", marginTop: "0.3rem" }}
                onClick={() => {
                  this.setState({
                    visible: false
                  })
                }}>
                {t('steps.IDCheckAck')}
              </Button>
            </div>
          }
          closeOnMaskClick={true}
          onClose={this.setVisable}
        />
      </div>
    )
  }
}

