import {
    SET_REDEEM_INFO,
    SET_REDEEM_TYPE,
    SET_REDEEM_REWARD_SUMMARY
} from '../actions/ActionTypes'

const initialState = {
    redeemInfo: {},
    redeemType: sessionStorage.getItem('redeemType') || ''
}

const reward = (state = initialState, action) => {
    let newState = {
        ...state
    }
    switch (action.type) {
        case SET_REDEEM_INFO:
            newState.redeemInfo = action.payload
            return newState
        case SET_REDEEM_TYPE:
            newState.redeemType = action.payload
            return newState
        case SET_REDEEM_REWARD_SUMMARY:
            newState.returnUrl = action.payload
            return newState    
        default:
            return state
    }
}

export default reward