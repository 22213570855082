import {
    SWITCH_SELECTED_LANG,
    SET_IS_FIRST_VISIT,
    SET_USER_INFO,
    SET_IS_TRANSACTION_PIN_SET
} from './ActionTypes'
import i18n from 'i18next'

import packageJson from '../../package.json';

export const switchSelectedLang = (selectedLang) => {
  const i18nLang = selectedLang === 'zh_CN' ? 'zh_CN' : 'en_US'
  localStorage.setItem('selectedLang', i18nLang)
  i18n.changeLanguage(i18nLang)
  return {
    type: SWITCH_SELECTED_LANG,
    selectedLang
  }
}

export const setIsFirstVisit = (isFirstVisit) => {
  localStorage.setItem('isFirstVisit', isFirstVisit)
  return {
    type: SET_IS_FIRST_VISIT,
    isFirstVisit
  }
}

export const setUserInfo = (userInfo)=>{
  if(!localStorage.getItem("version") || localStorage.getItem("version") !== packageJson.version) {
    localStorage.setItem("version", packageJson.version);
  }
  
  return {
    type: SET_USER_INFO,
    userInfo
  }
}

export const setIsTransactionPinSet = ()=>{
  return {
    type: SET_IS_TRANSACTION_PIN_SET,
    payload: true
  }
}
