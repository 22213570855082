import React, { useState, useEffect } from "react"
import { NavBar, InfiniteScroll, Loading } from "antd-mobile"
import "./index.less"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { transferTime } from "@utils"
import notice1 from "@assets/images/notify/icon_notice_email.png"
import notice2 from "@assets/images/notify/icon_notice_activity.png"
import notice3 from "@assets/images/notify/icon_notice_bonus.png"
import notice4 from "@assets/images/notify/icon_notice_sales.png"
import notice5 from "@assets/images/notify/icon_notice_fengkong.png"
import imgNoRecord from "@assets/images/notify/pic_norecord.png"
import { getTypeNotifyList } from "@api/account"
import { setFromMsg, setActiveFromMsg } from "@actions/common"

export default function Messages(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [type, setType] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [msglist, setList] = useState([])

  const back = () => {
    history.goBack()
  }

  const getList = async ({ page }) => {
    try {
      if (!type) return
      setLoading(true)
      const res = await getTypeNotifyList({
        categoryId: type,
        page,
      })
      setLoading(false)
      if (res.status_code === 100000) {
        const { pagination, list } = res.response_data
        const { current_page, page_size, total } = pagination
        let currentSearchList = page === 1 ? [] : msglist
        let newList = currentSearchList.concat(list)
        setPagination(pagination)
        setHasMore(current_page * page_size < total)
        setList(newList)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setType(params.get("type"))
    getList({ page: 1 })
  }, [])

  useEffect(() => {
    if (!type) return
    getList({
      page: 1,
    })
  }, [type])

  const loadMore = async () => {
    const { current_page } = pagination
    await getList({
      page: current_page + 1,
    })
  }

  const clickItem = (item) => {
    const { content } = item

    if (content.indexOf("/savingAccount") >= 0) {
      // 记录是从消息跳转到saving
      dispatch(setFromMsg(true))
    }

    // 立即激活或者重新激活
    if (content.indexOf('href="/"') >= 0 && content.indexOf('<span class="conect">立即激活</span>') >= 0) {
      dispatch(setActiveFromMsg(true))
    }
  }

  const getImg = (type = 1) => {
    let imgs
    if (type === 1) imgs = notice1

    if (type === 2) imgs = notice2

    if (type === 3) imgs = notice3

    if (type === 4) imgs = notice4

    if (type === 5) imgs = notice5
    return imgs || notice1
  }

  const getTitle = (type = 1) => {
    let title = ""
    if (type === 1) title = "系统通知"

    if (type === 2) title = "动账通知"

    if (type === 3) title = "奖励通知"

    if (type === 4) title = "活动通知"

    if (type === 5) title = "风控通知"
    return title || "通知"
  }

  return (
    <div className="message-list-page">
      <div className="nav-bar">
        <NavBar onBack={back}>{getTitle(type)}</NavBar>
      </div>

      <div className="message-list">
        {msglist.map((item, index) => {
          return (
            <div className="message-list-item" key={`category_id_${index}`}>
              <div className="message-time">{transferTime(item.notify_at, "MM-DD HH:mm")}</div>
              <div className="message-item">
                <div className="item-left">
                  <img className="left-Icon" src={getImg(item.category_id)} alt='' />
                </div>
                <div
                  className="item-content"
                  onClick={() => clickItem(item)}
                  dangerouslySetInnerHTML={{
                    __html: `${item.content}`,
                  }}
                />
              </div>
            </div>
          )
        })}

        {
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <span></span>
          </InfiniteScroll>
        }

        {loading && <div className="alignCenter">加载中<Loading /></div>}
        {msglist.length !== 0 && !loading && <div className="alignCenter">没有更多了</div>}

        {msglist.length === 0 && !loading && (
          <div className="no-message">
            <img src={imgNoRecord} alt="" />
            <p>暂时没有消息哦~</p>
          </div>
        )}
      </div>
    </div>
  )
}
