import {
  SET_COMMON_POP_CONTENT,
  SET_COMMONN_POP_STATUS,
  GET_COUNTRY_LIST,
  SET_KYC_ENTRANCE,
  SET_IS_NO_PERMISSION,
  SET_STOP_TAKING,
  SET_STORE_RESSET,
  SET_LOGIN_FROM_PAGE,
  SET_FROM_MSG,
  SET_ACTIVE_FROM_MSG,
  SET_HOME_EYE_STATUS
} from './ActionTypes'


import request from "@utils/request"
import { t } from 'i18next'

export const setCommonPopContent = (status_code)=> {
  return {
    type: SET_COMMON_POP_CONTENT,
    status_code
  }
}

export const setCommonPopStatus = (status)=> {
  return {
    type: SET_COMMONN_POP_STATUS,
    status
  }
}

export const getCountryList = ()=> {
  return (dispatch) => {
    request({
      method: "GET",
      url: `/app/static/country-list`
    }).then((res) => {  
      const tempCountryList = res.response_data
      const countryCodeList = tempCountryList.map((item) => 
      `+${item.country_calling_code} ${t(`general.countryName${item.code}`)}`)
      dispatch({
        type: GET_COUNTRY_LIST,
        countryCodeList
      })
    });
  }
}

export const setCountryList = (countryCodeList)=> {
  localStorage.setItem('countryCodeList', JSON.stringify(countryCodeList))
  return {
    type: GET_COUNTRY_LIST,
    countryCodeList
  }
}

export const setKycEntrance = (payload)=> {
  return {
    type: SET_KYC_ENTRANCE,
    payload
  }
}

export const setIsNoPermission = (payload) => {
  return {
    type: SET_IS_NO_PERMISSION,
    payload
  }
}

// 停服公告通知
export const setStopTaking = (data)=> {
  return {
    type: SET_STOP_TAKING,
    data
  }
}

// 清空store
export const clearStore = (data)=> {
  return {
    type: SET_STORE_RESSET,
    data
  }
}


// 进入页面如果有路径记住
export const setLoginFromPage = (data)=> {
  return {
    type: SET_LOGIN_FROM_PAGE,
    data
  }
}


// 是否从消息跳转
export const setFromMsg = (payload)=> {
  return {
    type: SET_FROM_MSG,
    payload
  }
}


// 立即激活，继续激活是否从消息跳转
export const setActiveFromMsg = (payload)=> {
  return {
    type: SET_ACTIVE_FROM_MSG,
    payload
  }
}

// 设置首页显示影藏金额
export const setHomeEyeStatus = (payload) => {
  localStorage.setItem('homeEyeStatus', payload)
  return {
    type: SET_HOME_EYE_STATUS,
    payload
  }
}

