import React, { Component } from 'react'
import { CheckCircleFill } from 'antd-mobile-icons'

import { Button, NavBar } from 'antd-mobile'
import { scrollToTop } from "@utils/utils"
import './index.less'
import { connect } from 'react-redux'
import { t } from 'i18next'

const mapStateToProps = (store) => {
  return {
    passwordRelatedChannel: store.password.passwordRelatedChannel,
  }
}
class EditTransactionPasswordSuccess extends Component {
  state = {
    reset: ''
  }
  componentDidMount() {
    scrollToTop()
    this.setState({
      reset: sessionStorage.getItem("reset")
    })
  }
  goMine = () => {
    const returnLink = this.props.passwordRelatedChannel.url
    // const search = this.props.passwordRelatedChannel.search

    if(returnLink) {
      this.props.history.go(-5)
      return
    }

    // // 有url 还需要带参数
    // if(returnLink && search) {
    //   this.props.history.replace({
    //     pathname: returnLink,
    //     search: this.props.passwordRelatedChannel.search
    //   })
    //   return
    // }

    // // 有url 不需要带参数
    // if(returnLink && !search) {
    //   this.props.history.replace({ pathname: returnLink, state: { router: 'setting' } })
    //   return
    // }

    // 其他
    this.props.history.replace({ pathname: "/setting", state: { router: 'setting' } })
  }
  goBack = () => {
    this.props.history.push("/setting")
  }
  render() {
    const { reset } = this.state
    return (
      <div className='editTransactionPassword-success-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          onBack={this.goMine}
        >
          {t("setPassword.Setpassword")}
        </NavBar>
        <section className="editTransactionPassword-success d-flex flex-column align-items-center">
          <div className="success-warp">
            <CheckCircleFill className="icons" fontSize={80} color="#1F49DA"/>
            <div className="title" style={{textAlign: "center"}}>
              {t("setPassword.transactionpassword")}
              {reset ?
                t("setPassword.reset") : t("setPassword.Modifythe")
              }
              {t("setPassword.successful")}
            </div>
          </div>
          <div className="btn-container">
            <Button
              type="warning"
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center']}
              onClick={this.goMine}
            >
              {t('general.done')}
            </Button>
          </div>
        </section>
      </div>

    )
  }
}

export default connect(mapStateToProps, null)(EditTransactionPasswordSuccess)
