
import './index.less'
import { Tabs } from 'antd-mobile'

import officialCn from "@assets/images/rmb/order/official_cn.png"
import appleCn from "@assets/images/rmb/order/apple_cn.png"

import officialEn from "@assets/images/rmb/order/official_en.png"
import appleEn from "@assets/images/rmb/order/apple_en.png"
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export default function RmbTutorial() {
  const history = useHistory()
  const {t} = useTranslation()
  const goTutorials = () => {
    history.push("/tutorials?key=linkApplePay")
  }
  // memo(()=>{
    return (
      <div id='rmb-tutorial'>
        <Tabs
          style={{
            '--title-font-size': '14px',
          }}
          >
          <Tabs.Tab title={ t('transaction_detail.tutorials.official.title') } key='official' > 
            <div className='official'>
              <div dangerouslySetInnerHTML={{__html: t('transaction_detail.tutorials.official.des')}}></div>
              <img alt='' src={t('transaction_detail.tutorials.official.img') === 'officialEn' ?officialEn: officialCn } />
  
            </div>
          </Tabs.Tab>
          <Tabs.Tab title={t('transaction_detail.tutorials.apple.title')} key='apple' >           
            <div className='apple-pay'>
              <div dangerouslySetInnerHTML={{__html: t('transaction_detail.tutorials.apple.des')}}></div>
              <div  style={{fontSize: '12px',  color:'#1F49DA'}} onClick={() => goTutorials()} dangerouslySetInnerHTML={{__html: t('transaction_detail.tutorials.apple.link')}}></div>
              <img alt='' src={t('transaction_detail.tutorials.apple.img') === 'appleEn' ? appleEn: appleCn } />
            </div>
            </Tabs.Tab>
        </Tabs>
      </div>
    )
  // })
}
  
