import './index.less'
import React, { Component } from 'react'
import { Mask, NavBar, Toast } from 'antd-mobile'
import Tip from '@comps/Tip'
import { getUserInfo, getPermissionByCode } from '@api/user'

import store from '../../../store';
import { t } from 'i18next'

const staticPath = 'https://static1.front.youworld.us/gs/tutorials'

const rmbIntroCn1 = `${staticPath}/intro/rmb/cn1.png`
const rmbIntroCn2 = `${staticPath}/intro/rmb/cn2.png`
const rmbIntroCn3 = `${staticPath}/intro/rmb/cn3.png`
const rmbIntroCn4 = `${staticPath}/intro/rmb/cn4.png`
const rmbIntroCn5 = `${staticPath}/intro/rmb/cn5.png`
const rmbIntroCn6 = `${staticPath}/intro/rmb/cn6.png`
const rmbIntroCn7 = `${staticPath}/intro/rmb/cn7.png`

const rmbIntroEn1 = `${staticPath}/intro/rmb/en1.png`
const rmbIntroEn2 = `${staticPath}/intro/rmb/en2.png`
const rmbIntroEn3 = `${staticPath}/intro/rmb/en3.png`
const rmbIntroEn4 = `${staticPath}/intro/rmb/en4.png`
const rmbIntroEn5 = `${staticPath}/intro/rmb/en5.png`
const rmbIntroEn6 = `${staticPath}/intro/rmb/en6.png`
const rmbIntroEn7 = `${staticPath}/intro/rmb/en7.png`


const cardIntroCn1 = `${staticPath}/intro/card/cn1.png`
const cardIntroCn2 = `${staticPath}/intro/card/cn2.png`
const cardIntroCn3 = `${staticPath}/intro/card/cn3.png`
const cardIntroCn4 = `${staticPath}/intro/card/cn4.png`

const cardIntroEn1 = `${staticPath}/intro/card/en1.png`
const cardIntroEn2 = `${staticPath}/intro/card/en2.png`
const cardIntroEn3 = `${staticPath}/intro/card/en3.png`
const cardIntroEn4 = `${staticPath}/intro/card/en4.png`

const rmbCn = [
  rmbIntroCn1,
  rmbIntroCn2,
  rmbIntroCn3,
  rmbIntroCn4,
  rmbIntroCn5,
  rmbIntroCn6,
  rmbIntroCn7
]
const rmbEn = [
  rmbIntroEn1,
  rmbIntroEn2,
  rmbIntroEn3,
  rmbIntroEn4,
  rmbIntroEn5,
  rmbIntroEn6,
  rmbIntroEn7
]
const cardCn = [
  cardIntroCn1,
  cardIntroCn2,
  cardIntroCn3,
  cardIntroCn4
]
const cardEn = [
  cardIntroEn1,
  cardIntroEn2,
  cardIntroEn3,
  cardIntroEn4
]

export default class Intro extends Component {
  state = {
    showDialog: false,
    userInfos: '',
    isFreeze: false,
    verifyStatus: ''
  }
  useClicked = async () => {
    const {userInfos, verifyStatus, isFreeze} = this.state
    if(isFreeze) {
      Toast.show({
        content: t('home.dollaraccounthasbeenfrozen'),
        duration: 1000,
      })
      return;
    }

    const res = await getPermissionByCode({ permissionCode: 'permission_instant_consumption_coin'})
    if (res.status_code === 300008) return
    if (userInfos && (verifyStatus === 0 || verifyStatus === 2)) {
      this.setState({
        showDialog: true,
      })
      return
    }
    
    this.props.history.push('/rmb')
    
  }
  async componentDidMount() {
    const userInfoRes = await getUserInfo().catch(error => { console.log(error)})
    this.setState({
      userInfos: userInfoRes,
      verifyStatus: userInfoRes?.response_data?.identity_verification_status,
      isFreeze: userInfoRes?.response_data?.default_financial_account?.status === 2,
      displayLoadingMask: false
    })
  }
  handleCloseTip = () => {
    this.setState({
      showDialog: false,
    })
  }
  render() {
    const { history, location } = this.props
    function back(){
      history.goBack();
    }
    const rmb = store.getState().user.selectedLang === 'zh_CN' ? rmbCn: rmbEn
    const card = store.getState().user.selectedLang === 'zh_CN' ? cardCn: cardEn
    const intro = location.search.indexOf('rmb') > -1 ? rmb: card
    const title = location.search.indexOf('rmb') > -1 ? t('home.rmbButton'):  t('home.purchaseGiftCardButton')
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    
    const introStyle = {
      backgroundColor: (store.getState().user.selectedLang !== 'zh_CN' && searchParams.get('type') === 'rmb') ? '#fff' : "#f9f9f9"
    }
    const {showDialog} = this.state

    return (
      <div id='tutorial-intro' style={introStyle}>
        <Mask visible={showDialog} disableBodyScroll>
          <Tip onClose={this.handleCloseTip} verifyStatus={this.state.verifyStatus} />
        </Mask>
        <NavBar onBack={back}>{title}</NavBar>
        {
          intro.map((item, index)=>{
            return (
              <img src={item} key={index} alt='' style={{width: '100%'}}/>
            )
          })
        }
        {
          searchParams.get('type') === 'rmb' && <div className='intro-action'>
           <div className='use-btn' onClick={()=>this.useClicked()}> {store.getState().user.selectedLang !== 'zh_CN' ? 'USE NOW' :'立即使用'}</div>
          </div>
        }
      </div>
    )
  }
}
