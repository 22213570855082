import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd-mobile'
import './index.less'
import { t } from 'i18next'

class ActivateAlert extends Component {
  render() {
    const { onClose, handleConfirm } = this.props
    return (
      <div className="activateAlert_dialog">
        <div className="dialog__alert">
          <div className="dialog__content">
            <p className="title">{t('steps.cancelPopUpTitle')}</p>
            <p className="sub-title">{t('steps.cancelPopUpDesc')}</p>
          </div>
          <div className="dialog_bottom__btns d-flex align-items-center justify-content-between">
            <Button className="confirm_btn" onClick={handleConfirm}>
              {t('steps.cancelPopUpOK')}
            </Button>
            <Button className="cancel_btn" onClick={onClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ActivateAlert)
