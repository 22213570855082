import React, { useState, useEffect, useRef } from "react"
import { NavBar, Toast, PullToRefresh, Mask } from "antd-mobile"
import { CloseCircleOutline, RightOutline } from "antd-mobile-icons"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { t } from "i18next"

import "./index.less"
import WidthDrawMoney from "@comps/WidthDrawMoney" //提现弹框
import WidthDrawPassword from "@comps/WidthDrawPassword" //提现密码弹框
import StepNumberkey from "@comps/StepNumberkey"
import FreezePasswordPop from "@comps/FreezePasswordPop"
import StopTakingNotice from "@comps/StopTakingNotice"
import RelateExceptionPop from "@comps/RelateExceptionPop"

import { getBalance, getUserInfo } from "@api/user"
import { getExternalAccounts, removeExternalAccount } from "@api/plaid"
import { getBindRequestList, accountFee } from "@api/account"
import { setCurrentBankInfo, setVerifyBankFrom, setSelectedWithdrawalBank, setSelectedBank } from "@actions/account"
import { setPasswordRelatedChannel } from "@actions/password"

import tillstarLoading from "@assets/images/common/tillstar_loading.gif"
import bank from '@assets/images/associatedBank/bank.png'
import addImg from "@assets/images/mine/icon_guanlian@3x.png"

import { handleDot } from "@utils/utils"
import { formatNumberToUSD, formatNum } from "@utils"

const MINI_AMOUNT = 0.01
const MAX_AMOUNT = 99999

const show = { display: "none" }
const vis = { display: "block" }

export default function WidthDraw() {
  const history = useHistory()
  const dispatch = useDispatch()
  const ChildRef = useRef()
  const selectedLang = useSelector((state) => state.user.selectedLang)
  const selectedWithdrawalBank = useSelector((state) => state.account.selectedWithdrawalBank)

  const [bankAccount, setBankAccount] = useState([])
  const [bankList, setBankList] = useState([])
  const [info, setInfo] = useState({})
  const [feeInfo, setFeeInfo] = useState({})
  const [externalItemClicked, setExternalItemClicked] = useState({})
  const [balance, setBalance] = useState({})
  const [bAcount, setBAcount] = useState({})
  const [service, setService] = useState({})

  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const [showRelateExceptionPop, setShowRelateExceptionPop] = useState(false)
  const [stopTakingPop, setStopTakingPop] = useState(false)
  const [fee, setFee] = useState(false)
  const [maskShow, setMaskShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isRecharge, setIsRecharge] = useState(true)
  const [vishow, setVishow] = useState(true)

  const [amount, setAmount] = useState("")
  const [withDrawInfo] = useState({})
  // const [userMobile] = useState("")
  const [feeCode] = useState("ach_out")

  const balances = balance?.balance
  const noGalileo = bAcount?.external_link_service_provider !== "Galileo"
  const noAbnormal = bAcount?.external_account_status !== 7
  const reauthorization = bAcount?.external_account_status === 4
  const accAbnormal = bAcount?.status === 6

  useEffect(() => {
    async function fetchData() {
      document.documentElement.scrollTop = 0 // ios置顶
      window.scrollTo(0, 0) //ios之外置顶
      dispatch(setVerifyBankFrom(""))
      // 重置选择账户
      dispatch(setSelectedBank({}))
      await getBindBankList()
      await getExternalFunction()
    }
    fetchData()
  }, [])

  const getBindBankList = async () => {
    const res = await getBindRequestList({ page: 1 })
    if (res.status_code === 100000) {
      setBankList(res.response_data.list)
    }
  }

  const getExternalFunction = async (type = "init") => {
    try {
      const [res, ress, info, recc] = await Promise.all([accountFee({ feeCode }), getBalance(), getUserInfo(), getExternalAccounts()])

      type === "init" && setLoading(false)
      if (info.status_code === 100000) {
        setInfo(info)
      }

      if (res.status_code === 100000) {
        setService(res)
        setFeeInfo(res?.response_data)
        const { freeLimitAvailable } = res.response_data
        freeLimitAvailable > 0 ? setFee(false) : setFee(true)
      }

      if (ress.status_code === 100000) {
        setBalance(ress.response_data)
      }

      if (recc.status_code === 100000) {
        const banksRes = recc?.response_data?.data

        if (banksRes.length === 0) return setVishow(true)

        const findFromLocal = banksRes.find((item) => item.id === Number(selectedWithdrawalBank))
        const findFromOne = banksRes.find((item) => item.status === 1)
        const newAcount = findFromLocal || findFromOne || banksRes[0]

        !selectedWithdrawalBank && dispatch(setSelectedWithdrawalBank(newAcount?.id))

        setVishow(false)
        setBAcount(newAcount)
        setBankAccount(banksRes)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const goBack = () => {
    history.push("/")
  }

  //   全部提现
  const withDrawAll = () => {
    setKeyNumber(handleDot(balances))
  }

  // // 提现
  // const withdrawAccount = async (tradePassword) => {
  //   await withdrawAccount({
  //     sourceAccountNo: userMobile, //提现账号
  //     destAccountNo: userMobile, //到账账号
  //     amount, //充值金额
  //     transactionPin: tradePassword, //交易密码
  //   })
  // }

  const submitRecharge = (isRecharge) => {
    if (amount > 0 || amount < 1000000) {
      setTimeout(() => {
        setIsRecharge(isRecharge)
        setMaskShow(true)
      }, 500)
    }
  }

  const hiddenMask = () => {
    setMaskShow(false)
  }

  const chooseCard = async () => {
    sessionStorage.setItem("recognition", "recognition")
    dispatch(setVerifyBankFrom("/withdraw"))
    dispatch(setSelectedBank({ from: "/withdraw" }))
    const userInfo = await getUserInfo()
    if (userInfo.response_data?.is_transaction_pin_set) {
      history.push({
        pathname: `/associatedBank`,
      })
    } else {
      dispatch(
        setPasswordRelatedChannel({
          channel: "addBank",
          type: "set",
          url: "/associatedBank",
          backUrl: "/withdraw",
        })
      )
      history.push("/verifyCode")
    }
  }

  //   选择银行账户
  const chooseBankCard = () => {
    sessionStorage.setItem("choose", "bank")
    dispatch(setSelectedBank({ from: "/withdraw" }))
    history.push(`/selectBank`)
  }

  const setKeyNumber = (val) => {
    if (accAbnormal && noAbnormal && val) {
      handleException(bAcount)
      return
    }
    if (parseFloat(val) > MAX_AMOUNT) {
      val = amount
    } else if (val.indexOf(".") === -1) {
      let arr = val.split("")
      let index = arr.findIndex((v) => v !== "0")
      index !== -1 && (val = arr.splice(index).join(""))
    } else if (val.indexOf(".") === 0) {
      val = 0 + val
    } else {
      let arr = val.split(".")[0].split("")
      let index = arr.findIndex((v) => v !== "0")
      if (index === -1) {
        val = "0." + val.split(".")[1]
      } else {
        val = arr.splice(index).join("") + "." + val.split(".")[1]
      }
    }

    setAmount(val)
  }

  const handleInputClick = () => {
    if (accAbnormal && noAbnormal) {
      handleException(bAcount)
      return
    }

    ChildRef.current.handleUnFold()
  }
  // 处理账户异常
  const handleException = (item) => {
    setShowRelateExceptionPop(true)
    setExternalItemClicked(item)
  }

  // 删除银行账户
  const removeBankAccount = async () => {
    const deleteAccount = await removeExternalAccount({ externalAccountId: externalItemClicked?.id })

    if (deleteAccount?.status_code === 100000) {
      closeRelateExceptionPop()
      Toast.show({
        content: t('common.remove_succeeded'),
        duration: 1000,
      })
    }

    const ress = await getExternalAccounts()

    if (ress.response_data.data.length) {
      let bAcount = ""
      let bankAccount = ress?.response_data?.data
      if (bankAccount.find((item) => item.status === 1)) {
        bAcount = bankAccount.find((item) => item.status === 1)
      } else {
        bAcount = bankAccount[0]
      }
      localStorage.setItem("IDofAccountChosen", bAcount?.id)
      setBAcount(bAcount)
      setLoading(false)
      setVishow(false)
    } else {
      setLoading(false)
      setVishow(false)
      setBAcount()
      setBankAccount([])
      getExternalFunction()
    }
  }

  const closeRelateExceptionPop = () => {
    setShowRelateExceptionPop(false)
  }

  const miniBalance = () => {
    return feeInfo.freeLimitAvailable === 0 ? MINI_AMOUNT + parseFloat(feeInfo.fee?.amount) : MINI_AMOUNT
  }

  const isNotSufficientBalance = () => {
    const realBalance = parseFloat(balances)
    const parseAmount = parseFloat(amount)
    
    if (feeInfo.freeLimitAvailable === 0) {
      const fee = parseFloat(feeInfo.fee?.amount)

      const isDisabled = amount === "" || realBalance <= fee || parseAmount > realBalance || parseAmount + fee > realBalance
      return isDisabled && !(parseAmount <= realBalance && parseAmount > (realBalance - fee > fee ? realBalance - fee : fee))
    } else {
      return parseAmount > realBalance
    }
  }

  const CommonLoading = () => (
    <div>
      <img className="tillstar-loading-icon" src={tillstarLoading} alt="" />
    </div>
  )

  return (
    <div id="withdraw" className="withdraw">
      <NavBar className="bgfff" onBack={() => goBack()}>
        {t('withdraw.navTitle')}
      </NavBar>

      {/* 停服公告 */}
      <div className="topNotice">
        <StopTakingNotice stopTakingPop={stopTakingPop} setStopTakingPop={(e) => setStopTakingPop(e)} />
      </div>

      {loading && <CommonLoading />}

      <PullToRefresh onRefresh={() => !loading && getExternalFunction("pull")} renderText={() => !loading && <CommonLoading />}>
        {isRecharge && maskShow ? (
          <WidthDrawMoney
            amount={amount}
            perTransFee={withDrawInfo?.perTransFee}
            service={service}
            balance={balances}
            hiddenMask={hiddenMask}
            submitRecharge={submitRecharge}
          />
        ) : (
          ""
        )}
        {!isRecharge && maskShow ? (
          <WidthDrawPassword
            amounts={amount}
            perTransFee={withDrawInfo?.perTransFee}
            bAcount={bAcount}
            service={service}
            balance={balances}
            openFreezePasswordPop={() => setIsPasswordFreeze(true)}
            hiddenMask={hiddenMask}
          />
        ) : (
          ""
        )}

        <div className="wrap">
          <div className="bank-tip">
            {t('withdraw.withdraw_cash')}
          </div>
          <div className="d-flex align-items-center justify-content-between" style={vishow ? vis : show}>
            <div className="d-flex" onClick={() => chooseCard()}>
              <div className="logo">
                <img src={addImg} alt="" />
              </div>
              <div className="bank-name">
                <div className={selectedLang === "zh_CN" ? "one-title" : "one-title font14"}>
                  {t('recharge.associated_bank_account')}
                </div>
                <div className="two-title">
                  {t('recharge.fast_bank_account_connection')}
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-between"
            onClick={() => chooseBankCard()}
            style={vishow === false ? vis : show}
          >
            <div className="d-flex align-items-center" style={{ position: "relative" }}>
              <div className="logo">
                <img src={bAcount?.institution_logo ? bAcount?.institution_logo : bank} alt="" />
              </div>
              <div className="bank-name">
                <div className="bank-names d-flex align-items-center">
                  {bAcount?.name} {bAcount?.number}
                  {accAbnormal && noAbnormal && (
                    <div className={["bank-account-status", `bank-account-status-${reauthorization ? 2 : 3}`].join(" ")}>
                      {reauthorization ? t('recharge.reauthorize') : t('recharge.account_abnormal')}
                    </div>
                  )}
                </div>
                <div className="bank-detail">
                  {!noGalileo && t('recharge.expected_to_arrive_hours')}
                  {noGalileo && !accAbnormal && t('recharge.balance_of_expected_to_arrive_hours', {
                        symbol: bAcount?.balance_currency_symbol,
                        balance: formatNum(bAcount?.balance_available)
                      })
                  }
                  {noGalileo && accAbnormal && !noAbnormal && t('recharge.get_balance_error')}
                  {noGalileo && accAbnormal && reauthorization && t('recharge.need_reauthorize')}
                  {noGalileo && accAbnormal && !reauthorization && noAbnormal && t('recharge.account_locked')}
                </div>
              </div>
              <RightOutline fontSize={14} color='#999' style={{ marginLeft: "auto" }} />
            </div>
          </div>

          {bankList.length > 0 && (
            <div className="waiting-list">
              <span className="waiting-list-txt">
                {bankList.length > 1
                  ? t(`associatedBank.pop.waiting_list_multiple`)
                  : t(`associatedBank.pop.waiting_list`, {
                      bank: (bankList[0] && bankList[0].account_nickname) || "**",
                    })}
              </span>
              <span
                className="to-check"
                onClick={(e) => {
                  e.stopPropagation()
                  bankList.length <= 1 && dispatch(setCurrentBankInfo(bankList[0]))
                  dispatch(setVerifyBankFrom("/withdraw"))
                  bankList.length > 1 ? history.push("/chooseBank") : history.push("/addBankVerify")
                }}
              >
                {bankList.length > 1 ? t(`associatedBank.pop.to_check_multiple`) : t(`associatedBank.pop.to_check`)}
              </span>
            </div>
          )}

          <div className="amount-input">
            <div className="d-flex align-items-center justify-content-between">
              <div className="amount">
                {t('withdraw.widthdraw_amount')}
              </div>
              <div className="rule" onClick={() => history.push("/feeRules")}>
                {t("recharge.feeLimit")}
              </div>
            </div>

            <div className="input-box d-flex justify-content-between" onClick={() => handleInputClick()}>
              <div className="d-flex align-items-center">
                <div className="unit d-flex align-items-center">$</div>
                <div className={"imitate-input"}>{formatNumberToUSD(amount)}</div>
              </div>
              <span
                style={amount !== "" ? vis : show}
                onClick={() => {
                  setAmount("")
                  ChildRef.current.emptyAll()
                }}
                className="del"
              >
                <CloseCircleOutline />
              </span>
            </div>

            <div style={vishow ? show : vis} className="input-minh">
              {amount.length === 4 && Number(amount) === 0 ? (
                <div className="input-tip">
                  <span className="red">
                    {t("withdraw.minimum_withdraw", {
                      symbol: '$',
                      min: formatNum(MINI_AMOUNT)
                    })}
                  </span>
                </div>
              ) : (Number(amount) <= balances && balances >= miniBalance()) || amount === "" ? (
                <div className="input-tip" style={{ display: "flex"}}>
                  <span className="tip-before">
                    {t('withdraw.available_balance', {
                      symbol: '$',
                      balance: formatNumberToUSD(formatNum(balances)),
                    })}
                  </span>
                  {balances > 0 && 
                  <><span className="tip-before">{selectedLang === 'zh_CN' ? '，' : ', '}</span>
                    <button className="tixian" onClick={() => withDrawAll()}>
                      {t('withdraw.all_out')}
                    </button>
                  </>}
                </div>
              ) : (
                amount !== "" && (
                  <div className="input-tip">
                    <span className="red">
                      {t('withdraw.amount_limit')}
                    </span>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="limit-tip">
            {fee ? <p className="fee-tip">
            {t('recharge.exempted_from_commission', {
                  symbol: feeInfo.fee?.currency?.symbol,
                  fee: formatNum(feeInfo.fee?.amount || 0),
                })}
            </p>
            : <p>
              <span className="color-tip">
                {t('withdraw.handling_charge', { trans: feeInfo.fee?.free_limit || 0 })}
              </span>
              {t('withdraw.excess_charge', {
                  symbol: feeInfo.fee?.currency?.symbol,
                  fee: formatNum(feeInfo.fee?.amount || 0),
                })}
            </p>}
          </div>
        </div>
        <StepNumberkey
          isLoading={loading}
          withDrawAll={amount}
          submitRecharge={submitRecharge}
          setKeyNumber={setKeyNumber}
          ref={ChildRef}
          title={t('withdraw.action_button')}
          isNotSufficientBalance={isNotSufficientBalance()}
          balance={balances}
          bankAccount={bankAccount}
          info={info}
          encode={bAcount}
          isAbnormal={accAbnormal && noAbnormal}
          setStopTakingPop={(e) => setStopTakingPop(e)}
          handleException={handleException}
        />
      </PullToRefresh>
      <Mask visible={isPasswordFreeze}>
        <FreezePasswordPop
          closeFreezePop={() => {
            setIsPasswordFreeze(false)
            history.push("/")
          }}
        />
      </Mask>
      <RelateExceptionPop
        showRelateExceptionPop={showRelateExceptionPop}
        closeRelateExceptionPop={closeRelateExceptionPop}
        externalItemClicked={externalItemClicked}
        removeBankAccount={removeBankAccount}
        getExternalFunction={getExternalFunction}
      />
    </div>
  )
}
