import React, { Component } from 'react'
import { NumberKeyboard, PasscodeInput, Button, NavBar, Toast } from 'antd-mobile'
import ExceptionPop from '@comps/ExceptionPop'
import { againEditTradePassword } from '@api/user'
import { scrollToTop } from "@utils/utils"
import { connect } from 'react-redux'
import { CloseOutline } from 'antd-mobile-icons'
import { t } from 'i18next'

import './index.less'

const mapStateToProps = (store) => {
  return {
    actionToken: store.password.actionToken,
    passwordRelatedChannel: store.password.passwordRelatedChannel,
  }
}
class EditTransactionPasswordC extends Component {
  state = {
    title: '设置密码',
    password: '',
    isSubmit: true,
    error: false,
    isExceptionPopVisible: false,
    isSubmitLoading: false
  }

  passcodeInputRef = React.createRef()

  componentDidMount() {
    scrollToTop()
    document.title = this.state.title
    this.passcodeInputRef?.current.focus()
  }
  goBack = () => {
    const { passwordRelatedChannel, history } = this.props
    if (passwordRelatedChannel.type === 'reset') {
      history.replace('/resetTransactionPasswordA')
    } else {
      history.replace('/tradePassword')
    }
  }
  submit = async () => {
    const { password } = this.state
    const { actionToken } = this.props
    this.setState({
      isSubmitLoading: true
    })
    const res = await againEditTradePassword('UpdateTransactionPin_ConfirmNewPin', actionToken, password)
    this.setState({
      isSubmitLoading: false
    })
    switch (res.status_code) {
      case 100000: 
        Toast.show({
          content: t("resetPin.Succeed"),
        })
        this.props.history.push('/editTransactionPasswordSuccess')
        break;
      // case 300001: 
      //   this.passcodeInputRef.current.focus()
      //   Toast.show({
      //     content: '请避免使用重复/连续数字作为密码哦',
      //   })
      //   break;
      // case 300002: 
      //   this.passcodeInputRef.current.focus()
      //   Toast.show({
      //     content: '请避免使用重复/连续数字作为密码哦',
      //   })
      //   break;
      case 301006: 
        this.setState({
          isExceptionPopVisible: true,
        })
        break;
      case 301007:
        this.passcodeInputRef.current.focus()
        this.setState({
          error: true,
          isSubmit: true,
        })
        break;
      default:
        this.passcodeInputRef.current.focus()
        this.setState({
          error: true
        })
        break;
    }
  }
  onPasswordChange = (code) => {
    if(code.length === 6){
      this.passcodeInputRef.current.blur()
      this.setState({
        password: code,
        isSubmit: false
      })
    } else if(code.length === 5) {
      this.setState({
        password: this.state.error ? '' : code,
        error: false,
        isSubmit: true
      })
    } else {
      this.setState({
        password: code,
        error: false,
        isSubmit: true
      })
    }
  }
  render() {
    const { error, password, isSubmit, isExceptionPopVisible, isSubmitLoading } = this.state
    return (
      <div className='editTransactionPasswordC-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          onBack={() => this.props.history.goBack()}
          right={<CloseOutline onClick={this.goBack} fontSize={24}/>}
        >
          {t("setPassword.Setpassword")}
        </NavBar>
        <section className="editTransactionPasswordC d-flex flex-column align-items-center">
          <div className="title-warp">
            {t("setPassword.entertransactionpasswordagain")}
            <div className='error-message'>{error && t("transactionPin.twoPinNotMatchErr")}</div>
          </div>
          <div className='verification-code'>
            <PasscodeInput
              ref={this.passcodeInputRef}
              length={6}
              value={password}
              seperated
              keyboard={<NumberKeyboard randomOrder={true}/>}
              onChange={this.onPasswordChange}
              error={error}
              style={{
              '--cell-gap': '12px',
            }}/>
          </div>
          <div className="varification-tips-b">
            <div 
              className="setting-password-b-times"
              dangerouslySetInnerHTML={{__html: t('setPassword.repeatedorconsecutive')}}
            >
            </div>
          </div>
          <div className="btn-container">
            <Button
              type="warning"
              loading={isSubmitLoading}
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center']}
              disabled={isSubmit}
              onClick={this.submit}
            >
              {t('steps.IDCheckDone')}
            </Button>
          </div>
        </section>
        <ExceptionPop visible={isExceptionPopVisible} onClose={() => {
          this.setState({
            isExceptionPopVisible: false,
          })
        }} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(EditTransactionPasswordC)
