import React, { Component } from 'react'
import photo from '@assets/images/myHeader/photo.png'
import share from '@assets/images/myHeader/share.png'
import logo from '@assets/images/myHeader/logo.png'
import search from '@assets/images/myHeader/icon_search.png'
import languageCn from '@assets/images/login/languageCn.png'
import languageEn from '@assets/images/login/languageEn.png'
import attention from '@assets/images/myHeader/attention.png'
import { withRouter } from 'react-router-dom'
import { Toast, Badge } from 'antd-mobile'

import './index.less'
import messages from "@assets/images/home/messages.png"
import { t } from 'i18next'
import { connect } from 'react-redux'
import { setLoginFromPage } from '@actions/common'

const mapDispatchToProps = {
  setLoginFromPage
}

const mapStateToProps = (store) => {
  return {
    selectedLang: store.user.selectedLang,
  }
}
class MyHeader extends Component {
  static defaultProps = {
    titleShow: true,
    photoShow: true,
    title: '',
    subTitle: '',
  }

  // 个人中心
  handleAvatar = () => {
    const { pathname } = window.location
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))

    if (!userInfo) {
      this.props.setLoginFromPage({
        pathname: pathname || "/",
        search: ""
      })
      this.props.history.push('/login')
      return
    }
    this.props.history.push('/mine')
  }

  // 消息中心
  handleMsg = () => {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    if (!userInfo) {
      this.props.history.push('/login')
      return
    }
    Toast.show({
      content: '功能正在开发中!',
      duration: 2000,
    })
  }
  // 搜索
  handleSearch = () => {
    this.props.history.push('/recordSearchList')
  }

  goMessage = () => {
    this.props.history.push('/messages')
  }

  render() {
    const {
      titleShow,
      subTitleShow,
      switchLangShow,
      searchShow,
      photoShow,
      isShareShow,
      title,
      subTitle,
      tipShow,
      navBarShow,
      selectedLang,
      onSwitch,
      showNotify
    } = this.props
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))

    return (
      <div className="myHeader d-flex align-items-center justify-content-between">
        {navBarShow 
        ?<span>{title}</span>
        :<div className="title-wrapper d-flex align-items-center">
          {titleShow &&  
            <div className='trackContainer'>
              <img src={logo} className="logo" alt="logo"/>
              <div className='trackReturn' hidden>logo</div>
            </div>}
          {subTitleShow && <span className="sub-title">{subTitle}</span> }
        </div>
      }
        <div className="img-wrapper d-flex align-items-center">
        {/* {isShortcutShow && 
        <div className="shortcut-section">
          <div className="star-icon" style={{backgroundImage: starBg}}>
            <img src={star} alt=""/>
            <span>{t('home.header.desktopShortcutTip')}</span>
            <div className="triangle"></div>
          </div>
          <img src={shortcut} className="avatar" alt="" onClick={this.props.showShortcutPopup} />
        </div>
              
            } */}
          {searchShow ? (
            <img
              src={search}
              className="search"
              alt=""
              onClick={this.handleSearch}
              style={{backgroundColor: "transparent", boxShadow: "unset"}}
            />) : null}
           {switchLangShow ? <img src={selectedLang === 'zh_CN' ? languageCn : languageEn} className="switch-lang" alt="" onClick={onSwitch} /> : null}
           {isShareShow && 
              <img src={share} className="avatar" alt="" onClick={this.props.shareShow} />
            }

            {showNotify && <span>
              <Badge content={userInfo?.notify_num ? userInfo?.notify_num > 99 ? '99+' : userInfo?.notify_num : 0}>
                {/* <BellOutline fontSize={30} onClick={this.goMessage} /> */}
                <img src={messages} className="messageImg" alt="" onClick={this.goMessage} />
              </Badge>
            </span>}
            
            {photoShow && 
              <div className='trackContainer'>
              <img src={photo} className="avatar" alt="" onClick={this.handleAvatar} />
              <div className='trackReturn' hidden>我的信息</div>
              </div>
            }
          </div>
          {
            tipShow ? (<div className="tip d-flex align-items-center justify-content-center">
              <img src={attention} alt="" />
              <span> {t('transfer.desc')}</span>
            </div>) : null
          }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyHeader))
