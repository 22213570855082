import { SearchBar, InfiniteScroll } from 'antd-mobile'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import TransactionListItem from '@comps/TransactionListItem'
import './index.less'
import noDataImg from '@assets/images/record/sousuo.png'
import { useDispatch, useSelector } from 'react-redux'
import { getRecentAccountHistoryList, getAllAccountHistoryList } from '@api/account'
import { useHistory } from 'react-router-dom'
import { setTransactionSearchQuery } from '@actions/transaction'

export default function SearchList() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isAllList = useSelector(state => state.transaction.isAllList)
  const searchQuery = useSelector(state => state.transaction.search_query)
  const [query, setQuery] = useState(searchQuery)
  const [searchList, setSearchList] = useState(null)
  const [pagination, setPagination] = useState({})
  const [hasMore, setHasMore] = useState(true)
  const searchBarRef = useRef()

  const getOrderList = async ({ query, page }) => {
    if (query.length === 0) return
    try {
      const res = isAllList ? await getAllAccountHistoryList({
        query, page
      }) : await getRecentAccountHistoryList({
        query, page
      })

      if (res.status_code === 100000) {
        const { pagination, list } = res.response_data
        let currentSearchList = page === 1 ? [] : searchList
        setSearchList(currentSearchList.concat(list))
        setPagination(pagination)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(async () => {
    searchBarRef.current.focus()
    if (query) {
      await getOrderList({
        page: 1,
        query
      })
    }
  }, [])

  useEffect(() => {
    const { current_page, page_size, total } = pagination
    setHasMore(current_page * page_size < total)
  }, [pagination])

  useEffect(() => {
    if (query.length === 0) {
      setSearchList(null)
    }
  }, [query])

  const loadMore = async () => {
    const { current_page } = pagination
    await getOrderList({
      page: current_page + 1,
      query
    })
  }

  const querySearch = (val) => {
    val = val.replace(/^\s*|\s*$/g, '')
    setQuery(val)
    dispatch(setTransactionSearchQuery(val))
  }

  return (
    <div id='transaction-search-list'>
      <SearchBar
        ref={searchBarRef}
        value={query}
        placeholder={t('record_list.search_placeholder')}
        onChange={val => querySearch(val)}
        showCancelButton={() => true}
        onCancel={() => {
          dispatch(setTransactionSearchQuery(''))
          history.replace('/recordList')
        }}
        onSearch={() => getOrderList({
          page: 1,
          query
        })}
        onBlur={() => getOrderList({
          page: 1,
          query
        })}
        style={{
          '--border-radius': '38px',
          '--placeholder-color': '#999999'
        }}
        maxLength={28}
        cancelText={t('common.cancel')}
      />

      {
        searchList?.length > 0 ?
          <>
            <TransactionListItem isShowBalance={false} transactionListData={searchList} t={t} />
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </>
          : searchList !== null && <div>
            <img className='no-search-data' src={noDataImg} alt="" />
            <p className='no-search-data-text'>{t('common.no_search_record')}</p>
          </div>
      }
    </div>
  )
}


