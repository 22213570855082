import React, { Component } from 'react'
import { Input, Button, Toast } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline, CloseOutline } from 'antd-mobile-icons'
import requireImg from '@assets/images/home/require.png'
import ExceptionPop from '@comps/ExceptionPop'
import { uploadDocumentInfo } from '@api/user'
import { NavBar } from 'antd-mobile'
import { scrollToTop } from "@utils/utils"
import './index.less'
import { connect } from 'react-redux'
import { setActionToken } from '@actions/password'
import {t} from "i18next";
const TOTAL_TIME = 60

const mapStateToProps = (store) => {
  return {
    actionToken: store.password.actionToken,
    passwordRelatedChannel: store.password.passwordRelatedChannel
  }
}

const mapDispatchToProps = {
  setActionToken,
}
class ResetTransactionPasswordC extends Component {
  state = {
    currentIndex: 0,
    currentValue: '',
    tiems: 60,
    maxLength: 1,
    time: TOTAL_TIME,
    firstName: '',
    lastName: '',
    idNumber: '',
    isReg: false,//格式验证通过
    isShowSSN: false,
    isFirstNameError: false,
    isLastNameError: false,
    isIdNumberError: false,
    isExceptionPopVisible: false,
    isSubmitLoading: false
  }
  inputRef = React.createRef()

  onExceptionPopClose = () => {
    this.setState({
      isExceptionPopVisible: false,
    })
  }
  ValidateCard = async () => {
    const { firstName, lastName, idNumber } = this.state
    if (firstName.length === 0 || lastName.length === 0 || idNumber.length !== 4) return
    const { actionToken, passwordRelatedChannel, setActionToken, history} = this.props
    const actionCode = passwordRelatedChannel.type === 'freeze' ? 'FreezeUserCard_VerifyNameAndIdentity' : 'ForgotTransactionPin_VerifyNameAndIdentity'
    this.setState({
      isSubmitLoading: true
    })
    const res = await uploadDocumentInfo(actionCode, actionToken, firstName, lastName, idNumber)
    this.setState({
      isSubmitLoading: false
    })
    switch(res.status_code) {
      case 100000:
        if (res.response_data) {
          setActionToken(res.response_data.token)
          if (passwordRelatedChannel.type === 'freeze') {
            history.push('/freezeAccountC')
          } else {
            history.push('/editTransactionPasswordB')
          }
        }
        break;
      case 301006:
        this.setState({
          isExceptionPopVisible: true,
        })
        break;
      case 301022:
        this.setState({
          isFirstNameError: true,
          isLastNameError: false,
          isIdNumberError: false
        })
        break;
      case 301023:
        this.setState({
          isFirstNameError: false,
          isLastNameError: true,
          isIdNumberError: false
        })
        break;
      case 301024:
        this.setState({
          isFirstNameError: false,
          isLastNameError: false,
          isIdNumberError: true
        })
        break;
      default:
        Toast.show({
          content: passwordRelatedChannel.type === 'freeze' ? t("freezeAccount.SSNFailed") : t("resetPin.IDNumErr")
        })
        break;
    }
  }

  get idNumberValue() {
    const { idNumber, isShowSSN } = this.state
    return isShowSSN ? idNumber : idNumber.replace(/[0-9]/g, '∗')
  }
  get isReg() {
    const { firstName, lastName, idNumber} = this.state
    return firstName && lastName && idNumber && idNumber.length === 4
  }

  get isInfoError() {
    const { isFirstNameError, isLastNameError, isIdNumberError } = this.state
    return isFirstNameError || isLastNameError || isIdNumberError
  }

  componentDidMount() {
    scrollToTop()
    this.inputRef.current.focus()
  }

  goBack=()=> {
    this.props.history.replace('/resetTransactionPasswordA')
  }
  
  render() {
    const { passwordRelatedChannel } = this.props
    const { isFirstNameError, isLastNameError, isIdNumberError, isShowSSN, idNumber, isExceptionPopVisible, isSubmitLoading } = this.state
    return (
      <div className='resetPasswordC-page'>
        <NavBar
          style={{
            '--height': '36px',
            '--background': '#ffffff',
          }}
          backArrow={false}
          right={<CloseOutline onClick={this.goBack} fontSize={24}/>}
        >
            {passwordRelatedChannel.type === 'freeze' 
            ? t("setPassword.authentication") : t('account.SecuritySettings')}
        </NavBar>
        <section className="resetPasswordC d-flex flex-column align-items-center">
          <div className="title-warp">
            <p>{t("setPassword.EnterSSNUploaded")}</p>
            <div className="sub-title">
              {t("freezeAccount.informationsecurity")}
              {t("setPassword.informationSecurityAssurance")}
            </div>
          </div>
          <div className="name-warp">
            <div className="name-section">
              <div className="input-box" >
                <img src={requireImg} alt="" />
                <Input className="input" placeholder={t("resetPin.InputFirstNamePlaceholder")} ref={this.inputRef} onChange={(val) => {
                  this.setState({
                    firstName: val,
                    isFirstNameError: false
                  })
                }} style={isFirstNameError && {
                  '--adm-color-text': '#FF3841'
                }}/>
              </div>
              <div className="input-box right-input">
                <img src={requireImg} alt="" />
                <Input className="input" placeholder={t("resetPin.InputLastNamePlaceholder")} onChange={(val) => {
                  this.setState({
                    lastName: val,
                    isLastNameError: false
                  })
                }} style={isLastNameError && {
                  '--adm-color-text': '#FF3841'
                }}/>
              </div>
            </div>
              <div className="ssn-section input-box">
                <img src={requireImg} alt="" />
                <Input className="input" type="tel" value={this.idNumberValue} placeholder={t("resetPin.InputIDPlaceholder")} onChange={(val) => {
                  this.setState({
                    isIdNumberError: false
                  })
                  if (val.length === this.idNumberValue.length - 1) {
                    this.setState({ idNumber: idNumber.slice(0, idNumber.length - 1)})
                  } else if (val.length <= 4) {
                    const currentChar = val.slice(-1)
                    const reg = new RegExp('[0-9]')
                    if (reg.test(currentChar)) {
                      const currentValue = idNumber + currentChar
                      this.setState({
                        idNumber: currentValue
                      })
                    }
                  } else {
                    this.setState({
                      idNumber
                    })
                  }
                }} style={isIdNumberError && {
                  '--adm-color-text': '#FF3841'
                }}/>
                {isShowSSN ? <EyeOutline fontSize={20} onClick={() => {
                  this.setState({
                    isShowSSN: false,
                  })
                }}
                /> : <EyeInvisibleOutline fontSize={20} onClick={() => {
                  this.setState({
                    isShowSSN: true,
                  })
                }}/>}
              </div>
              
          </div>
          <div className="btn-container">
            { this.isInfoError && <p>{t("resetPin.IDNumErr")}</p>}
            <Button
              loading={isSubmitLoading}
              type="warning"
              className={['warning-btn', 'for-back', 'd-flex', 'align-items-center', 'justify-content-center', !this.isReg ? 'is-reg' : '']}
              onClick={this.ValidateCard}
            >
              {t("freezeAccount.completeverification")}
            </Button>
          </div>
        </section>

        <ExceptionPop visible={isExceptionPopVisible} onClose={this.onExceptionPopClose} content={
          <div className='mask-title' style={{fontWeight: "600"}} dangerouslySetInnerHTML={{__html: t('transactionPin.reVerifyAfterLongTime')}}></div>
        }/>
      </div>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetTransactionPasswordC)
