
// 手续费规则 页面

import React, { useState, useEffect } from 'react'
import './index.less'
import rechargeIcon from '@assets/images/feeRules/chongzhi.png'
import transferIcon from '@assets/images/feeRules/xiane.png'
import { CloseOutline, DownOutline, UpOutline } from 'antd-mobile-icons'
import { useTranslation } from "react-i18next"
import { scrollToTop } from '@utils/utils'


export default function FeeRules() {
  const { t } = useTranslation()
  const [recharge, setRecharge] = useState([
    {
      isOpen: false
    },
    {
      isOpen: false
    }
  ])
  const [limit, setLimit] = useState([
    {
      isOpen: false
    },
    {
      isOpen: false
    },
    {
      isOpen: false
    }
  ])
  const closeFeeRules = () => {
    window.history.back(-1)
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  const clickItem = (curArray, setCurArray, index) => {
    const newArray = curArray.concat([])
    newArray[index].isOpen = !newArray[index].isOpen
    setCurArray(newArray)
  }

  return (
    <div id="fee-rules">
      <header className="feeRulesTitle">{t('fee_rules.title')}</header>
      <CloseOutline fontSize={24} color="#000" className="feeRulesClose" onClick={closeFeeRules}/>
      <section className="rulesSection">
        <div className="sectionTitle">
          <img className="sectionIcon" src={rechargeIcon} alt="" />
          {t(`fee_rules.recharge.title`)}
        </div>
        {
          recharge.map((item, index) => (
            <div className={`ruleItem ${item.isOpen ? 'ruleItemOpen' : ''}`} onClick={() => clickItem(recharge, setRecharge, index)} key={index}>
              <p className="ruleItemQ">
                {t(`fee_rules.recharge.questions.${index}.Q`)}
                { item.isOpen ? <UpOutline color="#666"/> : <DownOutline color="#666"/> }
              </p>
              {
                item.isOpen && <p className="ruleItemA" dangerouslySetInnerHTML={{ __html: t(`fee_rules.recharge.questions.${index}.A`) }}></p>
              }
            </div>
          ))
        }
      </section>

      <section className="rulesSection">
        <div className="sectionTitle">
          <img className="sectionIcon" src={transferIcon} alt="" />
          {t(`fee_rules.limit.title`)}
        </div>
        {
          limit.map((item, index) => (
            <div className={`ruleItem ${item.isOpen ? 'ruleItemOpen' : ''}`} onClick={() => clickItem(limit, setLimit, index)} key={index}>
              <p className="ruleItemQ">
                {t(`fee_rules.limit.questions.${index}.Q`)}
                { item.isOpen ? <UpOutline color="#666"/> : <DownOutline color="#666"/> }
              </p>
              {
                item.isOpen && <p className="ruleItemA"  dangerouslySetInnerHTML={{ __html: t(`fee_rules.limit.questions.${index}.A`) }}></p>
              }
            </div>
          ))
        }
      </section>
    </div>
  )
}
