import {
  GET_TRANSACTION_DETAIL_BY_CODE,
  GET_TRANSACTION_REFUND_REASON,
  SUBMIT_ORDER_REFUND_REQUEST,
  UPLOAD_ACTIVITY_RECEIPT,
  DELETE_ACTIVITY_RECEIPT,
  SUBMIT_ACTIVITY_RECEIPT,
  SET_TRANSACTION_LIST_DATE,
  GET_TRANSACTION_LIST,
  SET_TRANSACTION_TYPE,
  SET_TRANSACTION_SEARCH_LIST,
  SET_SEARCH_QUERY,
  SET_PENDING_TRANSACTION_POP,
  SET_RECEIPTS,
  SET_IS_ALL_LIST,
  SET_CURRENT_ALL_LIST_TAB_TYPE,
  SET_IS_RECORD_SEARCH_LIST
} from '../actions/ActionTypes'

const initialState = {
  pending_status: false,
  transaction_list: {
    "1": null,
    "2": null,
    "3": null,
    "4": null,
    "5": null,
    "current_date": {
      "month": (new Date()).getMonth() + 1,
      "year":  (new Date()).getFullYear()
    },
    "cost": {
      "1": {
        "debit": 0.00,
        "credit": 0.00
      },
      "2": {
        "debit": 0.00,
        "credit": 0.00
      },
      "3":  {
        "debit": 0.00,
        "credit": 0.00
      },
      "4":  {
        "debit": 0.00,
        "credit": 0.00
      },
      "5":  {
        "debit": 0.00,
        "credit": 0.00
      },
    },
    "query": "",
    "type": "1"
  },
  transactionStatus: 'Loading',
  status: 0,
  transaction_detail: {},
  refund_result: '',
  refund_reasons: [],
  receipt: [],
  receipt_status: '',
  search_list: [],
  search_query: sessionStorage.getItem('search_query') || '',
  search_status: '',
  faq_en: [
    {
      "q": "人民币消费是什么意思?",
      "a": "人民币消费指：用户可以使用人民币来消费美金商品。具体步骤是：用户在Tillstar钱包内，使用人民币的付款方式（微信、支付宝、银联）来购买消费金，随即使用消费金在门店或者官网进行消费。"
    },
    {
      "q": "钱包里有美金余额，也有消费金，消费的时候会先扣美金的余额吗？",
      "a": "您购买了美金消费金后，系统会优先扣除消费金，保留您的美金余额。"
    },
    {
      "q": "人民币购买消费金，没有使用可以退款吗?",
      "a": "人民币购买消费金后，超出20分钟没有使用可以自动原路退回。如果您购买后想立即退款也可提前申请退款。退款最晚不超过7个工作日即可原路退还。"
    }
  ],
  faq_cn: [
    {
      "q": "人民币消费是什么意思?",
      "a": "人民币消费指：用户可以使用人民币来消费美金商品。具体步骤是：用户在Tillstar钱包内，使用人民币的付款方式（微信、支付宝、银联）来购买消费金，随即使用消费金在门店或者官网进行消费。"
    },
    {
      "q": "钱包里有美金余额，也有消费金，消费的时候会先扣美金的余额吗？",
      "a": "您购买了美金消费金后，系统会优先扣除消费金，保留您的美金余额。"
    },
    {
      "q": "人民币购买消费金，没有使用可以退款吗?",
      "a": "人民币购买消费金后，超出20分钟没有使用可以自动原路退回。如果您购买后想立即退款也可提前申请退款。退款最晚不超过7个工作日即可原路退还。"
    }
  ],
  isAllList: (/^true$/i).test(sessionStorage.getItem('isAllList')) || false,
  currentAllListTabType: sessionStorage.getItem('currentAllListTabType') || 0,
  isRecordSearchList: (/^true$/i).test(sessionStorage.getItem('isRecordSearchList')) || false,
}
const transaction =  (state = initialState, action) => {
  let newState = {
    ...state
  }
  switch (action.type) {
    case GET_TRANSACTION_DETAIL_BY_CODE:
      return { 
        ...state,
        transaction_detail: action.transaction
      }
    case SUBMIT_ORDER_REFUND_REQUEST:
      return { 
        ...state,
        refund_result: action.refund_result
      }
    case GET_TRANSACTION_REFUND_REASON:
      return { 
        ...state,
        refund_reasons: action.reasons
      }
    case UPLOAD_ACTIVITY_RECEIPT:
      const newItem = {
        receipt_id: action.receipt_id,
        url: action.url
      }
      return { 
        ...state,
        receipt: [...state.receipt, newItem]
      }

    case DELETE_ACTIVITY_RECEIPT:
      return {
        ...state,
        receipt: state.receipt.filter((item) => item.url !== action.url)
      }
    case SUBMIT_ACTIVITY_RECEIPT:
      return {
        ...state,
        receipt_status: action.status
      }
    case SET_RECEIPTS:
      return {
        ...state,
        receipt: action.receipts
      }
    case SET_TRANSACTION_LIST_DATE:
      
      return {
        ...state,
        transaction_list: action.transaction_list
      }
    case GET_TRANSACTION_LIST: 
      newState.transaction_list.cost[newState.transaction_list.type].debit = parseFloat(action.transaction.debit)
      newState.transaction_list.cost[newState.transaction_list.type].credit = parseFloat(action.transaction.credit)
      newState.transaction_list[newState.transaction_list.type] = action.transaction.data
      return newState;
    case SET_TRANSACTION_TYPE:
      newState.transaction_list.type = action.transaction_type
      return newState;
    case SET_TRANSACTION_SEARCH_LIST:
      if(action.params.query.length === 0) {
        newState.search_list = []
      } else {
        if(action.transaction.data.length === 0) {
          newState.search_status = 'none'
        } else {
          newState.search_status = ''
        }
        newState.search_list = action.transaction.data
      }
      return newState;
    case SET_SEARCH_QUERY:
      newState.search_query = action.payload
      return newState;
    case SET_PENDING_TRANSACTION_POP: 
      return {
        ...state,
        pending_status: action.status
      }
    case SET_IS_ALL_LIST: 
      return {
        ...state,
        isAllList: action.payload
      }
    case SET_CURRENT_ALL_LIST_TAB_TYPE: 
      return {
        ...state,
        currentAllListTabType: action.payload
      }
    case SET_IS_RECORD_SEARCH_LIST: 
      return {
        ...state,
        isRecordSearchList: action.payload
      }
    default:
      return state
  }
}

export default transaction
