import React from 'react'
import { Button } from 'antd-mobile'
import { InformationCircleOutline } from 'antd-mobile-icons'
import './index.less'

export default function StepFooter(props) {
    const { notice, disabled, btnSubmit, btnText, licenseInfoBottom, isLoading } = props
    
    return (
        <div className='stepNine-page-footer'>
            {notice && <p className="notice">
                <InformationCircleOutline className="icon" fontSize={14} color="#F9941E"/>
                <span>{notice}</span>
            </p>}
            <Button 
                block5
                className='next-button'
                shape='rounded'
                color='danger'
                disabled={disabled}
                onClick={btnSubmit}
                loading={isLoading}
            >
                {btnText}
            </Button>
            <p>
              {licenseInfoBottom}
            </p>
        </div>
    )
}
