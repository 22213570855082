import React, { useState, useEffect, useRef } from "react"
import { NavBar, PullToRefresh, Toast, Mask, Popup, Checkbox } from "antd-mobile"
import { CloseOutline, CloseCircleOutline, RightOutline } from "antd-mobile-icons"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { t } from "i18next"
import { formatNumberToUSD, formatNum } from "@utils"
import "./index.less"

import RechargeMoney from "@comps/RechargeMoney" //充值弹框
import RechargePassword from "@comps/RechargePassword" //充值密码弹框
import StepNumberkey from "@comps/StepNumberkey"
import RelateExceptionPop from "@comps/RelateExceptionPop"
import FreezePasswordPop from "@comps/FreezePasswordPop"
import StopTakingNotice from "@comps/StopTakingNotice"
import InstantDepositNotice from "./InstantDepositNotice"

import { getUserInfo } from "@api/user"
import { getExternalAccounts, removeExternalAccount } from "@api/plaid"
import { accountFee } from "@api/account"
import { getInstantCreditLimit, getBindRequestList } from "@api/account"
import { setPasswordRelatedChannel } from "@actions/password"
import { setCurrentBankInfo, setVerifyBankFrom, setSelectedRechargeBank, setSelectedBank } from "@actions/account"

import tishi from "@assets/images/recharge/icon_tishi@2x.png"
import bank from "@assets/images/associatedBank/bank.png"
import addImg from "@assets/images/mine/icon_guanlian@3x.png"
import tillstarLoading from "@assets/images/common/tillstar_loading.gif"

const MINI_AMOUNT = 0.01
const MAX_AMOUNT = 99999

export default function RechargePage(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const ChildRef = useRef()
  const selectedLang = useSelector((state) => state.user.selectedLang)
  const selectedRechargeBank = useSelector((state) => state.account.selectedRechargeBank)

  const [stopTakingPop, setStopTakingPop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isHas, setIsHas] = useState(true)
  const [procedure, setProcedure] = useState(false)
  const [isRecharge, setIsRecharge] = useState(true)
  const [maskShow, setMaskShow] = useState(false)
  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const [isInstantDepositNoticeShow, setIsInstantDepositNoticeShow] = useState(false)
  const [autoEnterIn, setAutoEnterIn] = useState(false)
  const [control, setControl] = useState(true)
  const [showRelateExceptionPop, setShowRelateExceptionPop] = useState(false)
  const [cName, setCName] = useState(true)

  const [bankAccount, setBankAccount] = useState([])
  const [bankList, setBankList] = useState([])
  const [info, setInfo] = useState({})
  const [vipUserInfo, setVipUserInfo] = useState({})
  const [externalItemClicked, setExternalItemClicked] = useState({})
  const [charge, setCharge] = useState("")
  const [encode, setEncode] = useState("")
  const [amount, setAmount] = useState("")
  const [remind, setRemind] = useState(1)
  const [feeCode] = useState("ach_in")
  const [withDrawInfo] = useState({})


  // const balances = balance?.balance
  const noGalileo = encode?.external_link_service_provider !== "Galileo"
  const noAbnormal = encode?.external_account_status !== 7
  const reauthorization = encode?.external_account_status === 4
  const accAbnormal = encode?.status === 6

  const goBack = () => {
    history.push("/")
  }

  useEffect(async () => {
    document.documentElement.scrollTop = 0 // ios置顶
    window.scrollTo(0, 0) //ios之外置顶
    dispatch(setVerifyBankFrom(""))
    // 重置选择账户来源
    dispatch(setSelectedBank({}))
    getExternalFunction()
    getBindBankList()
    getLimitFunction()
  }, [])

  const getExternalFunction = async (type = "init") => {
    try {
      const [info, external, res] = await Promise.all([getUserInfo(), getExternalAccounts(), accountFee({ feeCode })])

      if (external.status_code !== 100000 || info.status_code !== 100000 || res.status_code !== 100000) {
        setLoading(false)
        setBankAccount([])
        setInfo({})
        setProcedure(false)
        setIsHas(true)
        return
      }
      type === "init" && setLoading(false)

      const { data } = external.response_data
      const { freeLimitAvailable } = res.response_data

      setBankAccount(data && data.length ? data : [])
      setInfo(info)
      setIsHas(data && data.length ? false : true)
      setCharge(res.response_data)
      setProcedure(freeLimitAvailable > 0 ? true : false)

      data && data.length && commonSetEnCode(data)
    } catch (err) {
      console.log(err)
    }
  }

  const commonSetEnCode = (data) => {
    const findFromLocal = data.find((item) => item.id === Number(selectedRechargeBank))
    const findFromOne = data.find((item) => item.status === 1)

    let encode = findFromLocal || findFromOne || data[0]
    !selectedRechargeBank && dispatch(setSelectedRechargeBank(encode?.id))
    setEncode(encode)
  }

  const getLimitFunction = async () => {
    const res = await getInstantCreditLimit()
    if (res.status_code === 100000) {
      setVipUserInfo(res.response_data)
    }
  }

  const getBindBankList = async () => {
    const res = await getBindRequestList({ page: 1 })
    if (res.status_code === 100000) {
      setBankList(res.response_data.list)
    }
  }

  const handleInputClick = () => {
    if (accAbnormal && noAbnormal) {
      handleException(encode)
      return
    }

    ChildRef.current.handleUnFold()
  }

  // 处理账户异常
  const handleException = (item) => {
    setShowRelateExceptionPop(true)
    setExternalItemClicked(item)
  }

  const submitRecharge = (isRecharge) => {
    setTimeout(() => {
      setIsRecharge(isRecharge)
      setMaskShow(true)
    }, 500)
  }

  //   选择银行账户
  const chooseBankCard = async () => {
    try {
      const res = await getUserInfo() //个人交易密码信息 true/flase
      if (res.status_code === 100000) {
        sessionStorage.setItem("recognition", "recognition")
        dispatch(setVerifyBankFrom("/recharge"))
        dispatch(setSelectedBank({ from: "/recharge" }))
        if (res.response_data?.is_transaction_pin_set) {
          history.push({
            pathname: `/associatedBank`,
          })
          return
        }

        sessionStorage.setItem("name", "abc")
        dispatch(
          setPasswordRelatedChannel({
            channel: "addBank",
            type: "set",
            url: "/associatedBank",
            backUrl: "/recharge",
          })
        )
        history.push("/verifyCode")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const bankCard = () => {
    dispatch(setSelectedBank({ from: "/recharge" }))
    history.push(`/selectBank?from=recharge`)
  }

  // 设置充值金额
  const setKeyNumber = (val) => {
    if (accAbnormal && noAbnormal && val) {
      handleException(encode)
      return
    }

    if (val.indexOf(".") === -1) {
      let arr = val.split("")
      let index = arr.findIndex((v) => v !== "0")
      index !== -1 && (val = arr.splice(index).join(""))
    } else if (val.indexOf(".") === 0) {
      val = 0 + val
    } else {
      let arr = val.split(".")[0].split("")
      let index = arr.findIndex((v) => v !== "0")
      if (index === -1) {
        val = "0." + val.split(".")[1]
      } else {
        val = arr.splice(index).join("") + "." + val.split(".")[1]
      }
    }
    const value = parseFloat(val)

    if (value < MINI_AMOUNT) {
      if (val.length === 4) {
        setRemind(2)
        setCName(false)
      }
    } else if (value >= MAX_AMOUNT) {
      // val = amount
      setRemind(3)
      setCName(true)
    } else if (parseFloat(encode?.balance_available) < value) {
      setRemind(4)
      setCName(true)
    } else {
      setRemind(1)
      setCName(true)
    }

    setAmount(val)
  }

  // useEffect(() => {
  //   console.log('amount=======', amount, remind)
  //   if(amount == "" && remind == 1) {
  //     PubSub.publish("del", "del")
  //     return
  //   }
  //   PubSub.publish('recharge', amount)
  // }, [amount, remind])

  // 删除银行账户
  const removeBankAccount = async () => {

    const deleteAccount = await removeExternalAccount({ externalAccountId: externalItemClicked?.id })

    if (deleteAccount?.status_code === 100000) {
      setShowRelateExceptionPop(false)
      Toast.show({
        content: t('common.remove_succeeded'),
        duration: 1000,
      })
    }

    const ress = await getExternalAccounts()

    if (ress.status_code === 100000) {
      const newAccount = ress.response_data.data
      if (!newAccount || !newAccount.length) {
        setIsHas(true)
        setLoading(false)
        setEncode({})
        setBankAccount([])
        getExternalFunction()
        return
      }

      const encode = newAccount.find((item) => item.status === 1) || newAccount[0]
      dispatch(setSelectedRechargeBank(encode?.id))

      setIsHas(false)
      setEncode(encode)
      setLoading(false)
      setBankAccount(newAccount)
    }
  }

  const CommonLoading = () => (
    <div>
      <img className="tillstar-loading-icon" src={tillstarLoading} alt="" />
    </div>
  )

  return (
    <div id="recharge-page" className="recharge">
      <NavBar className="bgfff" onBack={goBack}>
        {t('recharge.navTitle')}
      </NavBar>

      {/* 停服公告 */}
      <div className="topNotice">
        <StopTakingNotice stopTakingPop={stopTakingPop} setStopTakingPop={setStopTakingPop} />
      </div>

      {loading && <CommonLoading />}

      <PullToRefresh onRefresh={() => !loading && getExternalFunction("pull")} renderText={() => !loading && <CommonLoading />}>
        {isRecharge && maskShow && (
          <Mask visible={isRecharge && maskShow}>
            <RechargeMoney
              vipInfo={vipUserInfo}
              amount={amount}
              charge={charge?.fee?.amount}
              chargeSymbol={charge?.fee?.currency?.symbol}
              freeLimit={charge?.fee?.free_limit}
              freeLimitAvailable={charge?.freeLimitAvailable}
              hiddenMask={() => setMaskShow(false)}
              submitRecharge={(isRecharge) => {
                setMaskShow(true)
                setIsRecharge(isRecharge)
              }}
              showInstantDepositNotice={() => setIsInstantDepositNoticeShow(true)}
              autoEnterIn={autoEnterIn}
              setAutoEnterIn={(val) => setAutoEnterIn(val)}
            />
          </Mask>
        )}
        {!isRecharge && maskShow ? (
          <RechargePassword
            hiddenMask={() => setMaskShow(false)}
            amount={amount}
            achAccountId={encode?.id}
            perTransFee={withDrawInfo.perTransFee}
            Recharge={"Recharge"}
            openFreezePasswordPop={() => {
              setIsPasswordFreeze(true)
            }}
            autoEnterIn={autoEnterIn}
          />
        ) : (
          ""
        )}

        <div className="wrap">
          <div className="bank-tip">{t('recharge.bank_account')}</div>
          {isHas ? (
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center" onClick={chooseBankCard}>
                <div className="logo">
                  <img src={addImg} alt="" />
                </div>
                <div className="bank-name">
                  <div className={selectedLang === "zh_CN" ? "one-title" : "one-title font14"}>
                    {t('recharge.associated_bank_account')}
                  </div>
                  <div className="two-title">{t('recharge.fast_bank_account_connection')}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="banks d-flex align-items-center justify-content-between">
              <div className="bank-list d-flex align-items-center" onClick={bankCard}>
                <div className="logo">
                  <img src={encode?.institution_logo ? encode?.institution_logo : bank} alt="" />
                </div>
                <div className="bank-name">
                  <div className="bank-names d-flex align-items-center">
                    {encode?.name} {encode?.number}
                    {accAbnormal && noAbnormal && (
                      <div className={["bank-account-status", `bank-account-status-${reauthorization ? 2 : 3}`].join(" ")}>
                        {reauthorization ? t('recharge.reauthorize') : t('recharge.account_abnormal')}
                      </div>
                    )}
                  </div>
                  <div className="bank-detail">
                    {!noGalileo && t('recharge.expected_to_arrive_hours')}
                    {noGalileo && !accAbnormal && t('recharge.balance_of_expected_to_arrive_hours', {
                      symbol: encode?.balance_currency_symbol,
                      balance: formatNum(encode?.balance_available)
                    })
                    }
                    {noGalileo && accAbnormal && !noAbnormal && t('recharge.get_balance_error')}
                    {noGalileo && accAbnormal && reauthorization && t('recharge.need_reauthorize')}
                    {noGalileo && accAbnormal && !reauthorization && noAbnormal && t('recharge.account_locked')}
                  </div>
                </div>
                <div className="hidd">
                  <RightOutline fontSize={14} color='#999' />
                </div>
              </div>
            </div>
          )}
          {bankList.length > 0 && (
            <div className="waiting-list">
              <span className="waiting-list-txt">
                {bankList.length > 1
                  ? t(`associatedBank.pop.waiting_list_multiple`)
                  : t(`associatedBank.pop.waiting_list`, {
                    bank: (bankList[0] && bankList[0].account_nickname) || "**",
                  })}
              </span>
              <span
                className="to-check"
                onClick={() => {
                  bankList.length <= 1 && dispatch(setCurrentBankInfo(bankList[0]))
                  dispatch(setVerifyBankFrom("/recharge"))
                  bankList.length > 1 ? history.push("/chooseBank") : history.push("/addBankVerify")
                }}
              >
                {bankList.length > 1 ? t(`associatedBank.pop.to_check_multiple`) : t(`associatedBank.pop.to_check`)}
              </span>
            </div>
          )}

          {control && (
            <div className="hints">
              <img src={tishi} alt="" className="tishi" />
              <span>
                <em /> {t('recharge.please_recharging')}
              </span>
              <CloseOutline fontSize={12} color="#333333" alt="" className="quanshan" onClick={() => setControl(false)} />
            </div>
          )}

          <div className="amount-input">
            <div className="d-flex align-items-center justify-content-between title-box">
              <div className="amount">{t('recharge.deposit_amount')}</div>
              <div className="rule" onClick={() => history.push({ pathname: `/feeRules` })}>
                {t('recharge.feeLimit')}
              </div>
            </div>
            <div className="input-box d-flex justify-content-between" onClick={handleInputClick}>
              <div className="d-flex align-items-center">
                <div className="unit d-flex align-items-center">$</div>
                <div className={cName ? "imitate-input" : "imitate-input colorRed"}>{formatNumberToUSD(amount)}</div>
              </div>
              {amount !== "" && (
                <span
                  onClick={() => {
                    setAmount("")
                    setRemind(1)
                    ChildRef.current.emptyAll()
                  }}
                  className="del"
                >
                  <CloseCircleOutline />
                </span>
              )}
            </div>
            <div className={`under-tip ${selectedLang !== "zh_CN" ? "under-tip-en" : ""}`}>
              {remind === 1 && (
                <div className="hint">
                  {procedure ? (
                    <p>
                      <span className="free-tip">
                        {t('recharge.fee_can_reduced_first', { trans: charge?.fee?.free_limit || 0 })}
                      </span>
                      {t('recharge.fee_beyond', {
                        symbol: charge?.fee?.currency?.symbol,
                        fee: formatNum(charge?.fee?.amount || 0),
                      })}
                    </p>
                  ) : (
                    <p>
                      <span className="warning">
                        {t('recharge.exempted_from_commission', {
                          symbol: charge?.fee?.currency?.symbol,
                          fee: formatNum(charge?.fee?.amount || 0),
                        })}
                      </span>
                    </p>
                  )}
                </div>
              )}
              {remind === 2 && (
                <div className="warning">
                  {t('recharge.minimum_recharge', {
                    symbol: '$',
                    min: MINI_AMOUNT
                  })}
                </div>
              )}
              {remind === 3 && (
                <div className="warning">
                  {t('recharge.maximum_recharge', {
                    symbol: '$',
                    max: formatNumberToUSD(MAX_AMOUNT)
                  })}
                </div>
              )}
              {remind === 4 && <div className="warning">{t("recharge.externalBankAccountInsufficientBalance")}</div>}
            </div>

            {/* <div className="autoEnterIn">
              <Checkbox
                className="pickRadio"
                style={{
                  "--adm-color-primary": "#FF3841",
                  "--icon-size": "16px",
                }}
                onChange={(val) => setAutoEnterIn(val)}
                checked={autoEnterIn}
              >
                <div>
                  <p>{t("transactionDetail.text3")}</p>
                  <p>{t("transactionDetail.text4")}</p>
                </div>
              </Checkbox>
            </div> */}
          </div>

        </div>
        <StepNumberkey
          isLoading={loading}
          recharge={amount}
          submitRecharge={submitRecharge}
          setKeyNumber={setKeyNumber}
          setRemind={setRemind}
          ref={ChildRef}
          title={t('recharge.action_button')}
          bankAccount={bankAccount}
          info={info}
          balanceAvailable={encode?.balance_available}
          encode={encode}
          isAbnormal={accAbnormal && noAbnormal}
          handleException={handleException}
          setStopTakingPop={(e) => setStopTakingPop(e)}
        />
        <Mask visible={isPasswordFreeze}>
          <FreezePasswordPop
            closeFreezePop={() => {
              setIsPasswordFreeze(false)
              history.push("/")
            }}
          />
        </Mask>
      </PullToRefresh>
      <RelateExceptionPop
        showRelateExceptionPop={showRelateExceptionPop}
        closeRelateExceptionPop={() => setShowRelateExceptionPop(false)}
        externalItemClicked={externalItemClicked}
        removeBankAccount={removeBankAccount}
        getExternalFunction={getExternalFunction}
      />
      <Popup visible={isInstantDepositNoticeShow} onMaskClick={() => setIsInstantDepositNoticeShow(false)}>
        <InstantDepositNotice vipInfo={vipUserInfo} />
      </Popup>
    </div>
  )
}
