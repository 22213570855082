import React, { useState, useEffect } from "react"
import { NavBar, Toast } from "antd-mobile"
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { CheckCircleFill, AddOutline } from 'antd-mobile-icons'
import "./index.less"
import { removeExternalAccount, getExternalAccounts } from '@api/plaid'
import { formatNumberToUSD } from '@utils'
import { handleDot } from '@utils/utils'
import bankIcon from '@assets/images/associatedBank/bank.png'
import { useSelector, useDispatch } from "react-redux"
import RelateExceptionPop from '@comps/RelateExceptionPop'
import { setVerifyBankFrom, setSelectedRechargeBank, setSelectedWithdrawalBank } from '@actions/account'

const MAX_BANK_COUNT = 3

const BankList = (props) => {
    const { account, selectedBank, setSelectedBank, isRecharge, history, setExternalItemClicked, setShowRelateExceptionPop } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isPlaid = account.external_link_service_provider === 'Plaid'

    let accountStatusDesc = ''
    if (account.status === 1) {
        accountStatusDesc = t(`selectBank.bankInfo.${account.status}.${account.external_link_service_provider}.desc`, {
            symbol: account.balance_currency_symbol,
            balance: `${formatNumberToUSD(handleDot(account.balance_available))}` 
        })
    } else {
        accountStatusDesc = isPlaid && [4, 7].includes(account.external_account_status) 
        ? t(`selectBank.bankInfo.${account.status}_${account.external_account_status}.desc`)
        : t(`selectBank.bankInfo.${account.status}.desc`)
    }

    const selectANewBank = () => {
        if (isPlaid && account.status === 6) {
            setExternalItemClicked(account)
            setShowRelateExceptionPop(true)
            return
        }
        if (account.status === 1) {
            dispatch(setSelectedBank(account.id))
            history.push(isRecharge ? '/recharge' : '/withdraw')
        }
        
    }

    return (
        <div className="bank-list" onClick={selectANewBank}>
            <img src={account.institution_logo || bankIcon} alt="" />
            <div className="bank-info">
                <div className="bank-account">
                    {account.name} {account.number}
                    {
                        isPlaid && account.external_account_status === 4 && <span className="warning-tip tip">{t(`selectBank.bankInfo.${account.status}_${account.external_account_status}.tip`)}</span>
                    }
                    {
                        isPlaid && account.status === 6 && ![4, 7].includes(account.external_account_status) && <span className="error-tip tip">{t(`selectBank.bankInfo.${account.status}.tip`)}</span>
                    }
                    
                </div>
                <div className="balance-info">{accountStatusDesc}</div>
            </div>
            { +selectedBank === account.id && <CheckCircleFill className="selected" color="#FF3841" fontSize={20}/>}
        </div>
    )
}

export default function SelectBank() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [accounts, setAccounts] = useState([])
    const searchParams = new URLSearchParams(history.location.search)
    const selectedRechargeBank = useSelector(state => state.account.selectedRechargeBank)
    const selectedWithdrawalBank = useSelector(state => state.account.selectedWithdrawalBank)
    const [showRelateExceptionPop, setShowRelateExceptionPop] = useState(false)
    const [externalItemClicked, setExternalItemClicked] = useState({})
    const isRecharge = searchParams?.get('from') === 'recharge'
    const selectedBank = isRecharge ? selectedRechargeBank : selectedWithdrawalBank
    const setSelectedBank = isRecharge ? setSelectedRechargeBank : setSelectedWithdrawalBank

    const goBack = () => {
        history.goBack()
    }

    const getExternalBanks = async () => {
        try {
            const res = await getExternalAccounts()
            if (res.status_code === 100000) {
                const bankAccounts = res.response_data.data
                setAccounts(bankAccounts)
                if (!selectedBank) {
                    const normalAccount = bankAccounts.find(item => item.status === 1)
                    dispatch(setSelectedBank(normalAccount ? normalAccount.id : bankAccounts[0]?.id))
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const removeBankAccount = async () => {
      try {
        const res = await removeExternalAccount({ externalAccountId: externalItemClicked.id })
        if (res.status_code === 100000) {
            setShowRelateExceptionPop(false)
          Toast.show({
              content: t('selectBank.removeSuccess'),
              duration: 1000
          })
          getExternalBanks()
        }
        
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
        async function fetchData() {
            document.documentElement.scrollTop = 0
            window.scrollTo(0, 0)
            dispatch(setVerifyBankFrom(''))
            await getExternalBanks()
        }
        fetchData()
    }, [])

    // 添加银行账户
    const addBank = () => {
        dispatch(setVerifyBankFrom('/selectBank'))
        history.push(`/associatedBank`)
    }

    return (
        <div id="bank-selected">
            <NavBar
                style={{
                    "--height": "48px",
                    "--background": "#FFF"
                }}
                onBack={goBack}
            >
            {t('selectBank.navTitle')}
            </NavBar>
            <div className="bank-selected-container">
                <div className="title">{t('selectBank.title')}</div>
                <div className="bank-lists">
                    {
                        accounts.length > 0 && accounts.map((item, index) => <BankList
                        history={history}
                        account={item}
                        key={index}
                        selectedBank={selectedBank}
                        dispatch={dispatch}
                        setSelectedBank={setSelectedBank}
                        isRecharge={isRecharge}
                        setExternalItemClicked={setExternalItemClicked}
                        setShowRelateExceptionPop={setShowRelateExceptionPop}
                        />)
                    }
                    {
                        accounts.length < MAX_BANK_COUNT && <div className="bank-list add-bank" onClick={addBank}>
                            <div className="add-icon">
                                <AddOutline color="#FF3841" fontSize={28}/>
                            </div>
                            <div className="add-tip">{t('selectBank.addBankTip')}</div>
                        </div>
                    }
                </div>
            </div>
            <RelateExceptionPop
                showRelateExceptionPop={showRelateExceptionPop}
                closeRelateExceptionPop={() => setShowRelateExceptionPop(false)}
                externalItemClicked={externalItemClicked}
                removeBankAccount={removeBankAccount} 
                getExternalFunction={getExternalBanks}
            />
        </div>
    )
}
