import { List } from 'antd-mobile'
import React, { useState } from 'react'
import type1 from '@assets/images/record/type/1.png'
import type2 from '@assets/images/record/type/2.png'
import type3 from '@assets/images/record/type/3.png'

import type5 from '@assets/images/record/type/5.png'
import type6 from '@assets/images/record/type/6.png'
import type8 from '@assets/images/record/type/8.png'

import type9 from '@assets/images/record/type/9.png'
import type10 from '@assets/images/record/type/10.png'
import type13 from '@assets/images/reward/redeem.png'

import iconSwitch from '@assets/images/record/type/icon_switch.png'
import instantIcon from '@assets/images/record/instant.png'
import waitingIcon from '@assets/images/record/waiting.png'
import backIcon from '@assets/images/record/back.png'

import { handleDot } from '@utils/utils'
import { Trans } from 'react-i18next'
import { formatNumberToUSD } from '@utils'

import './index.less'
import { useHistory } from 'react-router-dom'
import { setSwitchRmbPop, setSwitchRmbUnabledPop } from '@actions/rmb'
import { useDispatch, useSelector } from 'react-redux'
import CommonPop from '../CommonPop'
import { setTransferOrigin } from '@actions/transfer'
import { setIsRecordSearchList } from '@actions/transaction'
import dayjs from 'dayjs'
import { getModuleStatus } from "@api/common"

const ListItem = (props) => {
  const { item, t, history, setCode, isAllList, isShowSavingsAccountBalance = true, isShowBalance } = props

  let icon = null

  if (RegExp(/60/).test(item.status)) {
    icon = type6
  }
  // us消费 商户消费
  if (RegExp(/50/).test(item.status)) {
    icon = type5
  }
  //退款
  if (RegExp(/80/).test(item.status) || [6013, 6014].includes(item.status)) {
    icon = type8
  }
  // 转账
  if ([1, 10, 12].includes(item.type) || (item.type === 4 && item.associated_activity.type === 1)) {
    icon = type1
  }

  // 充值
  if (item.type === 2 || (item.type === 4 && item.associated_activity.type === 2)) {
    icon = type2
  }
  // 提现
  if (item.type === 3 || (item.type === 4 && item.associated_activity.type === 3)) {
    icon = type3
  }

  // 消费金购礼卡
  if (RegExp(/90/).test(item.status)) {
    icon = type9
    return (<></>) //隐藏消费金购卡
  }

  // 储蓄账户转入转出
  if (item.type === 10) {
    icon = type10
    //return (<></>) // 隐藏储蓄账户
  }

  if (item.type === 13) {
    icon = type13
    //return (<></>) // 隐藏返点
  }

  let amountTextStyle = {
    color: "#333"
  }

  let amountPriceStyle = {}
  let transactionTipsStyle = {
    color: "#828282"
  }

  if ([5009, 8001, 8004, 8007].includes(item.status)) {
    amountTextStyle = {
      color: '#bdbdbd'
    }
  }

  if ([1001, 2002, 2003, 2006, 2009, 3001, 3002, 6013, 8010].includes(item.status)) {
    amountPriceStyle = {
      color: '#bdbdbd'
    }
  }

  if ([2001, 2004, 2005, 2007, 2008, 2010, 13001, 13002].includes(item.status)) {
    amountPriceStyle = {
      color: '#1F49DA'
    }
  }

  if ([5005, 5006, 5007, 5009, 5010, 5011, 5012, 8001, 8002, 8003, 8004, 8005, 8006, 8007, 8008, 8009].includes(item.status) || (item.status === 8010 && item.is_gift_card)) {
    transactionTipsStyle = {
      color: '#FF3841'
    }
  }

  const symbol = ((item.type === 2 && item.status !== 2011) ||
    ([1, 10].includes(item.type) && item.credit_or_debit === 'C') ||
    item.status === 3001 ||
    item.status === 12001 ||
    [13001, 13002].includes(item.status) ||
    (RegExp(/80/).test(item.status)) ||
    (RegExp(/60/).test(item.status) && ![6013, 6014].includes(item.status))) ? '+' : '-'

  const showGreyAmount = [2011, 6013].includes(item.status)

  const itemClicked = async (item) => {
    // 停机维护
    let result = await getModuleStatus('module_billing_detail');
    if (result.status_code === 300012) return
    dispatch(setIsRecordSearchList(history.location.pathname === '/recordSearchList'))

    if ([1, 2, 3, 4, 10, 12, 13].includes(item.type)) {
      dispatch(setTransferOrigin(''))
      history.push("/transactionDetail?code=" + item.code)
    } else {
      history.push("/rmbOrder?code=" + item.code)
    }
  }
  const dispatch = useDispatch()
  const operateHistoryStatus = ([1, 2, 3, 4, 9, 10].includes(item.type))
  const showTitleWithAmount = [2004, 2005, 2006, 2007, 2008, 2009, 2012, 2013].includes(item.status)
  const showStatusTips = [5005, 5007, 5011, 5012, 8001, 8003, 8004, 8006, 8007, 8009].includes(item.status)

  return <List.Item key={item.code} onClick={() => itemClicked(item)} arrow={false}>
    <div className='list-item'>
      <div className='icon'>
        <img src={icon} alt='' />
      </div>
      <div className='transaction-title'>
        <div className='top'>
          <div className='left'>
            <span className='title-txt'>
              {
                item.type === 1 && (item.credit_or_debit === 'C'
                  ? item.sender ? t(`transaction_list.title.1_1`, { name: `${item.sender?.first_name}${item.sender?.last_name ? '*' : ''}` }) : t('transaction_base_detail.1')
                  : item.receiver ? t(`transaction_list.title.1`, { name: `${item.receiver?.first_name}${item.receiver?.last_name ? '*' : ''}` }) : t('transaction_base_detail.1')
                )
              }
              {
                item.type === 4 && (t(`transaction_base_detail.${item.associated_activity.type}`) + t(`transaction_base_detail.fee`))
              }
              {item.status === 2010 && (item.external_account.name || t(`transaction_list.title.${item.type}`))}
              {
                item.status !== 2010 && item.type === 2 && (showTitleWithAmount ? <Trans i18nKey={t(`transaction_list.title.${item.status}`)} values={{
                  amount: [2004, 2005, 2006, 2012, 2013].includes(item.status) ? formatNumberToUSD(`$${handleDot(item.amount_detail?.total)}`) : formatNumberToUSD(`$${handleDot(item.amount)}`)
                }} /> :
                  <span dangerouslySetInnerHTML={{ __html: t(`transaction_list.title.${item.type}`, { merchant: item.merchant?.name, name: `${item.sender?.first_name}${item.sender?.last_name ? '*' : ''}` }) }} />
                )
              }
              {
                item.type === 3 && ([3001, 3002].includes(item.status) ? t(`transaction_base_detail.${item.status}`) :
                  <span dangerouslySetInnerHTML={{ __html: t(`transaction_list.title.${item.type}`, { merchant: item.merchant?.name, name: `${item.sender?.first_name}${item.sender?.last_name ? '*' : ''}` }) }} />
                )
              }
              {
                item.type === 9 && <span dangerouslySetInnerHTML={{ __html: t(`transaction_list.title.${item.status}`, { brandName: `${item.associated_order?.card_name}` }) }} />
              }
              {
                item.type === 10 && t(`transaction_list.title.${item.status}.${item.product_code}.${item.credit_or_debit}`)
              }
              {
                !operateHistoryStatus && <span dangerouslySetInnerHTML={{ __html: t(`transaction_list.title.${item.status}`, { merchant: item.merchant?.name, name: `${item.sender?.first_name}${item.sender?.last_name ? '*' : ''}` }) }} />
              }
            </span>

            {[5002, 5009].includes(item.status) && <img src={iconSwitch} alt="" style={item.status === 5002 ? { opacity: 0.4 } : {}} onClick={(e) => {
              e.stopPropagation()
              setCode(item.code)
              if (item.status === 5009) {
                dispatch(setSwitchRmbPop(true))
              }

              if (item.status === 5002) {
                dispatch(setSwitchRmbUnabledPop(true))
              }
            }} />}
            {
              [2004, 2005, 2007, 2008, 2011, 2012, 2013].includes(item.status) && <div className={["instant-tip", item.status === 2011 ? "back" : ""].join(" ")}>
                <img src={[2007, 2008, 2012, 2013].includes(item.status) ? instantIcon : [2004, 2005].includes(item.status) ? waitingIcon : backIcon} alt="" />
                <span className="instant-text" >{t(`transaction_list.instant_tip.${item.status}`)}</span>
              </div>
            }
          </div>
          {
            item.type === 4
              ? <div className='right' style={amountTextStyle}>
                {Number(item.fee) === 0 ? <span className='fee'>- ${handleDot(item.origin_fee)}</span> : <span className='origin-fee'>- ${handleDot(item.origin_fee)}</span>}
                {Number(item.fee) === 0 && <span className='fee-tips'>{t('transaction_list.fee')}</span>}
              </div>
              :
              <div className='right' style={showGreyAmount ? {
                color: '#bdbdbd'
              } : {}}>
                {showStatusTips && <span className='status-tips'>{t(`transaction_list.waiting.${item.status}`)}</span>}
                <span style={amountPriceStyle}>{symbol} ${handleDot(formatNumberToUSD(item.amount))}</span>
              </div>
          }
        </div>
        <div className='bottom'>
          <div className='left'>{dayjs(item.system_timestamp).format('MM-DD HH:mm')}</div>
          {isAllList && isShowBalance ? <div className='right'>
            {t(`transaction_list.${item.product_code}_balance`, { amount: isShowSavingsAccountBalance ? `$${handleDot(formatNumberToUSD(item.balance))}` : `******` })}
          </div> : <div className='right' style={transactionTipsStyle}>
            {[6014, 8011].includes(item.status) && <span style={{ color: "#FF3841" }}>{t(`transaction_list.tips.${item.status}`)}</span>}
          </div>}
        </div>
      </div>
    </div>
  </List.Item>
}

export default function TransactionListItem(props) {
  const { transactionListData, t, isShowSavingsAccountBalance, isShowBalance = true } = props
  const history = useHistory()
  const [code, setCode] = useState(0)
  const switchRmbPop = useSelector(state => state.rmb.switch_rmb_pop)
  const switchRmbUnabledPop = useSelector(state => state.rmb.switch_rmb_unabled_pop)
  const isAllList = useSelector(state => state.transaction.isAllList)

  return (
    <div>
      <List>
        {
          transactionListData.map((item, index) => (
            <ListItem
              isShowBalance={isShowBalance} isShowSavingsAccountBalance={isShowSavingsAccountBalance}
              isAllList={isAllList} item={item} key={item.code} t={t} code={code} setCode={setCode} history={history}
            />
          )
          )
        }
      </List>
      {
        switchRmbPop && <CommonPop code={code} />
      }
      {
        switchRmbUnabledPop && <CommonPop code={code} />
      }

    </div>
  )
}
