import {
  SET_SAVING_ACCOUNT_INFO,
  SET_SAVING_ACCOUNT_TOKEN,
  SET_REFERRAL_CODE_FROM,
  SET_AUTO_ENTERIN_SAVING_ACCOUNT
} from './ActionTypes'

export const setSavingAccountInfo = (savingAccountInfo) => {
  sessionStorage.setItem('savingAccountInfo', JSON.stringify(savingAccountInfo))
  return {
    type: SET_SAVING_ACCOUNT_INFO,
    savingAccountInfo
  }
}

export const setSavingAccountToken = (savingAccountToken) => {
    sessionStorage.setItem('savingAccountToken', savingAccountToken)
    return {
      type: SET_SAVING_ACCOUNT_TOKEN,
      savingAccountToken
    }
}

export const setReferralCodeFrom = (referralCodeFrom) => {
  localStorage.setItem('referralCodeFrom', referralCodeFrom)
  return {
    type: SET_REFERRAL_CODE_FROM,
    referralCodeFrom
  }
}

export const setAutoEnterIn = (autoEnterIn) => {
  return {
    type: SET_AUTO_ENTERIN_SAVING_ACCOUNT,
    autoEnterIn: autoEnterIn
  }
}