import React, { useEffect, useState } from 'react'
import { getPolicyByCode } from '@api/login'
import SvgIcon from '@comps/SvgIcon'
import './index.less'
import { useHistory, useLocation } from 'react-router-dom'

export default function Policy() {
  const [content, setContent] = useState('')
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    async function fetchData() {
      const { response_data: {  policy }, status_code } = await getPolicyByCode(location.query?.code || 'terms_and_conditions')
      if (status_code === 100000) {
        setContent(policy)
      }
    }
    fetchData()
  }, [])

  const goBack = () => {
    const { from } = location.query

    if (from === 'relateStart') {
      window.history.back()
    } else if (from === 'payment') {
      const { pathname, search, currentValue } = location.query
      history.replace({
        pathname,
        search,
        currentValue
      })
    } else if (from === '/gcConfirm') {
      const { isSelectedAgreement } = location.query
      history.replace({
        pathname: '/gcConfirm',
        isSelectedAgreement
      })
    } else if (from === 'gcResult') {
      const { pathname, search } = location.query
      history.replace({
        pathname,
        search,
        from: 'nonConfirm'
      })
    } else if (from === 'mine/policy') {
      history.replace({
        pathname: '/mine/policy'
      })
    } else if (from === '/gcRefund') {
      const { from, search } = location.query
      history.replace({
        pathname: from,
        search,
        from: 'nonConfirm'
      })
    } else {
      history.replace({
        pathname: '/login',
        from: location.pathname
      })
    }
  }

  return (
    <div className="policy">
      <div className="close" onClick={goBack}>
        <SvgIcon iconClass="close" />
      </div>
      <div dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  )
}
