import { NavBar, Toast, Steps, Card, Dialog, Mask, Swiper, Ellipsis } from 'antd-mobile'
import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import fail from '@assets/images/rmb/order/fail.png'
import success from '@assets/images/rmb/order/success.png'
import cs from "@assets/images/rmb/order/cs.png"
import './index.less'
import { timers, handleDot, contactService, scrollToTop } from '@utils/utils'
import { useHistory } from 'react-router-dom'
import { CheckCircleOutline, CloseCircleOutline, ClockCircleFill } from 'antd-mobile-icons'
import { geTransationDetailByCode, initTransactionDetailAction } from '@actions/transaction'
import CopyToClipboard from 'react-copy-to-clipboard'
import avatarIcon from "@assets/images/mine/3.png"
import tillstarLoading from '@assets/images/common/tillstar_loading.gif'
import dayjs from 'dayjs'
import bank from '@assets/images/associatedBank/bank.png'
import lighting from '@assets/images/recharge/lighting.png'
import waiting from '@assets/images/recharge/waiting.png'
import balanceIcon from "@assets/images/rmb/order/flow_us_enabled.png"
import { formatNumberToUSD } from '@utils'

import { getPermissionByCode, submitUserBehavior } from '@api/user'
import VerifyTranPwd from '@pages/Home/VerifyTranPwd'
import { setActionToken, setPasswordRelatedChannel } from '@actions/password'
import FreezePasswordPop from '@comps/FreezePasswordPop'
import { getBannerContent, showHomeThreeGuide } from '@actions/rmb'


const BaseContent = createContext()
const { Step } = Steps

const DepositSteps = () => {
  const {t, transactionDetail } = useContext(BaseContent)
  const { status, progress_time } = transactionDetail
  const progressStatus = {
    "2000": {
      current: 1,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "wait"
      },
    },
    "2001": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "finish"
      }
      
    },
    "2002": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "error"
      }
    },
    "2003": {
      current: 2,
      "status": {
        "1": "error",
        "2": "error",
        "3": "error"
      }
    },
    "2004": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "wait"
      }
    },
    "2005": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "finish"
      }
    },
    "2006": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "error"
      }
    },
    "2012": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "wait"
      }
    },
    "2013": {
      current: 2,
      "status": {
        "1": "finish",
        "2": "finish",
        "3": "finish"
      }
    },
    "3000": {
      current: 1,
      "status": {
        "1": "finish",
        "2": "finish"
      }
    },
    "3002": {
      current: 1,
      "status": {
        "1": "error",
        "2": "error"
      }
    }
  }

  const getIcon = (status) => {
    let iconSvg = undefined
    switch (status) {
      case 'error': 
        iconSvg = <CloseCircleOutline fontSize={18}/>
        break;
      case 'finish': 
        iconSvg = <CheckCircleOutline fontSize={18}/>
        break;
      case 'wait': 
        iconSvg = <ClockCircleFill  fontSize={18}/>
        break;
      default:
        break;
    }
    return iconSvg
  }
  return (
    <Steps
          direction='vertical'
          current={progressStatus[status].current}
          style={{
            '--adm-color-danger': '#FF3841',
          }}
        >
        {
          progress_time.map((item, index) => <Step
          key={index}
          status={progressStatus[status].status[index+1]}
          title={t(`transaction_base_detail.steps.${status}.${index+1}`)}
          description={dayjs(item).format('MM-DD HH:mm')}
          icon={getIcon(progressStatus[status].status[index+1])}
        />)
        }
    </Steps>
  )
}

// 充值 2
const DepositDetailHeaderFlow = (props)=> {
  const { showAutoTransfer } = props;
  const {t, transactionDetail} = useContext(BaseContent)
  const savingAccountToken = useSelector(state => state.savingAccount.savingAccountToken)
  const userInfo =  useSelector(state => state.user.userInfo)
  const [isPasswordFreeze, setIsPasswordFreeze] = useState(false)
  const verifyPwdHandler = useRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const [hotCards, setHotCards] = useState([])

  let statusStyle={}
  if([2002, 2003, 2006, 2009, 2011].includes(transactionDetail.status)) {
    statusStyle = {
      color: "#FF3841",
      backgroundColor: '#FFF3F4'
    }
  } else if (transactionDetail.status === 2000) {
    statusStyle = {
      color: "#999999",
      backgroundColor: '#FAFAFA'
    }
  }
  const external_account = transactionDetail.type === 4 ? transactionDetail.associated_activity.external_account : transactionDetail.external_account

  const closeVerifyPwd = () => {
    verifyPwdHandler.current.close()
    verifyPwdHandler.current = undefined
    history.push('/savingAccount')
  }

  const forgetPassword = () => {
    verifyPwdHandler.current.close()
    dispatch(setPasswordRelatedChannel({
      channel: 'savingAccount',
      type: 'reset',
      url: '/savingAccount',
      backUrl: '/savingAccount',
    }))
    history.push('/resetTransactionPasswordA')
  }

  const setCurrentActionToken = (token) => {
    dispatch(setActionToken(token))
  }

  const verifyPwd = () => {
    if (userInfo.is_transaction_pin_set) {
      verifyPwdHandler.current = Dialog.show({
          closeOnMaskClick: true,
          className: 'verifyPWDDialog',
          content: <VerifyTranPwd
          actionType="checkSavingAccount"
          setPasswordActionToken={setCurrentActionToken}
          forgetPassword={forgetPassword}
            closeVerifyTranPwd={closeVerifyPwd}
          openFreezePasswordPop={() => {
              verifyPwdHandler.current.close()
              setIsPasswordFreeze(true)
          }}/>,
      })
    } else {
      Toast.show({
        content: t("realAuthentication.settheradingpassword"),
        duration: 5000,
      })
      setTimeout(() => {
        dispatch(setPasswordRelatedChannel({
          channel: 'savingAccount',
          type: 'set',
          url: '/',
          backUrl: '/',
        }))
        history.push('/verifyCode')
      }, 2000)
    }
  }

  const enterSavingAccount = async () => {
    const res = await getPermissionByCode({
      permissionCode: 'permission_savings_account'
    })

    if (res.status_code === 300008) return
    if (savingAccountToken) {
      history.push('/savingAccount')
    } else {
      verifyPwd({
        type: 'savingAccount'
      })
    }
  }

  const bannerContentClicked = (item) => {
    if(item.page_url === null) return;
    if( item.page_url.indexOf('wwww') > -1 || item.page_url.indexOf('http') > -1) {
      history.replace(item.page_url)
    } else {
      history.push(item.page_url)
    }
  }

  const getBanner = async () => {
    if(userInfo?.is_attempt_bound_wallet && transactionDetail?.type === 2) {
      return;
    }
    const res = await getBannerContent('activity_detail_page_middle')
      if (res.status_code === 100000) {
        setHotCards(res.response_data.banner_list)
      }
  }

  useEffect(() => {
    getBanner()
  }, [])

  return (
    <div>
      <div className='deposit-header-flow'>
        <div>
          <img src={external_account?.institution?.logo || bank} alt='' /> 
        </div>
        <div className='name'>{external_account?.institution?.name }</div>
        <div className={['amount', (Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? 'free-amount' : ''].join(' ')} style={{color: "#161719"}}>${(Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? handleDot(transactionDetail.origin_fee) : [2004, 2005, 2006, 2012, 2013].includes(transactionDetail.status) ? handleDot(formatNumberToUSD(transactionDetail.amount_detail?.total)) : handleDot(formatNumberToUSD(transactionDetail.amount))}</div>
        { Number(transactionDetail.fee) === 0 && transactionDetail.type === 4 && transactionDetail.associated_activity.type === 2 && <div className="fee-tip">
          <Trans i18nKey={t('transaction_base_detail.deposit_fee_tip')} values={{limit: transactionDetail.fee_setting.free_limit}}> {t('transaction_base_detail.deposit_fee_tip')}</Trans>
        </div>}
        { !transactionDetail.associated_activity && <div ><span className='status' style={statusStyle}>{t(`transaction_base_detail.tips.${transactionDetail.status}`)}</span> </div>}

        {showAutoTransfer && <div className="autoEnterIn">
          <span>{ t("transactionDetail.text1") }</span>
          <span className='goView' onClick={() => enterSavingAccount()}>{ t("transactionDetail.text2") }</span>
        </div>}
        <Mask visible={isPasswordFreeze}>
            <FreezePasswordPop closeFreezePop={() => {
              setIsPasswordFreeze(false)
              history.push('/')
            }}/>
        </Mask>

      </div>

      {!userInfo?.is_attempt_bound_wallet && transactionDetail?.type === 2 && 
            <div className="center-banner" >
              <Swiper indicator={ ()=>null}>
                { 
                  hotCards.map((item, index)=>(
                      <Swiper.Item key={index}>
                        <div onClick={()=> bannerContentClicked(item)} key={index} >
                            <img src={item.material_link}  alt=''/>
                        </div>
                    </Swiper.Item>
                    
                  ))
                }
              </Swiper>
            </div>
          }
    </div>

  )
}

// 转账 1
const TransferDetailHeaderFlow = ()=> {
  const {t, transactionDetail} = useContext(BaseContent)
  let name = ''
  let avatar = ''
  if(transactionDetail.type === 4) {
    name = transactionDetail.credit_or_debit === 'D'
    ? `${transactionDetail.associated_activity.receiver?.first_name}${transactionDetail.associated_activity.receiver?.last_name ? '*' : ''}`
    : `${transactionDetail.associated_activity.sender?.first_name}${transactionDetail.associated_activity.sender?.last_name ? '*' : ''}`
    avatar = transactionDetail.credit_or_debit === 'D' ? transactionDetail.associated_activity.receiver?.avatar : transactionDetail.associated_activity.sender?.avatar
  } else {
    name = transactionDetail.credit_or_debit === 'D'
    ? `${transactionDetail.receiver?.first_name}${transactionDetail.receiver?.last_name ? '*' : ''}`
    : `${transactionDetail.sender?.first_name}${transactionDetail.sender?.last_name ? '*' : ''}`
    avatar = transactionDetail.credit_or_debit === 'D' ? transactionDetail.receiver?.avatar : transactionDetail.sender?.avatar
  }

  return (
    <div className='transfer-header-flow'>
      <div>
        <img src={ [10001, 1000, 12001, 12002].includes(transactionDetail.status) ? success: fail} alt='' /> 
      </div>
      {
        [10001, 1000].includes(transactionDetail.status) ?  
        (transactionDetail.status === 1000 ? <div className='success-transfer'>
            <img src={avatar || avatarIcon} alt=''/>
            <span><Trans i18nKey={transactionDetail.credit_or_debit === 'D' ? t('transaction_base_detail.transfer_d') : t('transaction_base_detail.transfer_c')} values={{name: name}}> {transactionDetail.credit_or_debit === 'D' ? t('transaction_base_detail.transfer_d') : t('transaction_base_detail.transfer_c')}</Trans></span>
          </div> : transactionDetail.product_code === 'tillstar_virtual_debit_card' && <div className='success-transfer'>{t(`transaction_list.title.${transactionDetail.status}.${transactionDetail.product_code}.${transactionDetail.credit_or_debit}`)}</div>)
        :  transactionDetail.type !== 12 ? <div className='fail-transfer'>
            <img src={avatar || avatarIcon} alt=''/>
            <span><Trans i18nKey={t('transaction_base_detail.transfer_d_fail')} values={{name: name}}> { t('transaction_base_detail.transfer_d_fail')}</Trans></span>
          </div> : <div className='fail-transfer'>
            <span><Trans i18nKey={t(`transaction_base_detail.tips.${transactionDetail.status}`)} /></span>
          </div>
      }
      { transactionDetail.type === 1 && transactionDetail.status === 1001 && <div className="error-tip" dangerouslySetInnerHTML={{__html: t(`transaction_base_detail.failed_reason.${transactionDetail.failed_reason.code}`)}}></div>}
      <div className={['amount', (Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? 'free-amount' : ''].join(' ')} style={transactionDetail.status === 1001 ? {color: "#FF3841"} : {color: "#161719"}}>${(Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? handleDot(formatNumberToUSD(transactionDetail.origin_fee)) : handleDot(formatNumberToUSD(transactionDetail.amount))}</div>
      { transactionDetail.type === 1 && transactionDetail.status === 1000 && <div className="tip">{t('transaction_base_detail.transfer_success')}</div>}
      { transactionDetail.type === 12 && <div className="tip">{t(`transaction_base_detail.statement.${transactionDetail.status }`)}</div>}
      { transactionDetail.type === 10 && transactionDetail.status === 10001 &&  
      (transactionDetail.product_code === 'tillstar_virtual_debit_card' ? 
        <div className="tip">
          {t(`saving_account.transfer_result.${transactionDetail.credit_or_debit === 'D'? 'out' : 'in'}.title`)}
        </div>  : <div className="savings-tip">
            {t(`saving_account.transfer_result.${transactionDetail.credit_or_debit}.tip`)}
        </div> )
      }
      { Number(transactionDetail.fee) === 0 && transactionDetail.type === 4 && transactionDetail.associated_activity.type === 1 && <div className="fee-tip">
        <Trans i18nKey={t('transaction_base_detail.transfer_fee_tip')} values={{limit: transactionDetail.fee_setting.free_limit}}> {t('transaction_base_detail.transfer_fee_tip')}</Trans>
      </div>}
    </div>
  )
}

// 提现 3
const WithdrawDetailHeaderFlow = ()=> {
  const {t, transactionDetail} = useContext(BaseContent)
  let statusStyle = { }
  if(![3000, 3003].includes(transactionDetail.status)) {
    statusStyle = {
      color: "#FF3841",
      backgroundColor: '#FFF3F4'
    }
  }
  const external_account = transactionDetail.type === 4 ? transactionDetail.associated_activity.external_account : transactionDetail.external_account
  
  return (
    <div className='deposit-header-flow'>
      <div>
        <img src={external_account?.institution?.logo || bank } alt='' /> 
      </div>
      <div className='name'>{ external_account?.institution?.name }</div>
      <div className={['amount', (Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? 'free-amount' : ''].join(' ')} style={{color: "#161719"}}>${(Number(transactionDetail.fee) === 0 && transactionDetail.type === 4) ? handleDot(formatNumberToUSD(transactionDetail.origin_fee)) : handleDot(formatNumberToUSD(transactionDetail.amount))}</div>
      { !transactionDetail.associated_activity && <div ><span className='status' style={statusStyle}>{ t(`transaction_base_detail.tips.${transactionDetail.status}`)}</span> </div>}
      { Number(transactionDetail.fee) === 0 && transactionDetail.type === 4 && transactionDetail.associated_activity.type === 2 && <div className="fee-tip">
        <Trans i18nKey={t('transaction_base_detail.deposit_fee_tip')} values={{limit: transactionDetail.fee_setting.free_limit}}> {t('transaction_base_detail.deposit_fee_tip')}</Trans>
      </div>}
      { Number(transactionDetail.fee) === 0 && transactionDetail.type === 4 && transactionDetail.associated_activity.type === 3 && <div className="fee-tip">
        <Trans i18nKey={t('transaction_base_detail.withdraw_fee_tip')} values={{limit: transactionDetail.fee_setting.free_limit}}> {t('transaction_base_detail.withdraw_fee_tip')}</Trans>
      </div>}
    </div>
  )
}

// 奖励点兑现
const RedeemDetailHeaderFlow = ()=> {
  const { t, transactionDetail } = useContext(BaseContent)
  
  return (
    <div className='deposit-header-flow'>
      <img src={success} alt=''/> 
      <div className='amount'>${handleDot(formatNumberToUSD(transactionDetail.amount))}</div>
      <div className='tip'>{t(`transaction_list.title.${transactionDetail.status}`)}</div>
    </div>
  )
}

const TransactionInfo = ()=> { 
  const {t, transactionDetail} = useContext(BaseContent)
  const system_timestamp = transactionDetail.system_timestamp ? timers(new Date(transactionDetail.system_timestamp)) : ""
  const isShowProcessingStatus = [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2012, 2013, 3000, 3002].includes(transactionDetail.status)
  const isShowInstantInfo = [2004, 2012, 2005, 2006, 2013].includes(transactionDetail.status)
  const isShowInstantItem = [2004, 2005, 2006, 2007, 2008, 2009, 2012, 2013].includes(transactionDetail.status)
  
  return (
    <div className='transaction-info'> 
      { isShowInstantInfo && <Card className="instant-info" title={t(`transaction_base_detail.instant_info.title`)}>
        <div className="deposit instant-deposit">
          <div className="deposit-header">
            <img className="lighting" src={lighting} alt="" />
            <span className="label">{t(`transaction_base_detail.instant_info.instant_title`)}</span>
            <span className={["tip", transactionDetail.status === 2006 ? "error" : ""].join(" ")}>{t(`transaction_base_detail.instant_info.tip.${transactionDetail.status}.instant`)}</span>
          </div>
          <div className="deposit-amount">${handleDot(transactionDetail.amount_detail.instant_arrival)}</div>
        </div>
        <div className="deposit standard-deposit">
          <div className="deposit-header">
            <img className="waiting" src={waiting} alt="" style={transactionDetail.status === 2006 ? {opacity: 0.4}: {}}/>
            <span className="label" style={transactionDetail.status === 2006 ? {opacity: 0.4}: {}}>{t(`transaction_base_detail.instant_info.standard_title`)}</span>
            <span className={["tip", transactionDetail.status === 2006 ? "error" : "",  [2004, 2012].includes(transactionDetail.status) ? "warning" : ""].join(" ")}>{t(`transaction_base_detail.instant_info.tip.${transactionDetail.status}.standard`)}</span>
          </div>
          <div className="deposit-amount" style={transactionDetail.status === 2006 ? {opacity: 0.4}: {}}>${handleDot(transactionDetail.amount_detail.standard_arrival)}</div>
        </div>
      </Card>}
      {transactionDetail.type === 2 
      && 
      <>
        <div className='item'>
          <div className='key'>{t('transaction_base_detail.deposit_bank')}</div>
          <div className='value'>{ transactionDetail.external_account?.name } { transactionDetail.external_account?.number }</div>
        </div>
        {
      isShowProcessingStatus && 
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.deposit_process')}</div>
            <div className='value'><DepositSteps/></div>
          </div>
        }
        <div className='item'>
          <div className='key'>{[2009, 2011].includes(transactionDetail.status) ? t('transaction_base_detail.return_amount') : t('transaction_base_detail.deposit_amount')}</div>
          <div className='value'>${[2004, 2005, 2006, 2012, 2013].includes(transactionDetail.status) ? formatNumberToUSD(handleDot(transactionDetail.amount_detail?.total)) : formatNumberToUSD(handleDot(transactionDetail.amount))}</div>
        </div>
        {isShowInstantItem && <div className='item'>
          <div className='key'>{t('transaction_base_detail.instant_amount')}</div>
          <div className='value'>${isShowInstantInfo ? formatNumberToUSD(handleDot(transactionDetail.amount_detail.instant_arrival)) : formatNumberToUSD(handleDot(transactionDetail.amount))}</div>
        </div>}

      {![2003, 2010, 2011].includes(transactionDetail.status) &&
        <div className='item'>
          <div className='key'>{t('transaction_base_detail.deposit_fee')}</div>
          <div className='value'>
            { Number(transactionDetail.fee) === 0 ?
              <span className='fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
              :  <span className='origin-fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
            }
            { Number(transactionDetail.fee) === 0 &&
              <span className='fee-tips'>
                {t('transaction_list.fee')} 
              </span>
            }
          </div>
        </div>
      }
      </>
      }
      {transactionDetail.type === 3 
      && 
      <>
        <div className='item'>
          <div className='key'>{t('transaction_base_detail.withdraw_account')}</div>
          <div className='value'>{ transactionDetail.external_account?.name } { transactionDetail.external_account?.number }</div>
        </div>
        {
      isShowProcessingStatus && 
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.deposit_process')}</div>
            <div className='value'><DepositSteps/></div>
          </div>
        }
        <div className='item'>
          <div className='key'>{t('transaction_base_detail.withdraw_amount')}</div>
          <div className='value'>{formatNumberToUSD(`$${handleDot(transactionDetail.amount)}`)}</div>
        </div>
        {
          ![3002, 3003].includes(transactionDetail.status) && <div className='item'>
          <div className='key'>{t('transaction_base_detail.withdraw_fee')}</div>
          <div className='value'>
            { Number(transactionDetail.fee) === 0 ?
              <span className='fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
              :  <span className='origin-fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
            }
            { Number(transactionDetail.fee) === 0 &&
              <span className='fee-tips'>
                {t('transaction_list.fee')} 
              </span>
            }
          </div>
        </div>
        }
        
      </>
      }

      {
        (transactionDetail.type === 1 && transactionDetail.status === 1000 && transactionDetail.credit_or_debit === 'D') &&   
        <>
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.transfer_method')}</div>
            <div className='value'>{t('transaction_base_detail.transfer_content')}</div>
          </div>
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.transfer_fee')}</div>
            <div className='value'>            
            { Number(transactionDetail.fee) === 0 ?
              <span className='fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
              :  <span className='origin-fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
            }
            { Number(transactionDetail.fee) === 0 &&
              <span className='fee-tips'>
                {t('transaction_list.fee')} 
              </span>
            }</div>
          </div>
        </>
      }
      {
         transactionDetail.type === 4 && 
         <>
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.category')}</div>
            <div className='value'>{t(`transaction_base_detail.${transactionDetail.associated_activity.type}`)}{t('transaction_base_detail.fee')}</div>
          </div>
          <div className='item'>
            <div className='key'>{t(`transaction_base_detail.${transactionDetail.associated_activity.type}`)}{t('transaction_base_detail.fee')}</div>
            <div className='value'>            
            { Number(transactionDetail.fee) === 0 ?
              <span className='fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
              :  <span className='origin-fee'>
                ${handleDot(transactionDetail.origin_fee)} 
              </span>
            }
            { Number(transactionDetail.fee) === 0 && transactionDetail.type !== 4 &&
              <span className='fee-tips'>
                {t('transaction_list.fee')} 
              </span>
            }</div>
          </div>
        </>
        
      }
      {transactionDetail.status === 10001 && (['tillstar_virtual_debit_card', 'tillstar_debit_card'].includes(transactionDetail.product_code) ? <div className='item'>
          <div className='key'>{t(`transaction_base_detail.saving_account_${transactionDetail.credit_or_debit}.type`)}</div>
          <div className='value'>
          {transactionDetail.credit_or_debit === 'D' && <img className="balance-icon" src={balanceIcon} alt="" />}
            {t(`transaction_base_detail.saving_account_${transactionDetail.credit_or_debit}.source`)}
          </div>
        </div>: <div className='item'>
          <div className='key'>{t(`transaction_base_detail.${transactionDetail.product_code}_${transactionDetail.credit_or_debit}.type`)}</div>
          <div className='value'>
          {transactionDetail.credit_or_debit === 'C' && <img className="balance-icon" src={balanceIcon} alt="" />}
            {t(`transaction_base_detail.${transactionDetail.product_code}_${transactionDetail.credit_or_debit}.source`)}
          </div>
        </div>)}
      {
        transactionDetail.type === 13  && 
        <>
          <div className='item'>
            <div className='key'>{t('saving_account.reward.redeem.result.point')}</div>
            <div className='value'>{
                transactionDetail.status === 13002 
                ? t('saving_account.reward.spending_rewards.title')
                : t('saving_account.reward.deposit_rewards.title')
            }</div>
          </div>
          <div className='item'>
              <div className='key'>{t('saving_account.reward.redeem.result.redeem_amount')}</div>
              <div className='value'>{formatNumberToUSD(transactionDetail.redeem_points)}</div>
          </div>
          <div className='item'>
              <div className='key'>{t('saving_account.reward.redeem.result.remained_amount')}</div>
              <div className='value'>{formatNumberToUSD(transactionDetail.remained_points)}</div>
          </div>
        </>
      }
      <div className='item'>
        <div className='key'>{t('transaction_base_detail.created_time')}</div>
        <div className='value'>{system_timestamp}</div>
      </div>
      <div className='item'>
        <div className='key'>{t('transaction_base_detail.code')}</div>
        <CopyToClipboard text={transactionDetail.code} onCopy={()=>copy(t)}>
          <label className='value'>{transactionDetail.code}<span className='code-copy'>{t('common.copy')}</span></label>
        </CopyToClipboard>
      </div>
      {
        (transactionDetail.type === 1 && transactionDetail.message) &&   
          <div className='item'>
            <div className='key'>{t('transaction_base_detail.transfer_message')}</div>
            <div className='value'>
              <Ellipsis
                direction='end'
                className='value'
                content={transactionDetail.message}
                expandText={t("common.unfold")}
                collapseText={t("common.packUp")}
              />
            </div>
          </div>
      }
    </div>
  )
}
const copy = (t)=>{
  Toast.show({
    content: t('common.copy_tip'),
    duration: 1000,
  })
}

export default function TransactionListDetail() {
  const {t} = useTranslation()
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const dispatch = useDispatch()
  const [displayLoadingMask, setDisplayLoadingMask] = useState(true)
  const transactionDetail = useSelector(state => state.transaction.transaction_detail)
  const [showAutoTransfer, setShowAutoTransfer] = useState(false)
  const selectedLang = useSelector(state => state.user.selectedLang)
  
  const init = async () => {
    const res = await geTransationDetailByCode({activityCode: searchParams.get('code')}, 'check')
    setDisplayLoadingMask(false)
    if (res.status_code === 100000) {
      dispatch(initTransactionDetailAction(res.response_data.detail))

      const { is_auto_transfer } = res.response_data.detail;
      setShowAutoTransfer(is_auto_transfer);
    }

  }

  useEffect(()=> {
    scrollToTop()

    init()

    return () => {
      dispatch(initTransactionDetailAction({}))
    }
  },[])

  const backToHome = () => {
    submitUserBehavior('saving_back_home')
    // 记录是否显示引导
    dispatch(showHomeThreeGuide(true))
  }

  return (
    <div id='transaction-list-detail'>
      <BaseContent.Provider value={{t, transactionDetail}}>
        <div>
          <NavBar onBack={()=> {
            if(window.history.length <= 1) {
              window.location.href = "/"
              return
            }
            window.history.back(-1)

            // if (transferOrigin) {
            //   history.push(transferOrigin)
            // } else {
            //   window.history.back(-1)
            // } 
          }}>{t('transaction_base_detail.nav_bar')}</NavBar>
        </div>
        { displayLoadingMask && <div><img className='tillstar-loading-icon' src={tillstarLoading} alt="" /></div>}
        <div className='transaction-content'> 
          {
            (transactionDetail.type === 2 || (transactionDetail.type === 4 && transactionDetail.associated_activity.type === 2)) && <DepositDetailHeaderFlow showAutoTransfer={showAutoTransfer} />
          }
          {
            ([1, 10, 12].includes(transactionDetail.type) || (transactionDetail.type === 4 && transactionDetail.associated_activity.type === 1)) && <TransferDetailHeaderFlow />
          }
          {
           ( transactionDetail.type === 3 || (transactionDetail.type === 4 && transactionDetail.associated_activity.type === 3)) && <WithdrawDetailHeaderFlow />
          } 
          {
            [11, 13].includes(transactionDetail.type) && <RedeemDetailHeaderFlow />
          }

          <TransactionInfo/>
        </div>
        <div className="operater-order-action">
          <div className='cs' onClick={contactService}>
            <img src={cs} alt=''/> 
            <span>
              {t('transaction_base_detail.question')}
              {selectedLang === 'en_US' && <br/>}
              <span style={{ color: "#1F49DA" }}>{t('transaction_base_detail.contactUs')} </span> 
            </span>
          </div>
          {
             ![11, 13].includes(transactionDetail.type) && (([1, 2].includes(transactionDetail.type) && transactionDetail.status === 1001 )
             ? <div className='back-home' onClick={()=>{
                history.push('/recharge')
              }}>{t('transaction_base_detail.back_deposit')}</div>
          : <div className='back-home' onClick={()=>{
              // 记录充值结果返回首页
              transactionDetail.is_auto_transfer&& transactionDetail.type === 2 && backToHome()
              history.push('/')
            }}>{t('transactionDetail.back_btn')}</div>)}
        </div>
      </BaseContent.Provider>
    </div>
  )
}
